import React, { memo, useMemo } from 'react';
import {
  Box,
  Container,
  MediaQuery,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useContainerStyles } from '@/utils/style/useContainer';
import ProductCard from '@/components/Collection/ProductCard/ProductCard';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';
import { Carousel } from '@mantine/carousel';
import { IBlock, ILabel } from '@/@types/generated/contentful';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { ShopItem } from '@/@types/shopitem';
import HighlightCard from '@/components/Cards/HighlightCard';
import { formatCfItems } from '@/helpers/formatCfItems';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: 42,
    fontWeight: 500,
    [theme.fn.smallerThan('md')]: {
      fontSize: 28,
      fontWeight: 600,
    },
  },
}));

const useCarouselListIndicatorStyles = createStyles((theme) => ({
  controls: {
    height: '100%',
    display: 'flex',
    top: '0px !important',
    alignItems: 'center',
    padding: '0px 80px',
  },
  control: {
    padding: 6,
    borderRadius: '100%',
    background: theme.colors.gray[9],
  },
}));

const ListCarousel = ({ hits }: { hits: (ShopItem | any)[] }) => {
  const theme = useMantineTheme();

  const classnames = {
    ...useCarouselIndicatorsStyles({}).classes,
    ...useCarouselListIndicatorStyles().classes,
  };

  const props = {
    classNames: classnames,
    dragFree: false,
    loop: false,
    initialSlide: 3,
    withControls: true,
    withIndicators: false,
    nextControlIcon: (
      <NoozIcon
        size={28}
        color='white'
        iconKey='ChevronRight'
      />
    ),
    previousControlIcon: (
      <NoozIcon
        size={28}
        color='white'
        iconKey='ChevronLeft'
      />
    ),

    height: '100%',
  };
  const renderedHits = (() => {
    return hits?.map((hit, index) => {
      if (hit.defaultVariantId) {
        return (
          <Carousel.Slide key={hit.defaultVariantId}>
            <Box>
              <ProductCard
                position={index}
                item={hit}
              />
            </Box>
          </Carousel.Slide>
        );
      } else if (Array.isArray(hit)) {
        const props = formatCfItems({
          fields: {
            items: hit,
          },
        });
        return (
          <Carousel.Slide key={`marketing-item-${index}`}>
            <Box
              h={'98%'}
              w={'100%'}>
              <HighlightCard
                {...props}
                color={theme.colors.main[0]}
              />
            </Box>
          </Carousel.Slide>
        );
      }
      return null;
    });
  })();

  return hits && hits.length > 0 ? (
    <>
      <MediaQuery
        smallerThan={theme.breakpoints.lg}
        styles={{ display: 'none' }}>
        <span>
          <Carousel
            {...CAROUSEL_PROPS}
            {...props}
            slideGap={20}
            slideSize={'25%'}>
            {renderedHits}
          </Carousel>
        </span>
      </MediaQuery>
      <MediaQuery
        largerThan={theme.breakpoints.lg}
        styles={{ display: 'none' }}>
        <span>
          <MediaQuery
            smallerThan={theme.breakpoints.md}
            styles={{ display: 'none' }}>
            <span>
              <Carousel
                {...CAROUSEL_PROPS}
                {...props}
                slideGap={20}
                slideSize={'33%'}>
                {renderedHits}
              </Carousel>
            </span>
          </MediaQuery>
        </span>
      </MediaQuery>
      <MediaQuery
        largerThan={theme.breakpoints.md}
        styles={{ display: 'none' }}>
        <span>
          <Carousel
            {...CAROUSEL_PROPS}
            {...props}
            withControls={false}
            loop={false}
            dragFree={false}
            slideGap={10}
            slideSize={'80%'}>
            {renderedHits}
          </Carousel>
        </span>
      </MediaQuery>
    </>
  ) : null;
};

const ProductCardListCarousel = ({
  facets,
  label,
}: {
  facets: IBlock;
  label: ILabel;
}) => {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();
  const hits = useMemo<(ShopItem | any)[]>(() => {
    return (
      facets.fields.items
        ?.map((i) =>
          i.fields?.value
            ? // @ts-ignore
              facets.shopitems?.items?.find?.((s) => s.sku === i.fields?.value)
            : i.fields.items,
        )
        .filter((s) => !!s) || []
    );
  }, [facets]);

  return (
    <>
      <Container
        mb={{ base: 20, md: 40 }}
        className={containerStyles.classes.container}>
        <NoozText
          component='h3'
          m={0}
          className={classes.label}>
          {label?.fields.text}
        </NoozText>
      </Container>
      <ListCarousel hits={hits} />
    </>
  );
};

export default memo(ProductCardListCarousel);
