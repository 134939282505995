import useHeroDuoCompareClasses from '@/components/Hero/useHeroDuoCompareClasses';
import { Box, clsx, Container } from '@mantine/core';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import { useContainerStyles } from '@/utils/style/useContainer';
import { IBadge, IImage, ILabel } from '@/@types/generated/contentful';
import React from 'react';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import NoozText from '@/components/Nooz/NoozText/NoozText';

interface HeroDuoCompareProps {
  leftImage?: IImage;
  rightImage?: IImage;
  leftImageMobile?: IImage;
  rightImageMobile?: IImage;
  badgeLeft?: IBadge;
  badgeRight?: IBadge;
  title?: ILabel;
}

const HeroDuoCompare = ({
  leftImage,
  leftImageMobile,
  rightImage,
  rightImageMobile,
  badgeLeft,
  badgeRight,
  title,
}: HeroDuoCompareProps) => {
  const classes = useHeroDuoCompareClasses();

  const containerStyles = useContainerStyles();

  return (
    <>
      {title?.fields.text ? (
        <Container
          mt={80}
          className={containerStyles.classes.container}>
          <NoozText
            component='h3'
            m={0}
            className={clsx(classes.label)}>
            {title.fields?.text}
          </NoozText>
        </Container>
      ) : null}
      <Box className={clsx(containerStyles.classes.container)}>
        <Box className={clsx(classes.container)}>
          <ImgComparisonSlider className={classes.sliderWrapper}>
            <figure
              slot='first'
              className={clsx(classes.img, classes.before)}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                alt={''}
                className={clsx(classes.img, 'desktop')}
                src={leftImage?.fields.media?.fields.file.url + '?fm=webp'}
              />
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                alt={''}
                className={clsx(classes.img, 'mobile')}
                src={
                  leftImageMobile?.fields.media?.fields.file.url + '?fm=webp'
                }
              />
              {badgeLeft ? (
                <Box
                  className={clsx(classes.badge, 'text-badge')}
                  sx={{
                    background: badgeLeft.fields.color?.fields.hex,
                    color: badgeLeft.fields.label?.fields.color?.fields.hex,
                  }}>
                  {badgeLeft.fields.label?.fields.text}
                  {badgeLeft.fields.icon ? (
                    <NoozIcon
                      iconKey={badgeLeft.fields.icon.fields.iconKey}
                      strokeWidth={2}
                      color={
                        badgeLeft.fields.icon.fields.color?.fields.hex ||
                        badgeLeft.fields.label?.fields.color?.fields.hex
                      }
                    />
                  ) : null}
                </Box>
              ) : null}
            </figure>
            <figure
              slot='second'
              className={clsx(classes.img, classes.after)}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                alt={''}
                className={clsx(classes.img, 'desktop')}
                src={rightImage?.fields.media?.fields.file.url + '?fm=webp'}
              />
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                alt={''}
                className={clsx(classes.img, 'mobile')}
                src={
                  rightImageMobile?.fields.media?.fields.file.url + '?fm=webp'
                }
              />
              {badgeRight ? (
                <Box
                  className={clsx(classes.badge, 'text-badge')}
                  sx={{
                    background: badgeRight.fields.color?.fields.hex,
                    color: badgeRight.fields.label?.fields.color?.fields.hex,
                  }}>
                  {badgeRight.fields.label?.fields.text}
                  {badgeRight.fields.icon ? (
                    <NoozIcon
                      iconKey={badgeRight.fields.icon.fields.iconKey}
                      strokeWidth={2}
                      color={
                        badgeRight.fields.icon.fields.color?.fields.hex ||
                        badgeRight.fields.label?.fields.color?.fields.hex
                      }
                    />
                  ) : null}
                </Box>
              ) : null}
            </figure>
            <Box
              slot='handle'
              className={'handler'}
            />
          </ImgComparisonSlider>
        </Box>
      </Box>
    </>
  );
};

export default HeroDuoCompare;
