import React from 'react';
import { Image, ImageProps } from '@mantine/core';

const NoozImage = (props: ImageProps) => {
  return (
    <Image
      {...props}
      alt=''
    />
  );
};

export const ShopstoryNoozImage = (props: any) => {
  return props.image?.fields.media?.fields?.file.url ? (
    <NoozImage
      {...props.image}
      src={props.image?.fields.media?.fields?.file.url}
      // src={props.image?.fields.media?.fields?.file.url + '?fm=webp'}
      width='100%'
      height='100%'
    />
  ) : null;
};

export default NoozImage;
