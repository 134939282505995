import { Drawer, DrawerProps, useMantineTheme } from '@mantine/core';
import * as React from 'react';
import zIndexes from '../style/zIndex';
import { useCallback, useContext } from 'react';
import EmptyCartSideView, {
  useCartSideViewDrawerProps,
} from '@/components/Cart/EmptyCartSideView/EmptyCartSideView';
import CartDrawer from '@/components/Cart/CartDrawer/CartDrawer';
import {
  HeaderActiveTabContext,
  HeaderDrawerContext,
} from '@/components/Header/Header';
import { SearchDrawerContext } from '@/components/Search/SearchDrawer/SearchDrawer';
import useClasses from '@/components/Cart/CartDrawer/useClasses';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import useCartItems from '@/utils/hook/useCartItems';
import { TIMING_FUNCTION } from '@/utils/style/animation';
import { useCartStock } from '@/utils/hook/cart/useCartStock';
import { useMediaQuery } from '@mantine/hooks';

export interface DrawerProviderProps {
  children: React.ReactNode;
}

export interface IDrawer extends DrawerProps {
  opened: boolean;
  body: React.ReactNode;
  footer: React.ReactNode;
}

export type IDrawerContext = {
  drawer: IDrawer;
  setDrawer: (_: IDrawer) => void;
  resetDrawer: () => void;
};

export const drawerContextInitState: {
  drawer: IDrawer;
  setDrawer: () => void;
  resetDrawer: () => void;
} = {
  drawer: {
    opened: false,
    title: '',
    body: '',
    footer: '',
    padding: 'xl',
    size: 'xl',
    position: 'right',
    zIndex: zIndexes.drawer,
    withCloseButton: true,
    onClose: null as unknown as () => void,
  },
  setDrawer: () => {},
  resetDrawer: () => {},
};

export const DrawerContext = React.createContext<IDrawerContext>(
  drawerContextInitState,
);

const useDrawerContext = (): IDrawerContext => {
  return React.useContext(DrawerContext);
};

export const useToggleCartDrawer = (openOnly?: boolean) => {
  const { updateStock } = useCartStock();
  const headerHeight = useHeaderHeight();
  const classesCart = useClasses(headerHeight.wrapper);
  const { headerActiveTabState } = useContext(HeaderActiveTabContext);
  const [searchDrawer, setSearchDrawer] = useContext(SearchDrawerContext);
  const cartSideViewDrawerProps = useCartSideViewDrawerProps();
  const theme = useMantineTheme();
  const mediaQuery = theme.fn.smallerThan('md');
  const isDesktop = useMediaQuery(mediaQuery.split('@media ')[1]);

  const cartLineItems = useCartItems();

  const {
    headerDrawerState: [, setHeaderDrawer],
  } = useContext(HeaderDrawerContext);
  const { drawer, setDrawer } = useDrawerContext();

  const overlayColor =
    theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[5];

  return useCallback(() => {
    if (drawer.opened) {
      if (!openOnly) {
        // @ts-ignore
        setDrawer((prev) => ({
          ...prev,
          opened: false,
        }));
      }

      //@ts-ignore
      if (window.zE) {
        // @ts-ignore
        window.zE('messenger', 'show');
      }
      return;
    }

    if (!openOnly) {
      if (searchDrawer.opened)
        setSearchDrawer({
          ...drawerContextInitState.drawer,
        });
      setHeaderDrawer((prev) => {
        return {
          ...prev,
          opened: false,
        };
      });
      headerActiveTabState[1](null);
    }

    updateStock();

    //@ts-ignore
    if (window.zE) {
      // @ts-ignore
      window.zE('messenger', 'hide');
    }

    setDrawer({
      ...drawerContextInitState.drawer,
      ...cartSideViewDrawerProps,
      size: 750,
      withOverlay: !isDesktop,
      overlayProps: {
        color: overlayColor,
        blur: 6,
      },
      classNames: {
        inner: classesCart.drawerInnerFull,
        content: classesCart.drawerContentCart,
        header: classesCart.drawerHeader,
        body: classesCart.drawerBody,
      },
      withCloseButton: false,
      opened: true,

      transitionProps: {
        transition: 'slide-left',
        duration: 300,
        timingFunction: TIMING_FUNCTION,
      },
      scrollAreaComponent: Drawer.NativeScrollArea,
      trapFocus: false,

      body:
        cartLineItems.length === 0 && !openOnly ? (
          <EmptyCartSideView
            onClose={() => {
              // @ts-ignore
              setDrawer((prev) => ({
                ...prev,
                opened: false,
              }));
              //@ts-ignore
              if (window.zE) {
                // @ts-ignore
                window.zE('messenger', 'show');
              }
            }}
          />
        ) : (
          <CartDrawer
            onClose={() => {
              // @ts-ignore
              setDrawer((prev) => ({
                ...prev,
                opened: false,
              }));
              //@ts-ignore
              if (window.zE) {
                // @ts-ignore
                window.zE('messenger', 'show');
              }
            }}
          />
        ),
    });
  }, [
    searchDrawer,
    classesCart,
    headerActiveTabState,
    setSearchDrawer,
    cartSideViewDrawerProps,
    cartLineItems,
    setHeaderDrawer,
    setDrawer,
    overlayColor,
    updateStock,
  ]);
};

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [drawer, setDrawer] = React.useState<IDrawer>(
    drawerContextInitState.drawer,
  );

  const resetDrawer = () => {
    setDrawer({ ...drawerContextInitState.drawer, size: drawer.size });
  };

  return (
    <DrawerContext.Provider value={{ drawer, setDrawer, resetDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

export default useDrawerContext;
