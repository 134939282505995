//@ts-nocheck
import React from 'react';
import { createStyles } from '@mantine/core';
import { motion, AnimatePresence } from 'framer-motion';
import Link from '../../Link/Link';
import Image from 'next/image';

const useHeaderLogoStyles = createStyles((theme) => {
  return {
    wrapper: {
      position: 'absolute',
      left: 'calc(50% - 55px)',
      transform: 'translateX(calc(-50% + 55px))',
      width: '110px !important',

      [theme.fn.smallerThan('md')]: {
        width: '90px !important',
      },
    },
    image: {
      width: '90px !important',
      [theme.fn.smallerThan('md')]: {
        width: '90px !important',
      },
    },
  };
});

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: 'easeOut', duration: 0.5, delay: 0.3 },
};

const HeaderLogo = ({ isWhite }) => {
  const { classes } = useHeaderLogoStyles();

  return (
    <div className={classes.wrapper}>
      <Link href='/'>
        <AnimatePresence initial={false}>
          <motion.div
            {...motionProps}
            style={{ position: 'absolute', top: -15 }}
            key='22323'>
            <Image
              src={'/assets/logo-black.svg'}
              alt='Black logo'
              width={90}
              height={25}
              unoptimized
              priority
              className={classes.image}
            />
          </motion.div>
        </AnimatePresence>
      </Link>
    </div>
  );
};

export default HeaderLogo;
