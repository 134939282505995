import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import {
  Button,
  clsx,
  createStyles,
  Drawer,
  useMantineTheme,
} from '@mantine/core';
import { ButtonWrapperProps } from '@/interface.custom';
import { IButton } from '@/@types/generated/contentful';
import Link from '@/components/Link/Link';
import { functions } from '@/helpers/Functions';
import { motion } from 'framer-motion';
import zIndexes from '@/utils/style/zIndex';

const useStyles = createStyles({
  root: {
    transition: 'opacity 0.4s ease',
    '&:hover': {
      opacity: 0.7,
    },
    '&:focus': {
      opacity: 1,
    },
  },
  squared: {
    borderRadius: 0,
  },
  fullWidth: {
    width: '100%',
  },
});

const NoozButton = ({
  urlLink,
  href,
  squared,
  facets,
  squareRadius,
  target = '_blank',
  rel = 'noreferrer noopener',
  ...props
}: ButtonWrapperProps) => {
  const [mounted, setMount] = useState(false);
  const { classes } = useStyles();
  const [drawer, setDrawer] = useState<any>({
    opened: false,
    body: null,
  });

  const theme = useMantineTheme();

  useEffect(() => {
    setMount(true);
  }, []);

  const cfFunction = (e: any) => {
    if (props?.function?.fields.key) {
      functions[props.function.fields.key](e, {
        setDrawer,
      });
    }
  };

  const Component = useMemo(() => {
    if (urlLink) {
      return ({ children }: PropsWithChildren) => (
        <a
          href={urlLink}
          target={target}
          rel={rel}>
          {children}
        </a>
      );
    } else if (href) {
      return ({ children }: PropsWithChildren) => (
        <Link
          className={props.fullWidth ? classes.fullWidth : undefined}
          href={href}
          facet={facets}>
          {children}
        </Link>
      );
    }
    return React.Fragment;
  }, [urlLink, href, facets]);

  return mounted ? (
    <>
      <Component>
        <Button
          {...props}
          onClick={props.onClick || cfFunction}
          className={clsx(props.className, squared && classes.squared)}
          classNames={classes}
          radius={'xl'}>
          {props.children}
        </Button>
      </Component>

      <Drawer
        {...drawer}
        padding={'xl'}
        size={600}
        position={'right'}
        zIndex={zIndexes.drawer}
        withCloseButton={false}
        overlayProps={{
          color:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
        }}
        styles={{
          body: { height: '100%' },
          inner: { zIndex: 9999 },
        }}
        onClose={() => setDrawer((prev: any) => ({ ...prev, opened: false }))}>
        <motion.div
          {...{
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 },
            transition: { ease: 'easeOut', duration: 0.6, delay: 0.2 },
          }}
          style={{
            height: '100%',
          }}>
          {drawer.body}
        </motion.div>
      </Drawer>
    </>
  ) : null;
};

export const NoozShopstoryButton = ({ item }: { item: IButton; sx: any }) => {
  return (
    item.fields.slug?.fields.url && (
      <Link
        href={item.fields.slug?.fields.url}
        facet={item.fields.slug?.fields.facet}>
        {/*@ts-ignore*/}
        <NoozButton
          {...item.fields}
          color={item.fields.color?.fields?.classname}>
          {item.fields.label?.fields.text}
        </NoozButton>
      </Link>
    )
  );
};

export default NoozButton;
