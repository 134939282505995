import {
  Box,
  Container,
  Flex,
  Group,
  clsx,
  createStyles,
  useMantineTheme,
  rem,
  Stack,
} from '@mantine/core';
import React from 'react';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { Property } from 'csstype';
import Link from '@/components/Link/Link';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozButton from '../../Nooz/NoozButton/NoozButton';
import { IBlock, IButton, IImage, ILabel } from '@/@types/generated/contentful';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import useWindowHeight from '@/utils/hook/useWindowInnerHeight';

const useStyles = createStyles((theme) => {
  const headerHeight = useHeaderHeight();
  const windowHeight = useWindowHeight();
  return {
    container: {
      minHeight: rem(680),
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.fn.smallerThan('md')]: {
        height: `calc(${windowHeight} - ${headerHeight.wrapper}px - 80px)`,
      },
      [theme.fn.smallerThan('sm')]: {
        height: `unset`,
        minHeight: `unset`,
        flexDirection: 'column',
      },
    },
    leftImg: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '50%',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        height: '50%',
        width: '100%',
      },
    },
    rightImg: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      width: '50%',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        height: '50%',
        width: '100%',
        left: 0,
        top: 'unset',
        bottom: 0,
      },
    },
    label: {
      fontSize: 60,
      [theme.fn.smallerThan('md')]: {
        fontSize: 36,
      },
    },
    buttons: {
      [theme.fn.smallerThan('md')]: {
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
      },
    },
  };
});

interface HeroBannerProps {
  height?: string;
  labels: IBlock;
  labelsMobile: IBlock;
  buttons: IBlock;
  leftImage?: IImage;
  rightImage?: IImage;
  imgMobile?: IImage;
  vertical?: Property.AlignItems;
  horizontal?: Property.JustifyContent;
  txtPos: 'left' | 'right';
}

const HeroDuoBannerV2 = ({
  labels,
  labelsMobile,
  leftImage,
  rightImage,
  imgMobile,
  buttons,
  txtPos,
}: HeroBannerProps) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();

  return (
    <>
      <Box>
        <Container
          h={'100%'}
          className={clsx(containerStyles.classes.container, classes.container)}
          sx={(theme) => ({
            [theme.fn.smallerThan(theme.breakpoints.sm)]: {
              display: 'none',
            },
          })}>
          <Box
            className={classes.leftImg}
            sx={(theme) => ({
              background: `no-repeat center/cover url("${leftImage?.fields.media?.fields.file.url}")`,
            })}
          />
          <Box
            className={classes.rightImg}
            sx={(theme) => ({
              background: `no-repeat center/cover url("${rightImage?.fields.media?.fields.file.url}")`,
            })}
          />
          <Flex
            py={{
              base: '1.5rem',
              xs: '5.5rem',
              lg: 100,
              xl: 150,
            }}
            h={'100%'}
            direction={'column'}
            w={'50%'}
            gap={0}
            sx={{ position: 'absolute', [txtPos]: 0 }}
            align={'center'}>
            {(labels.fields.items as ILabel[])?.map(
              (label: ILabel, index: number) => {
                // @ts-ignore

                return (
                  <NoozText
                    m={0}
                    lh={1}
                    key={index}
                    sx={
                      label?.fields.size &&
                      !['base', ''].includes(label.fields.size)
                        ? {
                            fontSize: (() => {
                              try {
                                return parseInt(label?.fields.size);
                              } catch (e) {
                                return undefined;
                              }
                            })(),

                            [theme.fn.smallerThan('sm')]: {
                              fontSize: (() => {
                                try {
                                  return parseInt(label?.fields.size) === 50
                                    ? 28
                                    : undefined;
                                } catch (e) {
                                  return undefined;
                                }
                              })(),
                            },
                          }
                        : undefined
                    }
                    align={'center'}
                    italic={label?.fields.italic}
                    weight={label?.fields.weight || 500}
                    size={
                      label?.fields.size !== 'base'
                        ? label?.fields.size
                        : undefined
                    }
                    color={label.fields.color?.fields.classname || 'white'}
                    // @ts-ignore
                    family={label?.fields.family}
                    className={classes.label}
                    // @ts-ignore
                    dangerouslySetInnerHTML={{
                      __html: label?.fields.text,
                    }}></NoozText>
                );
              },
            )}
          </Flex>

          <Group
            className={classes.buttons}
            sx={{ position: 'absolute', bottom: 60 }}
            position={'center'}>
            {buttons.fields?.items?.map(
              // @ts-ignore
              (button: IButton, index: number) => {
                if (button.fields.urlLink) {
                  return (
                    // @ts-ignore
                    <NoozButton
                      key={index}
                      miw={260}
                      {...button.fields}
                      variant={button.fields.type || 'filled'}
                      color={button?.fields?.color?.fields?.classname || 'dark'}
                      squared={button.fields.squareRadius}
                      sx={
                        button?.fields?.label?.fields.color?.fields.classname
                          ? // @ts-ignore
                            (theme) => ({
                              color:
                                theme.colors[
                                  button?.fields.label?.fields.color?.fields
                                    .classname as string
                                ],
                            })
                          : null
                      }
                      size={button.fields.size || 'lg'}
                      urlLink={button.fields.urlLink}>
                      {button?.fields?.label?.fields.text}
                    </NoozButton>
                  );
                }
                if (typeof button.fields.slug?.fields.url === 'string')
                  // @ts-ignore
                  return (
                    <Link
                      key={index}
                      href={button.fields.slug?.fields.url as string}
                      facet={button.fields.slug?.fields.facet}>
                      {/* @ts-ignore */}
                      <NoozButton
                        miw={260}
                        key={index}
                        {...button.fields}
                        variant={button.fields.type || 'filled'}
                        color={
                          button?.fields?.color?.fields?.classname || 'dark'
                        }
                        squared={button.fields.squareRadius}
                        sx={
                          button?.fields?.label?.fields.color?.fields.classname
                            ? // @ts-ignore
                              (theme) => ({
                                color:
                                  theme.colors[
                                    button?.fields.label?.fields.color?.fields
                                      .classname as string
                                  ],
                              })
                            : null
                        }
                        size={button.fields.size || 'lg'}>
                        {button?.fields?.label?.fields.text}
                      </NoozButton>
                    </Link>
                  );
                return null;
              },
            )}
          </Group>
        </Container>

        {/* MOBILE */}
        <Container
          h={'100%'}
          className={clsx(containerStyles.classes.container, classes.container)}
          sx={(theme) => ({
            [theme.fn.largerThan(theme.breakpoints.sm)]: {
              display: 'none',
            },
          })}>
          <Flex
            py={'1.5rem'}
            h={'100%'}
            w={{ base: '100%', sm: '90%' }}
            align={'center'}>
            <Flex
              sx={{ gap: 24 }}
              direction={'column'}
              h={'auto'}
              w={'100%'}
              justify={{ base: 'space-between', sm: 'normal' }}>
              <Flex
                direction={'column'}
                gap={24}>
                <Box style={{ zIndex: 1 }}>
                  {(labelsMobile.fields.items as ILabel[])?.map(
                    (label: ILabel, index: number) => {
                      return (
                        <NoozText
                          m={0}
                          lh={1}
                          id={`${label.fields.size}px`}
                          sx={
                            label.fields.size !== 'base'
                              ? {
                                  fontSize: `${label.fields.size}px !important`,
                                }
                              : undefined
                          }
                          key={index}
                          align={label.fields.align || 'center'}
                          italic={label.fields.italic}
                          weight={label.fields.weight || 500}
                          size={
                            label.fields.size !== 'base'
                              ? label.fields.size
                              : undefined
                          }
                          color={
                            label.fields.color?.fields.classname || 'white'
                          }
                          // @ts-ignore
                          family={label.fields.family}
                          className={classes.label}
                          // @ts-ignore
                          dangerouslySetInnerHTML={{
                            __html: label.fields.text,
                          }}></NoozText>
                      );
                    },
                  )}
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <Box
            sx={() => ({
              position: 'relative',
            })}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={imgMobile?.fields.media?.fields.file.url + '?fm=webp'}
              alt={imgMobile?.fields.altText || 'Hero banner'}
              width={'100%'}
              style={{
                aspectRatio: '340/440',
                objectFit: 'cover',
              }}
            />
            <Stack
              className={classes.buttons}
              align={'center'}>
              {buttons.fields?.items?.map(
                // @ts-ignore
                (button: IButton, index: number) => {
                  if (button.fields.urlLink) {
                    return (
                      // @ts-ignore
                      <NoozButton
                        key={index}
                        miw={260}
                        {...button.fields}
                        variant={button.fields.type || 'filled'}
                        color={
                          button?.fields?.color?.fields?.classname || 'dark'
                        }
                        squared={button.fields.squareRadius}
                        sx={
                          button?.fields?.label?.fields.color?.fields.classname
                            ? // @ts-ignore
                              (theme) => ({
                                color:
                                  theme.colors[
                                    button?.fields.label?.fields.color?.fields
                                      .classname as string
                                  ],
                              })
                            : null
                        }
                        size={button.fields.size || 'lg'}
                        urlLink={button.fields.urlLink}>
                        {button?.fields?.label?.fields.text}
                      </NoozButton>
                    );
                  }
                  if (typeof button.fields.slug?.fields.url === 'string') {
                    // @ts-ignore
                    return (
                      <Link
                        key={index}
                        href={button.fields.slug?.fields.url as string}
                        facet={button.fields.slug?.fields.facet}>
                        {/* @ts-ignore */}
                        <NoozButton
                          miw={260}
                          key={index}
                          {...button.fields}
                          variant={button.fields.type || 'filled'}
                          color={
                            button?.fields?.color?.fields?.classname || 'dark'
                          }
                          squared={button.fields.squareRadius}
                          sx={
                            button?.fields?.label?.fields.color?.fields
                              .classname
                              ? // @ts-ignore
                                (theme) => ({
                                  color:
                                    theme.colors[
                                      button?.fields.label?.fields.color?.fields
                                        .classname as string
                                    ],
                                })
                              : null
                          }
                          size={button.fields.size || 'lg'}>
                          {button?.fields?.label?.fields.text}
                        </NoozButton>
                      </Link>
                    );
                  }
                  return null;
                },
              )}
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default HeroDuoBannerV2;
