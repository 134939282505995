import { useMemo } from 'react';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

const MOBILE_STICKY_MENU = 40;
const DESKTOP_HEADER_WRAPPER_HEIGHT = 90;
const MOBILE_HEADER_WRAPPER_HEIGHT = 92 + MOBILE_STICKY_MENU; // header + sticky

const DESKTOP_HEADER_BANNER_HEIGHT = 30;
const MOBILE_HEADER_BANNER_HEIGHT = 28;

const useHeaderHeight = () => {
  const theme = useMantineTheme();
  const mediaQuery = theme.fn.smallerThan('md');
  const matches = useMediaQuery(mediaQuery.split('@media ')[1]);

  return useMemo(() => {
    return {
      wrapper: matches
        ? MOBILE_HEADER_WRAPPER_HEIGHT
        : DESKTOP_HEADER_WRAPPER_HEIGHT,
      banner: matches
        ? MOBILE_HEADER_BANNER_HEIGHT
        : DESKTOP_HEADER_BANNER_HEIGHT,
      marginTop: 0,
      stickyHeight: matches ? MOBILE_STICKY_MENU : 0,
    };
  }, [matches]);
};

export default useHeaderHeight;
