import React from 'react';
import { createStyles } from '@mantine/core';
import { motion } from 'framer-motion';
import { IBlock } from '@/@types/generated/contentful';
import ErrorBoundary from '@/components/ErrorBoundary';
import SecondaryHighlightCard from './Secondary/SecondaryHighlightCard';

const useStyles = createStyles((theme) => {
  return {
    motion: {
      height: '100%',
    },
    wrapper: {
      background: theme.colors.gray[1],
    },
  };
});

export const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: 'easeOut', duration: 0.6, delay: 1 },
};

export default function HeaderDesktopCollectionSecondaryNavigation({
  details,
}: {
  details: IBlock;
}) {
  const { classes } = useStyles();

  return (
    <ErrorBoundary>
      <motion.span
        {...motionProps}
        transition={{ delay: 0.3 }}
        className={classes.motion}>
        <SecondaryHighlightCard elements={details} />
      </motion.span>
    </ErrorBoundary>
  );
}
