import { Box, Image, createStyles } from '@mantine/core';
import React from 'react';
import { IImage } from '@/@types/generated/contentful';

interface ImageBannerProps {
  image: IImage;
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    borderRadius: theme.radius.xl,
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    [theme.fn.smallerThan('md')]: {
      borderRadius: theme.radius.lg,
    },
  },
  button: {
    width: 60,
    height: 60,
    borderRadius: 60,
    [theme.fn.smallerThan('md')]: {
      width: 50,
      height: 50,
      borderRadius: 50,
    },
  },
}));

const ImageBanner = ({ image }: ImageBannerProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Image
        src={image.fields.media?.fields.file.url + '?fm=webp'}
        alt={image.fields.altText}
        style={{ width: '100%', height: '100%' }}
      />
    </Box>
  );
};

export default ImageBanner;
