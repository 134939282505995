import React from 'react';
import {
  BackgroundImage,
  Card,
  Center,
  createStyles,
  Stack,
  useMantineTheme,
  getStylesRef,
} from '@mantine/core';
import Link from '../Link/Link';
import { ILabel, IPath } from '@/@types/generated/contentful';
import NoozText from '../Nooz/NoozText/NoozText';

const useStyles = createStyles((theme) => {
  const image = getStylesRef('image');

  return {
    card: {
      cursor: 'pointer',
      position: 'relative',
      [theme.fn.largerThan(theme.breakpoints.sm)]: {
        [`&:hover .${image}`]: {
          transform: 'scale(1.03)',
        },
      },
    },
    image: {
      ref: image,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: '100%',
      backgroundSize: 'cover',
      transition: 'transform 500ms ease',
    },
  };
});

interface ActionCardProps {
  slug: IPath;
  image: {
    fields: {
      file: {
        url: string;
      };
    };
  };
  label: ILabel;
  subLabel: ILabel;
  size: '1x1' | '2x1' | '2x2';
}

export default function ActionCard({
  size,
  slug,
  image,
  label,
}: ActionCardProps) {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  return (
    <Link
      href={slug.fields.url as string}
      facet={slug.fields.facet || []}>
      <Card
        className={classes.card}
        radius='lg'>
        <Card.Section
          h={0}
          pb={size === '2x1' ? '50%' : '100%'}
          mt='sm'>
          <BackgroundImage
            src={image?.fields?.file?.url}
            className={classes.image}
          />
        </Card.Section>
      </Card>
      <Center mt={10}>
        <Stack spacing={1}>
          <NoozText
            align='center'
            size={16}
            weight={500}
            color={theme.colors.dark[7]}>
            {label?.fields.text}
          </NoozText>
        </Stack>
      </Center>
    </Link>
  );
}
