import { Box, Image, clsx, createStyles, useMantineTheme } from '@mantine/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ShopItemVariant } from '@/@types/shopitem';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles(() => ({
  imagesWrapper: {
    width: '100%',
    aspectRatio: '1',
    position: 'relative',
  },
  hoverImg: {
    top: 0,
    position: 'absolute',
    opacity: 0,
    transition: 'opacity 0.3s linear',
  },
  hoverImgActive: {
    opacity: 1,
    transition: 'opacity 0.3s linear',
  },
}));

interface CarouselProps {
  hovered?: boolean;
  images: string[];
  variantId?: string;
}

const Carousel: React.FC<CarouselProps> = ({ hovered, images, variantId }) => {
  const { classes } = useStyles();
  const [idx, setIdx] = useState(0);
  const timer = useRef<number | undefined>();

  useEffect(() => {
    if (hovered) {
      if (timer.current) clearInterval(timer.current);
      setIdx(0);
      timer.current = window.setInterval(() => {
        setIdx((prev) => (prev >= images.length - 1 ? 0 : prev + 1));
      }, 1250);
    }

    return () => {
      if (timer.current) clearInterval(timer.current);
      timer.current = undefined;
      setIdx(0);
    };
  }, [hovered, images]);

  return (
    <>
      {images.map((img, key) => (
        <Image
          key={`${variantId ? `${variantId}_` : ''}${img}`}
          src={img + '?fm=webp'}
          alt={img}
          className={clsx(
            classes.hoverImg,
            hovered && idx === key && classes.hoverImgActive,
          )}
        />
      ))}
    </>
  );
};

interface ProductCardNewImagesProps extends ShopItemVariant {
  productLifestyleImages: string[];
  hovered?: boolean;
  variantId?: string;
  mobile?: boolean;
}

const ProductCardNewImages: React.FC<ProductCardNewImagesProps> = ({
  hovered,
  productLifestyleImages,
  variantId,
  ...variant
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const { classes } = useStyles();
  const hoveredImages = useMemo(() => {
    const lifestyles =
      productLifestyleImages?.length > 0
        ? productLifestyleImages
        : variant.lifestyleImages;

    return [variant.posterImages?.[1], ...(lifestyles || [])].filter(Boolean);
  }, [variant, productLifestyleImages]);

  return (
    <Box
      className={classes.imagesWrapper}
      id={'img-wrapper'}>
      <Image
        src={variant.posterImages[0] + '?fm=webp'}
        alt={variant.title}
      />
      {isMobile ? null : (
        <Carousel
          hovered={hovered}
          images={hoveredImages}
          variantId={variantId}
        />
      )}
    </Box>
  );
};

export default ProductCardNewImages;
