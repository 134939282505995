import React from 'react';
import { Container, createStyles, Flex } from '@mantine/core';
import {
  IBlock,
  IButton,
  IColor,
  ILink,
  IParagraph,
} from '@/@types/generated/contentful';
import theme from '@/helpers/Theme';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import usePageContext from '@/utils/context/page';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { formatCfItems } from '@/helpers/formatCfItems';
import { useContainerStyles } from '@/utils/style/useContainer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    textAlign: 'center',
    [theme.fn.smallerThan('md')]: {
      textAlign: 'left',
    },
    [theme.fn.smallerThan('sm')]: {
      textAlign: 'center',
    },
  },
  text: {
    fontSize: 35,
    lineHeight: 0.8,
    [theme.fn.smallerThan('md')]: {
      fontSize: 32,
      marginBottom: 18,
    },
    [theme.fn.smallerThan('sm')]: {
      fontSize: 24,
      marginBottom: 16,
    },
  },
  icon: { marginRight: 8 },
  mailBtn: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}));
const ShareSection = ({
  text,
  button,
  emailButton,
}: {
  text: IParagraph;
  textColor: IColor;
  button: IButton;
  emailButton: IBlock;
}) => {
  const containerStyles = useContainerStyles();
  const { locale } = usePageContext();
  const styles = useStyles();
  const emailConfig = formatCfItems(emailButton);

  return (
    <Container className={containerStyles.classes.container}>
      <Flex
        direction={'column'}
        align={'center'}
        justify={'center'}
        className={styles.classes.wrapper}
        gap={{ base: 24, md: 48 }}>
        {text?.fields.richBody
          ? documentToReactComponents(text?.fields?.richBody, {
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => {
                  return (
                    <NoozText
                      lh={1.3}
                      className={styles.classes.text}>
                      {children}
                    </NoozText>
                  );
                },
                [BLOCKS.HEADING_3]: (node, children) => {
                  return (
                    <NoozText
                      size={44}
                      sx={(theme) => ({
                        fontSize: 44,
                        [theme.fn.smallerThan('md')]: {
                          fontSize: 32,
                        },
                      })}
                      lh={1.3}>
                      {children}
                    </NoozText>
                  );
                },
              },
              renderMark: {
                [MARKS.BOLD]: (text) => (
                  <NoozText
                    lh={1.3}
                    component='span'
                    variant='gradient'
                    gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
                    size='inherit'>
                    {text}
                  </NoozText>
                ),
              },
            })
          : null}
        <Flex gap={{ base: 32, md: 48 }}>
          <a
            href={
              // @ts-ignore
              button.fields.slug?.fields.longSlug ||
              button.fields.slug?.fields.url
            }
            target={'_blank'}
            rel='noreferrer'>
            {/*// @ts-ignore*/}
            <NoozButton
              {...button.fields}
              variant={button.fields.type || 'filled'}
              color={button?.fields?.color?.fields?.classname || 'dark'}
              size={button.fields.size || 'lg'}>
              <NoozIcon
                iconKey={'BrandWhatsapp'}
                color={
                  button?.fields?.icon?.fields.color?.fields.classname ||
                  button?.fields?.label?.fields.color?.fields.classname
                    ? theme.colors[
                        // @ts-ignore
                        button?.fields?.icon?.fields.color?.fields.classname ||
                          button?.fields?.label?.fields.color?.fields.classname
                      ][9]
                    : undefined
                }
                className={styles.classes.icon}
                size={24}
              />
              {button?.fields?.label?.fields.text}
            </NoozButton>
          </a>

          {emailButton ? (
            <a
              className={styles.classes.mailBtn}
              href={`mailto:?${[
                emailConfig?.title
                  ? `subject=${encodeURIComponent(
                      emailConfig?.title?.fields.text,
                    )}`
                  : null,
                emailConfig?.body
                  ? `body=${encodeURIComponent(
                      emailConfig?.body?.fields.text +
                        '\n' +
                        ((emailConfig?.link as ILink).fields.urlLink ||
                          `${process.env.NEXT_PUBLIC_DOMAIN}/${locale}/${
                            (emailConfig?.link as ILink).fields.slug?.fields.url
                          }`),
                    )}`
                  : null,
              ]
                .filter((f) => f)
                .join('&')}`}
              target={'_blank'}
              rel='noreferrer'>
              {/*// @ts-ignore*/}
              <NoozButton
                {...(emailConfig?.button || {})}
                variant={'outline'}
                color={
                  emailConfig?.button?.fields?.color?.fields?.classname ||
                  'dark'
                }
                size={emailConfig?.button?.fields.size || 'lg'}>
                <NoozIcon
                  iconKey={'Mail'}
                  color={
                    emailConfig?.button?.fields?.icon?.fields.color?.fields
                      .classname ||
                    emailConfig?.button?.fields?.label?.fields.color?.fields
                      .classname
                      ? theme.colors[
                          // @ts-ignore
                          emailConfig?.button?.fields?.icon?.fields.color
                            ?.fields.classname ||
                            emailConfig?.button?.fields?.label?.fields.color
                              ?.fields.classname
                        ][9]
                      : undefined
                  }
                  className={styles.classes.icon}
                  size={24}
                />
                {emailConfig?.button?.fields?.label?.fields.text}
              </NoozButton>
            </a>
          ) : null}
        </Flex>
      </Flex>
    </Container>
  );
};

export default ShareSection;
