import {
  createStyles,
  Title,
  Box,
  Grid,
  Center,
  Container,
  Flex,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import NoozText from '../Nooz/NoozText/NoozText';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozButton from '../Nooz/NoozButton/NoozButton';
import usePageContext from '@/utils/context/page';
import { useForm } from '@mantine/form';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';
import customNotification from '@/utils/customNotification';
import { useState } from 'react';
import DataLayerHelper from '@/helpers/Datalayer';
import { useMediaQuery } from '@mantine/hooks';
import { formatCfItems } from '@/helpers/formatCfItems';
import LocaleHelper from '@/helpers/Locale';

const useStyles = createStyles((theme) => ({
  footer: {
    background: '#000',
    paddingTop: 60,
    paddingBottom: 60,
    [theme.fn.smallerThan('sm')]: {
      paddingBottom: 60,
    },
  },
}));

const useColorConfig = () => {
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);
  return {
    ProductCategory: {
      background: '#F9F9F9',
      primaryColor: 'black',
      secondaryColor: '#1949E7',
      titleSize: isDesktop ? 60 : 40,
      bodySize: 20,
      padding: 30,
    },
    ProductCollection: {
      background: '#F9F9F9',
      primaryColor: 'black',
      secondaryColor: '#1949E7',
      titleSize: isDesktop ? 60 : 40,
      bodySize: 20,
      padding: 30,
    },
    Faq: {
      background: '#0A4984',
      primaryColor: 'white',
      secondaryColor: '#FDF185',
      titleSize: isDesktop ? 50 : 40,
      bodySize: 20,
      padding: 0,
    },
    FaqCategory: {
      background: '#0A4984',
      primaryColor: 'white',
      secondaryColor: '#FDF185',
      titleSize: isDesktop ? 50 : 40,
      bodySize: 20,
      padding: 0,
    },
    Home: {
      background: '#F9F9F9',
      primaryColor: 'black',
      secondaryColor: '#1949E7',
      titleSize: isDesktop ? 60 : 40,
      bodySize: 20,
      padding: 0,
    },
    default: {
      background: '#F9F9F9',
      primaryColor: 'black',
      secondaryColor: '#1949E7',
      titleSize: isDesktop ? 40 : 40,
      bodySize: 20,
      padding: 0,
    },
  };
};

const NOOZletterSubscribe = () => {
  const [isLoading, setLoading] = useState(false);
  const { classes } = useStyles();
  const { page, productCategory, layout, locale } = usePageContext();
  const containerStyles = useContainerStyles();
  const colorsConfig = useColorConfig();

  const { noozletter } = formatCfItems(layout?.fields.footer);
  const {
    prefixLabel,
    goodNoozLabel,
    label,
    noozLetterSuccess,
    noozLetterError,
    subLabel,
    submitLabel,
    emailPlaceholder,
    invalidEmail,
  } = formatCfItems(noozletter);
  // @ts-ignore
  const pageType = page?.fields?.pageType.type;
  // @ts-ignore
  const colors = colorsConfig[pageType] || colorsConfig.default;

  const form = useForm({
    initialValues: {
      email: '',
      lang: LocaleHelper.splitInfoWithInt(locale).language,
      country: LocaleHelper.splitInfoWithInt(locale).country,
    },
    validate: {
      email: (val) =>
        !val || (val && /^\S+@\S+$/.test(val)) ? null : 'Invalid email',
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const config = { ...NOOZ_NEXT_REGISTRY.KLAVYO.POST, data: formData };

    setLoading(true);

    DataLayerHelper.submitNewsletterEvent();

    NoozNextApi.instance(config)
      .then(() => {
        customNotification.success({ message: noozLetterSuccess.fields.text });
        form.reset();
      })
      .catch(() => {
        customNotification.error({ message: noozLetterError.fields.text });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      className={classes.footer}
      sx={{
        background: colors.background,
        color: colors.primaryColor,
        borderRadius: '30px 30px 0px 0px',
      }}>
      <Container
        py={colors.padding}
        className={containerStyles.classes.container}>
        <Center w='100%'>
          <Grid
            w='100%'
            gutterMd={30}
            grow>
            <Grid.Col
              xs={12}
              md={6}>
              <Box>
                <Title
                  order={4}
                  size={colors.titleSize}>
                  <NoozText
                    family='Mont'
                    size={colors.titleSize}
                    component={'span'}>
                    {prefixLabel?.fields.text}
                  </NoozText>{' '}
                  <Box
                    component='span'
                    sx={{
                      color:
                        productCategory?.fields.color?.fields.hex ||
                        colors.secondaryColor,
                    }}>
                    <NoozText
                      family='Mont'
                      transform='uppercase'
                      size={colors.titleSize}
                      component={'span'}>
                      {goodNoozLabel?.fields.text}
                    </NoozText>
                  </Box>{' '}
                  <NoozText
                    family='Mont'
                    size={colors.titleSize}
                    component={'span'}>
                    {label?.fields.text}
                  </NoozText>
                </Title>
              </Box>
            </Grid.Col>
            <Grid.Col
              xs={12}
              md={6}
              sx={{ position: 'relative' }}>
              <Flex
                h='100%'
                direction='column'
                justify={'center'}
                align={'start'}
                mb={40}>
                <NoozText size={colors.bodySize}>
                  {subLabel.fields.text}
                </NoozText>

                <Box
                  component={'form'}
                  mt={40}
                  w='100%'
                  onSubmit={form.onSubmit(onSubmit)}>
                  <TextInput
                    sx={{ flex: 3 }}
                    type='email'
                    value={form.values.email}
                    onChange={(event) =>
                      form.setFieldValue('email', event.currentTarget.value)
                    }
                    error={form.errors.email && invalidEmail.fields.text}
                    width='100%'
                    radius='xl'
                    color='white'
                    variant='default'
                    size='xl'
                    placeholder={emailPlaceholder.fields.text}
                  />
                  <NoozButton
                    mt={30}
                    loading={isLoading}
                    type='submit'
                    color='dark'
                    sx={{ flex: 1 }}
                    size='lg'>
                    {submitLabel.fields.text}
                  </NoozButton>
                </Box>
              </Flex>
            </Grid.Col>
          </Grid>
        </Center>
      </Container>
    </Box>
  );
};

export default NOOZletterSubscribe;
