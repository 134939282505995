import {
  Box,
  Container,
  Flex,
  clsx,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import React, { useMemo } from 'react';
import NoozText, {
  ShopStoryTextProps,
} from '@/components/Nooz/NoozText/NoozText';
import Link from '@/components/Link/Link';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozButton from '../../Nooz/NoozButton/NoozButton';
import { IBlock, IButton, IImage } from '@/@types/generated/contentful';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';

const useStyles = createStyles((theme) => ({
  image: {
    aspectRatio: '3',
    [theme.fn.smallerThan('sm')]: {
      aspectRatio: '1',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    marginTop: 16,
  },
  icon: {
    marginLeft: 4,
  },
}));
interface HeroBannerProps {
  height?: string;
  content: IBlock;
  image?: IImage;
}

const Labels = ({ entries }: { entries?: any[] }) => {
  const labels = useMemo(() => {
    return entries?.filter((e) => e.fields.key === 'label') || [];
  }, [entries]);
  return labels ? (
    <>
      {labels.map((l) =>
        Array.isArray(l.fields.textList) ? (
          <React.Fragment key={l.id}>
            {l.fields.textList.map((text: string, id: number) => (
              <NoozText
                key={id}
                {...ShopStoryTextProps(l.fields)}>
                {text}
              </NoozText>
            ))}
          </React.Fragment>
        ) : (
          <NoozText
            key={l.id}
            {...ShopStoryTextProps(l.fields)}>
            {l.fields.text}
          </NoozText>
        ),
      )}
    </>
  ) : null;
};
const Buttons = ({ entries }: { entries?: any[] }) => {
  const theme = useMantineTheme();
  const classes = useStyles().classes;
  const buttons = useMemo(() => {
    return entries?.filter((e) => e.fields.key === 'button') || [];
  }, [entries]);

  return buttons ? (
    <Flex
      mt={18}
      direction={{ base: 'column', sm: 'row' }}
      gap={12}>
      {buttons.map(
        // @ts-ignore
        (button: IButton, index: number) => {
          if (typeof button.fields.slug?.fields?.url !== 'string') return null;
          // @ts-ignore
          return (
            <Link
              key={index}
              href={button.fields.slug?.fields.url as string}>
              {/* @ts-ignore */}
              <NoozButton
                key={index}
                {...button.fields}
                variant={button.fields.type || 'filled'}
                color={button?.fields?.color?.fields?.classname || 'dark'}
                gradient={button.fields.color?.fields.gradientObject as any}
                className={'cta-button'}
                sx={
                  button?.fields?.label?.fields.color?.fields.classname
                    ? // @ts-ignore
                      (theme) => ({
                        color:
                          theme.colors[
                            // @ts-ignore
                            button.fields.label.fields.color.fields.classname
                          ],
                      })
                    : null
                }>
                <NoozText
                  color={'inherit'}
                  size={16}
                  weight={600}>
                  {button?.fields?.label?.fields.text}
                </NoozText>
                {button?.fields.icon ? (
                  <NoozIcon
                    iconKey={button.fields.icon.fields.iconKey}
                    strokeWidth={3}
                    className={classes.icon}
                    color={
                      theme.colors[
                        // @ts-ignore
                        button?.fields?.icon?.fields.color?.fields.classname ||
                          button?.fields?.label?.fields.color?.fields.classname
                      ][0]
                    }
                  />
                ) : null}
              </NoozButton>
            </Link>
          );
        },
      )}
    </Flex>
  ) : null;
};

const BannerCTA = ({ content, image }: HeroBannerProps) => {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();

  return (
    <>
      <Box>
        <Container
          w={'100%'}
          sx={(theme) => ({
            padding: '0 !important',
            margin: '0 52px',
            width: 'unset !important',
            borderRadius: 10,
            background: `no-repeat center/cover url("${image?.fields.media?.fields.file.url}?fm=webp") ${theme.colors.dark[7]}`,
            [theme.fn.smallerThan('sm')]: {
              margin: '0',
              backgroundImage: `url("${image?.fields.mobileMedia?.fields.file.url}?fm=webp") `,
            },
          })}
          className={clsx(containerStyles.classes.container, classes.image)}
        />

        <Container
          w={'100%'}
          className={clsx(containerStyles.classes.container, classes.wrapper)}>
          <Labels entries={content.fields.items} />
          <Buttons entries={content.fields.items} />
        </Container>
      </Box>
    </>
  );
};

export default BannerCTA;
