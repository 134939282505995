import siteMetadata from '@/siteMetadata';
import { IPath } from '@/@types/generated/contentful';
import LocaleHelper from '@/helpers/Locale';

class SeoHelper {
  static hrefLangLinks(localizedSlugs: Record<string, string>) {
    // @ts-ignore
    const links: any[] = [];
    const locales = LocaleHelper.getApplicationLocales();

    locales.forEach((locale) => {
      let localizedSlugsKeys = Object.keys(localizedSlugs || {});
      localizedSlugsKeys.map((s) => s.toLocaleLowerCase());
      const contentfulLocale = LocaleHelper.getValidContentfulLocale(locale);

      if (
        localizedSlugsKeys.find((k) => k === contentfulLocale) ||
        typeof localizedSlugs?.en === 'string'
      )
        links.push(
          <link
            key={`/${locale.toLowerCase()}`}
            rel='alternate'
            href={`${siteMetadata.siteUrl}/${locale.toLowerCase()}/${
              localizedSlugs[contentfulLocale] || localizedSlugs.en
            }`
              .replaceAll(/\/\//g, '/')
              .replace(/\/$/g, '')}
            hrefLang={
              locale === 'en-int'
                ? 'x-default'
                : locale
                    .toLowerCase()
                    .split('-')
                    .reduce((arr: string[], v, key) => {
                      arr.push(key > 0 ? v.toUpperCase() : v);
                      return arr;
                    }, [])
                    .join('-')
            }
          />,
        );
    });

    return links;
  }
  static canonicalLink(
    canonicalSlug?: IPath,
    baseUrl?: string,
    locale?: string,
  ) {
    if (canonicalSlug?.fields?.url) {
      return (
        <link
          rel='canonical'
          href={`${siteMetadata.siteUrl}/${locale}${
            canonicalSlug?.fields?.url || ''
          }`}
          data-baseprotocol='https'
          data-basehost={siteMetadata.siteUrl}
        />
      );
    } else {
      return (
        <link
          rel='canonical'
          href={baseUrl}
          data-baseprotocol='https'
          data-basehost={siteMetadata.siteUrl}
        />
      );
    }
  }
}

export default SeoHelper;
