import {
  Box,
  Center,
  Grid,
  Group,
  MediaQuery,
  clsx,
  createStyles,
  Container,
} from '@mantine/core';
import React, { useMemo } from 'react';
import NoozSlider from '@/components/Nooz/NoozSlider/NoozSlider';
import { formatCfItems } from '@/helpers/formatCfItems';
import { IBlock, ILabel } from '@/@types/generated/contentful';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import ClearReviewCard from '@/components/Cards/ClearReviewCard';
import ClearCard from '@/components/Cards/ClearCard';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import { Carousel } from '@mantine/carousel';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: 42,
    fontWeight: 500,
    [theme.fn.smallerThan('md')]: {
      fontSize: 28,
      fontWeight: 600,
    },
  },
  wrapper: {
    marginTop: 80,
    marginBottom: 40,
  },
  trustPilotCard: {
    height: 480,
    padding: 40,
    marginRight: 20,
    borderRadius: 20,
    overflow: 'hidden',
    background: theme.colors.lightGray[0],
    [theme.fn.smallerThan('md')]: {
      padding: '0px 30px',
      margin: 0,
      marginBottom: 20,
    },
  },
}));

const useSliderStyles = createStyles((theme) => ({
  indicators: {
    bottom: -30,
  },
}));
interface ITrustpilotReviews {
  label: ILabel;
  list: IBlock;
  cardType: 'ClearReview' | 'ClearCard';
}

const Card = {
  ClearReview: ClearReviewCard,
  ClearCard: ClearCard,
};

const dimensions = {
  ClearReview: {
    desktop: {
      h: '100%',
    },
    mobile: {
      h: '100%',
    },
  },
  ClearCard: {
    desktop: {
      h: 500,
    },
    mobile: {
      h: 500,
    },
  },
};

const CardsNavigation = ({ label, list, cardType }: ITrustpilotReviews) => {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();
  const CardComponent = useMemo(() => Card[cardType], [cardType]);
  const sliderStyles = useSliderStyles();
  const carouselIndicatorsStyles = useCarouselIndicatorsStyles({});

  return (
    <Box className={clsx(classes.wrapper)}>
      <Box
        className={clsx(containerStyles.classes.container)}
        component='section'>
        <Group
          position='apart'
          mb={{ base: 20, md: 40 }}>
          <NoozText
            component='h3'
            m={0}
            className={classes.label}>
            {label?.fields.text}
          </NoozText>
        </Group>
      </Box>
      <MediaQuery
        largerThan={'md'}
        styles={{ display: 'none' }}>
        {list.fields.items ? (
          <Carousel
            {...CAROUSEL_PROPS}
            loop={true}
            align={'center'}
            slideSize='90%'
            classNames={{
              ...sliderStyles.classes,
              ...carouselIndicatorsStyles.classes,
            }}
            withControls={false}
            withIndicators={true}
            nextControlIcon={<></>}
            previousControlIcon={<></>}
            height={'100%'}>
            {list.fields.items.map((item: any, index: number) => {
              const data = formatCfItems(item);
              return (
                <Carousel.Slide key={index}>
                  <Box {...dimensions[cardType].mobile}>
                    <CardComponent {...data} />
                  </Box>
                </Carousel.Slide>
              );
            })}
          </Carousel>
        ) : null}
      </MediaQuery>
      <MediaQuery
        smallerThan={'md'}
        styles={{ display: 'none' }}>
        <Container className={clsx(containerStyles.classes.container)}>
          <Grid gutter={30}>
            {list.fields.items?.map((item) => {
              const colWidth = 12 / (list.fields.items?.length || 3);
              const data = formatCfItems(item);
              return (
                <Grid.Col
                  key={(item as any).id}
                  span={12}
                  md={colWidth}>
                  <Box {...dimensions[cardType].desktop}>
                    <CardComponent {...data} />
                  </Box>
                </Grid.Col>
              );
            })}
          </Grid>
        </Container>
      </MediaQuery>
    </Box>
  );
};

export default CardsNavigation;
