import { Box, clsx, Drawer, Flex, SimpleGrid, Stack } from '@mantine/core';
import zIndexes from '@/utils/style/zIndex';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { motion } from 'framer-motion';
import { motionProps } from '@/components/Drawer/Drawer';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import React, { useMemo, useState } from 'react';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import useDrawerClasses from '@/components/Cart/CartDrawer/useClasses';
import useCartActions from '@/utils/hook/cart/useCartActions';
import { IImage } from '@/@types/generated/contentful';
import Currency from '@/helpers/Currency';
import { FREE_GIFT_KEY } from '@/components/Cart/CartDrawer/CartDrawerProgressBar';
import { useGamificationGifts } from '@/utils/hook/gamification';
import CartDrawerHeader from '@/components/Cart/CartDrawer/CartDrawerHeader';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import useCartItems from '@/utils/hook/useCartItems';

export const useGiftSelectorDrawer = (replaceProduct?: boolean) => {
  const { stocks } = usePageContext() as any as {
    stocks: Record<string, number>;
  };
  const nextGift = useGamificationGifts(replaceProduct);
  const [loading, setLoading] = useState(false);
  const [freeGiftDrawerOpened, setFreeGiftDrawerOpened] = useState(false);
  const headerHeight = useHeaderHeight();
  const classesCart = useDrawerClasses(headerHeight.wrapper);
  const { addToCart, removeFromCart } = useCartActions();
  const lineItems = useCartItems();
  const onSelect = (p: any) => async () => {
    if (!nextGift) return;
    setLoading(true);

    if (replaceProduct) {
      const activeGift = lineItems.find((li) => li.__itemId === nextGift.id);
      if (activeGift) await removeFromCart(activeGift.lineItems);
    }

    addToCart(
      [
        {
          quantity: 1,
          type: 'item',
          dataLayerItem: {
            item_id: parseInt(
              p.shopifyInfo.id.replace('gid://shopify/ProductVariant/', ''),
            ),
            parent_item_id: parseInt(
              (p.productShopifyId as unknown as string).replace(
                'gid://shopify/Product/',
                '',
              ),
            ),
            item_name: p.product.fields.label?.fields.text,
            item_variant: p.shopifyInfo.title,
            price: parseFloat(p.shopifyInfo.price.amount as unknown as string),
            quantity: 1,
            item_list_id: 'essentials_products',
            item_list_name: 'Essentials Products',
          },
          shopifyVariant: p.shopifyInfo as unknown as ShopifyProductVariant,
          contentfulItem: {
            title: p.product.fields.label?.fields.text,
            image: p.posterImage ? ({ fields: p.posterImage } as IImage) : null,
            price: Currency.toFormattedPrice(
              p.shopifyInfo.price.amount as unknown as string,
            ),
            discountPrice: p.shopifyInfo.compareAtPrice
              ? Currency.toFormattedPrice(
                  p.shopifyInfo.compareAtPrice.amount as unknown as string,
                )
              : undefined,
            currencyCode: p.shopifyInfo.price.currencyCode,
            url: (p.product?.fields as any).enSlug as string,
            id: (p as any).id,
          },
        },
      ],
      [
        { key: '__itemId', value: nextGift.id },
        { key: '__itemType', value: FREE_GIFT_KEY },
      ],
    ).finally(() => {
      setFreeGiftDrawerOpened(false);
      setLoading(false);
    });
  };

  const { layout } = usePageContext();
  const { yourGiftLabel, selectLabel } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );
  const DrawerComponent = () =>
    nextGift ? (
      <Drawer
        opened={freeGiftDrawerOpened}
        position={'right'}
        zIndex={zIndexes.drawer}
        classNames={{
          inner: classesCart.drawerInnerFull,
          content: clsx(classesCart.drawerContentCart, 'white'),
          header: classesCart.drawerHeader,
          body: classesCart.drawerBody,
        }}
        trapFocus={false}
        withCloseButton={false}
        onClose={() => setFreeGiftDrawerOpened(false)}
        overlayProps={{ color: 'transparent', opacity: 0, blur: 0 }}>
        <motion.div
          {...motionProps}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Flex className={clsx(classesCart.header)}>
            <CartDrawerHeader
              closeDrawer={() => setFreeGiftDrawerOpened(false)}
              title={yourGiftLabel}
            />
          </Flex>
          <Box style={{ flex: 1, overflow: 'auto' }}>
            <SimpleGrid
              cols={2}
              p={26}>
              {nextGift.products.map((p: any) => {
                return !p || !(stocks?.[p?.shopifyId] > 0) ? null : (
                  <Stack key={p.id}>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      width={'100%'}
                      src={p.posterImage?.media?.fields?.file.url + '?fm=webp'}
                      style={{ aspectRatio: '1' }}
                      alt={'gift'}
                    />
                    <NoozText
                      size={16}
                      weight={700}
                      color={'title'}>
                      {p.label}
                      {p.product?.fields.variant?.fields.color?.fields.text ? (
                        <NoozText
                          size={16}
                          weight={400}
                          color={'text'}
                          component={'span'}>
                          {` - ${p.product?.fields.variant?.fields.color?.fields.text}`}
                        </NoozText>
                      ) : null}
                    </NoozText>
                    <NoozButton
                      color={'dark'}
                      variant={'filled'}
                      disabled={loading}
                      onClick={onSelect(p)}>
                      {selectLabel.fields.text}
                    </NoozButton>
                  </Stack>
                );
              })}
            </SimpleGrid>
          </Box>
        </motion.div>
      </Drawer>
    ) : null;

  return { DrawerComponent, setFreeGiftDrawerOpened };
};

const CartDrawerGiftSelectorButton = ({}: {}) => {
  const nextGift = useGamificationGifts();
  const { DrawerComponent, setFreeGiftDrawerOpened } =
    useGiftSelectorDrawer(false);

  const { layout } = usePageContext();
  const { selectGiftLabel } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );
  return nextGift ? (
    <>
      <Box
        p={2}
        sx={{
          background: 'linear-gradient(to left, #069BE1, #424CB7)',
          borderRadius: 100,
        }}>
        <Flex
          sx={{
            background: '#F5F5F5',
            borderRadius: 100,
          }}>
          <NoozButton
            onClick={() => setFreeGiftDrawerOpened(true)}
            variant={'subtle'}
            sx={{ flex: 1 }}
            color='gray'>
            <NoozText
              sx={{
                background: 'linear-gradient(to right, #069BE1, #424CB7)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>
              {selectGiftLabel.fields.text}
            </NoozText>
          </NoozButton>
        </Flex>
      </Box>
      <DrawerComponent />
    </>
  ) : null;
};

export default CartDrawerGiftSelectorButton;
