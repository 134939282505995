import { useMantineTheme } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';

export interface UseBreakpointsReturn {
  isMobile?: boolean;
  isDesktop?: boolean;
  loading: boolean;
}

const useBreakpoints = (
  cbOnMount?: (_: Required<Omit<UseBreakpointsReturn, 'loading'>>) => void,
) => {
  const hasMount = useRef(false);
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

  const [value, setValue] = useState<UseBreakpointsReturn>({
    loading: true,
  });

  useEffect(() => {
    if (typeof isDesktop === 'boolean') {
      setValue({
        loading: false,
        isDesktop: isDesktop,
        isMobile: !isDesktop,
      });

      if (!hasMount.current) {
        cbOnMount?.({
          isDesktop: isDesktop,
          isMobile: !isDesktop,
        });
        hasMount.current = true;
      }
    }
  }, [isDesktop]);

  return value;
};

export default useBreakpoints;
