import { Box, createStyles, Flex } from '@mantine/core';
import { Quote } from 'tabler-icons-react';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import theme from '@/helpers/Theme';
import Stars from '../Stars/Stars';
import { IBlock, IImage, ILabel } from '@/@types/generated/contentful';

const useStyles = createStyles((theme) => ({
  reviewsWrapper: {
    padding: 40,
    gap: '.75rem',
    width: '100%',
    flex: 1,
  },
  card: {
    height: '100%',
    width: '100%',
    padding: 0,
    backgroundColor: theme.colors.white[0],
    border: '1px solid #D7D7D7',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    borderRadius: 20,
    transition: 'transform .5s ease',
    '& > *:not(:first-of-type)': {
      marginTop: 16,
    },
    '& > .description': {
      flex: 1,
      whiteSpace: 'pre-wrap',
    },
    '& > .footer': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  plusAction: {
    position: 'absolute',
    right: 40,
    bottom: 40,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      right: 20,
      bottom: 20,
    },
  },
}));

interface ReviewCardProps {
  stars: ILabel;
  label?: ILabel;
  image?: IImage;
  subLabels?: IBlock;
}

const ClearReviewCard = ({
  stars,
  label,
  image,
  subLabels,
}: ReviewCardProps) => {
  const { classes } = useStyles();

  return (
    <Box
      sx={() => ({
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        height: '100%',
        borderRadius: 20,
      })}>
      <Flex
        className={classes.card}
        justify={'space-between'}
        sx={{
          position: 'relative',
        }}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={image?.fields.media?.fields.file.url + '?fm=webp'}
          alt={''}
          style={{
            // position: 'absolute',
            height: 300,
            width: '100%',
            objectFit: 'cover',
          }}
        />
        <Quote
          size={30}
          color={'white'}
          fill={'white'}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            alignSelf: 'flex-end',
            zIndex: 1,
            margin: 30,
          }}
        />
        <Flex
          direction={'column'}
          gap={20}
          className={classes.reviewsWrapper}>
          {stars ? (
            <Stars
              stars={parseInt(stars.fields?.text || '0')}
              color={theme.colors.greenReview[0]}
            />
          ) : null}
          <Box sx={{ flex: 2 }}>
            <NoozText
              size={18}
              fw={500}
              sx={{ lineHeight: 'normal' }}>
              {label?.fields.text}
            </NoozText>
          </Box>

          {subLabels?.fields.items ? (
            <Box sx={{ alignSelf: 'flex-end' }}>
              {subLabels.fields.items.map((l) => (
                <NoozText
                  key={(l as any).id}
                  size={14}
                  fw={900}
                  align={'right'}
                  sx={{ lineHeight: 'normal' }}>
                  {(l as ILabel).fields.text}
                </NoozText>
              ))}
            </Box>
          ) : null}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ClearReviewCard;
