import React from 'react';
import {
  BackgroundImage,
  createStyles,
  Stack,
  getStylesRef,
  Group,
  ActionIcon,
  Box,
  Flex,
  useMantineTheme,
} from '@mantine/core';
import NoozText from '../Nooz/NoozText/NoozText';
import { IColor, ILabel, IPath } from '@/@types/generated/contentful';
import Link from '../Link/Link';
import NoozIcon from '../Nooz/NoozIcon/NoozIcon';
import { TIMING_FUNCTION } from '@/utils/style/animation';

const useStyles = createStyles((theme) => {
  const image = getStylesRef('image');

  return {
    card: {
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
    },
    image: {
      ref: image,
      transition: `transform 600ms ease`,
    },
    label: {
      fontSize: 24,
      fontWeight: 500,
      [theme.fn.smallerThan('md')]: {
        fontSize: 16,
        fontWeight: 500,
      },
    },
    subLabel: {
      fontSize: 20,
      fontWeight: 500,
      [theme.fn.smallerThan('md')]: {
        fontSize: 16,
        fontWeight: 500,
      },
    },
  };
});

interface ActionCardProps {
  image: any;
  label: ILabel;
  subLabel?: ILabel;
  slug: IPath;
  color?: IColor;
  height?: number;
  backgroundColor?: string;
}

export default function ActionCardBackdrop({
  backgroundColor,
  color,
  height,
  image,
  label,
  slug,
  subLabel,
}: ActionCardProps) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [hovered, setHovered] = React.useState(false);

  return (
    <Link
      href={slug?.fields.url as string}
      facet={slug?.fields.facet || []}>
      <Box
        bg={backgroundColor || '#f4f4f4'}
        className={classes.card}
        h={'100%'}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
        <BackgroundImage
          h={height || '75%'}
          src={image?.fields.file?.url}
          className={classes.image}></BackgroundImage>
        <Flex
          h={'25%'}
          w='100%'
          align='center'>
          <Group
            w='100%'
            noWrap
            p={{ base: 'md', md: 'lg' }}
            py={{ base: 'xs', md: 12 }}
            position='apart'>
            <Stack spacing={1}>
              <NoozText
                className={classes.label}
                weight={500}
                color={theme.colors.gray[7]}>
                {label?.fields.text}
              </NoozText>
              <NoozText
                color='dimmed'
                align='left'
                size={16}>
                {subLabel?.fields.text}
              </NoozText>
            </Stack>
            <ActionIcon
              size='md'
              radius='xl'
              color='white'
              variant='filled'
              sx={{
                transition: `border 0.5s ${TIMING_FUNCTION}`,
                border: hovered
                  ? `1px solid ${
                      theme.colors[color?.fields.classname || 'gray']?.[7]
                    }`
                  : '',
              }}>
              <NoozIcon
                iconKey={'ChevronRight'}
                strokeWidth={1}
                size={22}
              />
            </ActionIcon>
          </Group>
        </Flex>
      </Box>
    </Link>
  );
}
