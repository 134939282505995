import { Dialog, createStyles } from '@mantine/core';
import { useDialogContext } from '@/utils/context/dialog';
import zIndexes from '@/utils/style/zIndex';

const useStyles = createStyles(() => ({
  header: {
    position: 'absolute',
    right: 0,
    background: 'none',
  },
  body: {
    padding: 0,
  },
  close: {
    background: 'white',
    color: 'black',
  },
}));

const DialogWrapper = () => {
  const { classes } = useStyles();

  const { dialog, setDialog } = useDialogContext();

  return (
    <Dialog
      opened={dialog.opened}
      withCloseButton
      onClose={() => setDialog({ ...dialog, opened: false })}
      size={'100%'}
      classNames={{ root: classes.body }}
      styles={dialog.styles}
      zIndex={zIndexes.modal}
      // position={{ bottom: 0, right: "50%" }}
      radius='md'>
      {dialog.body}
    </Dialog>
  );
};

export { DialogWrapper as Dialog };
