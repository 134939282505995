import React, { memo } from 'react';
import { Container, Grid } from '@mantine/core';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { IBlock, ILabel } from '@/@types/generated/contentful';
import ProductCard from '@/components/Collection/ProductCard/ProductCard';
import HighlightCard from '@/components/Cards/HighlightCard';
import { formatCfItems } from '@/helpers/formatCfItems';
import { ShopItem } from '@/@types/shopitem';

const List = ({
  marketingItems,
  hits,
}: {
  marketingItems: IBlock;
  hits: ShopItem[];
}) => {
  let pos = 2;
  let index = 0;
  const interval = 5;

  const cardItems = [...(marketingItems.fields.items || [])];
  const list = [...hits];

  while (pos < list.length && index < cardItems.length) {
    //@ts-ignore
    list.splice(pos, 0, cardItems[index]);
    pos += interval;
    index += 1;
  }

  return (
    <Grid
      gutter={5}
      gutterSm={20}
      gutterMd={30}
      gutterLg={40}>
      {list?.map((item: ShopItem | any, key: number) => {
        if (item.defaultVariantId) {
          return (
            <Grid.Col
              span={6}
              md={4}
              key={item.defaultVariantId}>
              <ProductCard
                position={0}
                key={item.defaultVariantId}
                item={item}
              />
            </Grid.Col>
          );
        } else if (item.fields?.title) {
          return (
            <Grid.Col
              key={key}
              span={12}
              md={4}
              sx={(theme) => ({
                [theme.fn.smallerThan('md')]: {
                  margin: '16px 0px',
                },
              })}
              h={{ base: 400, sm: 500, md: 600, lg: 'auto' }}>
              <HighlightCard
                {...formatCfItems(item || {})}
                color={'black'}
              />
            </Grid.Col>
          );
        } else {
          return <></>;
        }
      })}
    </Grid>
  );
};

const ProductCardList = ({
  facets,
  label,
  marketingItems,
}: {
  facets: IBlock;
  label: ILabel;
  marketingItems: IBlock;
}) => {
  const containerStyles = useContainerStyles();

  return (
    // @ts-ignore
    <>
      <Container
        mb={40}
        className={containerStyles.classes.container}>
        {label?.fields?.text && (
          <NoozText size={42}>{label?.fields.text}</NoozText>
        )}
        <List
          marketingItems={marketingItems}
          hits={(facets as any).shopitems?.items || []}
        />
      </Container>
    </>
  );
};

export default memo(ProductCardList);
