import {
  ActionIcon,
  Box,
  Center,
  clsx,
  Flex,
  Group,
  Loader,
  ScrollArea,
  SimpleGrid,
  Stack,
} from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useStaticContent from '@/utils/hook/useStaticContent';
import format from 'date-fns/format';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';
import { capitalizeFirstLetter } from '@/utils';
import usePageContext from '@/utils/context/page';
import LocaleHelper from '@/helpers/Locale';

const RetailBookingStepDate = ({
  form,
  data,
  isCurrentDay,
  setForm,
  setActive,
}: {
  form: any;
  data: any;
  isCurrentDay: any;
  setForm: any;
  setActive: any;
}) => {
  const { locale } = usePageContext();
  const language = useMemo(() => {
    return locale ? LocaleHelper.splitInfo(locale).language : 'en';
  }, [locale]);
  const staticContent = useStaticContent();
  const [loading, setLoading] = useState(false);

  const scroll = useRef<null | HTMLDivElement>(null);
  const [infoScroll, setInfoScroll] = useState({
    hasPrev: false,
    hasNext: true,
  });
  const [currentMonth, setCurrentMonth] = useState<undefined | null | string>(
    form.date?.slots?.[0].longMonth,
  );
  useEffect(() => {
    if (form.date?.slots) {
      setCurrentMonth(form.date?.slots?.[0].longMonth);
    }
  }, [form]);

  const onNavigate = (toNext?: boolean) => () => {
    if (
      scroll.current &&
      scroll.current?.getElementsByClassName('mantine-ScrollArea-viewport')[0]
    ) {
      const div = scroll.current?.getElementsByClassName(
        'mantine-ScrollArea-viewport',
      )[0];

      // offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight
      if (div.getElementsByClassName('mantine-Stack-root')?.[0]) {
        const width =
          // @ts-ignore
          div.getElementsByClassName('mantine-Stack-root')[0].offsetWidth + 8;
        // @ts-ignore
        const scrollSnap = Math.trunc(div.offsetWidth / width) * width;
        div.scrollTo({
          behavior: 'smooth',
          left:
            (Math.trunc(div.scrollLeft / scrollSnap) + (toNext ? 1 : -1)) *
            scrollSnap,
        });
        setInfoScroll({
          hasPrev: div.scrollLeft !== 0,
          hasNext:
            Math.round((div as any).offsetWidth + div.scrollLeft) <
            div.scrollWidth,
        });
      }
    }
  };
  useEffect(() => {
    if (
      scroll.current?.getElementsByClassName('mantine-ScrollArea-viewport')[0]
    ) {
      const div = scroll.current?.getElementsByClassName(
        'mantine-ScrollArea-viewport',
      )[0];
      const fct = () => {
        let firstElementInViewport = -1;
        const children = Array.from(div.children?.[0]?.children?.[0].children);
        for (
          let i = 0;
          i < children.length && firstElementInViewport < 0;
          ++i
        ) {
          const { left } = children[i].getBoundingClientRect();
          if (left >= 0) {
            setCurrentMonth(children[i].getAttribute('data-month'));
            firstElementInViewport = i;
          }
        }

        setInfoScroll({
          hasPrev: div.scrollLeft !== 0,
          hasNext:
            Math.round((div as any).offsetWidth + div.scrollLeft) <
            div.scrollWidth,
        });
      };
      div.addEventListener('scrollend', fct);
      return () => {
        div?.removeEventListener('scrollend', fct);
      };
    }
  }, [scroll.current]);

  return (
    <>
      {form.date ? (
        <>
          <Stack
            spacing={'1rem'}
            w={'100%'}
            sx={{ overflow: 'hidden' }}>
            <NoozText
              weight={900}
              align={'center'}
              size={24}>
              {staticContent.dateTitle}
            </NoozText>
            <Group position={'apart'}>
              <NoozText
                color={'title'}
                size={16}
                weight={700}>
                {currentMonth || ''}
              </NoozText>
              <Group position={'right'}>
                <ActionIcon
                  onClick={onNavigate()}
                  variant={'outline'}
                  radius={'xl'}
                  disabled={!infoScroll.hasPrev}>
                  <NoozIcon iconKey={'ChevronLeft'} />
                </ActionIcon>
                <ActionIcon
                  onClick={onNavigate(true)}
                  variant={'outline'}
                  radius={'xl'}
                  disabled={!infoScroll.hasNext}>
                  <NoozIcon iconKey={'ChevronRight'} />
                </ActionIcon>
              </Group>
            </Group>
            <Box
              w={'100%'}
              sx={{ overflow: 'hidden' }}>
              <ScrollArea
                w={'100%'}
                ref={scroll}
                type='never'>
                <Flex gap={8}>
                  {data?.calendar?.map(
                    (el: {
                      weekDay: number;
                      day: number;
                      month: number;
                      date: number;
                      shortWeekDay: string;
                      shortMonth: string;
                      longWeekDay: string;
                      longMonth: string;
                      slots: { slot: string; active: boolean }[];
                    }) => {
                      const active = el.date === form.date?.date;
                      const onClick = () => {
                        if (el.slots.length === 0) return;

                        setForm({ date: el });
                        setLoading(true);
                        NoozNextApi.instance
                          .get(
                            NOOZ_NEXT_REGISTRY.ODOO.APPOINTMENTS.GET_DAY.replace(
                              '{date}',
                              format(new Date(el.date), 'yyyy-MM-dd'),
                            ),
                          )
                          .then(({ data }) => {
                            const calendar = data.calendarDay;

                            const shortWeekDay = capitalizeFirstLetter(
                              new Intl.DateTimeFormat(language, {
                                weekday: 'short',
                              }).formatToParts(el.date)[0].value,
                            );
                            const shortMonth = capitalizeFirstLetter(
                              new Intl.DateTimeFormat(language, {
                                month: 'short',
                              }).formatToParts(el.date)[0].value,
                            );
                            const longWeekDay = capitalizeFirstLetter(
                              new Intl.DateTimeFormat(language, {
                                weekday: 'long',
                              }).formatToParts(el.date)[0].value,
                            );
                            const longMonth = capitalizeFirstLetter(
                              new Intl.DateTimeFormat(language, {
                                month: 'long',
                              }).formatToParts(el.date)[0].value,
                            );
                            calendar.shortWeekDay = shortWeekDay;
                            calendar.shortMonth = shortMonth;
                            calendar.longWeekDay = longWeekDay;
                            calendar.longMonth = longMonth;
                            calendar.date = el.date;
                            setForm({ date: calendar });
                          })
                          .finally(() => {
                            setLoading(false);
                          });
                      };

                      return (
                        <Stack
                          key={el.date}
                          onClick={onClick}
                          className={clsx({
                            active,
                            disabled: el.slots.length === 0,
                          })}
                          align={'center'}
                          spacing={4}
                          data-month={el.longMonth}
                          sx={(theme) => ({
                            border: `1px solid ${theme.colors.text[0]}`,
                            borderRadius: theme.radius.md,
                            cursor: 'pointer',
                            padding: '1rem',
                            transition: 'background .5s',
                            '&.active': {
                              backgroundColor: theme.colors.main[0],
                              borderColor: theme.colors.main[0],
                            },
                            '&.disabled': {
                              backgroundColor: theme.colors.gray[1],
                              borderColor: 'none',
                              opacity: 0.5,
                              cursor: 'not-allowed',
                            },
                            '&:not(.active):hover': {
                              background: `${theme.colors.gray[1]} !important`,
                            },
                          })}>
                          <NoozText
                            size={12}
                            color={active ? 'white' : 'title'}>
                            {el.shortWeekDay}
                          </NoozText>
                          <NoozText
                            size={20}
                            weight={700}
                            color={active ? 'white' : 'title'}>
                            {el.day}
                          </NoozText>
                          <NoozText
                            size={12}
                            color={active ? 'white' : 'title'}>
                            {el.shortMonth}
                          </NoozText>
                        </Stack>
                      );
                    },
                  )}
                </Flex>
              </ScrollArea>
            </Box>
            <NoozText
              mt={12}
              size={24}
              weight={700}>
              {`${form.date.longWeekDay} ${form.date.day} ${form.date.longMonth}`}
            </NoozText>
          </Stack>
          <ScrollArea
            sx={{ flex: 1 }}
            type={'never'}>
            <SimpleGrid
              cols={4}
              breakpoints={[{ maxWidth: '36rem', cols: 3 }]}
              mt={8}>
              {loading ? (
                <Center
                  w='100%'
                  h={240}>
                  <Loader mt={8} />
                </Center>
              ) : (
                form.date.slots.map(
                  (slot: { slot: string; active: boolean }) => {
                    let inactive = !slot.active;

                    const minuteOfDay =
                      new Date().getHours() * 60 + new Date().getMinutes();
                    const minuteOfSlot =
                      parseInt(slot.slot.split(':')[0]) * 60 +
                      parseInt(slot.slot.split(':')[1]);
                    if (isCurrentDay && minuteOfDay + 30 > minuteOfSlot) {
                      inactive = true;
                    }
                    return (
                      <Box
                        key={slot.slot}
                        className={clsx({ inactive })}
                        sx={(theme) => ({
                          border: '1px solid',
                          borderRadius: 8,
                          cursor: 'pointer',

                          '&.inactive': {
                            backgroundColor: theme.colors.gray[2],
                            borderColor: theme.colors.gray[2],
                            opacity: 0.7,
                            cursor: 'not-allowed',
                          },
                        })}
                        onClick={() => {
                          if (inactive) return;
                          setForm({ slot });
                          setActive(1, true);
                        }}>
                        <NoozText
                          size={14}
                          weight={700}
                          w={'fit-content'}
                          m={'10px auto'}>
                          {slot.slot}
                        </NoozText>
                      </Box>
                    );
                  },
                )
              )}
            </SimpleGrid>
          </ScrollArea>
        </>
      ) : null}
    </>
  );
};

export default RetailBookingStepDate;
