import React, { useCallback, useEffect } from 'react';
import { Box, clsx, createStyles, Flex, Group } from '@mantine/core';
import { motion } from 'framer-motion';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { useMemo, useState } from 'react';
import {
  IBadge,
  IBlock,
  IImage,
  ILabel,
  IPath,
} from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import HighlightCard from '@/components/Cards/HighlightCard';
import { Carousel, Embla } from '@mantine/carousel';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { useCarouselListIndicatorStyles } from '@/components/Retail/RetailProductExplo';
import useScrollbarClasses from '@/utils/useScrollbarClasses';
import Image from 'next/image';
import Link from '@/components/Link/Link';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';

const MOBILE_BREAKPOINT = 'sm';

const useClasses = () =>
  createStyles((theme) => ({
    container: {
      flexDirection: 'row',
      gap: 30,
      [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
        flexDirection: 'column',
        gap: 20,
      },
    },
    titles: {
      marginLeft: 64,
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1,

      [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
        display: 'none',
      },
    },
    label: {
      fontSize: 42,
      fontWeight: 700,
      cursor: 'pointer',
      color: theme.colors.titleDimmed[0],
      '&.active': {
        color: theme.colors.title[0],
        fontWeight: 900,
      },
      [theme.fn.smallerThan(theme.breakpoints.lg)]: {
        fontSize: 36,
      },
    },
    carousel: {
      flex: 3,
      '& > div': {
        width: '100%',
      },
    },
  }))().classes;

export const carouselStyles = createStyles((theme) => ({
  controls: {
    padding: '10px 0 !important',
    right: 0,
    left: 6,
    height: 'unset',
    top: '0',
    bottom: '0',
    flexDirection: 'row-reverse',
    '& > button:first-of-type': { display: 'none' },
    [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
      display: 'none',
    },
  },
  control: {
    width: 'calc(20% - 25px)',
    height: '110%',
    opacity: 1,
    boxShadow: 'none',
    borderRadius: '1rem 0 0 1rem !important',
    border: 'none',
    padding: 0,
    background:
      'linear-gradient(to left, white 15%, transparent 100%) !important',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '20px !important',
    transition: 'transform .2s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    '&:active': {
      transform: 'scale(1)',
    },
  },
}));

interface CategoryItem {
  image?: IImage;
  label?: ILabel;
  subLabel?: ILabel;
  slug?: IPath;
  badge?: IBadge;
  badgeCount?: IBadge;
}

const CategoriesGrid = ({
  title,
  categories: {
    fields: { items },
  },
}: {
  title: ILabel;
  categories: IBlock;
}) => {
  const classes = useClasses();
  const scrollbarClasses = useScrollbarClasses();
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [activeKey, setActiveKey] = useState(0);
  const [emblaMobile, setEmblaMobile] = useState<Embla | null>(null);
  const categories = useMemo(() => {
    const arr: CategoryItem[] = [];

    if (items) {
      for (let el of items) {
        const { label, image, subLabel, slug, badge, badgeCount } =
          formatCfItems(el);
        arr.push({
          image: image as IImage,
          label: label as ILabel,
          subLabel: subLabel as ILabel,
          slug: slug as IPath,
          badge: badge as IBadge,
          badgeCount: badgeCount as IBadge | undefined,
        });
      }
    }

    return arr.map((e) => ({
      ...e,
      textSize: {
        label: {
          weight: 500,
          size: {
            base: 28,
            xs: 24,
          },
        },
        subLabel: {
          weight: 300,
          size: {
            base: 18,
            xs: 14,
          },
        },
      },
    }));
  }, [items]);

  const classnames = {
    ...useCarouselIndicatorsStyles({}).classes,
    ...useCarouselListIndicatorStyles().classes,
    ...carouselStyles().classes,
  };

  const handleScroll = useCallback(
    (ref: Embla | null, mobile: boolean) => () => {
      if (!ref) return;
      setActiveKey(ref.selectedScrollSnap());
    },
    [embla, categories],
  );

  useEffect(() => {
    if (embla) {
      embla?.on('scroll', handleScroll(embla, false));
      emblaMobile?.on('scroll', handleScroll(emblaMobile, true));
    }
  }, [embla]);

  const carouselProps = {
    classNames: classnames,
    dragFree: false,
    loop: true,
    withControls: true,
    withIndicators: false,
    nextControlIcon: (
      <NoozIcon
        size={48}
        strokeWidth={0.5}
        color='black'
        iconKey='ChevronRight'
      />
    ),
    height: '100%',
  };

  return (
    <>
      <Flex className={clsx(classes.container)}>
        <Flex className={clsx(classes.titles, scrollbarClasses.noScrollbar)}>
          <NoozText
            size={14}
            weight={700}
            sx={(theme) => ({
              [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
                display: 'none',
              },
            })}
            color={'titleDimmed'}
            transform={'uppercase'}>
            {title.fields.text}
          </NoozText>

          {categories.map(({ label, badgeCount: badge }: any, key) => {
            const onTitleClick = () => {
              embla?.scrollTo(key);
            };

            return (
              <Box
                key={label.fields.text}
                sx={(theme) => ({
                  [theme.fn.largerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
                    display: 'flex',
                    gap: 12,
                    alignItems: 'center',
                  },
                })}>
                <NoozText
                  onClick={onTitleClick}
                  className={clsx('button-mobile', classes.label, {
                    active: key === activeKey,
                  })}>
                  {label.fields.text}
                </NoozText>

                {key === activeKey && badge ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: { ease: 'easeOut', duration: 1 },
                    }}
                    exit={{
                      opacity: 0,
                      transition: {
                        ease: 'easeOut',
                        duration: 0.5,
                        delay: 0.4,
                      },
                    }}>
                    <NoozBadge
                      badgeProps={{
                        sx: (theme) => ({
                          [theme.fn.smallerThan(
                            theme.breakpoints[MOBILE_BREAKPOINT],
                          )]: {
                            display: 'none',
                          },
                        }),
                        variant: badge.fields.type || 'filled',
                        size: badge.fields.size || 'lg',
                        color:
                          badge.fields.color?.fields.classname || 'original',
                      }}
                      text={badge.fields.label.fields.text as string}
                      textProps={{
                        weight: 700,
                        size: 14,
                        transform: 'uppercase',
                        color:
                          badge.fields.label.fields.color?.fields.classname ||
                          'white',
                      }}
                    />
                  </motion.div>
                ) : null}
              </Box>
            );
          })}
        </Flex>
        <Flex className={classes.carousel}>
          <Carousel
            {...carouselProps}
            align={'start'}
            dragFree={false}
            slideGap={20}
            getEmblaApi={setEmbla}
            sx={(theme) => ({
              [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
                display: 'none',
              },
            })}
            slideSize={'40%'}>
            {categories.map((cat, idx) => {
              return (
                <Carousel.Slide
                  sx={{ aspectRatio: '3/4', padding: 10 }}
                  key={idx}>
                  <HighlightCard
                    {...(cat as any)}
                    textSize={{
                      label: {
                        size: { base: '42px', xs: '34px' },
                        weight: 600,
                      },
                      subLabel: { size: { base: '24px' } },
                    }}
                    radius={'lg'}
                  />
                </Carousel.Slide>
              );
            })}
          </Carousel>
          <Carousel
            {...carouselProps}
            align={'center'}
            loop
            withIndicators={true}
            getEmblaApi={setEmblaMobile}
            sx={(theme) => ({
              display: 'none',
              [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
                display: 'block',
              },
            })}
            styles={{
              root: {
                marginBottom: '1rem',
              },
              indicators: {
                bottom: '-1rem',
              },
            }}
            slideSize={'90%'}>
            {categories.map((cat, idx) => {
              return (
                <Carousel.Slide
                  sx={{ aspectRatio: '35/50', padding: 5 }}
                  key={idx}>
                  <Link href={cat.slug?.fields.url as string}>
                    <Flex
                      sx={{
                        borderRadius: 10,
                        overflow: 'hidden',
                        flexDirection: 'column',
                        cursor: 'pointer',
                      }}
                      align={'center'}
                      justify={'flex-end'}
                      h={'100%'}
                      w={'100%'}
                      pos={'relative'}
                      p={'0 20px 30px'}>
                      <Image
                        src={cat.image?.fields.media?.fields.file.url as string}
                        alt={cat.label?.fields.text || ''}
                        fill
                        style={{ zIndex: -1, objectFit: 'cover' }}
                      />
                      {cat.badge ? (
                        <NoozBadge // badgeProps={badgeProps}
                          badgeProps={{
                            color: 'dark',
                            size: 'xl',
                            variant: 'blurred',
                            sx: {
                              position: 'absolute',
                              top: '20px',
                              left: '20px',
                              backgroundColor: 'rgba(0, 0, 0, .3)',
                            },
                          }}
                          iconPosition={'right'}
                          textProps={{
                            size: 10,
                            weight: 500,
                            color: 'white',
                          }}
                          icon={
                            cat.badge?.fields.icon ? (
                              <NoozIcon
                                strokeWidth={1.5}
                                size={cat.badge?.fields.icon.fields.size || 18}
                                iconKey={cat.badge?.fields.icon.fields.iconKey}
                                color={
                                  cat.badge?.fields.icon.fields.color?.fields
                                    ?.hex || 'white'
                                }
                              />
                            ) : undefined
                          }
                          text={cat.badge?.fields.label.fields.text as string}
                        />
                      ) : undefined}
                      <NoozText
                        color={'white'}
                        size={42}
                        weight={700}>
                        {cat.label?.fields.text}
                      </NoozText>
                      <NoozText
                        color={'white'}
                        size={10}
                        weight={700}
                        sx={{
                          textDecoration: 'underline',
                          textTransform: 'uppercase',
                        }}>
                        {cat.subLabel?.fields.text}
                      </NoozText>
                    </Flex>
                  </Link>
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </Flex>
      </Flex>
    </>
  );
};

export default CategoriesGrid;
