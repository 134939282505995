import { useEffect, useMemo } from 'react';

const useWindowHeight = () => {
  useEffect(() => {}, [typeof window !== 'undefined' && window.innerHeight]);

  return useMemo(() => {
    return typeof window !== 'undefined' ? `${window.innerHeight}px` : '100vh';
  }, [typeof window !== 'undefined' && window.innerHeight]);
};

export default useWindowHeight;
