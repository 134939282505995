import DataLayerHelper from './Datalayer';
import { SyntheticEvent } from 'react';
import RetailBooking from '@/components/Retail/Booking/RetailBooking';

export const functions: {
  [key: string]: (
    _e: SyntheticEvent<MouseEvent>,
    _actions: { setDrawer?: any },
  ) => void;
} = {
  retailBooking: () => {
    DataLayerHelper.retailBookingInitEvent();
  },
  retailBookingDrawer: (e, { setDrawer }) => {
    e?.preventDefault();
    setDrawer((prev: any) => ({
      ...prev,
      opened: true,
      withCloseButton: true,
      // @ts-ignore
      body: (
        <RetailBooking
          setDrawer={setDrawer}
          onClose={() => {
            setDrawer((prev: any) => ({ ...prev, opened: false }));
          }}
        />
      ),
    }));
  },
};
