const Size = {
  convertFromCf: (size: number | string) => {
    switch (size) {
      case 'xs':
        return 8;
      case 'sm':
        return 12;
      case 'lg':
        return 20;
      case 'xl':
        return 24;
      case '2xl':
        return 28;
      case '3xl':
        return 32;
      case '4xl':
        return 36;
      case '5xl':
        return 40;
      case '6xl':
        return 44;
      case '7xl':
        return 48;
      case 'base':
      default:
        return typeof size === 'string' ? parseInt(size) : size;
    }
  },
};

export default Size;
