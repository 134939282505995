//@ts-nocheck
import {
  IBadge,
  IButton,
  IImage,
  ILabel,
  IPath,
} from '@/@types/generated/contentful';
import React, { useState } from 'react';
import {
  ActionIcon,
  BackgroundImage,
  Box,
  clsx,
  createStyles,
  Flex,
  Group,
  useMantineTheme,
} from '@mantine/core';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { TIMING_FUNCTION } from '@/utils/style/animation';
import useMarketingStyles from '@/components/Cards/useMarketingStyles';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';
import { AnimatePresence, motion } from 'framer-motion';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Link from '../Link/Link';
import { useMediaQuery } from '@mantine/hooks';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';

const useCartContentStyle = createStyles((theme, textSize) => ({
  label: {
    position: 'relative',
    fontSize: textSize.label?.size.base,
    fontWeight: textSize.label?.weight,
    [theme.fn.smallerThan(theme.breakpoints.lg)]: {
      fontSize: textSize.label?.size.xs,
    },
  },
  subLabel: {
    position: 'relative',
    fontWeight: textSize.subLabel?.weight,
    fontSize: textSize.subLabel?.size.base,
    [theme.fn.smallerThan(theme.breakpoints.lg)]: {
      fontSize: textSize.subLabel?.size.xs,
    },
  },
}));

const useBadgeStyle = createStyles(() => ({
  text: {
    color: 'white',
    textTransform: 'capitalize',
    fontSize: 18,
    marginRight: 10,
  },
}));

const BadgeIcon = ({ icon }) =>
  icon ? (
    <NoozIcon
      strokeWidth={1.5}
      size={icon.fields.size || 18}
      iconKey={icon.fields.iconKey}
      color={icon.fields.color?.fields?.hex || 'white'}
    />
  ) : null;

const RenderNoozBadge = ({ badge, badgeProps, iconPosition }) => {
  const badgeStyles = useBadgeStyle();
  return badge ? (
    <NoozBadge
      badgeProps={badgeProps}
      iconPosition={iconPosition}
      textProps={{ size: 16, weight: 500, className: badgeStyles.classes.text }}
      icon={
        <BadgeIcon
          color='white'
          size={18}
          icon={badge.fields.icon}
        />
      }
      text={badge.fields.label.fields.text as string}
    />
  ) : null;
};

const ActionInfoIconButton = ({ isActiveState, onClick }) => {
  const { classes } = useMarketingStyles();
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  return (
    <ActionIcon
      sx={{
        zIndex: 2,
        transform: `rotate(${isActiveState ? 45 : 0}deg)`,
        transition: `transform 0.3s ${TIMING_FUNCTION}`,
      }}
      size='xl'
      radius='xl'
      variant={isDesktop ? 'outline' : 'filled'}
      color='white'
      onClick={onClick}
      className={classes.closeWrapper}>
      <NoozIcon
        iconKey={'Plus'}
        color={isDesktop ? 'white' : 'black'}
        strokeWidth={1.5}
        size={36}
      />
    </ActionIcon>
  );
};

const ActionLinkIconButton = () => {
  const { classes } = useMarketingStyles();
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);

  return (
    <ActionIcon
      size='xl'
      radius='xl'
      variant={isDesktop ? 'outline' : 'filled'}
      color='white'
      className={classes.closeWrapper}>
      <NoozIcon
        iconKey={'ArrowRight'}
        color={isDesktop ? 'white' : 'black'}
        strokeWidth={isDesktop ? 0.7 : 1.5}
        size={28}
      />
    </ActionIcon>
  );
};

const CardContent = ({
  badge,
  variant,
  label,
  labels,
  subLabel,
  textSize,
  originalBadge,
  justifyContent = 'flex-end',
}) => {
  const { classes } = useMarketingStyles();
  const cartContentSytle = useCartContentStyle(textSize);
  return (
    <Box
      className={classes.card}
      sx={{ justifyContent }}>
      <Box mb={10}>
        <RenderNoozBadge
          badge={badge}
          badgeProps={
            originalBadge
              ? {
                  variant: badge.fields.type || 'filled',
                  size: badge.fields.size || 'lg',
                  color: badge.fields.color?.fields.classname || 'dark',
                }
              : {
                  variant: 'blurred',
                  size: 'xl',
                }
          }
          iconPosition={'right'}
        />
      </Box>
      <NoozText
        color={'white'}
        lh={1.2}
        className={cartContentSytle.classes.label}>
        {label?.fields.text}
      </NoozText>

      <Box>
        {labels
          ? (labels.fields.items as ILabel[])?.map(
              (label: ILabel, index: number) => {
                return (
                  <NoozText
                    m={0}
                    lh={1.2}
                    key={index}
                    italic={label?.fields.italic}
                    weight={label?.fields.weight || 500}
                    color={label.fields.color?.fields.classname || 'white'}
                    // @ts-ignore
                    family={label?.fields.family}
                    className={cartContentSytle.classes.label}>
                    {label?.fields.text}
                  </NoozText>
                );
              },
            )
          : null}
      </Box>
      {variant === 'link' && subLabel?.fields && (
        <NoozText
          color={'white'}
          mt={14}
          lh={1.2}
          className={cartContentSytle.classes.subLabel}>
          {subLabel?.fields.text}
        </NoozText>
      )}
    </Box>
  );
};

interface TextSizeProps {
  label: { size: { base: string; xs: string }; weight: number };
  subLabel: { size: { base: sting; xs: string }; weight: number };
}

interface HighlightCardProps {
  subLabel?: ILabel;
  label?: ILabel;
  badge?: IBadge;
  image?: IImage;
  color?: string;
  height?: number;
  slug?: IPath;
  animationVariant?: 'scale' | 'zoom';
  textSize?: TextSizeProps;
  originalColorBadge?: boolean;
  radius?: string;
}

const HighlightCardDefaultTextSize = {
  label: { size: { base: 36, xs: 24 }, weight: 500 },
  subLabel: { size: { base: 24, xs: 24 }, weight: 500 },
};

const createHighlightCardStyles = createStyles(
  (theme, { animationVariant }) => ({
    highlightBackgroundImage: {
      zIndex: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      [theme.fn.largerThan(theme.breakpoints.sm)]: {
        '&: hover': {
          transform: animationVariant === 'zoom' ? 'scale(1.03)' : '',
        },
      },
    },
    transparentBadge: {
      backgroundColor: 'transparent',
      color: theme.white,
      border: '1px solid',
      '& .mantine-Text-root': {
        color: theme.white,
      },
    },
    cardContentWrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      gap: 20,
      padding: 35,
      [theme.fn.smallerThan(theme.breakpoints.lg)]: {
        padding: 20,
        '&.buttons': {
          padding: '40px 20px',
        },
      },
    },
  }),
);

const HighlightCard = ({
  height,
  subLabel,
  label,
  badge,
  image,
  color,
  slug,
  radius = 'xl',
  animationVariant = 'scale',
  textSize = HighlightCardDefaultTextSize,
  labels,
  buttons,
  originalBadge,
}: HighlightCardProps) => {
  const highlightCardStyles = createHighlightCardStyles({ animationVariant });
  const marketingStyles = useMarketingStyles({ animationVariant });

  const hasInfo = !!subLabel;

  const type = slug ? 'link' : hasInfo ? 'info' : 'default';

  const [isActiveState, setIsActiveState] = useState(false);

  const handleCardClick = () => setIsActiveState(!isActiveState);

  const Component = type === 'link' ? Link : 'div';

  return (
    <Box
      h={height}
      className={marketingStyles.classes.cardWrapper}
      sx={(theme) => ({
        position: 'relative',
        borderRadius: theme.radius[radius],
      })}
      onClick={handleCardClick}>
      <Component
        href={slug?.fields.url}
        facet={slug?.fields.facet}
        style={{ position: 'relative', height: '100%', width: '100%' }}>
        <div className={marketingStyles.classes.overlay} />
        <Box
          sx={{
            height: '100%',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
          }}>
          <BackgroundImage
            h='100%'
            // className={classes.uspBackgroundImage}
            src={
              image?.fields.media?.fields.file.url || image?.fields?.file?.url
            }
            fit={'cover'}
            alt={image?.fields.altText}
          />
        </Box>
        <Flex
          className={clsx(highlightCardStyles.classes.cardContentWrapper, {
            buttons,
          })}
          h='100%'
          direction='column'
          justify={buttons ? 'space-between' : 'end'}
          align={buttons ? 'center' : 'end'}
          p={
            ['info', 'link'].includes(type)
              ? '40px 40px 105px'
              : buttons
              ? '40px 0'
              : undefined
          }>
          <CardContent
            variant={type}
            badge={badge}
            label={label}
            labels={labels}
            subLabel={subLabel}
            textSize={textSize}
            originalBadge={originalBadge}
            justifyContent={buttons ? 'flex-start' : undefined}
          />
          {buttons ? (
            <Group
              align={'center'}
              sx={(theme) => ({
                [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                  justifyContent: 'center',
                },
              })}>
              {buttons.fields?.items?.map(
                // @ts-ignore
                (button: IButton, index: number) => {
                  if (!button.fields.slug?.fields.url) return null;
                  return (
                    <Link
                      key={index}
                      href={button.fields.slug?.fields.url as string}>
                      {/* @ts-ignore */}
                      <NoozButton
                        key={index}
                        {...button.fields}
                        variant={button.fields.type || 'filled'}
                        color={
                          button?.fields?.color?.fields?.classname || 'dark'
                        }
                        size={button.fields.size || 'lg'}>
                        {button?.fields?.label?.fields.text}
                      </NoozButton>
                    </Link>
                  );
                },
              )}
            </Group>
          ) : null}
        </Flex>
        {type === 'info' && (
          <AnimatePresence>
            {isActiveState && (
              <motion.div
                initial={{ top: 'unset' }}
                animate={{
                  top: 0,
                  backgroundColor: color,
                  transition: { ease: 'easeOut', duration: 0.5 },
                }}
                exit={{
                  top: 'unset',
                  transition: { ease: 'easeOut', duration: 0.7 },
                }}
                className={marketingStyles.classes.description}>
                <motion.div
                  {...{
                    initial: { opacity: 0 },
                    animate: {
                      opacity: 1,
                      transition: {
                        ease: 'easeOut',
                        duration: 0.5,
                        delay: 0.4,
                      },
                    },
                    exit: {
                      opacity: 0,
                      transition: { ease: 'easeOut', duration: 0.3 },
                    },
                  }}>
                  {badge ? (
                    <NoozBadge
                      badgeProps={{
                        variant: 'filled',
                        color: 'white',
                        size: 'xl',
                      }}
                      textProps={{ size: 16, weight: 500, color: 'dark' }}
                      iconPosition='right'
                      icon={
                        <BadgeIcon
                          color='white'
                          size={18}
                          icon={badge.fields.icon}
                        />
                      }
                      text={badge?.fields.label.fields.text as string}
                    />
                  ) : null}
                  <NoozText
                    mt={10}
                    color='white'
                    size={20}>
                    {subLabel?.fields.text}
                  </NoozText>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        )}
        <Box
          sx={{
            position: 'absolute',
            bottom: 40,
            right: 40,
          }}>
          {type === 'info' && !buttons ? (
            <ActionInfoIconButton
              isActiveState={isActiveState}
              onClick={handleCardClick}
            />
          ) : null}
          {type === 'link' && !buttons ? (
            <ActionLinkIconButton
              isActiveState={isActiveState}
              onClick={handleCardClick}
            />
          ) : null}
        </Box>
      </Component>
    </Box>
  );
};

export default HighlightCard;
