import React, { useEffect, useRef } from 'react';
import Script from 'next/script';
import usePageContext from '@/utils/context/page';

const loadZendeskWidget = (callback: () => void) => {
  const existingScript = document.getElementById('ze-snippet');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=f29fd6b1-3fbd-4b83-8a9f-4d3b5b19ba9c'; // URL for the third-party library being loaded.
    script.id = 'ze-snippet'; // e.g., googleMaps or stripe
    document.body.appendChild(script);

    script.onload = () => {
      // @ts-ignore
      // window.zE('webWidget', 'hide');
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

const AppScripts = () => {
  const { page } = usePageContext();
  const config: any = {};
  // (components || [])?.find((c) => c.fields.key === 'config')?.fields
  //   ?.metaData || {};

  const mount = useRef(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      loadZendeskWidget(() => {
        //@ts-ignore
        window.zESettings = {
          chat: {
            concierge: {
              avatarPath:
                'https://theme.zdassets.com/theme_assets/853722/5aea0806b47d86fa1a0485481023c2d07d4c5c8f.jpg',
              name: 'Alessandra',
              title: { '*': 'Live support' },
            },
          },

          webWidget: {
            color: {
              theme: '#000000',
              launcher: '#000000', // This will also update the badge
              launcherText: '#FFFFFF',
              button: '#1949E7',
              resultLists: '#691840',
              header: '#000000',
              articleLinks: '#691840',
            },
          },
        };

        //@ts-ignore
        if (window.zE) {
          // @ts-ignore
          window.zE('messenger', 'hide');

          if (process.env.HIDE_ZENDESK !== 'true') {
            // @ts-ignore
            if (page?.fields.pageType.hideFooter) {
              // @ts-ignore
              window.zE('messenger', 'hide');
            }
          }
        }
      });
    }
    mount.current = true;
  }, []);

  if (process.env.HIDE_ZENDESK !== 'true') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // @ts-ignore
      if (page && mount && typeof window !== 'undefined' && window?.zE) {
        // @ts-ignore
        window?.zE?.(
          'messenger',
          // @ts-ignore
          config.hideZendesk || page.fields.pageType.hideZendesk
            ? 'hide'
            : 'show',
        );
      }
    }, [page, config]);
  }

  return (
    <>
      {/* google tag manager init */}
      <Script id='google-tag-manager'>
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
      `}
      </Script>
      <script
        type='text/javascript'
        src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
        async></script>
    </>
  );
};

export default AppScripts;
