import { Box, createStyles, Flex } from '@mantine/core';
import React from 'react';
import { IImage, ILink, IVideo } from '@/@types/generated/contentful';
import Link from '@/components/Link/Link';

interface HeroVideoProps {
  svg: IImage;
  mobileSvg: IImage;
  video: IVideo;
  mobileVideo: IVideo;
  link: ILink;
}
const useStyles = createStyles((theme) => ({
  container: {
    position: 'absolute',

    top: '20%',
    left: '10%',
    width: '40%',
    height: '60%',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    [theme.fn.smallerThan(theme.breakpoints.xs)]: {
      top: '5%',
      left: '5%',
      width: '90%',
      height: '40%',
    },
    // [theme.fn.smallerThan(theme.breakpoints.sm)]: {
    //   '&:first-of-type': {
    //     justifyContent: 'flex-start',
    //     paddingTop: '7vw',
    //   },
    //   '&:last-of-type': {
    //     justifyContent: 'flex-end',
    //     paddingBottom: '13vw',
    //   },
    // },
  },
  text: {
    fontSize: '8vw',
    lineHeight: '8vw',
    margin: '0',
    paddingBottom: '2vw',
    fontFamily: 'Mont',
    '&.bottom': {
      margin: '45px 0 20px',
      paddingBottom: 0,
    },
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      fontSize: '12vw',
      lineHeight: '12vw',
      paddingBottom: 0,
      '&.bottom': {
        margin: '0 0 18px',
        paddingBottom: 0,
      },
    },
  },
  buttonText: {
    color: 'black',
    display: 'flex',
    '& svg': {
      marginLeft: 6,
    },
  },
  svg: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    [theme.fn.smallerThan(theme.breakpoints.xs)]: {
      display: 'none',
    },
  },
  svgMobile: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    [theme.fn.largerThan(theme.breakpoints.xs)]: {
      display: 'none',
    },
  },
  desktopVideo: {
    [theme.fn.smallerThan(theme.breakpoints.xs)]: {
      display: 'none',
    },
  },
  mobileVideo: {
    display: 'none',
    [theme.fn.smallerThan(theme.breakpoints.xs)]: {
      display: 'block',
    },
  },
}));

const HeroVideoSvg = ({
  svg,
  mobileSvg,
  video,
  mobileVideo,
  link,
}: HeroVideoProps) => {
  const styles = useStyles();

  return (
    <Link href={link.fields.slug?.fields.url as string}>
      <Box sx={{ position: 'relative', cursor: 'pointer' }}>
        <video
          className={styles.classes.desktopVideo}
          width='100%'
          height='auto'
          poster={video.fields.poster?.fields.media?.fields.file.url}
          autoPlay
          playsInline
          muted
          loop>
          <source
            src={video.fields.url?.fields.file.url}
            type='video/mp4'></source>
        </video>
        <video
          className={styles.classes.mobileVideo}
          width='100%'
          height='auto'
          poster={mobileVideo.fields.poster?.fields.media?.fields.file.url}
          autoPlay
          playsInline
          muted
          loop>
          <source
            src={mobileVideo.fields.url?.fields.file.url}
            type='video/mp4'></source>
        </video>
        <Flex
          direction={'column'}
          className={styles.classes.container}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className={styles.classes.svg}
            src={svg.fields.media?.fields.file.url + '?fm=webp'}
            alt={''}
          />
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className={styles.classes.svgMobile}
            src={mobileSvg.fields.media?.fields.file.url + '?fm=webp'}
            alt={''}
          />
        </Flex>
      </Box>
    </Link>
  );
};
export default HeroVideoSvg;
