import { useEffect, useMemo } from 'react';
import LocaleHelper from '@/helpers/Locale';
import { formatCfItems } from '@/helpers/formatCfItems';
import usePageContext from '@/utils/context/page';
import useCommerceContext from '@/utils/context/commerce';
import ShopifyBuy from 'shopify-buy';
import useCartItems from '@/utils/hook/useCartItems';
import useCartActions from '@/utils/hook/cart/useCartActions';
import useShippingRates from '@/utils/hook/useShippingRates';
import { FREE_SHIPPING_ENABLED } from '@/components/Header/HeaderBanner/headerBanner';

export const useNextGamificationStep = () => {
  const { cart } = useCommerceContext() as unknown as {
    cart: ShopifyBuy.Checkout;
  };
  const cartValue = parseFloat(cart.subtotalPrice.amount as any);
  const { layout, locale } = usePageContext();
  const shippingRates = useShippingRates();
  const { progressSteps } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );
  const [target, percent, missingAmount] = useMemo(() => {
    if (!progressSteps.fields.items) return [null, 100, 0];

    const country = LocaleHelper.splitInfoWithInt(locale).country;
    let nextTransaction: any = null,
      percentage: number = 100,
      missingAmount: number = 0;

    const formattedSteps = progressSteps.fields.items
      .filter(
        (e: any) =>
          e.fields.key !== 'freeShipping' ||
          (e.fields.key === 'freeShipping' &&
            shippingRates?.free &&
            !FREE_SHIPPING_ENABLED),
      )
      .map((step: any) => {
        const props = formatCfItems(step);
        if (step.fields.key === 'freeShipping') {
          if (
            shippingRates?.free &&
            process.env.NEXT_PUBLIC_FREE_SHIPPING_ENABLED !== 'true'
          ) {
            return {
              key: step.fields.key,
              body: props.body,
              amount: parseFloat(shippingRates.free.min_order_subtotal),
            };
          } else {
            return null;
          }
        } else {
          return {
            key: step.fields.key,
            body: props.body,
            amount: parseFloat(
              props.json.fields.value[country] || props.json.fields.value.int,
            ),
          };
        }
      })
      .filter((s: any) => !!s);

    for (let i = 0; i < formattedSteps.length; i++) {
      if (formattedSteps[i].amount > cartValue) {
        nextTransaction = formattedSteps[i];
        if (i > 0) {
          const previousTransaction = formattedSteps[i - 1];
          percentage =
            100 -
            ((nextTransaction.amount - cartValue) /
              (nextTransaction.amount - previousTransaction.amount)) *
              100;
          missingAmount = nextTransaction.amount - cartValue;
        } else {
          percentage = (cartValue * 100) / nextTransaction.amount;
          missingAmount = nextTransaction.amount - cartValue;
        }
        break;
      }
    }

    return [nextTransaction, percentage, missingAmount];
  }, [cartValue, shippingRates]);

  return [target, percent, missingAmount, locale];
};

export const useGamificationGifts = (replaceProduct?: boolean) => {
  const lineItems = useCartItems();
  const { cart } = useCommerceContext() as unknown as {
    cart: ShopifyBuy.Checkout;
  };

  const { layout, locale, gifts } = usePageContext();
  const { progressSteps } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );

  return useMemo(() => {
    if (replaceProduct) {
      const freeItem = lineItems.find((li) => li.__itemType === 'free-item');
      if (freeItem) {
        const step = progressSteps.fields.items.find(
          (s: any) => s.id === freeItem.__itemId,
        );

        const { products } = formatCfItems(step);

        return {
          id: step.id,
          products: products.fields.items.map((pv: any) => gifts[pv.id]),
        };
      }
    }

    for (let step of progressSteps.fields.items) {
      if (step.fields.key === 'freeGift') {
        const { products, json } = formatCfItems(step);
        if (
          json &&
          parseFloat(cart.totalPrice.amount as any) >=
            (json.fields.value[LocaleHelper.splitInfoWithInt(locale).country] ||
              json.fields.value.int) &&
          !lineItems.find((li) => li.__itemId === step.id)
        ) {
          return {
            id: step.id,
            products: products.fields.items.map((pv: any) => gifts[pv.id]),
          };
        }
      }
    }

    return null;
  }, [progressSteps, cart, lineItems, locale, replaceProduct, gifts]);
};

const useGamification = () => {
  const lineItems = useCartItems();
  const { cart } = useCommerceContext() as unknown as {
    cart: ShopifyBuy.Checkout;
  };
  const { removeFromCart } = useCartActions();

  const { layout, locale } = usePageContext();
  const { progressSteps } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );

  useEffect(() => {
    let listToRemove = [];
    if (!cart) return;

    for (let step of progressSteps.fields.items) {
      if (step.fields.key === 'freeGift') {
        const { json } = formatCfItems(step);
        if (
          parseFloat(cart.totalPrice.amount as any) <
          (json.fields.value[LocaleHelper.splitInfoWithInt(locale).country] ||
            json.fields.value.int)
        ) {
          for (let li of lineItems) {
            const liId = li.customAttributes.find((c) => c.key === '__itemId');
            if (liId?.value === step.id) {
              listToRemove.push(li);
            }
          }
        }
      }
    }

    listToRemove = listToRemove.filter(
      (el) => !!lineItems.find((li) => li.__itemId === el.__itemId),
    );
    if (listToRemove.length > 0) {
      removeFromCart(listToRemove);
    }
  }, [cart, lineItems, progressSteps, removeFromCart]);
};

export default useGamification;
