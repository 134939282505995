// @ts-nocheck
import React, { useContext } from 'react';
import {
  ActionIcon,
  Burger,
  Group,
  Indicator,
  MediaQuery,
} from '@mantine/core';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft } from 'tabler-icons-react';
import HeaderMobileNavigationsDrawer from './HeaderNavigationsDrawer/HeaderMobileNavigationsDrawer';
import { HeaderActiveTabContext, HeaderDrawerContext } from '../Header';
import { SearchDrawerContext } from '@/components/Search/SearchDrawer/SearchDrawer';
import useDrawerContext, {
  drawerContextInitState,
} from '@/utils/context/drawer';

export default function HeaderMobileNavigations() {
  const { setDrawer } = useDrawerContext();

  const { headerDrawerState, headerDrawerBodyState } =
    useContext(HeaderDrawerContext);
  const searchDrawerState = useContext(SearchDrawerContext);
  const { headerActiveTabState } = useContext(HeaderActiveTabContext);

  const handleOnBurgerClick = () => {
    setDrawer(drawerContextInitState.drawer); // close all drawers
    headerDrawerBodyState[1](<HeaderMobileNavigationsDrawer />);
    headerDrawerState[1]((prev) => {
      return {
        ...prev,
        size: 'calc(100vw - 30px)',
        position: 'left',
        opened: !prev.opened,
        onClose: () => {
          headerDrawerState[1]((prev) => {
            return {
              ...prev,
              opened: false,
            };
          });
          headerActiveTabState[1](null);
        },
      };
    });
  };

  const handleOnReturnClick = () => {
    headerDrawerBodyState[1](<HeaderMobileNavigationsDrawer />);
    headerActiveTabState[1](null);
  };
  return (
    !searchDrawerState[0].opened && (
      <Group>
        {headerActiveTabState[0] ? (
          <AnimatePresence initial={false}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}>
              <ActionIcon
                onClick={handleOnReturnClick}
                size='lg'
                color={'black'}
                radius='xl'
                variant='light'>
                <ChevronLeft strokeWidth={1.5} />
              </ActionIcon>
            </motion.div>
          </AnimatePresence>
        ) : (
          <motion.div
            key='burger'
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <Burger
              aria-label={'Menu'}
              sx={{ color: 'inherit', transition: 'color 0.1s ease-in-out' }}
              onClick={handleOnBurgerClick}
              opened={headerDrawerState[0].opened}
            />
          </motion.div>
        )}
      </Group>
    )
  );
}
