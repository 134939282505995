import React, { useMemo } from 'react';
import { Box, Flex, Grid, useMantineTheme } from '@mantine/core';
import ProductCard from '@/components/Collection/ProductCard/ProductCard';
import CategoryMoreResults from '../CategoryMoreResults/CategoryMoreResults';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { IProductCard } from '@/interface.custom';
import i18n from '@/helpers/i18n';
import useStaticContent from '@/utils/hook/useStaticContent';
import { IBlock } from '@/@types/generated/contentful';
import usePageContext from '@/utils/context/page';
import HighlightCard from '@/components/Cards/HighlightCard';
import {
  SHOP_ITEMS_ACTION,
  useFilteredShopItems,
  useShopItemsDispatch,
} from '@/templates/ProductCategory.context';
import { formatCfItems } from '@/helpers/formatCfItems';
import { ShopItem } from '@/@types/shopitem';

interface CategoryFilterListProps {
  cols?: number;
  cardProps?: IProductCard;
  customCardElements?: any;
  premiumEnabled?: boolean;
}

export const EmptyCategoryList = () => {
  const staticContent = useStaticContent();
  return (
    <Flex
      w='100%'
      align={'center'}
      justify={'start'}>
      <NoozText
        size={40}
        color='gray'
        weight='500'>
        {i18n(staticContent, 'noProductFound')}
      </NoozText>
    </Flex>
  );
};
const interval = 5;

const CategoryFilterList = ({ premiumEnabled }: CategoryFilterListProps) => {
  const shopItems = useFilteredShopItems();
  const { productCategory } = usePageContext();
  const theme = useMantineTheme();
  const dispatch = useShopItemsDispatch();

  const list = useMemo(() => {
    let l = [...shopItems.items?.slice(0, shopItems.quantityToShow)];

    let pos = 2;
    let index = 0;
    const marketingItems: IBlock[] = productCategory?.fields
      .marketingItems as IBlock[];

    //@ts-ignore
    const uspsItems: IBlock[] = productCategory?.fields.usps as IBlock[];
    const cardItems = [...(marketingItems || []), ...(uspsItems || [])]?.slice(
      0,
      3,
    );
    while (pos < l.length && index < cardItems?.length) {
      //@ts-ignore
      l.splice(pos, 0, cardItems[index]);
      pos += interval;
      index += 1;
    }

    //@ts-ignore
    if (index < cardItems?.length) l = l.concat(cardItems?.slice(index));
    return l;
  }, [shopItems, productCategory]);

  return (
    <>
      {list?.length > 0 ? (
        <Grid
          gutter={5}
          gutterSm={20}
          gutterMd={30}
          gutterLg={40}>
          {list?.map((hit: any, key: number) => {
            if (hit?.fields?.title) {
              let item = hit?.fields;

              if (hit?.fields?.items) {
                item = formatCfItems(hit);
              }

              const color =
                theme.colors[
                  productCategory?.fields.color?.fields.classname as string
                ]?.[0] || theme.colors.main[0];

              return (
                <Grid.Col
                  key={key}
                  span={12}
                  md={4}
                  sx={(theme) => ({
                    [theme.fn.smallerThan('md')]: {
                      margin: '16px 0px',
                    },
                  })}
                  h={{ base: 400, sm: 'auto', md: 'auto', lg: 'auto' }}>
                  <HighlightCard
                    {...item}
                    color={color}
                  />
                </Grid.Col>
              );
            } else {
              return (
                <Grid.Col
                  key={key}
                  span={6}
                  md={4}>
                  <ProductCard
                    key={hit.defaultVariantId}
                    item={hit as ShopItem}
                    position={hit.ranking}
                  />
                </Grid.Col>
              );
            }
          })}
        </Grid>
      ) : (
        <EmptyCategoryList />
      )}
      {shopItems.quantity > 0 &&
      shopItems.quantityToShow < shopItems.quantity ? (
        <Box py={50}>
          <CategoryMoreResults
            remaining={shopItems.quantity - shopItems.quantityToShow}
            onClick={() => {
              dispatch({ type: SHOP_ITEMS_ACTION.SHOW_MORE });
            }}
          />
        </Box>
      ) : null}
    </>
  );
};

export default CategoryFilterList;
