import { Flex } from '@mantine/core';
import { Star } from 'tabler-icons-react';
import { memo } from 'react';

interface StarsProps {
  stars: number;
  color?: string;
}

const Stars = memo(({ stars, color }: StarsProps) => {
  return (
    <Flex sx={{ gap: '4px' }}>
      {Array(5)
        .fill({})
        .map((_, key) =>
          key < stars ? (
            <Flex
              key={key}
              align={'center'}
              justify={'center'}
              sx={{
                width: 20,
                height: 20,
                backgroundColor: color,
              }}>
              <Star
                fill={key < stars ? color && 'white' : 'transparent'}
                color={key < stars ? color && 'white' : 'transparent'}
                size={12}
              />
            </Flex>
          ) : null,
        )}
    </Flex>
  );
});

export default Stars;
