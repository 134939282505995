import { Title, TitleProps } from '@mantine/core';
import React, { useMemo } from 'react';

const NoozTitle = (props: TitleProps) => {
  const defaultedProps = useMemo<TitleProps>(
    () => ({
      ...props,
    }),
    [props],
  );

  return <Title {...defaultedProps} />;
};

export const NoozSectionTitle = (props: TitleProps) => {
  return (
    <NoozTitle
      order={3}
      mb={28}
      sx={(theme) => ({
        [theme.fn.smallerThan(theme.breakpoints.lg)]: {
          fontSize: 24,
        },
      })}
      fw={500}
      size={42}
      color='title'>
      {props.children}
    </NoozTitle>
  );
};

export default NoozTitle;
