import { Box, clsx, createStyles } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { memo, useMemo, useState } from 'react';
import { ProductCardColorPickerProps } from '@/interface.custom';
import Image from 'next/image';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { ShopItemVariant } from '@/@types/shopitem';

const useStyles = createStyles((theme, { mini }: { mini?: boolean }) => ({
  wrapper: {
    width: '100%',
    position: 'relative',
    marginTop: 20,
  },
  variantPicker: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    gap: mini ? 2 : 16,
    flexWrap: 'nowrap',
    transition: 'all .5s ease-out',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      gap: 2,
    },
  },
  variantThumbnail: {
    aspectRatio: '1',
    borderRadius: 4,
    cursor: 'pointer',
    flex: mini ? `0 0 calc((100% - 6px) / 4)` : '0 0 calc((100% - 80px) / 6)',
    overflow: 'hidden',
    position: 'relative',
    border: '2px solid transparent',
    '&.active': {
      borderColor: `${theme.colors.black[0]}`,
    },
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      flex: `0 0 calc((100% - 6px) / 4)`,
    },
  },
  paginationWrapper: {
    alignItems: 'center',
    aspectRatio: '1',
    background: theme.colors.lightGray[0],
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    border: '2px solid transparent',
    width: mini ? `calc((100% - 6px) / 4)` : `calc((100% - 80px) / 6)`,
    '&:hover': {
      borderColor: `${theme.colors.black[0]}`,
    },
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      width: `calc((100% - 6px) / 4)`,
    },
    '&.left': {
      left: 0,
    },
    '&.right': {
      right: 0,
    },
  },
  colorPicker: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  colorOption: {
    height: '1rem',
    width: '1rem',
    border: '1px solid grey',
    cursor: 'pointer',
    borderRadius: '1rem',
    ['&.active']: {
      border: '2px solid black',
      borderColor: `${theme.colors.black[0]}`,
    },
  },
  colorQty: {
    color: '#585858',
    fontSize: '16px',
    weight: '400',
    lineHeight: 0,
  },
}));

const ProductCardVariantPicker = memo(
  ({
    variants: vs,
    setVariant,
    variant,
    mini,
    sx,
    hasColorPicker,
  }: ProductCardColorPickerProps) => {
    const variants = useMemo(() => {
      let arr: any[] = [];
      if (vs) {
        for (let el of vs) {
          if (
            !arr.find(
              (v) =>
                v.title.replace(/(-L$)|(-M$)|(-S$)/, '') ===
                el.title.replace(/(-L$)|(-M$)|(-S$)/, ''),
            )
          )
            arr.push(el);
        }
      }

      return arr;
    }, [vs]);

    const { classes } = useStyles({ mini });
    const [page, setPage] = useState(0);

    return variants?.length > 0 && hasColorPicker ? (
      <Box
        className={clsx(classes.wrapper)}
        onClick={(e) => e.stopPropagation}>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={clsx(classes.variantPicker)}
          sx={(theme) => ({
            ...(sx || {}),
            transform: mini
              ? `translateX(calc(-${page * 50}% - ${page ? page : 0}px))`
              : `translateX(${`calc(-${((page * 2) / 3) * 100}% - ${
                  page * 11
                }px)`})`,
            [theme.fn.smallerThan(theme.breakpoints.sm)]: {
              transform: `translateX(calc(-${page * 50}% - ${
                page ? page : 0
              }px))`,
            },
          })}>
          {/* mini images  */}
          {variants.map((v: ShopItemVariant, index) => {
            const vId = v.id;
            const variantId = variant.id;
            const active = vId && variantId && vId === variantId;

            return (
              <Box
                key={`${variantId}_${index}`}
                className={clsx(classes.variantThumbnail, { active })}
                onMouseEnter={() => {
                  setVariant(v);
                }}
                onClick={() => {
                  setVariant(v);
                }}>
                <Image
                  src={`${v.posterImage || v.images[0]}?fit=thumb`}
                  alt={v.title}
                  fill={true}
                  style={{
                    objectFit: 'contain',
                  }}
                  onError={(e) => (e.currentTarget.src = '/favicon-32x32.ico')}
                />
              </Box>
            );
          })}
        </Box>

        {/* Pagination */}
        {page === 0 ? null : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { ease: 'easeOut', duration: 0.3 },
            }}
            exit={{
              opacity: 0,
              transition: { ease: 'easeOut', duration: 0.3 },
            }}>
            <Box
              sx={(theme) => ({
                display: variants.length <= 6 ? 'none' : 'flex',
                [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                  display: variants.length <= 4 ? 'none' : 'flex',
                },
              })}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setPage((prev) => prev - 1);
              }}
              className={clsx(classes.paginationWrapper, 'left')}>
              <NoozIcon
                iconKey={'ChevronLeft'}
                strokeWidth={0.7}
                size={24}
              />
            </Box>
          </motion.div>
        )}
        {
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { ease: 'easeOut', duration: 0.3 },
            }}
            exit={{
              opacity: 0,
              transition: { ease: 'easeOut', duration: 0.3 },
            }}>
            <Box
              sx={(theme) => ({
                display:
                  ((variants.length === 6 ||
                    variants.length - 5 - page * 4 <= 0) &&
                    !mini) ||
                  (mini &&
                    (variants.length === 4 ||
                      variants.length - 3 - page * 2 <= 0))
                    ? 'none'
                    : 'flex',
                [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                  display:
                    variants.length === 4 || variants.length - 3 - page * 2 <= 0
                      ? 'none'
                      : 'flex',
                },
              })}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setPage((prev) => {
                  return prev + 1;
                });
              }}
              className={clsx(classes.paginationWrapper, 'right')}>
              <NoozIcon
                iconKey={'ChevronRight'}
                size={24}
              />
            </Box>
          </motion.div>
        }
      </Box>
    ) : null;
  },
);

export default ProductCardVariantPicker;
