import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';
import { MantineGradient } from '@mantine/core';
import React from 'react';
import usePageContext from '@/utils/context/page';
import { ITemplate } from '@/@types/generated/contentful';
import { linearHotGradient } from '@/utils/style/useHighlightStyles';

export const NoozBadgeNew = ({ active }: { active?: boolean }) => {
  const {
    layout: {
      fields: { newBadge },
    },
  } = usePageContext() as { layout: ITemplate };

  return active ? (
    <NoozBadge
      badgeProps={{
        size: 'xl',
        variant: newBadge.fields.type || 'gradient',
        sx: (theme) =>
          newBadge.fields.type === 'white'
            ? {
                border: `0.0625rem solid ${newBadge.fields.color?.fields.hex}`,
                background: 'white',
              }
            : newBadge.fields.type === 'filled'
            ? {
                background:
                  theme.colors[
                    newBadge.fields.color?.fields.classname as any
                  ]?.[0] || newBadge.fields.color?.fields.hex,
              }
            : {},
        gradient:
          newBadge.fields.type === 'gradient'
            ? ((newBadge.fields.color?.fields.gradientObject || undefined) as
                | MantineGradient
                | undefined)
            : undefined,
      }}
      textProps={{
        size: 'sm',
        sx: (theme) =>
          newBadge.fields.type === 'gradient'
            ? {
                color: 'white',
                // gradient text
                // background: newBadge?.fields?.label?.fields?.color?.fields.hex,
                // WebkitBackgroundClip: 'text',
                // WebkitTextFillColor: 'transparent',
              }
            : {
                color:
                  newBadge?.fields?.label?.fields?.color?.fields.hex ||
                  (newBadge.fields.type === 'white'
                    ? theme.colors.dark[4]
                    : 'white'),
              },
      }}
      text={newBadge.fields.label?.fields?.text || 'New'}
    />
  ) : null;
};
