const zIndexes = {
  header: 1000,
  popover: 3000,
  tooltip: 4000,
  snackbar: 5000,
  drawer: 6000,
  appbar: 8000,
  modal: 9000,
};

export default zIndexes;
