import React from 'react';
import { Box } from '@mantine/core';
import usePageContext from '@/utils/context/page';
import { IPopupFields } from '@/@types/generated/contentful';
import { ShopStoryEl } from '../Shopstory/Shopstory';

const MainPopup = () => {
  const { layout } = usePageContext();

  const { mainShopstoryBlock } = layout?.fields.popup?.fields as IPopupFields;

  return (
    <Box sx={{ overflow: 'none', color: 'white' }}>
      {mainShopstoryBlock && (
        <ShopStoryEl shopstoryBlock={mainShopstoryBlock} />
      )}
    </Box>
  );
};

export default MainPopup;
