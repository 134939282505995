import React, { useState, useCallback, useMemo } from 'react';
import { Flex, Stack } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import ShopifyBuy from 'shopify-buy';
import Link from '@/components/Link/Link';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozImage from '@/components/Nooz/NoozImage/NoozImage';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import useCartActions from '@/utils/hook/cart/useCartActions';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import generateUUID from '@/helpers/uuid';
import Currency from '@/helpers/Currency';
import { IImageFields, IProduct } from '@/@types/generated/contentful';
import { ICartItem } from '@/interface.custom';
import { useCartStock } from '@/utils/hook/cart/useCartStock';
import usePageContext from '@/utils/context/page';

interface UpsellItemProps {
  id: string;
  productShopifyId: string;
  shopifyId: string;
  posterImage?: IImageFields;
  label?: string;
  subLabel?: string;
  price?: { amount: string; currencyCode: string };
  product: IProduct;
  shopifyInfo: ShopifyBuy.ProductVariant;
}

const ProductCarouselSlide = ({
  product,
  loading,
  onAddToCart,
  label,
  soldOut,
}: {
  product: UpsellItemProps;
  loading: boolean;
  onAddToCart: (_product: UpsellItemProps) => void;
  label: string;
  soldOut: string;
}) => {
  const { upsellStock } = useCartStock();
  const hasStock = upsellStock[product.shopifyId] > 0;
  return (
    <Carousel.Slide key={product.id}>
      <Flex gap={24}>
        <NoozImage
          src={product.posterImage?.media?.fields.file.url}
          height={70}
          width={70}
          style={{ objectFit: 'contain', borderRadius: 10 }}
        />
        <Flex
          direction='column'
          gap={6}>
          <Link href={product.product.fields.slug?.fields.url as string}>
            <NoozText
              size={14}
              color='title'
              lh='16.8px'
              weight={700}
              sx={{ '&:hover': { textDecoration: 'underline' } }}>
              {product.label}
            </NoozText>
          </Link>
          <NoozText
            size={14}
            color='text'
            lh='16.8px'
            weight={500}>
            {Currency.moneyToPrice(product.shopifyInfo.price)}
          </NoozText>
          <NoozButton
            disabled={
              !product.shopifyInfo.availableForSale || loading || !hasStock
            }
            variant='outline'
            size='xs'
            radius='xl'
            onClick={() => onAddToCart(product)}>
            {hasStock ? label : soldOut}
          </NoozButton>
        </Flex>
      </Flex>
    </Carousel.Slide>
  );
};

const CartDrawerUpsellCarousel = ({
  label,
  soldOut,
}: {
  label: string;
  soldOut: string;
}) => {
  const { upsell, stocks } = usePageContext();
  const products = useMemo(() => {
    return Object.values(upsell).map((p) => ({
      ...p,
      shopifyInfo: {
        ...p.shopifyInfo,
        availableForSale: stocks[p.shopifyInfo.id] > 0,
      },
    }));
  }, [upsell, stocks]);
  const { addToCart } = useCartActions();
  const [loading, setLoading] = useState(false);
  const carouselIndicatorsStyles = useCarouselIndicatorsStyles({
    color: 'white',
  });

  const handleAddToCart = useCallback(
    (product: UpsellItemProps) => {
      setLoading(true);
      const cartItem: ICartItem = {
        quantity: 1,
        type: 'item',
        dataLayerItem: {
          item_id: parseInt(
            product.shopifyInfo.id.replace('gid://shopify/ProductVariant/', ''),
          ),
          parent_item_id: parseInt(
            product.productShopifyId.replace('gid://shopify/Product/', ''),
          ),
          item_name: product.product.fields.label?.fields.text,
          item_variant: product.shopifyInfo.title,
          price: parseFloat(product.shopifyInfo.price.amount?.toString()),
          quantity: 1,
          item_list_id: 'upsell_products',
          item_list_name: 'Upsell Products',
        },
        shopifyVariant: product.shopifyInfo,
        contentfulItem: {
          title: product.product.fields.label?.fields.text,
          //@ts-ignore
          image: { fields: product.posterImage },
          price: Currency.toFormattedPrice(
            product.shopifyInfo.price.amount?.toString(),
          ),
          discountPrice: product.shopifyInfo.compareAtPrice
            ? Currency.toFormattedPrice(
                product.shopifyInfo.compareAtPrice.amount?.toString(),
              )
            : undefined,
          currencyCode: product.shopifyInfo.price.currencyCode,
          url: product.product.fields.slug?.fields.url,
          id: product.id,
        },
      };
      addToCart(
        [cartItem],
        [
          { key: '__itemId', value: generateUUID() },
          { key: '__itemType', value: 'single' },
        ],
      ).finally(() => {
        setLoading(false);
      });
    },
    [addToCart],
  );

  if (products.length === 0) return null;

  return (
    <Stack
      spacing={16}
      py={16}
      sx={{ background: 'white', borderRadius: 16 }}>
      <Carousel
        w='100%'
        loop
        align='start'
        slideSize='250px'
        slideGap={16}
        classNames={carouselIndicatorsStyles.classes}
        styles={{ viewport: { paddingLeft: 16 } }}
        withControls={false}
        withIndicators={false}>
        {products.map((product, key) => (
          <ProductCarouselSlide
            key={product.id || key}
            product={product}
            loading={loading}
            onAddToCart={handleAddToCart}
            label={label}
            soldOut={soldOut}
          />
        ))}
      </Carousel>
    </Stack>
  );
};

export default CartDrawerUpsellCarousel;
