import { ActionIcon, Flex, Group } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import React from 'react';
import { ILabel } from '@/@types/generated/contentful';

const CartDrawerHeader = ({
  closeDrawer,
  title,
  count,
}: {
  closeDrawer: () => any;
  title: ILabel;
  count?: number;
}) => {
  return (
    <>
      <Group
        p={'8px 16px'}
        position={'apart'}>
        <Group>
          <NoozText
            size={40}
            weight={700}
            color={'title'}>
            {title.fields.text}
          </NoozText>
          {count ? (
            <Flex
              align={'center'}
              justify={'center'}
              sx={(theme) => ({
                backgroundColor: theme.colors.main[0],
                height: 24,
                width: 24,
                borderRadius: 24,
              })}>
              <NoozText
                color={'white'}
                size={12}>
                {count}
              </NoozText>
            </Flex>
          ) : null}
        </Group>
        <ActionIcon
          color={'title'}
          onClick={closeDrawer}
          variant={'outline'}
          radius={99}>
          <NoozIcon iconKey={'X'} />
        </ActionIcon>
      </Group>
    </>
  );
};

export default CartDrawerHeader;
