import React, { useState, useCallback, ReactNode } from 'react';
import { Card, Group, createStyles, Box } from '@mantine/core';
import ProductCardBadges from '@/components/Collection/ProductCard/ProductCardBadges';
import ProductCardImages from '@/components/Collection/ProductCard/ProductCardImages/ProductCardImages';
import ProductCardPrice from './ProductCardPrice';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import ProductCardVariantPicker from '@/components/Collection/ProductCard/ProductCardVariantPicker/ProductCardVariantPicker';
import { ShopItem, ShopItemVariant } from '@/@types/shopitem';

export const useStyles = createStyles((theme, { type }: { type: string }) => ({
  card: {
    padding: 0,
    height: '100%',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    cursor: 'pointer',
  },
  imageSection: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.gray[1],
    width: '100%',
    // padding: '100% 0 0',
    margin: 0,
    [theme.fn.smallerThan('xs')]: {
      padding: 0,
      height: '100%',
    },
  },
  imageColorPicker: {
    padding: '12px 0px 6px 0px',
  },
  productLabelWrapper: {
    marginTop: 10,
    minHeight: '56px',
    alignItems: 'start',
  },
  productTitle: {
    margin: 0,
    fontSize: type === 'new' ? 18 : 20,
    color: '#2D2D2D',
  },
}));

interface ProductCardUiProps {
  item: ShopItem;
  variant: ShopItemVariant;
  setVariant: React.Dispatch<React.SetStateAction<ShopItemVariant>>;
  label: ReactNode;
  position: number;
  newIsVariantCustom?: boolean;
  elementKey?: string;
  forceMini?: boolean;
  premiumEnabled?: boolean;
}

const ProductCardDefault = React.memo(
  ({
    item,
    label,
    setVariant,
    variant,
    premiumEnabled,
  }: ProductCardUiProps) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const { classes } = useStyles({ type: 'default' });

    const handleMouseEnter = useCallback(() => {
      setHovered(true);
    }, []);

    const handleTouchStart = useCallback(() => {
      setHovered(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
      setTimeout(() => setHovered(false), 250);
    }, []);

    const handleTouchEnd = useCallback(() => {
      setTimeout(() => setHovered(false), 250);
    }, []);

    return (
      <Card
        p={0}
        className={classes.card}>
        <Box
          onMouseEnter={handleMouseEnter}
          onTouchStart={handleTouchStart}
          onMouseLeave={handleMouseLeave}
          onTouchEnd={handleTouchEnd}
          className={classes.imageSection}>
          <ProductCardBadges
            {...item}
            variant={variant}
            premiumEnabled={premiumEnabled}
          />
          <ProductCardImages
            {...variant}
            premiumEnabled={premiumEnabled}
            variantId={variant.id}
            hovered={hovered}
            productLifestyleImages={item.product?.lifestyleImages as string[]}
          />
        </Box>

        <ProductCardVariantPicker
          hasColorPicker={true}
          variants={item.variants as ShopItemVariant[]}
          variant={variant}
          setVariant={setVariant}
          className={classes.imageColorPicker}
        />

        <Group
          position='apart'
          noWrap
          spacing={40}
          className={classes.productLabelWrapper}>
          <NoozText
            weight={500}
            component='h3'
            className={classes.productTitle}>
            {label}
          </NoozText>
          <ProductCardPrice item={variant} />
        </Group>
      </Card>
    );
  },
);

export default ProductCardDefault;
