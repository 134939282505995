import * as contentful from 'contentful';
import axios from 'axios';

const NoozContentful = contentful.createClient({
  space: process.env.NEXT_PUBLIC_CF_SPACE_ID as string,
  environment: process.env.NEXT_PUBLIC_CF_ENVIRONMENT as string,
  accessToken: process.env.NEXT_PUBLIC_CF_DELIVERY_ACCESS_TOKEN as string,
  removeUnresolved: true,
});
export const NoozContentfulMaster = contentful.createClient({
  space: process.env.NEXT_PUBLIC_CF_SPACE_ID as string,
  environment: 'master',
  accessToken: process.env.NEXT_PUBLIC_CF_DELIVERY_ACCESS_TOKEN as string,
  removeUnresolved: true,
});

export const NoozCfGraphQl = axios.create({
  baseURL: `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CF_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CF_ENVIRONMENT}`,
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_CF_DELIVERY_ACCESS_TOKEN}`,
  },
});

export default NoozContentful;
