//@ts-nocheck
import React, { useContext, useEffect } from 'react';
import { createStyles, Drawer, useMantineTheme } from '@mantine/core';
import {
  HeaderActiveTabContext,
  HeaderDrawerContext,
  headerDrawerInitState,
} from '../Header';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import { TIMING_FUNCTION } from '@/utils/style/animation';
import { useRouter } from 'next/router';

const useStyles = createStyles((theme, headerHeight) => ({
  inner: {
    height: `calc(100vh - ${headerHeight}px)`,
    top: headerHeight,
    '* > .mantine-Drawer-body': {
      height: '100%',
      overflow: 'auto',
    },
  },
}));

export default function HeaderDrawer() {
  const router = useRouter();
  const theme = useMantineTheme();
  const headerHeight = useHeaderHeight();
  const { classes } = useStyles(headerHeight.wrapper + headerHeight.marginTop);

  const { headerActiveTabState } = useContext(HeaderActiveTabContext);
  const { headerDrawerState, headerDrawerBodyState } =
    useContext(HeaderDrawerContext);

  useEffect(() => {
    headerDrawerState[1]({ ...headerDrawerState[0], opened: false });
    headerActiveTabState[1](null);
  }, [router.asPath]);

  const overlayColor =
    theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[5];

  return (
    <Drawer
      {...headerDrawerState[0]}
      keepMounted={true}
      overlayProps={{
        color: overlayColor,
        onMouseEnter: () => {
          headerDrawerState[1]((prevState) => ({
            ...prevState,
            opened: false,
          }));

          headerActiveTabState[1](null);
        },
        blur: 6,
      }}
      onClose={() => {
        headerDrawerState[1]((prevState) => ({ ...prevState, opened: false }));
        headerActiveTabState[1](null);
      }}
      withCloseButton={false}
      transitionProps={{ duration: 300, timingFunction: TIMING_FUNCTION }}
      scrollAreaComponent={Drawer.NativeScrollArea}
      trapFocus={false}
      classNames={{ inner: classes.inner }}>
      {headerDrawerBodyState[0]}
    </Drawer>
  );
}
