import Currency from '@/helpers/Currency';
import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import ShopifyBuy from 'shopify-buy';
import { LocalStorage, LocalStorageKeys } from '../LocalStorage';
import usePageContext from './page';
import { ICommerceContext } from '@/interface.custom';
import {
  createShopifyCart,
  fetchShopifyCart,
} from '@/utils/services/ShopstoryStorefrontService';
import generateUUID from '@/helpers/uuid';
import { IProduct } from '@/@types/generated/contentful';
import LocaleHelper from '@/helpers/Locale';
import CartStockProvider from '@/utils/hook/cart/useCartStock';
import { FREE_SHIPPING_ENABLED } from '@/components/Header/HeaderBanner/headerBanner';
import { ShopItem } from '@/@types/shopitem';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';

export const CommerceContext = React.createContext<ICommerceContext>({
  client: null,
  cart: null,
  freeShipping: null,
  setCart: () => {
    throw Error('You forgot to wrap this in a Provider object');
  },
  recommendations: [],
} as unknown as ICommerceContext);
export interface CommerceProviderProps {
  children: React.ReactNode;
}

const useCommerceContext = (): ICommerceContext => {
  return useContext(CommerceContext);
};

export const useDiscounts = () =>
  useContext(CommerceContext).cart?.discountApplications;

const CommerceContextProvider = ({ children }: CommerceProviderProps) => {
  const [recommendations, setReco] = useState<ShopItem[]>([]);
  const { freeItem } = usePageContext() as unknown as { freeItem?: IProduct };
  const freeVariant: ShopifyBuy.CheckoutLineItemInput | null = useMemo(
    () =>
      !freeItem
        ? null
        : {
            variantId: 'gid://shopify/ProductVariant/47007321555290',
            quantity: 1,
            customAttributes: [
              { key: '__itemType', value: 'single' },
              { key: '__itemId', value: generateUUID() },
              {
                key: '__dataLayerItem',
                value: JSON.stringify({
                  item_id: freeItem.fields.variants?.[0].fields.title,
                  item_name: freeItem.fields.label?.fields.text,
                  item_variant: freeItem.fields.variants?.[0].fields.title,
                  price: 0.0,
                  quantity: 1,
                }),
              },
              {
                key: '__contentfulItem',
                value: JSON.stringify({
                  title: freeItem.fields.label?.fields.text,
                  image:
                    freeItem.fields.variants?.[0].fields.posterImage || null,
                }),
              },
            ],
          },
    [freeItem],
  );
  const [mount, setMount] = useState(false);
  const { shippingZone, geoMarket, locale: lc } = usePageContext();
  const locale = useMemo(
    () =>
      lc === 'en-int'
        ? `en-${LocaleHelper.getValidShopifyCountry(lc).toLowerCase()}`
        : lc,
    [lc],
  );
  const [cart, setCart] = useState<ShopifyBuy.Checkout | null>(
    LocalStorage.getInitialCart(),
  );
  const currencyCode = useMemo(() => {
    // @ts-ignore
    return geoMarket?.currencySettings?.baseCurrency.currencyCode;
  }, [geoMarket]);

  const freeShipping = useMemo(() => {
    const freeShippingRate =
      shippingZone?.zone?.price_based_shipping_rates?.find(
        (zone: Record<string, string>) => {
          return parseFloat(zone.price) === 0;
        },
      );

    const price = freeShippingRate?.min_order_subtotal;
    const priceLabel = `${parseInt(price?.toString())}${Currency.toSymbol(
      currencyCode,
    )}`;

    return {
      amount: price,
      priceLabel: priceLabel,
      isActive: FREE_SHIPPING_ENABLED ? false : !!freeShippingRate,
    };
  }, [shippingZone]);

  const client = useRef(
    ShopifyBuy.buildClient({
      storefrontAccessToken: process.env
        .NEXT_PUBLIC_SHOPIFY_SF_PUBLIC_TOKEN as string,
      domain: process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN as string,
      apiVersion: '2023-01',
      language: locale,
    }),
  );

  useEffect(() => {
    async function getNewCart() {
      const newCart = await createShopifyCart(
        {
          ...geoMarket,
          name: LocaleHelper.getValidShopifyCountry(locale as string),
        },
        locale as string,
      );

      LocalStorage.set(LocalStorageKeys.CART, JSON.stringify(newCart));
      setCart(newCart);
    }

    async function refreshExistingCart(cartId: string) {
      try {
        const refreshedCart = await fetchShopifyCart(
          {
            ...geoMarket,
            name: LocaleHelper.getValidShopifyCountry(locale as string),
          },
          cartId,
        );

        if (
          refreshedCart == null ||
          (process.env.NEXT_PUBLIC_OLDEST_CART &&
            new Date(refreshedCart.createdAt).getTime() -
              new Date(
                process.env.NEXT_PUBLIC_OLDEST_CART as string,
              ).getTime() <
              0) ||
          refreshedCart.completedAt
        ) {
          return getNewCart();
        }

        const cartHasBeenPurchased = Boolean(refreshedCart.completedAt);

        if (cartHasBeenPurchased) {
          getNewCart().then(() => {});
        } else {
          const getLocaleCurrency = (locale: string) => {
            const { country } = LocaleHelper.splitInfo(locale, true);
            switch (country) {
              case 'US':
                return 'USD';
              case 'CA':
                return 'CAD';
              case 'GB':
                return 'GBP';
              case 'CH':
                return 'CHF';
              default:
                return 'EUR';
            }
          };
          if (
            LocaleHelper.getValidShopifyCountry(locale) !==
              refreshedCart.buyerIdentity?.countryCode ||
            getLocaleCurrency(locale as string) !== refreshedCart.currencyCode
          ) {
            let switchedCart = await createShopifyCart(
              {
                ...geoMarket,
                name: LocaleHelper.getValidShopifyCountry(locale as string),
              },
              locale as string,
              refreshedCart,
            );
            const codes = refreshedCart.discountApplications
              .map((d) => (d as unknown as Record<string, any>).code)
              .filter((c) => c);

            if (!switchedCart) return refreshedCart;
            if (switchedCart) {
              for (const code of codes) {
                switchedCart = await client.current.checkout.addDiscount(
                  switchedCart.id,
                  code,
                );
              }
            }
            const tmpCart = await fetchShopifyCart(
              {
                ...geoMarket,
                name: LocaleHelper.getValidShopifyCountry(locale as string),
              },
              switchedCart.id,
            );
            LocalStorage.set(LocalStorageKeys.CART, JSON.stringify(tmpCart));
            setCart(tmpCart);
          } else {
            setCart(refreshedCart);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (cart === null) {
      getNewCart()
        .then(() => {})
        .catch(console.error);
    } else {
      refreshExistingCart(String(cart.id)).then(() => {});
    }
  }, [locale]);

  useEffect(() => {
    const loadPromoCode = () => {
      setMount(true);
      const urlParams = new URLSearchParams(window.location.search);
      const promoCode = urlParams.get('promo-code');
      if (cart && typeof promoCode === 'string') {
        if (
          !cart?.discountApplications?.find(
            // @ts-ignore
            (discount) => discount.code === promoCode,
          )
        ) {
          client.current.checkout
            .addDiscount(cart.id, promoCode)
            .then((res) => {
              return fetchShopifyCart(
                {
                  ...geoMarket,
                  name: LocaleHelper.getValidShopifyCountry(locale as string),
                },
                res.id,
              );
            })
            .then((res) => {
              if (res) setCart(res);
            })
            .catch((err) => console.error(err));
        }
      }
    };
    if (!mount) {
      loadPromoCode();
    }
  }, [cart, mount]);
  useEffect(() => {
    if (cart?.lineItems && cart?.lineItems?.length > 0) {
      const id = JSON.parse(
        cart?.lineItems?.[0]?.customAttributes.find(
          (e) => e.key === '__dataLayerItem',
        )?.value || '{}',
      )?.parent_item_id;
      NoozNextApi.instance(
        NOOZ_NEXT_REGISTRY.PRODUCTS.RECOMMEND(id, lc as string),
      ).then(({ data }) => {
        setReco(data);
      });
    }
  }, [cart]);

  return (
    <CommerceContext.Provider
      value={{
        client: client.current,
        cart,
        setCart,
        // @ts-ignore
        freeShipping,
        freeVariant,
        recommendations,
      }}>
      <CartStockProvider>{children}</CartStockProvider>
    </CommerceContext.Provider>
  );
};
export const CommerceProvider = ({ children }: CommerceProviderProps) => {
  const { locale } = usePageContext();
  return locale ? (
    <CommerceContextProvider>{children}</CommerceContextProvider>
  ) : (
    <>{children}</>
  );
};

export default useCommerceContext;
