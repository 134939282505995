import {
  ActionIcon,
  Box,
  createStyles,
  Divider,
  Flex,
  Group,
  NativeSelect,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import React, { useMemo, useState } from 'react';
import { ICartLine } from '@/interface.custom';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import Currency from '@/helpers/Currency';
import { notifications } from '@mantine/notifications';
import useCartActions from '@/utils/hook/cart/useCartActions';
import Link from '@/components/Link/Link';
import { useCartStock } from '@/utils/hook/cart/useCartStock';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import { FREE_GIFT_KEY } from '@/components/Cart/CartDrawer/CartDrawerProgressBar';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import { useGiftSelectorDrawer } from '@/components/Cart/CartDrawer/CartDrawerGiftSelectorButton';
import Image from 'next/image';

const useClasses = () =>
  createStyles(() => ({
    link: {
      '&:hover .link-title': {
        textDecoration: 'underline',
      },
    },
  }))().classes;

const LineItem = ({
  loading,
  setLoading,
  soldOutLabel,
  setFreeGiftDrawerOpened,
  onRemove,
  ...i
}: ICartLine & {
  setFreeGiftDrawerOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  soldOutLabel: string;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onRemove?: () => void;
}) => {
  const classes = useClasses();
  const theme = useMantineTheme();
  const { layout } = usePageContext();
  const { updateLabel, freeLabel } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );

  const { removeFromCart, updateFromCart } = useCartActions();
  const updateQuantity = (li: ICartLine, diff: number) => {
    setLoading(true);
    updateFromCart(li.lineItems, diff)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        notifications.hide('loading');
      });
  };
  const removeItem = (li: ICartLine) => () => {
    setLoading(true);
    removeFromCart(li.lineItems)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        // notifications.hide('loading');
      });
  };
  const diopter = i.__contentfulItem?.itemOpt?.find((o) => o.key === 'diopter');
  const size = i.__contentfulItem?.itemOpt?.find((o) => o.key === 'sizeLabel');

  return (
    <Group spacing={12}>
      <Image
        height={69}
        width={69}
        alt={i.__contentfulItem.title || ''}
        src={
          i.__contentfulItem?.image?.fields?.media?.fields.file.url + '?fm=webp'
        }
        style={{
          objectFit: 'cover',
          borderRadius: 16,
          alignSelf: 'flex-start',
        }}
      />
      <Stack
        sx={{ flex: '1 !important', alignSelf: 'stretch' }}
        align={'flex-start'}
        justify={'space-between'}
        spacing={0}>
        {(() => {
          const Component = i.__contentfulItem.url ? Link : React.Fragment;
          const searchParams = new URLSearchParams();
          (i.variant?.selectedOptions || [])
            .filter((opt) => ['Color', 'Diopter'].includes(opt.name))
            .forEach(({ name, value }) => {
              searchParams.append(
                name.toLowerCase(),
                (value || '').toLowerCase(),
              );
            });

          const props = i.__contentfulItem.url
            ? {
                href: `${i.__contentfulItem.url}${
                  searchParams.toString() ? `?${searchParams.toString()}` : ''
                }`,
                className: classes.link,
              }
            : {};

          return (
            // @ts-ignore
            <Component {...props}>
              <Group
                align={'flex-end'}
                spacing={'1px'}>
                <NoozText
                  size={14}
                  weight={700}
                  className={'link-title'}
                  lh={'18px'}>
                  {[i.__contentfulItem.title, i.__contentfulItem.categoryTitle]
                    .filter((f) => !!f)
                    .join(' - ')}
                </NoozText>
              </Group>
            </Component>
          );
        })()}

        {[FREE_GIFT_KEY, 'single'].includes(i.__itemType) ? (
          <>
            {i.__contentfulItem.color?.fields.text ? (
              <NoozText
                size={15}
                weight={400}
                color={'text'}
                lh={'20px'}>
                {[
                  size?.value,
                  diopter?.value && diopter.value !== '-'
                    ? `+${diopter.value.replace('+', '')}`
                    : null,
                  i.__contentfulItem.color?.fields.text,
                ]
                  .filter((f) => !!f)
                  .join(', ')}
              </NoozText>
            ) : null}
            {i.__itemType === 'single' && !i.soldOut ? (
              <NativeSelect
                data={
                  i.quantity < 7
                    ? ['1', '2', '3', '4', '5', '6', '7', '8', '9']
                    : (() => {
                        let arr = [];
                        for (let j = 1; j <= i.quantity + 2; ++j) {
                          arr.push(j.toString());
                        }
                        return arr;
                      })()
                }
                radius='xl'
                mt={6}
                disabled={loading}
                value={i.quantity.toString()}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                styles={{
                  input: {
                    borderColor: theme.colors.black[9],
                    height: 23,
                    minHeight: 23,
                    lineHeight: 'unset',
                  },
                }}
                sx={{
                  '& svg': {
                    color: `${theme.colors.black[9]} !important`,
                  },
                }}
                onChange={(event) => {
                  const amount =
                    parseInt(event.currentTarget.value) - i.quantity;
                  if (amount !== 0) updateQuantity(i, amount);
                }}
              />
            ) : null}
            {i.__itemType === FREE_GIFT_KEY ? (
              <NoozButton
                size='xs'
                onClick={() => {
                  if (setFreeGiftDrawerOpened) setFreeGiftDrawerOpened(true);
                }}
                styles={{ root: { height: 23 } }}
                variant={'outline'}
                color={'dark'}>
                {updateLabel.fields.text}
              </NoozButton>
            ) : null}
          </>
        ) : null}
        {i.__itemType === 'pack' && i.__components ? (
          <>
            {i.__components?.map((i, key) => (
              <Flex
                key={key}
                gap={12}
                mt={8}>
                <Image
                  height={32}
                  width={32}
                  alt={i.name}
                  src={i.posterImage}
                  style={{
                    objectFit: 'cover',
                    borderRadius: 8,
                    alignSelf: 'center',
                  }}
                />
                <NoozText
                  size={15}
                  weight={400}
                  color={'text'}
                  lh={'20px'}>
                  {i.name}
                  <br />
                  {i.options}
                </NoozText>
              </Flex>
            ))}
          </>
        ) : null}
      </Stack>
      <Stack
        justify={'space-between'}
        align={'center'}
        sx={{
          alignSelf: 'stretch',
        }}>
        <Stack spacing={0}>
          <NoozText
            w={'max-content'}
            size={15}
            weight={700}
            color={
              i.__contentfulItem?.discountPrice || i.variant?.compareAtPrice
                ? theme.colors.red[6]
                : 'title'
            }>
            {i.soldOut ? (
              soldOutLabel
            ) : i.__itemType === FREE_GIFT_KEY ? (
              freeLabel.fields.text
            ) : (
              <>
                {Currency.toFormattedPrice(i.variant?.price.amount as any)}
                <NoozText
                  color={'main'}
                  size={15}
                  weight={700}
                  component={'span'}>
                  {Currency.toSymbol(i.variant?.price.currencyCode as any)}
                </NoozText>
              </>
            )}
          </NoozText>
          {i.__contentfulItem?.discountPrice || i.variant?.compareAtPrice ? (
            <NoozText
              color={'text'}
              strikethrough
              align={'right'}
              size={14}
              weight={500}>
              {`${Currency.toFormattedPrice(
                i.__contentfulItem?.discountPrice ||
                  (i.variant?.compareAtPrice as any).amount,
              )} ${Currency.toSymbol(i.variant?.price.currencyCode as any)}`}
            </NoozText>
          ) : null}
        </Stack>
        {i.soldOut && !onRemove ? null : (
          <ActionIcon
            variant='outline'
            radius='xl'
            size={23}
            color={'title'}
            disabled={loading}
            onClick={i.soldOut && onRemove ? onRemove : removeItem(i)}>
            <NoozIcon
              iconKey={'Trash'}
              strokeWidth={2}
              size={14}
              color={theme.colors.main[0]}
            />
          </ActionIcon>
        )}
      </Stack>
    </Group>
  );
};

const CartDrawerLineItems = ({ lineItems }: { lineItems: ICartLine[] }) => {
  const { layout } = usePageContext();
  const { soldOut } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );
  const ts = new Date().getTime();
  const [loading, setLoading] = useState(false);
  const { outOfStock, cleanOutOfStock } = useCartStock();

  const { DrawerComponent, setFreeGiftDrawerOpened } =
    useGiftSelectorDrawer(true);

  return (
    <>
      <DrawerComponent />
      <Stack
        spacing={16}
        mx={16}>
        {outOfStock.map((i, key) => (
          <React.Fragment key={i.__itemId}>
            <LineItem
              {...{ ...i, loading, setLoading }}
              onRemove={() => cleanOutOfStock(key)}
              soldOutLabel={soldOut?.fields.text || ''}
            />
            <Divider />
          </React.Fragment>
        ))}
        {lineItems.map((i, key) =>
          i.soldOut && i.expiredTs && ts < i.expiredTs ? null : (
            <React.Fragment key={i.__itemId}>
              <LineItem
                {...{ ...i, loading, setLoading }}
                setFreeGiftDrawerOpened={setFreeGiftDrawerOpened}
                soldOutLabel={soldOut?.fields.text || ''}
              />
              {key !== lineItems.length - 1 ? <Divider /> : null}
            </React.Fragment>
          ),
        )}
        <Box
          w={'100%'}
          h={16}
        />
      </Stack>
    </>
  );
};

export default CartDrawerLineItems;
