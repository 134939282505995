import { IBlock, IButton, IImage, ILabel } from '@/@types/generated/contentful';
import {
  Box,
  Container,
  createStyles,
  Flex,
  MantineTheme,
} from '@mantine/core';
import ProductCard from '@/components/Collection/ProductCard/ProductCard';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import { useContainerStyles } from '@/utils/style/useContainer';
import React, { memo } from 'react';
import NoozText, { weightFromCf } from '@/components/Nooz/NoozText/NoozText';
import SkeletonProductCard from '@/components/Collection/ProductCard/SkeletonProductCard';
import { ShopItem } from '@/@types/shopitem';

interface LookBookLineShopItemProps {
  labels: IBlock;
  image: IImage;
  button: IButton;
  facets: IBlock;
  imagePosition: 'right' | 'left';
}
const useClasses = () =>
  createStyles((theme) => ({
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        //   height: '100%',
        //   width: '100%',
        aspectRatio: '1',
      },
    },
  }))().classes;

const LookBookLineShopItem = ({
  labels,
  image,
  button,
  facets,
  imagePosition,
}: LookBookLineShopItemProps) => {
  const classes = useClasses();
  const shopItems: ShopItem[] = (
    ((facets as any).shopitems.items || []) as ShopItem[]
  )
    .slice(0, 2)
    .map((si) => ({
      ...si,
      variants: si.variants.filter(
        (v) => v.new || v.id === si.defaultVariantId,
      ),
    }));

  const containerStyles = useContainerStyles();

  return (
    <Container
      mb={{ base: 20, md: 40 }}
      className={containerStyles.classes.container}
      sx={{
        width: '100%',
        maxWidth: 1200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Flex
        sx={(theme) => ({
          flexDirection: imagePosition === 'left' ? 'row' : 'row-reverse',
          gap: 32,
          '& > *': { flex: 1 },
          [theme.fn.smallerThan(theme.breakpoints.sm)]: {
            flexDirection: 'column',
            gap: 16,
          },
        })}
        m={'0 auto'}>
        <Box pos={'relative'}>
          <Flex
            sx={(theme) => {
              return {
                flexDirection: 'column',
                top: 60,
                left: 32,
                right: 32,
                [theme.fn.smallerThan('sm')]: {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  top: 32,
                  gap: 8,
                },
              };
            }}
            pos={'absolute'}>
            {labels.fields.items?.map((l) => {
              const label = l as ILabel;
              return (
                <NoozText
                  align={'center'}
                  key={(l as any).id}
                  sx={(theme) => {
                    return {
                      fontSize: `${label.fields.size}px`,
                      lineHeight: 1,
                      [theme.fn.smallerThan('sm')]: {
                        fontSize: `${
                          label.fields.mobileSize || label.fields.size
                        }px`,
                        lineHeight: label.fields.mobileSize ? 1.26 : 1,
                      },
                    };
                  }}
                  italic={label?.fields.italic}
                  weight={label ? weightFromCf(label?.fields) : 500}
                  size={
                    label?.fields.size !== 'base'
                      ? label?.fields.size
                      : undefined
                  }
                  color={label.fields.color?.fields.classname || 'white'}
                  // @ts-ignore
                  family={label?.fields.family}>
                  {label.fields.text}
                </NoozText>
              );
            })}
          </Flex>
          {image?.fields.media?.fields.file?.url ? (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src={image.fields.media.fields.file.url + '?fm=webp'}
              alt={image.fields.altText}
              className={classes.image}
            />
          ) : null}
          <NoozButton
            size={'xl'}
            sx={(theme: MantineTheme) => ({
              position: 'absolute',
              bottom: 60,
              left: '50%',
              transform: 'translateX(-50%) !important',
              '&:active': {
                transform: 'translate(-50%, 0.0625rem) !important',
              },
              [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                display: 'none',
                left: 'unset',
                transform: 'unset',
              },
            })}
            color={button.fields.color?.fields.classname}
            href={button.fields.slug?.fields?.url}
            facets={button.fields.slug?.fields?.facet}
            urlLink={
              button.fields.urlLink && button.fields.urlLink !== ''
                ? button.fields.urlLink
                : undefined
            }
            squared={button.fields.squareRadius}>
            {button.fields.label?.fields.text}
          </NoozButton>
        </Box>
        <Flex
          sx={(theme) => {
            return {
              flexDirection: 'column',
              gap: 16,
              [theme.fn.smallerThan('sm')]: {
                flexDirection: 'row',
                gap: 20,
              },
            };
          }}>
          {shopItems.map((si, key) => (
            <ProductCard
              key={si.defaultVariantId}
              item={si}
              position={key}
              type={'default'}
              newIsVariantCustom
            />
          ))}
          {shopItems.length === 0
            ? [...(facets.fields.items || []), { id: 1 }, { id: 2 }]
                .splice(0, 1)
                .map((el) => (
                  <SkeletonProductCard
                    key={(el as any).id}
                    label={(el as ILabel)?.fields.text}
                  />
                ))
            : null}
        </Flex>
      </Flex>
      <NoozButton
        size={'lg'}
        sx={(theme: MantineTheme) => ({
          marginTop: 30,
          [theme.fn.largerThan(theme.breakpoints.sm)]: {
            display: 'none',
          },
        })}
        color={button.fields.color?.fields.classname}
        href={button.fields.slug?.fields?.url}
        facets={button.fields.slug?.fields?.facet}
        urlLink={
          button.fields.urlLink && button.fields.urlLink !== ''
            ? button.fields.urlLink
            : undefined
        }
        squared={button.fields.squareRadius}>
        {button.fields.label?.fields.text}
      </NoozButton>
    </Container>
  );
};

export default memo(LookBookLineShopItem);
