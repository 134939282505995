import { TIMING_FUNCTION } from '@/utils/style/animation';
import { createStyles } from '@mantine/core';

const useMarketingStyles = createStyles((theme, props) => {
  //@ts-ignore
  const animationVariant = props?.animationVariant;
  return {
    cardWrapper: {
      position: 'relative',
      cursor: 'pointer',
      overflow: 'hidden',
      height: '100%',
      transition: `transform 0.5s ${TIMING_FUNCTION}`,
      [theme.fn.largerThan(theme.breakpoints.md)]: {
        '&: hover': {
          transform: animationVariant === 'scale' ? 'scale(1.03)' : '',
        },
      },
    },
    linkFullHeight: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
    },
    highlightBackgroundImage: {
      borderRadius: 20,
      zIndex: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      [theme.fn.largerThan(theme.breakpoints.sm)]: {
        '&: hover': {
          transform: animationVariant === 'scale' ? 'scale(1.03)' : '',
        },
      },
    },
    overlay: {
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      backgroundImage:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .50) 90%)',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        backgroundImage:
          'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .30) 90%)',
      },
    },
    description: {
      position: 'absolute',
      zIndex: 1,
      borderRadius: 20,
      height: '100%',
      width: '100%',
      padding: 40,
      flexDirection: 'column',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        padding: 25,
      },
      '& .text': {
        flex: 1,
        fontSize: 20,
        fontWeight: 500,
        color: theme.white,
        margin: '16px 0',
        overflow: 'auto',
        [theme.fn.smallerThan(theme.breakpoints.lg)]: {
          fontSize: 16,
        },
      },
    },
    closeIcon: {
      transform: 'rotate(45deg)',
    },
    closeWrapper: {
      zIndex: 1,
    },
    modelCard: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 280,
      '& > *:not(:first-of-type)': {
        marginTop: 16,
      },
      '& .mantine-Image-figure, & .mantine-Image-imageWrapper, & .mantine-Image-image':
        {
          maxHeight: 500,
        },
    },
    card: {
      zIndex: 1,
      borderRadius: 20,
      position: 'relative',
      top: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
  };
});
export default useMarketingStyles;
