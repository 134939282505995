import React from 'react';
import {
  BackgroundImage,
  Card,
  Center,
  createStyles,
  Stack,
  getStylesRef,
} from '@mantine/core';
import Link from '../Link/Link';
import { ILabel, IPath } from '@/@types/generated/contentful';
import NoozText from '../Nooz/NoozText/NoozText';

const useStyles = createStyles((theme) => {
  const image = getStylesRef('image');

  return {
    card: {
      cursor: 'pointer',
      position: 'relative',
      [theme.fn.largerThan(theme.breakpoints.sm)]: {
        [`&:hover .${image}`]: {
          transform: 'scale(1.03)',
        },
      },
    },
    image: {
      ref: image,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      backgroundSize: 'cover',
      transition: 'transform 500ms ease',
    },
  };
});

interface ActionCardProps {
  slug: IPath;
  image: {
    fields: {
      file: {
        url: string;
      };
    };
  };
  label: ILabel;
  subLabel: ILabel;
  align?: 'left' | 'center' | 'right';
}

export default function ActionHorizontalCard({
  align = 'left',
  slug,
  image,
  label,
  subLabel,
}: ActionCardProps) {
  const { classes } = useStyles();

  return (
    <Link href={slug.fields.url as string}>
      <Card
        className={classes.card}
        radius='lg'>
        <Card.Section
          h={0}
          pb={'50%'}
          mt='sm'>
          <BackgroundImage
            src={image.fields?.file?.url}
            className={classes.image}
          />
        </Card.Section>
        <Center
          mt={10}
          h='100%'
          w='100%'
          sx={{ position: 'absolute', left: 0, top: 0 }}>
          <Stack
            w='100%'
            p={'lg'}
            spacing={1}>
            <NoozText
              align={align}
              size={24}
              color={label.fields.color?.fields.classname || 'title'}
              weight={600}>
              {label?.fields.text}
            </NoozText>
            <NoozText
              align={align}
              size={16}
              color={subLabel.fields.color?.fields.classname || 'dimmed'}>
              {subLabel?.fields.text}
            </NoozText>
          </Stack>
        </Center>
      </Card>
    </Link>
  );
}
