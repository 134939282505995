import axios, { AxiosInstance } from 'axios';
import * as process from 'process';
import { API_KEY_VALUES } from '@/helpers/apiMiddleware';

export class NoozNextApiClass {
  instance: AxiosInstance;

  constructor(front?: boolean) {
    this.instance = axios.create({
      baseURL: front ? undefined : process.env.NEXT_PUBLIC_DOMAIN,
      headers: {
        locale: 'en-gb',
      },
    });
  }
  setLocaleHeaders(locale: string) {
    if (this.instance)
      this.instance.interceptors.request.use(
        (config) => {
          if (config?.headers) {
            config.headers['locale'] = locale;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        },
      );
  }
}

export const NOOZ_NEXT_REGISTRY = {
  LOGIN: { method: 'POST', url: '/api/auth/login' },
  REFRESH: { method: 'GET', url: '/api/auth/refresh' },
  RESET_PASSWORD: { method: 'POST', url: '/api/auth/reset-password' },
  SIGN_UP: { method: 'POST', url: '/api/auth/signup' },
  CUSTOMER: {
    GET: { method: 'GET', url: '/api/customer' },
    UPDATE: { method: 'PUT', url: '/api/customer' },
    COMPANY_UPDATE: { method: 'PUT', url: '/api/customer/company' },
    CREATE_ADDR: { method: 'POST', url: '/api/customer/address' },
    UPDATE_ADDR: { method: 'PUT', url: '/api/customer/address' },
    AS_FAVORITE_ADDR: { method: 'PUT', url: '/api/customer/address/favorite' },
    DELETE_ADDR: { method: 'DELETE', url: '/api/customer/address' },
    GET_WISHLIST: { method: 'GET', url: '/api/customer/wishlist' },
    CREATE_WISHLIST: { method: 'POST', url: '/api/customer/wishlist' },
    UPDATE_WISHLIST: { method: 'PUT', url: '/api/customer/wishlist' },
  },
  SHOPIFY: {
    COUNTRIES: {
      method: 'GET',
      url: '/api/shopify/countries',
    },
    ORDERS: {
      TRACK: {
        method: 'GET',
        url: '/api/shopify/orders/track',
      },
    },
    PRODUCTS: {
      GET: { method: 'POST', url: '/api/shopify/products' },
      GET_STOCK: { method: 'POST', url: '/api/shopify/products/stocks' },
    },
    VARIANTS: {
      GET_STOCK: { method: 'POST', url: '/api/shopify/variants/stocks' },
    },
  },
  ODOO: {
    APPOINTMENTS: {
      GET_DAY: '/api/odoo/appointments/day/{date}',
    },
    CREATE_PROSPECT: {
      method: 'POST',
      url: '/api/odoo/prospects',
    },
    GET_STORES: {
      method: 'GET',
      url: '/api/odoo/stores',
    },
  },
  KLAVYO: {
    POST: {
      method: 'POST',
      url: '/api/klavyo',
      headers: { 'x-api-key': API_KEY_VALUES.NEXT_APP },
    },
  },
  MAGIC_MIRROR: {
    GET_SESSION: {
      method: 'GET',
      url: '/api/magic-mirror/sessions/{sessionId}',
      headers: { 'x-api-key': API_KEY_VALUES.NEXT_APP },
    },
    DELETE_SESSION: {
      method: 'DELETE',
      url: '/api/magic-mirror/sessions/{sessionId}',
      headers: { 'x-api-key': API_KEY_VALUES.NEXT_APP },
    },
    SET_GDPR: {
      method: 'PUT',
      url: '/api/magic-mirror/sessions/{sessionId}/gdpr',
      headers: { 'x-api-key': API_KEY_VALUES.NEXT_APP },
    },
    LINK_SESSION: {
      method: 'GET',
      url: '/api/magic-mirror/sessions/{sessionId}/link-to/{userId}',
      headers: { 'x-api-key': API_KEY_VALUES.NEXT_APP },
    },
    GET_USER_SESSIONS: {
      method: 'GET',
      url: '/api/magic-mirror/users/{userId}',
      headers: { 'x-api-key': API_KEY_VALUES.NEXT_APP },
    },
  },
  STORES: {
    MAP: { method: 'GET', url: '/api/stores/map' },
  },
  LOGS: {
    PACK: {
      method: 'POST',
      url: '/api/logs/packs',
      headers: { 'x-api-key': API_KEY_VALUES.NEXT_APP },
    },
    IMG_ERROR: {
      method: 'POST',
      url: '/api/logs/image_error',
      headers: { 'x-api-key': API_KEY_VALUES.NEXT_APP },
    },
  },
  PRODUCTS: {
    RECOMMEND: (id: string, locale: string) => ({
      method: 'GET',
      url: `/api/products/recommend?locale=${locale}&product_id=${id}`,
    }),
    SEARCH: (query: string, locale: string) => ({
      method: 'GET',
      url: `/api/products/search?locale=${locale}&query=${query}`,
    }),
    TOP: (locale: string, limit?: number | string) => ({
      method: 'GET',
      url: `/api/products/top?locale=${locale}${
        limit ? `&limit=${limit}` : ''
      }`,
    }),
  },
};

const NoozNextApi = new NoozNextApiClass();
export default NoozNextApi;
