import React, { useMemo } from 'react';
import { BRAND } from '@/utils';
import usePageContext from '@/utils/context/page';
import { useRouter } from 'next/router';
import Url from '@/helpers/Url';
import Head from 'next/head';
import { ShopItem, ShopItemVariant } from '@/@types/shopitem';

export const validateEntry = (e: any, replace = '') =>
  (e === undefined || e === null ? replace : e) || '';

const brandSchemaOrg = {
  '@context': 'http://schema.org',
  '@type': 'Brand',
  name: validateEntry(BRAND),
  logo: {
    '@type': 'ImageObject',
    url: 'https://www.nooz-optics.com/assets/logo-black.svg',
  },
  sameAs: [
    'https://www.facebook.com/noozoptics/',
    'https://www.nooz-optics.com/fr-fr/',
    'https://www.youtube.com/channel/UCn1a0CQV12gRQ1l-UBOtkSQ',
  ],
};
const returnBrandSchemaOrgJSON = () => {
  return {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: validateEntry(BRAND),
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'FR',
      addressLocality: 'Paris',
      postalCode: '75002',
      streetAddress: '26 rue Vivienne',
    },
    brand: brandSchemaOrg,
  };
};

export const HomeSchemaOrgJSON = () => {
  const { page, locale } = usePageContext();
  const router = useRouter();
  return (
    <Head>
      <script
        type='application/ld+json'
        id={'json-website'}
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url: validateEntry(router.asPath),
            name: validateEntry(BRAND),
            inLanguage: validateEntry(locale),
            image: validateEntry(
              page?.fields.seoMetadata?.fields?.image?.fields?.file.url,
            ),
            publisher: {
              '@type': 'Organization',
              name: validateEntry(BRAND),
              logo: {
                '@type': 'ImageObject',
                url: validateEntry(
                  page?.fields.seoMetadata?.fields?.image?.fields?.file.url,
                ),
              },
              address: {
                '@type': 'PostalAddress',
                addressCountry: 'FR',
                addressLocality: 'Paris',
                postalCode: '75002',
                streetAddress: '26 rue Vivienne',
              },
            },
            keywords: validateEntry(
              page?.fields.seoMetadata?.fields?.description,
            ),
            sameAs: [
              'https://www.facebook.com/noozoptics/',
              'https://www.nooz-optics.com/fr-fr/',
              'https://www.youtube.com/channel/UCn1a0CQV12gRQ1l-UBOtkSQ',
            ],
          }),
        }}></script>
    </Head>
  );
};

export const CategoryListSchemaOrgJSON = () => {
  const { locale, page, productCategory, shopitems } = usePageContext();

  const returnEntityListItems = useMemo(
    () =>
      shopitems?.items
        ?.reduce((arr: any[], si) => {
          if (!arr.find((el) => el.product.shopifyId === si.product.shopifyId))
            arr.push(si);
          return arr;
        }, [])
        ?.map((hit: ShopItem) => {
          const { product, variants } = hit;
          return {
            '@context': 'http://schema.org',
            '@type': 'Product',
            '@id': validateEntry(product.shopifyId),
            name: validateEntry(product?.label),
            brand: brandSchemaOrg,
            category: validateEntry(product?.categoryLabel),
            // description: validateEntry(product?.fields.subLabel?.fields.text),
            url: validateEntry(Url.getLocalizedDomainUrl(locale, product?.url)),
            image: validateEntry(
              // @ts-ignore
              variants?.[0]?.posterImage?.replace(/^\/\//, 'https://'),
            ),
            offers: variants?.map((variant: ShopItemVariant) => {
              if (!variant.price) console.log(variant);
              return {
                '@type': 'Offer',
                sku: validateEntry(variant.title),
                priceCurrency: validateEntry(
                  variant.price?.currencyCode,
                  'EUR',
                ),
                availability: 'http://schema.org/InStock',
                priceValidUntil: '2099-01-01T00:00:00',
                url: validateEntry(product?.url),
                price: validateEntry(variant.price?.amount),
                priceSpecification: {
                  '@type': 'CompoundPriceSpecification',
                  name: 'Price',
                  price: validateEntry(variant.price?.amount),
                  priceCurrency: validateEntry(
                    variant.price?.currencyCode,
                    'EUR',
                  ),
                  priceComponent: [
                    {
                      name: 'VAT',
                      price: validateEntry(variant.price?.amount),
                      valueAddedTaxIncluded: true,
                    },
                  ],
                },
              };
            }),
          };
        })
        .reduce((prev: Record<string, any>[], obj: Record<string, any>) => {
          const has = prev.find((v) => v.url === obj.url);

          if (!has) {
            prev.push(obj);
          }

          return prev;
        }, []),
    [shopitems],
  );

  return (
    <Head>
      <script
        type='application/ld+json'
        id={'json-collection'}
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'CollectionPage',
            '@id': validateEntry(page?.fields.slug.fields.url),
            url: validateEntry(
              Url.getLocalizedDomainUrl(locale, page?.fields.slug.fields.url),
            ),
            name: validateEntry(productCategory?.fields?.title),
            description: validateEntry(
              page?.fields.seoMetadata?.fields?.description,
            ),
            mainEntity: {
              '@type': 'ItemList',
              itemListElement: returnEntityListItems || [],
            },
            image: shopitems.items?.[0]?.variants?.[0]?.posterImage?.replace(
              /^\/\//,
              'https://',
            ),
          }),
        }}></script>
    </Head>
  );
};

export const ProductSchemaOrgJSON = () => {
  // @ts-ignore
  const { product, locale, shopifyProduct, page } = usePageContext();

  return (
    <Head>
      <script
        type='application/ld+json'
        id={'json-product'}
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Product',
            '@id': validateEntry(page?.fields.slug.fields.url),
            name: validateEntry(product?.fields.label?.fields.text),
            brand: brandSchemaOrg,
            url: validateEntry(
              Url.getLocalizedDomainUrl(locale, page?.fields.slug.fields.url),
            ),
            category: validateEntry(
              product?.fields.category?.fields.label?.fields.text,
            ),
            description:
              validateEntry(product?.fields.subLabel?.fields.text) || '',
            image: (
              product?.fields.variants?.[0]?.fields?.images?.[0]?.fields.media
                ?.fields?.file.url || ''
            ).replace(/^\/\//, 'https://'),
            offers: (shopifyProduct.variants || [])?.map(
              (variantData: any) => ({
                '@type': 'Offer',
                sku: validateEntry(variantData.sku),
                priceCurrency: validateEntry(variantData?.price.currencyCode),
                availability: variantData.availableForSale
                  ? 'http://schema.org/InStock'
                  : 'http://schema.org/OutOfStock',
                priceValidUntil: '2099-01-01T00:00:00',
                url: validateEntry(page?.fields.slug.fields.url),
                price: validateEntry(variantData?.price.amount),
                priceSpecification: {
                  '@type': 'CompoundPriceSpecification',
                  name: 'Price',
                  price: validateEntry(variantData?.price.amount),
                  priceCurrency: validateEntry(variantData?.price.currencyCode),
                  priceComponent: [
                    {
                      name: 'VAT',
                      price: validateEntry(variantData?.price.amount),
                      valueAddedTaxIncluded: true,
                    },
                  ],
                },
              }),
            ),
          }),
        }}></script>
    </Head>
  );
};
