import { ModalContext } from '@/utils/context/modal';
import { useContext } from 'react';
import dynamic from 'next/dynamic';
import { IModalContext } from '@/interface.custom';

const Modal = dynamic(() =>
  import('@mantine/core').then((mantine) => mantine.Modal),
);

const ModalWrapper = () => {
  const {
    modal: { opened, className, size, body, onClose, ...modal },
    setModal,
  } = useContext(ModalContext) as IModalContext;

  return body ? (
    <Modal
      {...modal}
      onClose={() => {
        onClose?.();
        setModal({ opened: false, body: '', className: '', size: undefined });
      }}
      closeButtonProps={{
        size: 'md',
        radius: 'xl',
        color: 'black',
        variant: 'filled',
      }}
      opened={opened}
      className={className}
      size={size}
      withCloseButton={true}>
      {body}
    </Modal>
  ) : null;
};

export { ModalWrapper as Modal };
