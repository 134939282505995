import React, { useEffect, useState } from 'react';
import { Box, Container, createStyles } from '@mantine/core';
import CategoryHeaderNavigation from './CategoryHeaderNavigations';
import { subscribe, unsubscribe } from '@/helpers/events';
import useScroll from '@/utils/hook/useScroll';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import usePageContext from '@/utils/context/page';
import { useContainerStyles } from '@/utils/style/useContainer';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import zIndexes from '@/utils/style/zIndex';

const useStyles = createStyles(() => ({
  wrapper: {
    position: 'fixed',
    width: '100vw',
    zIndex: zIndexes.header,
    transition: 'top 0.6s ease-in-out',
  },
}));

const CategoryHeaderSticky = () => {
  const [isActive, setIsActive] = useState(false);
  const { productCategory, breadcrumbs } = usePageContext();
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();
  const { isScrollingDown } = useScroll();
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    const handleHeaderEvent = (event: any) => {
      const { outOfWindow } = event.detail;
      if (typeof outOfWindow === 'boolean') {
        setIsActive(outOfWindow);
      }
    };

    subscribe('header', handleHeaderEvent);

    return () => {
      unsubscribe('header', handleHeaderEvent);
    };
  }, []);

  const topStyle = !isScrollingDown
    ? headerHeight.wrapper + headerHeight.marginTop
    : 0;

  //@ts-ignore
  return isActive && !productCategory?.fields?.handle?.includes('Packs') ? (
    <Box
      bg='white'
      py='sm'
      className={classes.wrapper}
      sx={{ top: topStyle, boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }}>
      <Container className={containerStyles.classes.container}>
        <Box mb={10}>
          <Breadcrumbs list={breadcrumbs} />
        </Box>
        <CategoryHeaderNavigation />
      </Container>
    </Box>
  ) : null;
};

export default CategoryHeaderSticky;
