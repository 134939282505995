import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useCommerceContext from '@/utils/context/commerce';
import ShopifyBuy from 'shopify-buy';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';
import { ICartLine } from '@/interface.custom';
import useCartActions from '@/utils/hook/cart/useCartActions';
import usePageContext from '@/utils/context/page';
import useCartItems from '@/utils/hook/useCartItems';
import { FREE_GIFT_KEY } from '@/components/Cart/CartDrawer/CartDrawerProgressBar';

interface ICartStockContext {
  isLoading: boolean;
  outOfStock: ICartLine[];
  upsellStock: Record<string, number>;
  updateStock: () => void;
  cleanOutOfStock: (_: number) => void;
}

const CartStockContext = createContext<ICartStockContext>({
  isLoading: true,
  outOfStock: [],
  upsellStock: {},
  updateStock: () => {},
  cleanOutOfStock: (_: number) => {},
});

export const useCartStock = () => useContext(CartStockContext);

const CartStockProvider = ({ children }: PropsWithChildren) => {
  const [upsellStock, setUpsellStock] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { locale, upsell } = usePageContext();
  const lineItems = useCartItems();
  const { cart } = useCommerceContext() as unknown as {
    cart: ShopifyBuy.Checkout;
  };
  const [outOfStock, setOutOfStock] = useState<ICartLine[]>([]);
  const abortCtrl = useRef(new AbortController());
  const { removeFromCart } = useCartActions();

  useEffect(() => {
    if (upsell) {
      const upsellItems = Object.values(upsell).map((e: any) => e.shopifyId);
      if (upsellItems) {
        let tm: any;
        const cb = () => {
          NoozNextApi.instance({
            ...NOOZ_NEXT_REGISTRY.SHOPIFY.VARIANTS.GET_STOCK,
            data: { ids: upsellItems, locale: locale },
            signal: abortCtrl.current.signal,
          }).then(({ data }) => {
            setUpsellStock(data);
            tm = setTimeout(cb, 1000 * 60 * 5);
          });
        };
        cb();

        return () => {
          if (tm) clearTimeout(tm);
        };
      }
    }
  }, []);
  const cleanOutOfStock = (idx: number) => {
    setOutOfStock((prev) => {
      const arr = [...prev];
      arr.splice(idx, 1);
      return arr;
    });
  };

  // timeout and at cart change
  // useEffect(() => {
  //   let timeout: NodeJS.Timer | null = null;
  //
  //   const updateCartFromStock = () => {
  //     setIsLoading(true);
  //     const variantIds = [
  //       // @ts-ignore
  //       ...new Set(
  //         lineItems.reduce((arr: any[], li) => {
  //           if (li.__itemType !== 'packParent') {
  //             arr.push(li.variant?.id);
  //           } else {
  //             for (let elLi of li.lineItems) {
  //               if (elLi.__itemType === 'pack') {
  //                 arr.push(elLi.variant?.id);
  //               }
  //             }
  //           }
  //           return arr;
  //         }, []),
  //       ),
  //     ];
  //
  //     if (variantIds.length === 0) {
  //       setIsLoading(false);
  //       // timeout = setTimeout(updateCartFromStock, 5 * 60 * 1000);
  //       return;
  //     }
  //     abortCtrl.current.abort();
  //     abortCtrl.current = new AbortController();
  //     NoozNextApi.instance({
  //       ...NOOZ_NEXT_REGISTRY.SHOPIFY.VARIANTS.GET_STOCK,
  //       data: { ids: variantIds, locale: locale },
  //       signal: abortCtrl.current.signal,
  //     })
  //       .then(async ({ data: stocks }) => {
  //         let toRemove: ICartLine[] = [];
  //
  //         for (let li of lineItems) {
  //           const customAttributes = li.customAttributes.reduce(
  //             (obj: Record<string, any>, { key, value }) => {
  //               try {
  //                 obj[key] = JSON.parse(value as any);
  //               } catch (e) {
  //                 obj[key] = value;
  //               }
  //               return obj;
  //             },
  //             {},
  //           );
  //           switch (customAttributes.__itemType) {
  //             case 'single':
  //             case FREE_GIFT_KEY:
  //               if (li.quantity <= stocks[(li.variant as any).id]) {
  //                 stocks[(li.variant as any).id] -= li.quantity;
  //               } else {
  //                 toRemove.push({
  //                   ...li,
  //                   soldOut: true,
  //                   expiredTs: new Date().getTime() + 1000 * 60 * 5,
  //                 });
  //               }
  //               break;
  //             case 'packParent':
  //               const updateStock: Record<string, number> = {};
  //               for (let i = 0; i < li.lineItems.length; ++i) {
  //                 const elLi = li.lineItems[i];
  //                 if (elLi.__itemType === 'pack') {
  //                   if (elLi.quantity <= stocks[(elLi.variant as any).id]) {
  //                     if (
  //                       typeof updateStock[(elLi.variant as any).id] ===
  //                       'number'
  //                     ) {
  //                       updateStock[(elLi.variant as any).id] += elLi.quantity;
  //                     } else {
  //                       updateStock[(elLi.variant as any).id] = elLi.quantity;
  //                     }
  //                     stocks[(elLi.variant as any).id] -= elLi.quantity;
  //                   } else {
  //                     // if not enough stock to sell pack, re-inject stock that have been deduced at check
  //                     for (let [key, value] of Object.entries(updateStock)) {
  //                       stocks[key] += value;
  //                     }
  //                     li.soldOut = true;
  //                     break;
  //                   }
  //                 }
  //               }
  //
  //               if (li.soldOut) {
  //                 toRemove.push({
  //                   ...li,
  //                   expiredTs: new Date().getTime() + 1000 * 60 * 5,
  //                 });
  //               }
  //               break;
  //             default:
  //               break;
  //           }
  //         }
  //
  //         if (toRemove.length > 0) {
  //           setOutOfStock((prev) => [...prev, ...toRemove]);
  //           await removeFromCart(toRemove);
  //         }
  //       })
  //       .catch((e) => {
  //         console.error(e);
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //         // combien de temps garde-t-on les produits supprimer?
  //         // timeout = setTimeout(updateCartFromStock, 5*60*1000);
  //       });
  //   };
  //
  //   updateCartFromStock();
  //
  //   return () => {
  //     if (timeout) clearInterval(timeout);
  //   };
  // }, [cart, removeFromCart, lineItems]);

  const updateStock = useCallback(() => {
    setIsLoading(true);
    const variantIds = [
      // @ts-ignore
      ...new Set(
        lineItems.reduce((arr: any[], li) => {
          if (li.__itemType !== 'pack') {
            arr.push(li.variant?.id);
          } else {
            arr = arr.concat(
              (li.__components || []).map(
                (v) => `gid://shopify/ProductVariant/${v.id}`,
              ),
            );
          }
          return arr;
        }, []),
      ),
    ];
    if (variantIds.length === 0) {
      setIsLoading(false);
      return;
    }
    abortCtrl.current.abort();
    abortCtrl.current = new AbortController();

    NoozNextApi.instance({
      ...NOOZ_NEXT_REGISTRY.SHOPIFY.VARIANTS.GET_STOCK,
      data: { ids: variantIds, locale: locale },
      signal: abortCtrl.current.signal,
    })
      .then(async ({ data }) => {
        let stocks = data,
          toRemove: ICartLine[] = [];

        for (let li of lineItems) {
          if (!li.__contentfulItem.preOrder) {
            const customAttributes = li.customAttributes.reduce(
              (obj: Record<string, any>, { key, value }) => {
                try {
                  obj[key] = JSON.parse(value as any);
                } catch (e) {
                  obj[key] = value;
                }
                return obj;
              },
              {},
            );
            switch (customAttributes.__itemType) {
              case 'single':
              case FREE_GIFT_KEY:
                if (li.quantity <= stocks[(li.variant as any).id]) {
                  stocks[(li.variant as any).id] -= li.quantity;
                } else {
                  toRemove.push({
                    ...li,
                    soldOut: true,
                    expiredTs: new Date().getTime() + 1000 * 60 * 5,
                  });
                }
                break;
              case 'pack':
                let available = true,
                  packStock = { ...stocks };
                for (let element of li.__components || []) {
                  if (
                    packStock[`gid://shopify/ProductVariant/${element.id}`] &&
                    packStock[`gid://shopify/ProductVariant/${element.id}`] -
                      1 >=
                      0
                  ) {
                    packStock[
                      `gid://shopify/ProductVariant/${element.id}`
                    ] -= 1;
                  } else {
                    available = false;
                  }
                }
                if (available) {
                  stocks = packStock;
                } else {
                  toRemove.push({
                    ...li,
                    soldOut: true,
                    expiredTs: new Date().getTime() + 1000 * 60 * 5,
                  });
                }
                break;
              default:
                break;
            }
          }
        }

        if (toRemove.length > 0) {
          setOutOfStock((prev) => [...prev, ...toRemove]);
          await removeFromCart(toRemove);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [cart, removeFromCart, lineItems]);

  return (
    <CartStockContext.Provider
      value={{
        isLoading,
        upsellStock,
        outOfStock,
        updateStock,
        cleanOutOfStock,
      }}>
      {children}
    </CartStockContext.Provider>
  );
};
export default CartStockProvider;
