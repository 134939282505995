import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ActionIcon,
  Box,
  Container,
  Flex,
  Grid,
  Group,
  MediaQuery,
  Title,
  createStyles,
} from '@mantine/core';
import {
  ProductHitsWrapper,
  SearchInput,
  SearchInputProps,
  useInput,
} from '../Search';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import { IBlock } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { SearchSuggestion } from './SearchSuggestion';
import { SearchDrawerContext } from '../SearchDrawer/SearchDrawer';
import { useContainerStyles } from '@/utils/style/useContainer';
import { ShopItem } from '@/@types/shopitem';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 50,
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 10000,
    [theme.fn.smallerThan('md')]: {
      paddingTop: 20,
    },
  },
  titleWrapper: {
    marginBottom: 20,
    [theme.fn.smallerThan('md')]: {
      marginBottom: 10,
    },
  },
  title: {
    fontSize: 48,
    [theme.fn.smallerThan('md')]: {
      fontSize: 38,
    },
  },
  searchWrapper: {
    marginBottom: 40,
    [theme.fn.smallerThan('md')]: {
      paddingTop: 0,
      paddingBottom: 20,
      marginBottom: 20,
    },
  },
  noResultsWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
    flexWrap: 'wrap',
    [theme.fn.smallerThan('md')]: {
      alignItems: 'flex-start',
    },
  },
  noResultsLabel: {
    fontSize: 40,
    [theme.fn.smallerThan('md')]: {
      fontSize: 24,
    },
  },
  noResultsQuery: {
    fontSize: 40,
    fontWeight: 700,
    [theme.fn.smallerThan('md')]: {
      fontSize: 24,
    },
  },
}));

const SearchViewNoResults = ({
  query,
  isLoading,
  quantity,
}: {
  query: string;
  isLoading: boolean;
  quantity: number;
}) => {
  const { classes } = useStyles();
  const { layout } = usePageContext();
  const { search }: { search: IBlock } = formatCfItems(layout?.fields.header);
  const { noResultsLabel } = formatCfItems(search);

  return !isLoading && quantity === 0 && query ? (
    <Flex
      w='100%'
      wrap={'wrap'}
      className={classes.searchWrapper}>
      <NoozText
        component='span'
        className={classes.noResultsLabel}>
        {noResultsLabel.fields.text}
      </NoozText>
      <NoozText
        component='span'
        weight='bold'
        className={classes.noResultsQuery}>{` ${query}`}</NoozText>
    </Flex>
  ) : null;
};

export const useGetRecommendations = (
  input: SearchInputProps,
  defaultLoading = false,
) => {
  const { locale } = usePageContext();
  const [loading, setLoading] = useState(defaultLoading);
  const [reco, setReco] = useState<{ items: ShopItem[]; total: number }>({
    items: [],
    total: 0,
  });
  const timeout = useRef<undefined | NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = undefined;

    if (!input.query) {
      setReco({
        items: [],
        total: 0,
      });
      setLoading(false);
    } else {
      setLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          let { data } = await NoozNextApi.instance({
            ...NOOZ_NEXT_REGISTRY.PRODUCTS.SEARCH(
              input.query.trim().replace(/  +/g, ' ').replaceAll(' ', ','),
              locale as string,
            ),
          });
          setReco(data);
        } catch (e) {}
        setLoading(false);
      }, 300);
    }
  }, [input.query]);

  return { loading, reco };
};

export const SearchView = ({ defaultReco }: { defaultReco: ShopItem[] }) => {
  const searchRef = useRef();
  const searchDrawerState = useContext(SearchDrawerContext);
  const { classes } = useStyles();
  const { layout } = usePageContext();
  const { search }: { search: IBlock } = formatCfItems(layout?.fields.header);
  const input = useInput();
  const { loading, reco } = useGetRecommendations(input);
  const { label } = formatCfItems(search);

  return (
    <Container className={useContainerStyles().classes.container}>
      <Box className={classes.wrapper}>
        <Group
          mb={40}
          className={classes.titleWrapper}
          position='apart'>
          <Title
            order={4}
            className={classes.title}>
            {label?.fields.text}
          </Title>
          <MediaQuery
            smallerThan={'md'}
            styles={{ display: 'none' }}>
            <ActionIcon
              onClick={() =>
                //@ts-ignore
                searchDrawerState[1]({
                  ...searchDrawerState[0],
                  opened: false,
                })
              }
              variant='filled'
              radius='xl'
              color='black'>
              <NoozIcon
                iconKey='X'
                color='white'
              />
            </ActionIcon>
          </MediaQuery>
        </Group>
        <Box className={classes.searchWrapper}>
          <SearchInput
            {...input}
            loading={loading}
          />
        </Box>
      </Box>
      <SearchSuggestion onSearch={searchRef.current} />
      <Box
        mt={20}
        pb={100}>
        <Grid gutter={40}>
          <ProductHitsWrapper
            defaultReco={defaultReco}
            reco={reco}
            query={input.query}
          />
        </Grid>

        <SearchViewNoResults
          query={input.query}
          isLoading={loading}
          quantity={reco.total}
        />
      </Box>
    </Container>
  );
};
