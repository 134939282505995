import React from 'react';
import { createStyles } from '@mantine/core';
import Search from '@/components/Search/Search';
import CartAction from '@/components/Header/HeaderAction/CartAction';
import { APP_INFO } from '@/utils';
import B2BCartAction from '@/components/Header/HeaderAction/B2BCartAction';

export const useStyles = createStyles(() => {
  return {
    button: {
      color: 'inherit',
      transition: 'color 0.1s ease-in-out',
    },
    link: {
      color: 'inherit',
    },
  };
});

const HeaderAction = ({}: { identifier: string }) => {
  return (
    <>
      {/* TODO ACTIVE/INACTIVE SEARCH HEADER */}
      <Search />

      {/*{!isSearchActive && (*/}
      {/*  <MediaQuery*/}
      {/*    largerThan='md'*/}
      {/*    styles={{ display: 'none' }}>*/}
      {/*    <CartAction />*/}
      {/*  </MediaQuery>*/}
      {/*)}*/}

      {APP_INFO.type === 'B2B' ? <B2BCartAction /> : <CartAction />}
    </>
  );
};

export default HeaderAction;
