import {
  Box,
  clsx,
  createStyles,
  Flex,
  Grid,
  Group,
  Image,
  useMantineTheme,
} from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { useContainerStyles } from '@/utils/style/useContainer';
import { IBlock, IImageFields, ILabel } from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import React from 'react';
import { Carousel } from '@mantine/carousel';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import { NoozSectionTitle } from '../Nooz/NoozTitle/NoozTitle';
import { useMediaQuery } from '@mantine/hooks';
import { RetailOpeningInfo } from '@/components/Retail/RetailInfo';

const useStyles = createStyles((theme) => ({
  title: {
    display: 'flex',
    height: 78,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    fontFamily: 'Gilmer',
    fontSize: 42,
    fontWeight: 500,
    marginBottom: 20,
  },
  desktop: {
    [theme.fn.smallerThan(theme.breakpoints.xs)]: {
      display: 'none',
    },
  },
  mobile: {
    [theme.fn.largerThan(theme.breakpoints.xs)]: {
      display: 'none',
    },
  },
  card: {
    maxWidth: 500,
  },
  img: {
    '& .mantine-Image-image': {
      width: '100%',
      height: 'auto',
      aspectRatio: '1.5',
      objectFit: 'contain',
      borderRadius: '20px',
    },
  },
  label: {
    display: 'flex',
    height: '60px',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.colors.dark,
    fontFamily: 'Gilmer',
    fontSize: '22px',
    fontWeight: 900,
  },
  content: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '12px',
  },
  controls: {
    top: '25%',
  },
  control: {
    '&[data-inactive]': {
      opacity: 0,
      cursor: 'default',
    },
  },
}));

const GridCards = ({ label, cards }: { label: ILabel; cards: IBlock }) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { classes: c } = useContainerStyles();

  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);

  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  return (
    <>
      <Box className={clsx(c.container)}>
        <NoozSectionTitle className={classes.title}>
          {label.fields.text}
        </NoozSectionTitle>
        <Grid
          gutter={isDesktop ? 30 : isTablet ? 20 : 10}
          className={clsx(classes.desktop)}>
          {(cards.fields.items || []).map((card, key) => {
            const { label, image, ...props } = formatCfItems(card);

            return (
              <Grid.Col
                key={(card as any).id + '_' + key}
                w='100%'
                span={6}>
                <Flex
                  w='100%'
                  direction={'column'}
                  align={'start'}
                  justify={'center'}>
                  <Image
                    className={classes.img}
                    src={
                      (image?.fields as IImageFields)?.media?.fields?.file
                        ?.url + '?fm=webp'
                    }
                    alt={''}
                    mb={'lg'}
                  />
                  <NoozText
                    color={'title'}
                    size={24}
                    fw={700}>
                    {label?.fields.text}
                  </NoozText>
                  <NoozText
                    color={'text'}
                    size={18}
                    fw={500}>
                    {(props['content'] as ILabel)?.fields.text}
                  </NoozText>
                </Flex>
              </Grid.Col>
            );
          })}
        </Grid>
        <Carousel
          {...CAROUSEL_PROPS}
          loop={false}
          align={'start'}
          slideSize='80%'
          slideGap={10}
          className={classes.mobile}
          classNames={{
            ...useCarouselIndicatorsStyles({}).classes,
            controls: classes.controls,
            control: classes.control,
          }}
          withControls={true}
          withIndicators={false}
          height={'100%'}>
          {(cards.fields.items || []).map((card, key) => {
            const { label, image, ...props } = formatCfItems(card);

            return (
              <Carousel.Slide key={(card as any).id + '_' + key}>
                <Flex
                  direction={'column'}
                  className={classes.card}>
                  <Image
                    className={classes.img}
                    src={
                      (image?.fields as IImageFields).media?.fields?.file?.url +
                      '?fm=webp'
                    }
                    alt={''}
                  />
                  <NoozText className={classes.label}>
                    {label.fields.text}
                  </NoozText>
                  {card.fields.type === 'content' ? (
                    ((props['content'] as ILabel).fields.textList || [])
                      .length > 0 ? (
                      ((props['content'] as ILabel).fields.textList || []).map(
                        (str, key) => (
                          <NoozText
                            key={key}
                            className={classes.content}>
                            {str}
                          </NoozText>
                        ),
                      )
                    ) : (
                      <NoozText className={classes.content}>
                        {(props['content'] as ILabel).fields.text}
                      </NoozText>
                    )
                  ) : null}
                  {card.fields.type === 'opening hours' ? (
                    <RetailOpeningInfo days={props['days']} />
                  ) : null}
                </Flex>
              </Carousel.Slide>
            );
          })}
        </Carousel>
      </Box>
    </>
  );
};

export default GridCards;
