//@ts-nocheck
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IImageFields, IParagraphFields } from '@/@types/generated/contentful';
import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { BLOCKS } from '@contentful/rich-text-types';
import { Container, ContainerProps, Image, Title } from '@mantine/core';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozText from '../Nooz/NoozText/NoozText';
import Link from '@/components/Link/Link';

const Text = ({ children }) => <p>{children}</p>;

export const options = (containerProps: ContainerProps = {}) => ({
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <Container
        size='sm'
        {...containerProps}>
        <Title order={1}>{children}</Title>
      </Container>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Container
        size='sm'
        {...containerProps}>
        <Title
          order={2}
          size={'1.5em'}
          mb={20}
          mt={30}>
          {children}
        </Title>
      </Container>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Container
        size='sm'
        {...containerProps}>
        <Title
          order={3}
          size={'1.17em'}
          mb={20}
          mt={30}>
          {children}
        </Title>
      </Container>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Container
        size='sm'
        {...containerProps}>
        <Text>{children}</Text>
      </Container>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <Container
        size='sm'
        {...containerProps}>
        <ol>{children}</ol>
      </Container>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <Container
        size='sm'
        {...containerProps}>
        <ul>{children}</ul>
      </Container>
    ),
    [BLOCKS.QUOTE]: (node) => {
      return (
        <Container
          my={80}
          mah={700}
          h={'auto'}
          size='lg'>
          <NoozText
            w='100%'
            lh={1.3}
            color='white'
            family='Mont'
            italic
            sx={(theme) => ({
              fontSize: 60,
              [theme.fn.smallerThan('md')]: {
                fontSize: 40,
              },
            })}>
            {`"${node.content?.[0]?.content?.[0]?.value}"`}
          </NoozText>
        </Container>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      if (node.data.target?.fields?.key === 'image') {
        const containerStyle = useContainerStyles();
        const src = (node.data.target.fields as IImageFields).media?.fields.file
          .url;
        const mobileSrc = (node.data.target.fields as IImageFields).mobileMedia
          ?.fields.file.url;
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { ease: 'easeOut', duration: 1 },
            }}
            exit={{
              opacity: 0,
              transition: { ease: 'easeOut', duration: 0.5, delay: 0.4 },
            }}>
            <Container
              my={80}
              mah={700}
              h={'auto'}
              className={containerStyle.classes.container}>
              <Image
                mah={700}
                size='cover'
                sx={(theme) => ({
                  borderRadius: theme.radius.lg,
                  overflow: 'hidden',
                  ...(mobileSrc
                    ? {
                        [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                          display: 'none',
                        },
                      }
                    : {}),
                })}
                alt={node.data.target.fields.altText || ''}
                src={src + '?fm=webp'}
              />
              {mobileSrc ? (
                <Image
                  mah={700}
                  size='cover'
                  sx={(theme) => ({
                    borderRadius: theme.radius.lg,
                    overflow: 'hidden',
                    [theme.fn.largerThan(theme.breakpoints.sm)]: {
                      display: 'none',
                    },
                  })}
                  alt={node.data.target.fields.altText || ''}
                  src={mobileSrc + '?fm=webp'}
                />
              ) : null}
            </Container>
          </motion.div>
        );
      }
      return null;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      if (node.data.target.fields.file) {
        const containerStyle = useContainerStyles();
        if (node.data.target.fields.file.contentType?.includes('image')) {
          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { ease: 'easeOut', duration: 1 },
              }}
              exit={{
                opacity: 0,
                transition: { ease: 'easeOut', duration: 0.5, delay: 0.4 },
              }}>
              <Container
                my={80}
                mah={700}
                h={'auto'}
                className={containerStyle.classes.container}>
                <Image
                  mah={700}
                  size='cover'
                  sx={(theme) => ({
                    borderRadius: theme.radius.lg,
                    overflow: 'hidden',
                  })}
                  alt={node.data.target.fields.altText || ''}
                  src={node.data.target.fields.file.url + '?fm=webp'}
                />
              </Container>
            </motion.div>
          );
        }
        if (node.data.target.fields.file.contentType?.includes('video')) {
          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { ease: 'easeOut', duration: 1, delay: 1 },
              }}
              exit={{
                opacity: 0,
                transition: { ease: 'easeOut', duration: 0.5, delay: 0.4 },
              }}>
              <Container
                my={80}
                mah={700}
                h={'auto'}
                className={containerStyle.classes.container}>
                <video
                  width='100%'
                  height='100%'
                  autoPlay
                  loop
                  muted
                  src={node.data.target.fields.file.url}>
                  <source
                    src={node.data.target.fields.file.url}
                    type={node.data.target.fields.file.contentType}
                  />
                </video>
              </Container>
            </motion.div>
          );
        }
      }
    },
    ['entry-hyperlink']: (node) => {
      try {
        if (node.data.target.fields.key !== 'link') return null;
        return (
          <Link
            htmlProps={{ target: '_blank' }}
            href={node.data.target.fields.slug?.fields.url}
            target={node.data.target.fields.openNewTab ? '_blank' : undefined}
            rel={
              node.data.target.fields.openNewTab
                ? 'noopener noreferrer'
                : undefined
            }
            underline>
            {node.content[0].value}
          </Link>
        );
      } catch (e) {
        console.error(e);
        return 'error url';
      }
    },
  },
  renderText: (text) => {
    return text.split('\n').reduce((children: any, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
});

export const Body: FC<IParagraphFields> = ({
  richBody,
  containerProps,
}: IParagraphFields & { containerProps?: ContainerProps }) => {
  return richBody
    ? documentToReactComponents(richBody, options(containerProps))
    : null;
};
