import React from 'react';
import { Container, Flex, createStyles, useMantineTheme } from '@mantine/core';
import { formatCfItems } from '@/helpers/formatCfItems';
import NoozText from '../../Nooz/NoozText/NoozText';
import { IBlock, IIcon, ILabel } from '@/@types/generated/contentful';
import Stars from '../../Stars/Stars';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';

const useStyles = createStyles((theme) => ({
  row: {
    display: 'flex',
    overflow: 'hidden',
    whiteSpaceCollapse: 'collapse',
    textWrap: 'nowrap',
    flexShrink: 0,
    gap: 80,
    padding: '20px 0',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    minWidth: '100%',
    animation: 'slideRight 40s linear infinite',
    '@keyframes slideRight': {
      from: { transform: 'translateX(0px)' },
      to: { transform: 'translateX(calc(-100% - 50px))' },
    },
  },
  rowItem: {
    gap: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontSize: 20,
    [theme.fn.smallerThan('md')]: {
      fontSize: 18,
    },
  },
  subLabel: {
    fontSize: 20,
    [theme.fn.smallerThan('md')]: {
      fontSize: 18,
    },
  },
}));

const gradients = [
  { from: '#7a4442', to: '#c5a09f', deg: 45 },
  { from: '#8a618f', to: '#87478f', deg: 45 },
  { from: '#7861b0', to: '#6946bc', deg: 45 },
  { from: '#74a5c4', to: '#85bde2', deg: 45 },
];

const ReassuranceBanner = ({ list }: { list: IBlock }) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const containerStyles = useContainerStyles();

  const returnReassuranceRow = () => {
    return (
      <Flex className={classes.row}>
        {list?.fields.items?.map((item: any, index: number) => {
          const {
            label,
            subLabel,
            icon,
          }: { label: ILabel; subLabel: ILabel; icon: IIcon } =
            formatCfItems(item);
          const isSatisfaction = item.fields.key === 'satisfaction';
          // @ts-ignore
          return (
            <Flex
              key={index}
              className={classes.rowItem}>
              {isSatisfaction ? (
                <Stars
                  stars={5}
                  color={gradients[index].from}
                />
              ) : (
                <>
                  <NoozText
                    lh={1}
                    weight={600}
                    className={classes.label}
                    variant={label?.fields?.variant}
                    td={label?.fields?.decoration}
                    color={label?.fields.color?.fields.classname}
                    gradient={gradients[index]}
                    align='center'>
                    {label?.fields?.text}
                  </NoozText>

                  <NoozIcon
                    iconKey={icon?.fields?.iconKey}
                    size={26}
                    color={gradients[index]?.from}
                  />
                </>
              )}
              <NoozText
                align='center'
                className={classes.subLabel}
                variant={subLabel?.fields?.variant}
                gradient={gradients[index]}
                td={subLabel?.fields?.decoration}
                color={subLabel?.fields.color?.fields.classname}>
                {subLabel?.fields?.text}
              </NoozText>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  return (
    <Container className={containerStyles.classes.container}>
      <Flex
        sx={{
          overflow: 'hidden',
          background: theme.colors.lightGray,
          borderRadius: theme.radius.xl,
          gap: 80,
          ':hover': {
            '*': {
              animationPlayState: 'paused',
            },
          },
        }}>
        {returnReassuranceRow()}
        {returnReassuranceRow()}
      </Flex>
    </Container>
  );
};

export default ReassuranceBanner;
