import usePageContext from '../context/page';

export const returnCollectionPageType = (
  productCategory: any,
): 'split' | 'grid' => {
  const collectionPageType =
    productCategory?.fields.handle === 'glassesReading' ? 'split' : 'grid';

  return collectionPageType;
};

function useCollectionPageType() {
  const { productCategory } = usePageContext();

  return returnCollectionPageType(productCategory);
}
export default useCollectionPageType;
