import {
  IBadge,
  IBlock,
  IButton,
  IColor,
  IIcon,
  IImage,
  IInput,
  ILink,
  IPath,
  IPopup,
  IProduct,
  IProductVariant,
} from '@/@types/generated/contentful';
import { Entry } from 'contentful';

export const formatCfItems: any = (props: Record<string, any>) => {
  const ComponentsProps: any = {};
  if (props && props.fields?.items) {
    props.fields.items.forEach(
      (item: any) => (ComponentsProps[item?.fields?.key as string] = item),
    );
  }
  if (typeof props === 'object' && props.length > 0) {
    props.forEach(
      (item: any) => (ComponentsProps[item?.fields?.key as string] = item),
    );
  }
  return ComponentsProps;
};
// eslint-disable-next-line no-unused-vars
let qty = 0;
type CleanEntryFct = (
  _entry?: Entry<any>,
  _autoShopstoryHandling?: boolean,
) => null | Entry<any>;
const CleanEntry: {
  product: CleanEntryFct;
  badge: CleanEntryFct;
  color: CleanEntryFct;
  image: CleanEntryFct;
  label: CleanEntryFct;
  icon: CleanEntryFct;
  path: CleanEntryFct;
  block: CleanEntryFct;
  button: CleanEntryFct;
  input: CleanEntryFct;
  link: CleanEntryFct;
  popup: CleanEntryFct;
  productVariant: CleanEntryFct;
} = {
  product: (entry) => {
    if (!entry) return null;
    const { fields } = entry as IProduct;
    const { title, label, key } = fields;

    return {
      sys: entry.sys,
      fields: { title, label, key },
    } as Entry<any>;
  },
  productVariant: (entry) => {
    if (!entry) return null;
    const { fields } = entry as IProductVariant;
    const { title } = fields;

    return {
      sys: entry.sys,
      fields: { title },
    } as Entry<any>;
  },
  color: (entry) => {
    if (!entry) return null;
    const { fields } = entry as IColor;
    const { title, hex, hue, shopifyKey, gradientObject, key } = fields;

    return {
      sys: entry.sys,
      fields: { title, hex, hue, shopifyKey, key, gradientObject },
    } as Entry<any>;
  },
  image: (entry) => {
    if (!entry) return null;
    const { fields } = entry as IImage;
    const { title, media, altText, key } = fields;

    return {
      sys: entry.sys,
      fields: { title, media, altText, key },
    } as Entry<any>;
  },
  label: (entry) => {
    if (!entry) return null;
    const { fields } = entry;
    const { text, key, variant, weight } = fields;

    return {
      sys: entry.sys,
      fields: { text, key, variant, weight },
    } as Entry<any>;
  },
  icon: (entry, autoShopstoryHandling) => {
    if (!entry) return null;
    const { fields } = entry as IIcon;
    const { key, iconKey, size, color } = fields;

    return {
      sys: entry.sys,
      fields: {
        iconKey,
        key,
        size,
        color: color
          ? cfFormat.cleanFields(color as Entry<any>, autoShopstoryHandling)
          : null,
      },
    } as Entry<any>;
  },
  path: (entry) => {
    if (!entry) return null;
    const { fields } = entry as IPath;
    const { title, url, facet, key } = fields;

    return { sys: entry.sys, fields: { title, url, facet, key } } as Entry<any>;
  },
  block: (entry, autoShopstoryHandling) => {
    if (!entry) return null;
    const { fields } = entry as IBlock;
    const { key, items, type } = fields;

    return {
      sys: entry.sys,
      fields: {
        key,
        type,
        items: items?.map((item) =>
          cfFormat.cleanFields(item as Entry<any>, autoShopstoryHandling),
        ),
      },
    } as Entry<any>;
  },
  button: (entry, autoShopstoryHandling) => {
    if (!entry) return null;
    const { fields } = entry as IButton;
    const { key, label, slug } = fields;

    return {
      sys: entry.sys,
      fields: {
        key,
        label: label
          ? cfFormat.cleanFields(label, autoShopstoryHandling)
          : null,
        slug: slug ? cfFormat.cleanFields(slug, autoShopstoryHandling) : null,
      },
    } as Entry<any>;
  },
  input: (entry, autoShopstoryHandling) => {
    if (!entry) return null;
    const { fields } = entry as IInput;
    const { key, label, placeholder } = fields;

    return {
      sys: entry.sys,
      fields: {
        key,
        label: label
          ? cfFormat.cleanFields(label, autoShopstoryHandling)
          : null,
        placeholder: placeholder
          ? cfFormat.cleanFields(placeholder, autoShopstoryHandling)
          : null,
      },
    } as Entry<any>;
  },
  link: (entry, autoShopstoryHandling) => {
    if (!entry) return null;
    const { fields } = entry as ILink;
    // @ts-ignore
    const { label, slug, key, urlLink, icon, badge } = fields;

    return {
      sys: entry.sys,
      fields: {
        key,
        urlLink,
        label: label
          ? cfFormat.cleanFields(label, autoShopstoryHandling)
          : null,
        badge: badge
          ? cfFormat.cleanFields(badge, autoShopstoryHandling)
          : null,
        icon: icon ? cfFormat.cleanFields(icon, autoShopstoryHandling) : null,
        slug: slug ? slug : null,
      },
    } as Entry<any>;
  },
  badge: (entry, autoShopstoryHandling) => {
    if (!entry) return null;
    const { fields } = entry as IBadge;
    // @ts-ignore
    const { label, color, key } = fields;

    return {
      sys: entry.sys,
      fields: {
        key,
        label: label
          ? cfFormat.cleanFields(label, autoShopstoryHandling)
          : null,
        color: color
          ? cfFormat.cleanFields(color, autoShopstoryHandling)
          : null,
      },
    } as Entry<any>;
  },
  popup: (entry) => {
    if (!entry) return null;
    const { fields } = entry as IPopup;

    return {
      sys: entry.sys,
      fields,
    } as Entry<any>;
  },
};

const cfFormat = {
  preformatPackOption: (opt: IBlock) => {
    return {
      ...opt,
      fields: {
        ...opt.fields,
        items: opt.fields.items?.filter(
          (i) =>
            typeof i?.fields?.key === 'string' &&
            ['label', 'subLabel', 'button'].includes(i.fields.key),
        ),
      },
    };
  },

  cleanFields: (
    entry: Entry<any>,
    autoShopstoryHandling = true,
  ): Entry<any> | null => {
    const filteredFields: Record<string, any> = {};

    if (!entry?.sys) return entry;
    qty += 1;
    if (entry?.fields?.key === 'upsell') {
      // @ts-ignore
      return { fields: { key: 'upsell', items: [] } };
    }
    if (entry.sys?.type === 'Asset') {
      return null;
    } else if (
      entry.sys?.contentType?.sys?.id &&
      [
        'label',
        'path',
        'image',
        'color',
        'block',
        'badge',
        'button',
        'input',
        'link',
        'popup',
        'product',
      ].includes(entry.sys.contentType.sys.id)
    ) {
      return {
        // @ts-ignore
        ...CleanEntry[entry.sys.contentType.sys.id](
          entry,
          autoShopstoryHandling,
        ),
      };
    } else if (entry.fields) {
      Object.entries(entry.fields || {}).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          const filteredArray: any[] = [];
          value.forEach((item) => {
            if (typeof item === 'object') {
              filteredArray.push(
                cfFormat.cleanFields(item as Entry<any>, autoShopstoryHandling),
              );
            } else {
              filteredArray.push(item);
            }
          });
          filteredFields[key] = filteredArray;
        } else if (typeof value === 'object') {
          filteredFields[key] = cfFormat.cleanFields(
            value as Entry<any>,
            autoShopstoryHandling,
          );
        } else {
          filteredFields[key] = value;
        }
      });

      if (
        entry.sys.contentType?.sys.id === 'shopstoryBlock' &&
        process.env.NEXT_PUBLIC_LAYOUT_SHOPSTORYBLOCK === 'client-side' &&
        autoShopstoryHandling
      )
        return null;

      return { sys: entry.sys, fields: filteredFields } as Entry<any>;
    }
    return entry;
  },
  formatEntriesResponse: (object: any) => {
    if (object?.sys?.id) object.id = object.sys.id;

    Object.keys(object).forEach((k) => {
      if (
        (k === 'metadata' || k === 'sys') &&
        cfFormat.formatEntriesResponse(object[k])
      ) {
        delete object[k];
        return;
      } else if (object[k]?.items) {
        object[k]?.items?.forEach((obj: any) => {
          cfFormat.formatEntriesResponse(obj);
        });
      }
    });
    if (!Object.keys(object).length) {
      return null;
    }

    return object as NoozCfEntry<any>;
  },
};

export default cfFormat;
