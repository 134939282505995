import React, { useEffect, useMemo, useState } from 'react';
import { Box, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IPopupFields } from '@/@types/generated/contentful';
import usePageContext from '@/utils/context/page';
import { useDialogContext } from '@/utils/context/dialog';
import useCookieBannerContext from '@/utils/context/cookieBanner';
import { MainPopupModal, useMainPopupModalContext } from './MainPopupModal';
import TeaserPopup from './TeaserPopup';
import {
  TeaserPopupDrawer,
  TeaserPopupDrawerContext,
  useTeaserPopupDrawerContext,
} from './TeaserPopupDrawer';
import MainPopup from './MainPopup';
import { useStorage } from '@/utils/hook/useStorage';

const formatBoolean = (value: string | null) => {
  return typeof value === 'string' ? value === 'true' : value;
};

const Popup = () => {
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.sm})`,
    true,
    { getInitialValueInEffect: false },
  );
  const { layout, page, components } = usePageContext();
  const popupFields = layout?.fields.popup?.fields as IPopupFields;

  const { dialog, setDialog } = useDialogContext();
  const cookieContext = useCookieBannerContext();
  const { modal, setModal } = useMainPopupModalContext();
  const { drawer, setDrawer } = useTeaserPopupDrawerContext();
  const [hasOpened, setHasOpened] = useState(false);
  const [isScrollBeforeTimeOut, setIsScrollBeforeTimeOut] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const forceHidePopup = useMemo(() => {
    const config: any = Array.isArray(components)
      ? {}
      : components?.config?.fields?.metaData || {};
    return config.hidePopup;
  }, [components]);
  const {
    title,
    teaserComponent,
    mainComponent,
    teaserScrollTrigger,
    teaserTimerTrigger,
    mainScrollTrigger,
    mainTimerTrigger,
    trigger,
  } = popupFields || {};

  const popupKey = title?.replace(/ /g, '_');
  const [hasSeenPopup, setHasSeenPopup] = useStorage(
    trigger === 'newSession' ? 'session' : 'local',
    `has_seen_${
      trigger === 'newSession' ? 'session' : 'local'
    }_popup_${popupKey}`,
    false,
    formatBoolean,
  );

  const handleMainPopupOpen = () => {
    setHasOpened(true);
    setModal({ ...modal, opened: true, body: <MainPopup /> });
  };

  const handleTeaserPopupOpen = () => {
    setHasOpened(true);
    setDialog({
      ...dialog,
      opened: true,
      styles: {
        root: { background: 'transparent', borderRadius: 20 },
      },
      body: (
        <Box
          w={400}
          h={{ base: 160, sm: 200 }}>
          <TeaserPopup />
        </Box>
      ),
    });
  };

  const handleOpenPopup = (open: Function) => {
    if (trigger === 'pageLoad' && !hasOpened) {
      open();
    } else if (['newSession', 'newUser'].includes(trigger) && !hasSeenPopup) {
      open();
      setHasSeenPopup('true');
    }
  };

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      (!mainScrollTrigger && !teaserScrollTrigger)
    ) {
      return;
    }
    const onScroll = (e: any) => {
      if (e.target.documentElement) {
        setScrollTop(e.target.documentElement.scrollTop);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (
      !popupFields ||
      title === 'Empty popup' ||
      forceHidePopup ||
      /^Landing - /.test(page?.fields.title || '') ||
      (page?.fields as any).pageType.type === 'Product'
    ) {
      setDialog((prev) => ({ ...prev, opened: false }));
      return;
    }
    if (!mainTimerTrigger && !teaserTimerTrigger) return;
    if (mainTimerTrigger && !teaserComponent && !cookieContext.opened) {
      const mainDialogTimeOut = setTimeout(() => {
        handleOpenPopup(handleMainPopupOpen);
      }, mainTimerTrigger * 1000);
      return () => clearTimeout(mainDialogTimeOut);
    }

    if (teaserTimerTrigger) {
      const teaserTimeOut = setTimeout(() => {
        handleOpenPopup(handleTeaserPopupOpen);
      }, teaserTimerTrigger * 1000);
      return () => clearTimeout(teaserTimeOut);
    }
  }, [isScrollBeforeTimeOut, cookieContext.opened, page, forceHidePopup]);

  useEffect(() => {
    // @ts-ignore
    if (
      !popupFields ||
      title === 'Empty popup' ||
      forceHidePopup ||
      /^Landing - /.test(page?.fields.title || '') ||
      (page?.fields as any).pageType.type === 'Product'
    ) {
      setDialog((prev) => ({ ...prev, opened: false }));

      return;
    }
    if (
      !cookieContext.opened &&
      mainScrollTrigger > 0 &&
      mainComponent &&
      scrollTop > mainScrollTrigger
    ) {
      handleOpenPopup(handleMainPopupOpen);
      setIsScrollBeforeTimeOut(true);
    }

    if (
      !cookieContext.opened &&
      teaserScrollTrigger > 0 &&
      scrollTop > teaserScrollTrigger
    ) {
      handleOpenPopup(handleTeaserPopupOpen);
      setIsScrollBeforeTimeOut(true);
    }
  }, [scrollTop, cookieContext.opened, page, forceHidePopup]);

  // @ts-ignore
  if (
    !popupFields ||
    title === 'Empty popup' ||
    forceHidePopup ||
    /^Landing - /.test(page?.fields.title || '') ||
    (page?.fields as any).pageType.type === 'Product'
  )
    return null;

  return (
    <TeaserPopupDrawerContext.Provider value={{ drawer, setDrawer }}>
      <>
        <MainPopupModal />
        <TeaserPopupDrawer />
      </>
    </TeaserPopupDrawerContext.Provider>
  );
};

export default Popup;
