import usePageContext from '@/utils/context/page';
import { createStyles, Flex, Group } from '@mantine/core';
import React, { useMemo } from 'react';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { formatCfItems } from '@/helpers/formatCfItems';
import NoozRichBody from '@/components/Nooz/NoozRichBody/NoozRichBody';
import useShippingRates from '@/utils/hook/useShippingRates';
import Currency from '@/helpers/Currency';
import { FREE_SHIPPING_ENABLED } from '@/components/Header/HeaderBanner/headerBanner';

export const useClasses = () =>
  createStyles(() => ({
    control: {
      border: 'none',
      backgroundColor: 'none',
      boxShadow: 'none',
    },
    row: {
      display: 'flex',
      overflow: 'hidden',
      whiteSpaceCollapse: 'collapse',
      textWrap: 'nowrap',
      flexShrink: 0,
      gap: 40,
      padding: '0',
      flexWrap: 'nowrap',
      justifyContent: 'space-around',
      minWidth: '100%',
      animation: 'slideRight 40s linear infinite',
      '@keyframes slideRight': {
        from: { transform: 'translateX(0px)' },
        to: { transform: 'translateX(calc(-100% - 50px))' },
      },
    },
    rowItem: {
      gap: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }))().classes;

const CartDrawerReassurance = ({}: { delay?: number }) => {
  const shippingRates = useShippingRates();
  const classes = useClasses();
  const { layout } = usePageContext();
  const { reassurances } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );

  const Iteration = () => (
    <Flex className={classes.row}>
      {(reassurances.fields.items || []).map((r: any, key: number) => {
        const { body, icon } = formatCfItems(r);

        if (
          r.fields.key === 'freeShipping' &&
          (!shippingRates?.free || FREE_SHIPPING_ENABLED)
        ) {
          return null;
        }

        return (
          <Group
            className={classes.rowItem}
            key={`${r.id}-${key}`}
            spacing={8}
            sx={{
              '& > p': {
                margin: 0,
                flex: 1,
              },
            }}>
            {icon ? (
              <Flex
                bg='white'
                p={4}
                sx={{ borderRadius: '100%' }}>
                <NoozIcon
                  iconKey={icon.fields.iconKey}
                  color={icon.fields.color?.fields.hex}
                />
              </Flex>
            ) : null}
            <NoozRichBody
              {...body.fields}
              replaceStrings={
                r.fields.key === 'freeShipping'
                  ? {
                      //  on a le pris des livraisons en euros uniquement
                      '{{amount}}': `${Currency.moneyToPrice({
                        ...shippingRates.free.price,
                        amount: shippingRates.free.min_order_subtotal,
                      })}`,
                    }
                  : undefined
              }
            />
          </Group>
        );
      })}
    </Flex>
  );

  return (
    <Flex
      sx={(theme) => ({
        overflow: 'hidden',
        background: theme.colors.lightGray,
        borderRadius: theme.radius.xl,
        gap: 40,
        ':hover': {
          '*': {
            animationPlayState: 'paused',
          },
        },
      })}>
      <Iteration />
      <Iteration />
    </Flex>
  );
};

export default CartDrawerReassurance;
