import ShopifyBuy from 'shopify-buy';
import { LocalStorageKeys } from './keys';

function remove(key: string) {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    try {
      window.localStorage.removeItem(key);
    } catch {
      console.warn(' Error reading from local storage');
    }
  }
}
function set(key: string, value: string) {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    try {
      window.localStorage.setItem(key, value);
    } catch {
      console.warn(' Error reading from local storage');
    }
  }
}

function get(key: string) {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return null;
  }

  try {
    const item = window.localStorage.getItem(key);
    return item;
  } catch {
    return null;
  }
}
function getAllKeyFromSearch(search: string) {
  const arr: string[] = [];

  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return arr;
  }

  try {
    const regex = new RegExp(search);

    for (let i = 0; i < window.localStorage.length; i++) {
      if (
        typeof window.localStorage.key(i) === 'string' &&
        regex.test(window.localStorage.key(i) as string)
      )
        arr.push(window.localStorage.key(i) as string);
    }
    return arr;
  } catch {
    return arr;
  }
}

function getInitialCart(): ShopifyBuy.Checkout | null {
  function isCart(potentialCart: any): potentialCart is ShopifyBuy.Checkout {
    return (
      potentialCart != null &&
      potentialCart.id != null &&
      potentialCart.webUrl != null &&
      potentialCart.lineItems != null &&
      potentialCart.__typename === 'Checkout'
    );
  }
  const existingCartString = get(LocalStorageKeys.CART);
  if (existingCartString === null) {
    return null;
  }

  try {
    const existingCart = JSON.parse(existingCartString);
    if (!isCart(existingCart)) {
      return null;
    }

    return existingCart as ShopifyBuy.Checkout;
  } catch {
    return null;
  }
}

export const LocalStorage = {
  get,
  getAllKeyFromSearch,
  set,
  getInitialCart,
  remove,
};
