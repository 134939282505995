import React, { Fragment } from 'react';
import { formatCfItems } from '@/helpers/formatCfItems';
import {
  Divider,
  Flex,
  Group,
  Indicator,
  NavLink,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { motion } from 'framer-motion';
import {
  IBadge,
  IBlock,
  IButton,
  ILabel,
  ILinkFields,
  IPath,
} from '@/@types/generated/contentful';
import Link from '@/components/Link/Link';
import { ChevronRight } from 'tabler-icons-react';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import useLocalizedSlug from '@/utils/hook/useLocalizedSlug';
import useAuth from '@/utils/context/authentication';

const motionProps = {
  initial: { opacity: 0, transform: 'translateX(100px)' },
  animate: { opacity: 1, transform: 'translateX(0px)' },
  exit: { opacity: 0, transform: 'translateX(100px)' },
  transition: { ease: 'easeOut', duration: 0.3 },
};

const MobileMasterNavigationMobileNavLink = ({
  label,
  slug,
  badge,
}: {
  label: ILabel;
  slug: IPath;
  badge?: IBadge;
}) => {
  const theme = useMantineTheme();
  const href = useLocalizedSlug(slug?.fields.url as string);
  return (
    <NavLink
      sx={{ padding: '3px 0px' }}
      component='a'
      href={href.pathname}
      variant='subtle'
      color='gray'
      label={
        <Group position='apart'>
          <Indicator
            disabled={!badge || badge?.fields.key === 'empty'}
            sx={{
              '& .mantine-Indicator-indicator': {
                height: 'fit-content',
                width: 'fit-content',
                padding: '0px 10px',
                transform: 'translate(105%, 0%)',
              },
            }}
            label={badge?.fields.label?.fields.text}
            color={badge?.fields.color?.fields.classname}>
            <NoozText
              color={theme.colors.gray[8]}
              size={14}>
              {label?.fields.text}
            </NoozText>
          </Indicator>
          <ChevronRight
            size={20}
            strokeWidth={1.3}
          />
        </Group>
      }
    />
  );
};

export default function HeaderMobileNavigationsMasterNavigation({
  details,
}: {
  details: IBlock;
}) {
  const {
    mainList,
    mainLabel,
    servicesList,
    servicesLabel,
    button,
    account,
  }: {
    button: IButton;
    mainList: IBlock;
    mainLabel: ILabel;
    servicesList: IBlock;
    servicesLabel: ILabel;
    account?: IPath;
  } = formatCfItems(details);
  const auth = useAuth();

  // function to handle nav link return with external link
  const returnNavLink = (label: ILabel, slug: IPath, badge?: IBadge) => {
    if (slug?.fields.url) {
      return (
        <MobileMasterNavigationMobileNavLink
          label={label}
          slug={slug}
          badge={badge}
        />
      );
    }
    return null;
  };

  return (
    <Flex
      p='lg'
      direction='column'>
      <motion.div {...motionProps}>
        <Stack spacing={5}>
          {mainLabel ? (
            <Divider
              labelProps={{ weight: 600, size: 16 }}
              label={mainLabel?.fields.text}
            />
          ) : null}
          {mainList?.fields.items?.map((mainLink, i) => {
            const slug = mainLink.fields.slug as IPath;
            const label = mainLink.fields.label as ILabel;
            const badge = (mainLink.fields as ILinkFields).badge;
            return (
              <React.Fragment key={`${mainLabel?.fields.text}-mainList-${i}`}>
                {returnNavLink(label, slug, badge)}
              </React.Fragment>
            );
          })}
          {button && (
            <NoozButton
              href={
                auth.isAuthenticated && account
                  ? account.fields.url
                  : (button.fields.slug?.fields.url as string)
              }
              fullWidth={false}
              color={'dark'}
              my={34}>
              {button.fields.label?.fields.text}
            </NoozButton>
          )}
        </Stack>
      </motion.div>
      <motion.div {...motionProps}>
        <Stack spacing={5}>
          {servicesLabel ? (
            <Divider
              labelProps={{ weight: 600, size: 16 }}
              label={servicesLabel?.fields.text}
            />
          ) : null}
          {servicesList?.fields.items?.map((serviceLink, i) => {
            const slug = serviceLink.fields.slug as IPath;
            const label = serviceLink.fields.label as ILabel;
            const badge = (serviceLink.fields as ILinkFields).badge;
            return (
              <React.Fragment
                key={`${mainLabel?.fields.text}-servicesList-${i}`}>
                {returnNavLink(label, slug, badge)}
              </React.Fragment>
            );
          })}
        </Stack>
      </motion.div>
    </Flex>
  );
}
