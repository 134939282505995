// @ts-nocheck
import Cookies from 'js-cookie';

export const INTERNATIONAL_LOCAL = 'en-int';

/**
 *
 * MARCHE AUSTRALIE
 * en-au
 *
 * MARCHE CANADA
 * en-ca
 * fr-ca
 *
 * MARCHE EU
 * de-de
 * ??? en-de
 * fr-fr
 * ??? en-fr
 * ??? de-at
 * pt-pt
 * es-es
 * ???en-es
 * it-it
 * ??? en-it
 * ??? fr-be
 * ??? nl-be
 * nl-nl
 * ??? en-nl
 *
 * MARCHE NEW ZEALAND
 * en-nz
 *
 * MARCHE SINGAPORE
 * en-sg
 *
 * MARCHE SUISSE
 * fr-ch
 * it-ch
 * de-ch
 *
 * MARCHE UK
 * en-bg
 *
 * MARCHE US
 * en-us
 */
export const LOCALES = [
  INTERNATIONAL_LOCAL,
  'fr-ca',
  'en-ca',

  'de-de',
  'en-au',
  'en-gb',
  'en-nz',
  'en-sg',
  'pt-pt',
  'fr-fr',
  'it-it',
  'es-es',
  'nl-nl',

  'en-us',

  'fr-ch',
  'it-ch',
  'de-ch',

  'fr-be',
  'nl-be',
];
export const LOCALES_WITHOUT_INT = LOCALES.filter(
  (l) => l !== INTERNATIONAL_LOCAL,
);

export const CF_LOCALES = [
  'en',
  'en-CA',
  'en-US',
  'fr-FR',
  'fr-CA',
  'de-DE',
  'es-ES',
  'it-IT',
  'nl-NL',
  'pt-PT',
];
export const LANGUAGES = [
  {
    key: 'en',
    label: 'English',
    labelTranslations: {
      en: 'English',
      fr: 'Anglais',
      de: 'Englisch',
      nl: 'Engels',
      it: 'Inglese',
      pt: 'Inglês',
      es: 'Inglés',
    },
  },
  {
    key: 'fr',
    label: 'French',
    labelTranslations: {
      en: 'French',
      fr: 'Français',
      de: 'Französisch',
      nl: 'Frans',
      it: 'Francese',
      pt: 'Francês',
      es: 'Francés',
    },
  },
  {
    key: 'de',
    label: 'German',
    labelTranslations: {
      en: 'German',
      fr: 'Allemand',
      nl: 'Duits',
      it: 'Tedesco',
      pt: 'Alemão',
      es: 'Alemán',
      de: 'Deutsch',
    },
  },
  {
    key: 'nl',
    label: 'Dutch',
    labelTranslations: {
      en: 'Dutch',
      fr: 'Néerlandais',
      de: 'Niederländisch',
      it: 'Olandese',
      pt: 'Holandês',
      es: 'Holandés',
      nl: 'Nederlands',
    },
  },
  {
    key: 'it',
    label: 'Italian',
    labelTranslations: {
      en: 'Italian',
      fr: 'Italien',
      de: 'Italienisch',
      nl: 'Italiaans',
      pt: 'Italiano',
      es: 'Italiano',
      it: 'Italiano',
    },
  },
  {
    key: 'pt',
    label: 'Portuguese',
    labelTranslations: {
      en: 'Portuguese',
      fr: 'Portugais',
      de: 'Portugiesisch',
      nl: 'Portugees',
      it: 'Portoghese',
      es: 'Portugués',
      pt: 'Português',
    },
  },
  {
    key: 'es',
    label: 'Spanish',
    labelTranslations: {
      en: 'Spanish',
      fr: 'Espagnol',
      de: 'Spanisch',
      nl: 'Spaans',
      it: 'Spagnolo',
      pt: 'Espanhol',
      es: 'Español',
    },
  },
];
export const REMOVED_LOCALE = [
  'en-is',
  'en-ie',
  'en-cz',
  'en-hr',
  'en-hu',
  'en-is',
  'en-no',
  'en-pl',
  'en-ro',
  'en-bg',
  'en-dk',
  'en-ee',
  'en-fi',
  'en-fr',
  'en-cy',
  'fr-mc',
  'en-sk',
  'en-se',
  'en-si',
  'en-lt',
  'en-lu',
  'en-lv',
  'en-mc',
  'en-gr',
  'en-de',
  'en-fr',
  'de-at',
  'en-es',
  'en-it',
  'en-nl',
  'es-us',
];

export const getCountryLocal = (locale?: string) => {
  const { country } = LocaleHelper.splitInfoWithInt(locale || 'en-fr');
  return (country === 'int' ||
    (country === 'fr' &&
      Cookies.get(COUNTRY_COOKIE_NAME)?.toLowerCase() === 'mc')) &&
    Cookies.get(COUNTRY_COOKIE_NAME)?.toLowerCase()
    ? COUNTRIES[Cookies.get(COUNTRY_COOKIE_NAME)?.toLowerCase()] ||
        COUNTRIES['int']
    : COUNTRIES[country as keyof typeof COUNTRIES];
};
export const getCountryCodeLocal = (locale?: string) => {
  const { country } = LocaleHelper.splitInfoWithInt(locale || 'en-fr');
  return (country === 'int' ||
    (country === 'fr' &&
      Cookies.get(COUNTRY_COOKIE_NAME)?.toLowerCase() === 'mc')) &&
    Cookies.get(COUNTRY_COOKIE_NAME)?.toLowerCase() &&
    Object.keys(COUNTRIES).includes(
      Cookies.get(COUNTRY_COOKIE_NAME)?.toLowerCase(),
    )
    ? Cookies.get(COUNTRY_COOKIE_NAME)?.toLowerCase()
    : (country as keyof typeof COUNTRIES);
};

export const COUNTRY_COOKIE_NAME = 'country-origin';
export const CUSTOM_REDIRECT_REMOVED_LOCALE = {
  'fr-mc': 'fr-fr',
  'de-at': 'de-de',
};

const returnCountryLanguages = (keys: string[]) => {
  return keys.map((lg) => LANGUAGES.find((l) => lg === l.key));
};

const LocaleHelper = {
  splitInfoWithInt: (locale = 'en-int', toUpperCase = false) => {
    let [language, country] = (
      toUpperCase ? locale.toUpperCase() : locale.toLowerCase()
    ).split('-');
    return {
      language,
      country: country,
    };
  },
  splitInfo: (locale, toUpperCase = false) => {
    let [language, country] = (
      toUpperCase ? locale.toUpperCase() : locale.toLowerCase()
    ).split('-');
    if (!country || country?.toUpperCase() === 'INT')
      country = toUpperCase ? 'FR' : 'fr';
    return {
      language,
      // country: toUpperCase ? 'FR'.toUpperCase() : 'en'.toLowerCase(),
      country: country,
    };
  },
  getCountryLanguages: (keys) => {
    return keys.map((lg) => LANGUAGES.find((l) => lg === l.key));
  },
  getApplicationLocales: () => {
    return LOCALES;
  },
  getWarehouseId: (locale: string) => {
    const { country } = LocaleHelper.splitInfo(locale);

    switch (country) {
      case 'au': // Australia
      case 'ca': // Canada
      case 'nz': // New Zealand
      case 'sg': // Singapore
      case 'us': // United States
        return {
          id: 'gid://shopify/Location/83530088794',
          name: 'USA Fill',
        };
      default:
        return {
          id: 'gid://shopify/Location/36458102923',
          name: 'Europe Warehouse (LogVAD)',
        };
    }
  },
  checkLocaleSupported: (locale) => {
    const locales = LocaleHelper.getApplicationLocales();

    if (!locales.includes(locale)) {
      throw new Error(`Locale ${locale} is not supported`);
    }
  },
  getValidShopifyCountry: (locale) => {
    const cookie = Cookies.get(COUNTRY_COOKIE_NAME);
    const country = LocaleHelper.splitInfoWithInt(locale, true)?.country;

    return country === 'INT' &&
      cookie &&
      Object.keys(COUNTRIES).includes(cookie?.toLowerCase())
      ? cookie
      : country === 'INT'
      ? 'FR'
      : country;
  },

  getValidContentfulLang: (l) => {
    const locale = l || '';
    if (!l) {
      console.trace('No locale', l);
    }
    const loweredLocale = locale.toLowerCase();
    const { language } = LocaleHelper.splitInfo(locale);
    return (
      CF_LOCALES.find((l) => l.toLowerCase() === loweredLocale) ||
      CF_LOCALES.find((l) => LocaleHelper.splitInfo(l).language === language) ||
      'en'
    ).split('-')[0];
  },
  getValidContentfulLocale: (locale) => {
    const loweredLocale = locale.toString().toLowerCase();
    const matchingLocale = CF_LOCALES.find(
      (l) => l.toLowerCase() === loweredLocale,
    );
    if (matchingLocale) return matchingLocale;

    const lng = LocaleHelper.splitInfo(locale).language;
    if (['en-sg', 'en-au', 'en-nz'].includes(loweredLocale)) return 'en-US';

    switch (lng) {
      case 'it':
        return 'it-IT';
      case 'es':
        return 'es-ES';
      case 'nl':
        return 'nl-NL';
      case 'fr':
        return 'fr-FR';
      case 'pt':
        return 'pt-PT';
      case 'de':
        return 'de-DE';

      default:
        return 'en';
    }
  },
  getDefaultLocaleFromCountry: (countryCode) => {
    if (countryCode && COUNTRIES[countryCode.toLowerCase()]) {
      return `${
        COUNTRIES[countryCode.toLowerCase()].defaultLanguage
      }-${countryCode.toLowerCase()}`;
    }
    switch ((countryCode || '').toLowerCase()) {
      case 'at':
        return 'de-de';
      case 'mc':
        return 'fr-fr';
      case 'co':
        return 'en-us';
      default:
        return INTERNATIONAL_LOCAL;
    }
  },

  getLocale: (tmpLocale) => {
    return LOCALES.includes(tmpLocale)
      ? tmpLocale
      : CUSTOM_REDIRECT_REMOVED_LOCALE[tmpLocale] || INTERNATIONAL_LOCAL;
  },
  getLocaleMarket: (locale: string) => {
    if (
      ['en-us', 'en-ca', 'fr-ca', 'en-au', 'en-nz', 'en-sg'].includes(
        locale.toLowerCase(),
      )
    )
      return 'US';
    return 'EU';
  },
};

export const COUNTRIES = {
  at: {
    key: 'at',
    label: 'Austria',
    currency: 'EUR',
    labelTranslations: {
      en: 'Austria',
      nl: 'Oostenrijk',
      de: 'Österreich',
      fr: 'Autriche',
      es: 'Austria',
      it: 'Austria',
      pt: 'Áustria',
    },
    languages: returnCountryLanguages(['de']),
    defaultLanguage: 'de',
    warehouse: 'eu',
    flag: '/flags/AT.svg',
  },
  au: {
    key: 'au',
    label: 'Australia',
    currency: 'AUD',
    labelTranslations: {
      en: 'Australia',
      nl: 'Australië',
      de: 'Australien',
      fr: 'Australie',
      es: 'Australia',
      it: 'Australia',
      pt: 'Austrália',
    },
    languages: LocaleHelper.getCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'us',
    flag: '/flags/AU.svg',
  },
  be: {
    key: 'be',
    label: 'Belgium',
    currency: 'EUR',
    labelTranslations: {
      en: 'Belgium',
      nl: 'België',
      de: 'Belgien',
      fr: 'Belgique',
      es: 'Bélgica',
      it: 'Belgio',
      pt: 'Bélgica',
    },
    languages: LocaleHelper.getCountryLanguages(['fr', 'nl']),
    defaultLanguage: 'fr',
    warehouse: 'eu',
    flag: '/flags/BE.svg',
  },
  bg: {
    key: 'bg',
    label: 'Bulgaria',
    currency: 'BGN',
    labelTranslations: {
      en: 'Bulgaria',
      nl: 'Bulgarije',
      de: 'Bulgarien',
      fr: 'Bulgarie',
      es: 'Bulgaria',
      it: 'Bulgaria',
      pt: 'Bulgária',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/BG.svg',
  },
  ca: {
    key: 'ca',
    label: 'Canada',
    currency: 'CAD',
    labelTranslations: {
      en: 'Canada',
      nl: 'Canada',
      de: 'Kanada',
      fr: 'Canada',
      es: 'Canadá',
      it: 'Canada',
      pt: 'Canadá',
    },
    languages: LocaleHelper.getCountryLanguages(['en', 'fr']),
    defaultLanguage: 'en',
    warehouse: 'us',
    flag: '/flags/CA.svg',
  },
  cy: {
    key: 'cy',
    label: 'Cyprus',
    currency: 'EUR',
    labelTranslations: {
      en: 'Cyprus',
      nl: 'Cyprus',
      de: 'Zypern',
      fr: 'Chypre',
      es: 'Chipre',
      it: 'Cipro',
      pt: 'Chipre',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/CY.svg',
  },
  cz: {
    key: 'cz',
    label: 'Czech Republic',
    currency: 'CZK',
    labelTranslations: {
      en: 'Czech Republic',
      nl: 'Tsjechië',
      de: 'Tschechische Republik',
      fr: 'République tchèque',
      es: 'República Checa',
      it: 'Repubblica Ceca',
      pt: 'República Tcheca',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/CZ.svg',
  },
  ch: {
    key: 'ch',
    label: 'Switzerland',
    currency: 'CHF',
    labelTranslations: {
      en: 'Switzerland',
      nl: 'Zwitserland',
      de: 'Schweiz',
      fr: 'Suisse',
      es: 'Suiza',
      it: 'Svizzera',
      pt: 'Suíça',
    },
    languages: LocaleHelper.getCountryLanguages(['fr', 'de', 'it']),
    defaultLanguage: 'fr',
    warehouse: 'eu',
    flag: '/flags/CH.svg',
  },
  de: {
    key: 'de',
    label: 'Germany',
    currency: 'EUR',
    labelTranslations: {
      en: 'Germany',
      nl: 'Duitsland',
      de: 'Deutschland',
      fr: 'Allemagne',
      es: 'Alemania',
      it: 'Germania',
      pt: 'Alemanha',
    },
    languages: LocaleHelper.getCountryLanguages(['de']),
    defaultLanguage: 'de',
    warehouse: 'eu',
    flag: '/flags/DE.svg',
  },
  dk: {
    key: 'dk',
    label: 'Denmark',
    currency: 'DKK',
    labelTranslations: {
      en: 'Denmark',
      nl: 'Denemarken',
      de: 'Dänemark',
      fr: 'Danemark',
      es: 'Dinamarca',
      it: 'Danimarca',
      pt: 'Dinamarca',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/DK.svg',
  },
  ee: {
    key: 'ee',
    label: 'Estonia',
    currency: 'EUR',
    labelTranslations: {
      en: 'Estonia',
      nl: 'Estland',
      de: 'Estland',
      fr: 'Estonie',
      es: 'Estonia',
      it: 'Estonia',
      pt: 'Estônia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/EE.svg',
  },
  es: {
    key: 'es',
    label: 'Spain',
    currency: 'EUR',
    labelTranslations: {
      en: 'Spain',
      nl: 'Spanje',
      de: 'Spanien',
      fr: 'Espagne',
      es: 'España',
      it: 'Spagna',
      pt: 'Espanha',
    },
    languages: LocaleHelper.getCountryLanguages(['es']),
    defaultLanguage: 'es',
    warehouse: 'eu',
    flag: '/flags/ES.svg',
  },
  fi: {
    key: 'fi',
    label: 'Finland',
    currency: 'EUR',
    labelTranslations: {
      en: 'Finland',
      nl: 'Finland',
      de: 'Finnland',
      fr: 'Finlande',
      es: 'Finlandia',
      it: 'Finlandia',
      pt: 'Finlândia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/FI.svg',
  },
  fr: {
    key: 'fr',
    label: 'France',
    currency: 'EUR',
    labelTranslations: {
      en: 'France',
      nl: 'Frankrijk',
      de: 'Frankreich',
      fr: 'France',
      es: 'Francia',
      it: 'Francia',
      pt: 'França',
    },
    languages: LocaleHelper.getCountryLanguages(['fr']),
    defaultLanguage: 'fr',
    warehouse: 'eu',
    flag: '/flags/FR.svg',
  },
  gb: {
    key: 'gb',
    label: 'United Kingdom',
    currency: 'GBP',
    labelTranslations: {
      en: 'United Kingdom',
      nl: 'Verenigd Koninkrijk',
      de: 'Vereinigtes Königreich',
      fr: 'Royaume-Uni',
      es: 'Reino Unido',
      it: 'Regno Unito',
      pt: 'Reino Unido',
    },
    languages: LocaleHelper.getCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/UK.svg',
  },
  gr: {
    key: 'gr',
    label: 'Greece',
    currency: 'EUR',
    labelTranslations: {
      en: 'Greece',
      nl: 'Griekenland',
      de: 'Griechenland',
      fr: 'Grèce',
      es: 'Grecia',
      it: 'Grecia',
      pt: 'Grécia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/GR.svg',
  },
  hr: {
    key: 'hr',
    label: 'Croatia',
    currency: 'HRK',
    labelTranslations: {
      en: 'Croatia',
      nl: 'Kroatië',
      de: 'Kroatien',
      fr: 'Croatie',
      es: 'Croacia',
      it: 'Croazia',
      pt: 'Croácia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/HR.svg',
  },
  hu: {
    key: 'hu',
    label: 'Hungary',
    currency: 'HUF',
    labelTranslations: {
      en: 'Hungary',
      nl: 'Hongarije',
      de: 'Ungarn',
      fr: 'Hongrie',
      es: 'Hungría',
      it: 'Ungheria',
      pt: 'Hungria',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/HU.svg',
  },
  ie: {
    key: 'ie',
    label: 'Ireland',
    currency: 'EUR',
    labelTranslations: {
      en: 'Ireland',
      nl: 'Ierland',
      de: 'Irland',
      fr: 'Irlande',
      es: 'Irlanda',
      it: 'Irlanda',
      pt: 'Irlanda',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/IE.svg',
  },
  int: {
    key: 'int',
    label: 'International',
    currency: 'EUR',
    labelTranslations: {
      en: 'International',
      nl: 'International',
      de: 'International',
      fr: 'International',
      es: 'International',
      it: 'International',
      pt: 'International',
    },
    languages: LocaleHelper.getCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
  },
  is: {
    key: 'is',
    label: 'Iceland',
    currency: 'ISK',
    labelTranslations: {
      en: 'Iceland',
      nl: 'IJsland',
      de: 'Island',
      fr: 'Islande',
      es: 'Islandia',
      it: 'Islanda',
      pt: 'Islândia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/IS.svg',
  },
  it: {
    key: 'it',
    label: 'Italy',
    currency: 'EUR',
    labelTranslations: {
      en: 'Italy',
      nl: 'Italië',
      de: 'Italien',
      fr: 'Italie',
      es: 'Italia',
      it: 'Italia',
      pt: 'Itália',
    },
    languages: LocaleHelper.getCountryLanguages(['it']),
    defaultLanguage: 'it',
    warehouse: 'eu',
    flag: '/flags/IT.svg',
  },
  lt: {
    key: 'lt',
    label: 'Lithuania',
    currency: 'EUR',
    labelTranslations: {
      en: 'Lithuania',
      nl: 'Litouwen',
      de: 'Litauen',
      fr: 'Lituanie',
      es: 'Lituania',
      it: 'Lituania',
      pt: 'Lituânia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/LT.svg',
  },
  lu: {
    key: 'lu',
    label: 'Luxembourg',
    currency: 'EUR',
    labelTranslations: {
      en: 'Luxembourg',
      nl: 'Luxemburg',
      de: 'Luxemburg',
      fr: 'Luxembourg',
      es: 'Luxemburgo',
      it: 'Lussemburgo',
      pt: 'Luxemburgo',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/LU.svg',
  },
  lv: {
    key: 'lv',
    label: 'Latvia',
    currency: 'EUR',
    labelTranslations: {
      en: 'Latvia',
      nl: 'Letland',
      de: 'Lettland',
      fr: 'Lettonie',
      es: 'Letonia',
      it: 'Lettonia',
      pt: 'Letônia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/LV.svg',
  },
  mc: {
    key: 'mc',
    label: 'Monaco',
    currency: 'EUR',
    labelTranslations: {
      en: 'Monaco',
      nl: 'Monaco',
      de: 'Monaco',
      fr: 'Monaco',
      es: 'Mónaco',
      it: 'Monaco',
      pt: 'Mônaco',
    },
    languages: returnCountryLanguages(['en', 'fr']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/MC.svg',
  },
  nl: {
    key: 'nl',
    label: 'Netherlands',
    currency: 'EUR',
    labelTranslations: {
      en: 'Netherlands',
      nl: 'Nederland',
      de: 'Niederlande',
      fr: 'Pays-Bas',
      es: 'Países Bajos',
      it: 'Paesi Bassi',
      pt: 'Países Baixos',
    },
    languages: LocaleHelper.getCountryLanguages(['nl']),
    defaultLanguage: 'nl',
    warehouse: 'eu',
    flag: '/flags/NL.svg',
  },
  nz: {
    key: 'nz',
    label: 'New Zealand',
    currency: 'NZD',
    labelTranslations: {
      en: 'New Zealand',
      nl: 'Nieuw-Zeeland',
      de: 'Neuseeland',
      fr: 'Nouvelle-Zélande',
      es: 'Nueva Zelanda',
      it: 'Nuova Zelanda',
      pt: 'Nova Zelândia',
    },
    languages: LocaleHelper.getCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'us',
    flag: '/flags/NZ.svg',
  },
  no: {
    key: 'no',
    label: 'Norway',
    currency: 'NOK',
    labelTranslations: {
      en: 'Norway',
      nl: 'Noorwegen',
      de: 'Norwegen',
      fr: 'Norvège',
      es: 'Noruega',
      it: 'Norvegia',
      pt: 'Noruega',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/NO.svg',
  },
  pl: {
    key: 'pl',
    label: 'Poland',
    currency: 'PLN',
    labelTranslations: {
      en: 'Poland',
      nl: 'Polen',
      de: 'Polen',
      fr: 'Pologne',
      es: 'Polonia',
      it: 'Polonia',
      pt: 'Polônia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/PL.svg',
  },
  pt: {
    key: 'pt',
    label: 'Portugal',
    currency: 'EUR',
    labelTranslations: {
      en: 'Portugal',
      nl: 'Portugal',
      de: 'Portugal',
      fr: 'Portugal',
      es: 'Portugal',
      it: 'Portogallo',
      pt: 'Portugal',
    },
    languages: LocaleHelper.getCountryLanguages(['pt']),
    defaultLanguage: 'pt',
    warehouse: 'eu',
    flag: '/flags/PT.svg',
  },
  ro: {
    key: 'ro',
    label: 'Romania',
    currency: 'RON',
    labelTranslations: {
      en: 'Romania',
      nl: 'Roemenië',
      de: 'Rumänien',
      fr: 'Roumanie',
      es: 'Rumanía',
      it: 'Romania',
      pt: 'Romênia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/RO.svg',
  },
  se: {
    key: 'se',
    label: 'Sweden',
    currency: 'SEK',
    labelTranslations: {
      en: 'Sweden',
      nl: 'Zweden',
      de: 'Schweden',
      fr: 'Suède',
      es: 'Suecia',
      it: 'Svezia',
      pt: 'Suécia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/SE.svg',
  },
  sg: {
    key: 'sg',
    label: 'Singapore',
    currency: 'SGD',
    labelTranslations: {
      en: 'Singapore',
      nl: 'Singapore',
      de: 'Singapur',
      fr: 'Singapour',
      es: 'Singapur',
      it: 'Singapore',
      pt: 'Singapura',
    },
    languages: LocaleHelper.getCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'us',
    flag: '/flags/SG.svg',
  },
  sk: {
    key: 'sk',
    label: 'Slovakia',
    currency: 'EUR',
    labelTranslations: {
      en: 'Slovakia',
      nl: 'Slowakije',
      de: 'Slowakei',
      fr: 'Slovaquie',
      es: 'Eslovaquia',
      it: 'Slovacchia',
      pt: 'Eslováquia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/SK.svg',
  },
  si: {
    key: 'si',
    label: 'Slovenia',
    currency: 'EUR',
    labelTranslations: {
      en: 'Slovenia',
      nl: 'Slovenië',
      de: 'Slowenien',
      fr: 'Slovénie',
      es: 'Eslovenia',
      it: 'Slovenia',
      pt: 'Eslovênia',
    },
    languages: returnCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'eu',
    flag: '/flags/SI.svg',
  },
  us: {
    key: 'us',
    label: 'United States',
    currency: 'USD',
    labelTranslations: {
      en: 'United States',
      nl: 'Verenigde Staten',
      de: 'Vereinigte Staaten',
      fr: 'États-Unis',
      es: 'Estados Unidos',
      it: 'Stati Uniti',
      pt: 'Estados Unidos',
    },
    languages: LocaleHelper.getCountryLanguages(['en']),
    defaultLanguage: 'en',
    warehouse: 'us',
    flag: '/flags/US.svg',
  },
};

export default LocaleHelper;
