import { createStyles } from '@mantine/core';

export const linearHotGradient = 'linear-gradient(to right, #F67600, #EE264B)';

export const linearHotGradientText = {
  background: linearHotGradient,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};
export const useHighlightStyles = createStyles(() => ({
  outlineButton: {
    borderRadius: 100,
    background:
      'linear-gradient(white, white) padding-box,linear-gradient(to right, #F67600, #EE264B) border-box',
    border: '1px solid transparent',
    '&.active': {
      background: 'linear-gradient(to right, #F67600, #EE264B) border-box',
    },
  },
  text: {
    // background:
    //   '-webkit-linear-gradient(to-right, #F67600, #EE264B), linear-gradient(to right, #F67600, #EE264B)',
    // WebkitBackgroundClip: 'text',
    // WebkitTextFillColor: 'transparent',
    background: linearHotGradient,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    '&.active': {
      background: 'unset',
      WebkitTextFillColor: 'unset',
      color: 'white',
    },
  },
}));
