import { NextRouter } from 'next/router';

const Url = {
  decodeUrl: (str: string) => decodeURIComponent(str.replace(/\+/g, ' ')),
  getQueryParam: (router: NextRouter, param: string) => {
    const regex = new RegExp(`[&?]${param}=([^&]+).*$`);
    const path = Url.decodeUrl(router.asPath);
    const match = path.match(regex);
    return match?.[1] || null;
  },
  getLocalizedDomainUrl: (locale?: string, url?: string) =>
    `${(process.env.NEXT_PUBLIC_DOMAIN as string).replace(/\/$/, '')}/${
      locale || ''
    }/${(url || '').replace(/^\//, '')}`,
};

export default Url;
