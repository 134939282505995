import React, { useEffect, useMemo, useState } from 'react';
import {
  COUNTRIES,
  COUNTRY_COOKIE_NAME,
  getCountryCodeLocal,
  getCountryLocal,
} from '@/helpers/Locale';
import usePageContext from '@/utils/context/page';
import {
  Box,
  Divider,
  Group,
  Image,
  LoadingOverlay,
  NativeSelect,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Language } from 'tabler-icons-react';
import NoozButton from '../Nooz/NoozButton/NoozButton';
import { formatCfItems } from '@/helpers/formatCfItems';
import { IBlock } from '@/@types/generated/contentful';
import NoozText from '../Nooz/NoozText/NoozText';
import { ICountryDictionary } from '@/helpers/interface';
import LocaleHelper from '@/helpers/Locale';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { expiresCountryOrigin } from '@/helpers/Cookie';

interface FormValues {
  country: keyof typeof COUNTRIES;
  language: string;
}

const LocalisationSideView = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const { layout, locale, localizedSlugs } = usePageContext();
  const initialValues = useMemo(() => {
    const { language } = LocaleHelper.splitInfoWithInt(
      locale as string,
      false,
    ) as any;
    return {
      country: (getCountryCodeLocal(locale) || '') as string,
      language: language as string,
    } as FormValues;
  }, [locale]);

  const form = useForm<FormValues>({
    initialValues,
  });

  const { localization }: { localization: IBlock } = formatCfItems(
    layout?.fields.header,
  );

  const { button, countryInput, languageInput } = formatCfItems(localization);

  const country: keyof typeof COUNTRIES = form.values.country;

  const countryConfig = COUNTRIES[country];

  const onCountrySelectChange = ({ country, language }: FormValues) => {
    setIsLoading(true);
    const newLocale = LocaleHelper.getLocale(`${language}-${country}`);

    if (country === 'int') {
      Cookies.remove(COUNTRY_COOKIE_NAME);
    } else {
      Cookies.set(COUNTRY_COOKIE_NAME, country.toUpperCase(), {
        expires: expiresCountryOrigin(),
      });
    }
    if (typeof window !== 'undefined')
      window.location.href = `/${newLocale}/${
        localizedSlugs?.[
          LocaleHelper.getValidContentfulLocale(newLocale as string)
        ] ||
        localizedSlugs?.en ||
        ''
      }`;
  };

  const prefetch = ({ country, language }: FormValues) => {
    const newLocale = LocaleHelper.getLocale(`${language}-${country}`);
    if (country === 'int') {
      Cookies.remove(COUNTRY_COOKIE_NAME);
    } else {
      Cookies.set(COUNTRY_COOKIE_NAME, country.toUpperCase(), {
        expires: expiresCountryOrigin(),
      });
    }

    router.prefetch(
      `/${newLocale}/${
        localizedSlugs?.[
          LocaleHelper.getValidContentfulLocale(newLocale as string)
        ] ||
        localizedSlugs?.en ||
        ''
      }`,
    );
  };

  return (
    <Box
      sx={() => ({
        width: '100%',
      })}>
      <LoadingOverlay
        visible={isLoading}
        overlayBlur={2}
      />
      <form onSubmit={form.onSubmit((values) => onCountrySelectChange(values))}>
        <NoozText
          size={18}
          weight='bold'
          mb={10}>
          <NoozText
            size={18}
            color={'sun'}
            component='span'
            mr={10}>
            -
          </NoozText>
          <NoozText
            size={18}
            component='span'>
            {countryInput?.fields.label.fields.text}
          </NoozText>
        </NoozText>
        <NativeSelect
          mb={20}
          radius='xl'
          data={(
            Object.keys(COUNTRIES)?.map((c) => ({
              label:
                //@ts-ignore
                COUNTRIES[c as keyof typeof COUNTRIES].labelTranslations[
                  (locale?.split('-')[0] || 'en') as keyof ICountryDictionary
                ],
              value: c,
            })) || []
          ).sort((a, b) => a.label.localeCompare(b.label))}
          icon={
            (COUNTRIES[country] as any)?.flag ? (
              <Image
                src={(COUNTRIES[country] as any)?.flag}
                alt={`${country} country flag`}
                width={15}
              />
            ) : undefined
          }
          label={countryInput?.fields.placeholder?.fields.text}
          {...form.getInputProps('country')}
          onChange={(e) => {
            const value = e?.currentTarget?.value as keyof typeof COUNTRIES;
            form.getInputProps('country').onChange(e);
            form.setFieldValue('language', COUNTRIES[value]?.defaultLanguage);
            prefetch({
              country: value,
              language: COUNTRIES[value]?.defaultLanguage,
            });
          }}
        />
        <Divider
          my='xl'
          color='dark'
        />
        <NoozText
          size={18}
          weight='bold'
          mb={10}>
          <NoozText
            size={18}
            color={'bluelight'}
            component='span'
            mr={10}>
            -
          </NoozText>
          <NoozText
            size={18}
            component='span'>
            {languageInput?.fields.label.fields.text}
          </NoozText>
        </NoozText>
        <NativeSelect
          mb={20}
          radius={'xl'}
          data={
            countryConfig.languages.map((l: Record<string, any>) => ({
              value: l.key,
              label: l.labelTranslations[locale?.split('-')[0] || 'en'],
            })) || []
          }
          disabled={countryConfig.languages.length === 1}
          icon={<Language size={14} />}
          label={countryInput?.fields.placeholder?.fields.text}
          {...form.getInputProps('language')}
          onChange={(e) => {
            const value = e.currentTarget.value;
            form.getInputProps('language').onChange(e);
            prefetch({ country, language: value });
          }}
        />
        <Group
          position='left'
          mt='md'>
          <NoozButton
            disabled={!form.isDirty()}
            type='submit'
            color='dark'>
            {button.fields.label.fields.text}
          </NoozButton>
        </Group>
      </form>
    </Box>
  );
};
export default LocalisationSideView;
