import React from 'react';
import { Box, Container, Flex, Grid, clsx, createStyles } from '@mantine/core';
import { formatCfItems } from '@/helpers/formatCfItems';
import { useContainerStyles } from '@/utils/style/useContainer';
import { IBlock, ILabel, ILink } from '@/@types/generated/contentful';
import NoozText from '../Nooz/NoozText/NoozText';
import ActionCardBackdrop from '../Cards/ActionCardBackdrop';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: 32,
    fontWeight: 600,
    [theme.fn.smallerThan('md')]: {
      fontSize: 28,
    },
  },
}));

export function IntroCardNavigation({
  list,
  title,
  backgroundColor,
  color,
  padding,
  subLabel,
}: {
  list: IBlock;
  link: ILink;
  title: ILabel;
  backgroundColor?: string;
  color: string;
  padding?: string;
  subLabel?: ILabel;
}) {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();

  const returnGridColumns = () => {
    return list.fields.items?.slice(0, 4)?.map((item, index) => {
      return (
        <React.Fragment key={`${index}`}>
          <Grid.Col
            span={6}
            md={3}>
            <Box h={{ base: 180, xs: 230, sm: 300, lg: 280 }}>
              <ActionCardBackdrop
                {...formatCfItems(item)}
                image={...formatCfItems(item).image.fields.media}
              />
            </Box>
          </Grid.Col>
        </React.Fragment>
      );
    });
  };

  return (
    <Box sx={{ background: backgroundColor, color: color, padding: padding }}>
      <Container className={clsx(containerStyles.classes.container)}>
        <Flex direction={{ base: 'column', lg: 'row' }}>
          <Box
            mr={40}
            mb={{ base: 30, lg: 0 }}
            sx={{ flex: 1 }}>
            {title && (
              <NoozText
                mb={14}
                lh={1.3}
                align='left'
                component='h1'
                m={0}
                className={classes.label}>
                {title?.fields.text}
                <NoozText
                  lh={0}
                  size={40}
                  component='span'
                  color='reading'>
                  .
                </NoozText>
              </NoozText>
            )}
            {subLabel && (
              <NoozText
                align='left'
                m={0}
                size={18}>
                {subLabel?.fields.text}
              </NoozText>
            )}
          </Box>
          <Grid
            sx={{ flex: 4 }}
            gutter={12}
            gutterLg={20}>
            {returnGridColumns()}
          </Grid>
        </Flex>
      </Container>
    </Box>
  );
}
