import { createStyles } from '@mantine/core';

export const CROSS_SELL_WIDTH = 243;

const useClasses = (headerHeight: number) =>
  createStyles((theme, { headerHeight }: Record<string, number>) => {
    return {
      header: {
        flexDirection: 'column',
      },
      reassuranceWrapper: {
        backgroundColor: theme.colors.gray[0],
        overflow: 'hidden',
      },
      body: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        '& > .content': {
          flexDirection: 'column',
        },
      },
      footer: {
        flexDirection: 'column',
        backgroundColor: theme.colors.lightGray[0],
      },
      drawerInner: {
        height: `calc(100vh - ${headerHeight}px)`,
        top: headerHeight,
        [theme.fn.smallerThan(theme.breakpoints.sm)]: {
          top: '0 !important',
          height: '100% !important',
          zIndex: 9000,
        },
      },
      drawerInnerFull: {
        zIndex: 9000,
      },
      drawerContent: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      drawerContentCart: {
        flex: '0 0 750px !important',
        [theme.fn.smallerThan(751)]: {
          flex: '0 0 75% !important',
        },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        // flex: '0 0 50% !important',
        // [theme.fn.smallerThan(1300)]: {
        //   flex: '0 0 60% !important',
        // },
        // [theme.fn.smallerThan(1000)]: {
        //   flex: '0 0 75% !important',
        // },
        [theme.fn.smallerThan(theme.breakpoints.xs)]: {
          flex: '0 0 100% !important',
        },
        '&.white': {
          backgroundColor: 'white',
        },
      },
      drawerHeader: {
        paddingBottom: 0,
      },
      drawerBody: {
        flex: 1,
        padding: 0,
        overflow: 'hidden',
        '& > div': {
          height: '100%',
        },
      },
      crossSell: {
        left: CROSS_SELL_WIDTH,
        top: 0,
        height: '100%',
        overflow: 'auto',
        position: 'absolute',
        backgroundColor: theme.colors.white[0],
        borderRight: `1px solid ${theme.colors.black[0]}`,
        transition: 'transform 500ms 0.7s',
      },
      crossSellAnim: {
        transform: `translateX(-${CROSS_SELL_WIDTH}px)`,
      },
    };
  })({ headerHeight }).classes;

export default useClasses;
