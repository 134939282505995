import {
  Box,
  Center,
  Container,
  Image,
  createStyles,
  MantineGradient,
} from '@mantine/core';
import React from 'react';
import { useContainerStyles } from '@/utils/style/useContainer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IColor, IParagraph } from '@/@types/generated/contentful';
import NoozText from '../Nooz/NoozText/NoozText';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

interface QuotationProps {
  body: IParagraph;
  quoteColor: IColor;
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    textAlign: 'center',
    [theme.fn.smallerThan('md')]: {
      textAlign: 'left',
    },
    [theme.fn.smallerThan('sm')]: {
      textAlign: 'center',
    },
  },
  text: {
    fontSize: 35,
    lineHeight: 0.8,
    [theme.fn.smallerThan('md')]: {
      fontSize: 32,
      marginBottom: 18,
    },
    [theme.fn.smallerThan('sm')]: {
      fontSize: 24,
      marginBottom: 16,
    },
  },
}));

const Quotation = ({ body, quoteColor }: QuotationProps) => {
  const containerStyles = useContainerStyles();
  const { classes } = useStyles();

  return (
    <Container className={containerStyles.classes.container}>
      <Box className={classes.wrapper}>
        {body?.fields?.richBody
          ? documentToReactComponents(body?.fields?.richBody, {
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => {
                  return (
                    <NoozText
                      lh={1.3}
                      align='center'
                      className={classes.text}>
                      {children}
                    </NoozText>
                  );
                },
                [BLOCKS.HEADING_3]: (node, children) => {
                  return (
                    <NoozText
                      size={44}
                      align='center'
                      sx={(theme) => ({
                        fontSize: 44,
                        [theme.fn.smallerThan('md')]: {
                          fontSize: 32,
                        },
                      })}
                      lh={1.3}>
                      {children}
                    </NoozText>
                  );
                },
              },
              renderMark: {
                [MARKS.BOLD]: (text) => {
                  const props = {
                    ...(quoteColor.fields.gradientObject
                      ? {
                          variant: 'gradient',
                          gradient: quoteColor.fields.gradientObject,
                        }
                      : {
                          color: quoteColor.fields.hex,
                        }),
                  };

                  return (
                    <NoozText
                      lh={1.3}
                      component='span'
                      size='inherit'
                      {...(props as any)}>
                      {text}
                    </NoozText>
                  );
                },
              },
            })
          : null}
      </Box>
    </Container>
  );
};

export default Quotation;
