import { Box, createStyles, Stack } from '@mantine/core';
import React, { useMemo } from 'react';
import Currency from '@/helpers/Currency';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import {
  useGamificationGifts,
  useNextGamificationStep,
} from '@/utils/hook/gamification';
import CartDrawerGiftSelectorButton from '@/components/Cart/CartDrawer/CartDrawerGiftSelectorButton';
import NoozRichBody from '@/components/Nooz/NoozRichBody/NoozRichBody';
import { BLOCKS } from '@contentful/rich-text-types';

export const FREE_GIFT_KEY = 'free-item';
const useClasses = () =>
  createStyles((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.gray[4],
      borderRadius: 50,
      position: 'relative',
      height: 8,
      width: '100% ',
    },
    active: {
      height: '100%',
      borderRadius: 50,
      background: theme.colors.main[0],
      width: '50%',
    },
  }))().classes;

const CartDrawerProgressBar = ({ currency }: { currency: string }) => {
  const classes = useClasses();
  const { layout } = usePageContext();

  const { progressSteps, fullRewardsBody } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );
  const [target, percent, missingAmount] = useNextGamificationStep();

  const nextGift = useGamificationGifts();

  if (
    !(
      Array.isArray(progressSteps.fields.items) &&
      progressSteps.fields.items.length > 0
    )
  ) {
    return null;
  } else if (nextGift) {
    return <CartDrawerGiftSelectorButton />;
  }

  return (
    <Stack px={16}>
      {target?.body?.fields || fullRewardsBody?.fields ? (
        <NoozRichBody
          {...(target?.body?.fields || fullRewardsBody?.fields)}
          replaceStrings={{
            '{{amount}}': `${missingAmount.toFixed(2)}${Currency.toSymbol(
              currency,
            )}`,
          }}
          styles={{
            [BLOCKS.PARAGRAPH]: {
              sx: (theme: any) => ({
                fontSize: 14,
                textAlign: 'center',
                [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                  fontSize: 12,
                },
              }),
            },
          }}
        />
      ) : null}

      {
        <Box className={classes.wrapper}>
          <Box
            className={classes.active}
            w={`${percent > 100 ? 100 : percent}%`}
          />
        </Box>
      }
    </Stack>
  );
};

export default CartDrawerProgressBar;
