import React from 'react';
import { Box, Flex, createStyles } from '@mantine/core';
import NoozText from '../Nooz/NoozText/NoozText';
import NoozButton from '../Nooz/NoozButton/NoozButton';
import { IButton, ILabel } from '@/@types/generated/contentful';
import Link from '../Link/Link';
import useMarketingStyles from './useMarketingStyles';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: 36,
    fontWeight: 600,
    [theme.fn.smallerThan('md')]: {
      fontSize: 24,
    },
  },
  subLabel: {
    fontSize: 18,
    [theme.fn.smallerThan('md')]: {
      fontSize: 16,
    },
  },
}));

const TextCard = ({
  label,
  subLabel,
  button,
}: {
  label: ILabel;
  subLabel: ILabel;
  button: IButton;
}) => {
  const { classes } = useStyles();
  //@ts-ignore
  const marketingStyles = useMarketingStyles({ animationVariant: 'scale' });
  return (
    <Box
      className={marketingStyles.classes.cardWrapper}
      p={{ base: 24, md: 40 }}
      py={{ base: 40, md: 80 }}
      sx={(theme) => ({
        borderRadius: theme.radius.xl,
        background: 'white',
        position: 'relative',
        [theme.fn.smallerThan('md')]: {
          borderRadius: theme.radius.lg,
        },
      })}>
      <Flex
        h='100%'
        direction='column'
        justify='center'
        gap={{ base: 12, md: 20 }}>
        <NoozText
          className={classes.label}
          lh={1.3}>
          {label.fields.text}
        </NoozText>
        <NoozText
          className={classes.subLabel}
          lh={1.3}>
          {subLabel.fields.textList && subLabel.fields.textList.length > 0
            ? subLabel.fields.textList.join(' ')
            : subLabel.fields.text}
        </NoozText>
        {button.fields.label?.fields.text ? (
          ((button.fields.slug?.fields.url || button.fields.urlLink) &&
            button.fields.urlLink) ||
          button?.fields.function ? (
            <NoozButton
              urlLink={button?.fields.urlLink}
              function={button?.fields.function}
              color={button.fields.color?.fields.classname || 'dark'}
              size='md'>
              {button.fields.label?.fields.text}
            </NoozButton>
          ) : (
            <Link href={button.fields.slug?.fields.url as string}>
              <NoozButton
                color={button.fields.color?.fields.classname || 'dark'}
                size='md'>
                {button.fields.label?.fields.text}
              </NoozButton>
            </Link>
          )
        ) : null}
      </Flex>
    </Box>
  );
};

export default TextCard;
