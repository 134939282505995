import { Container, Group, clsx } from '@mantine/core';
import React from 'react';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozButton from '../../Nooz/NoozButton/NoozButton';
import { IBlock, IButton } from '@/@types/generated/contentful';

interface ButtonsBannerProps {
  buttons?: IBlock;
}

const ButtonsBanner = ({ buttons }: ButtonsBannerProps) => {
  const containerStyles = useContainerStyles();
  return (
    <Container
      className={clsx(containerStyles.classes.container)}
      sx={{ display: 'flex', justifyContent: 'center' }}>
      <Group>
        {buttons?.fields?.items
          ? (buttons.fields.items as IButton[]).map(
              (button: IButton, index: number) => {
                return (
                  // @ts-ignore
                  <NoozButton
                    key={index}
                    {...button.fields}
                    href={button.fields.slug?.fields.url as string}
                    urlLink={button.fields.urlLink as string}
                    variant={button.fields.type || 'filled'}
                    gradient={button.fields.color?.fields.gradientObject as any}
                    color={button?.fields?.color?.fields?.classname || 'dark'}
                    className={'cta-button'}
                    sx={
                      button?.fields?.label?.fields.color?.fields.classname
                        ? // @ts-ignore
                          (theme) => ({
                            color:
                              theme.colors[
                                // @ts-ignore
                                button.fields.label.fields.color.fields
                                  .classname
                              ],
                          })
                        : null
                    }
                    size={button.fields.size || 'lg'}>
                    {button?.fields?.label?.fields.text}
                  </NoozButton>
                );
              },
            )
          : null}
      </Group>
    </Container>
  );
};

export default ButtonsBanner;
