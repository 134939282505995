import React from 'react';
import { Box, Center, Divider } from '@mantine/core';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import useStaticContent from '@/utils/hook/useStaticContent';
import NoozText from '@/components/Nooz/NoozText/NoozText';

interface CategoryMoreResultsProps {
  onClick?: () => void;
  remaining: number;
}
const CategoryMoreResults = ({
  onClick,
  remaining,
}: CategoryMoreResultsProps) => {
  const { categoryFilters } = useStaticContent();
  const { moreResultsLabel, showMoreLabel } = categoryFilters;

  return (
    <Box pos='relative'>
      <Divider
        my='xs'
        label={
          <NoozButton
            variant='filled'
            color={'black'}
            onClick={onClick}>
            {showMoreLabel}
          </NoozButton>
        }
        labelPosition='center'
      />
      <Center>
        <NoozText>{`${remaining} ${moreResultsLabel}`}</NoozText>
      </Center>
    </Box>
  );
};

export default CategoryMoreResults;
