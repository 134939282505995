import { createStyles, rem } from '@mantine/core';

const useRetailStyles = () =>
  createStyles((theme) => ({
    label: {
      fontSize: 62,
      fontWeight: 900,
      color: theme.colors.dark,
      lineHeight: 1.3,
      textAlign: 'center',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        fontSize: 40,
        marginBottom: theme.spacing.md,
      },
    },
    subLabel: {
      fontSize: 24,
      fontWeight: 500,
      color: theme.colors.terracota?.[0],
      textAlign: 'center',
      marginTop: '-40px 60px 0',
    },
    wrapper: {
      height: rem(680),
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        height: 80,
        width: '50%',
        background:
          'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)',
        zIndex: 2,

        [theme.fn.smallerThan(theme.breakpoints.sm)]: {
          height: 70,
          width: 75,
          left: 0,
          bottom: 0,
          top: 'unset',
          background:
            'linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: 80,
        width: '50%',
        background:
          'linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)',
        [theme.fn.smallerThan(theme.breakpoints.sm)]: {
          height: 70,
          width: 75,
          right: 0,
          bottom: 0,
          top: 'unset',
          left: 'unset',
          background:
            'linear-gradient(-90deg, #FFF 0%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      [theme.fn.smallerThan(theme.breakpoints.lg)]: {
        height: rem(700),
      },
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        flexDirection: 'column-reverse',
        height: 'fit-content',
      },
    },
    element: {
      position: 'relative',
      width: '50%',
      height: '100%',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        height: '50%',
        width: '100%',
      },
      '& *': {
        msOverflowStyle: 'none',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    listWrapper: {
      height: '1OO%',
      overflow: 'auto',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        display: 'flex',
        gap: 30,
        height: 70,
        alignItems: 'center',
        scrollSnapType: 'x proximity',
      },
      [theme.fn.largerThan(theme.breakpoints.sm)]: {
        scrollSnapType: 'y proximity',
      },
      '& > *': {
        scrollSnapAlign: 'center',
      },
    },
    imageCarousel: {
      aspectRatio: '1',
      overflow: 'hidden',
      display: 'flex',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        scrollSnapType: 'x mandatory',
        overflow: 'auto',
        '& > *': {
          scrollSnapAlign: 'center',

          msOverflowStyle: 'none',
        },
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },

    productWrapper: {
      display: 'flex',
      backgroundColor: '#F4F4F4',
      borderRadius: 20,
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        borderRadius: 0,
        height: 'auto',
        aspectRatio: '1',
      },
    },
    productName: {
      position: 'absolute',
      top: 20,
      margin: 0,
      left: 60,
      display: 'flex',
      height: '60px',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      flexShrink: 0,
      color: '#2D2D2D',

      fontFamily: 'Mont',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 900,
      lineHeight: 'normal',
      textTransform: 'uppercase',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        fontSize: '14px',
        height: 35,
        left: 35,
      },
    },
    productColor: {
      position: 'absolute',
      top: 20,
      margin: 0,
      right: 60,
      display: 'flex',
      height: '60px',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      flexShrink: 0,
      color: '#2D2D2D',

      textAlign: 'right',
      fontFamily: 'Mont',
      fontSize: '20px',
      fontStyle: 'italic',
      fontWeight: 600,
      lineHeight: 'normal',
      textTransform: 'uppercase',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        fontSize: '14px',
        height: 35,
        right: 35,
      },
    },
    img: {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
    },
    text: {
      cursor: 'pointer',
      color: '#EDEDED',
      fontFamily: 'Mont',
      fontSize: 62,
      fontStyle: 'normal',
      fontWeight: 900,
      lineHeight: '66px',
      '&.active': {
        color: '#2D2D2D',
        fontSize: 64,
      },
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        fontSize: 42,
        lineHeight: '46px',
        '&.active': {
          fontSize: 44,
        },
      },
    },
    colorPicker: {
      position: 'absolute',
      bottom: 60,
      left: 60,
      right: 60,
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        bottom: 35,
        left: 35,
        right: 35,
      },
    },
    color: {
      height: 28,
      width: 28,
      borderRadius: 28,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    active: {
      background: theme.white,
      height: 14,
      width: 14,
      borderRadius: 28,
    },
  }))().classes;

export default useRetailStyles;
