import React, { useContext, ReactNode } from 'react';
import { Modal, createStyles } from '@mantine/core';
import { IModal, IModalContext } from '@/interface.custom';

export const MainPopupModalContext = React.createContext<IModalContext>({
  modal: {
    opened: false,
    body: '',
  },
  setModal: () => {},
});

const useStyles = createStyles((theme) => ({
  content: {
    position: 'relative',
  },
  header: {
    position: 'absolute',
    right: 0,
    background: 'none',
  },
  body: {
    padding: 0,
  },
}));

export const useMainPopupModalContext = (): IModalContext => {
  const context = useContext(MainPopupModalContext);
  if (!context) {
    throw new Error(
      'useMainPopupModalContext must be used within a MainPopupModalProvider',
    );
  }
  return context;
};

interface MainPopupModalProviderProps {
  children: ReactNode;
}

export const MainPopupModalProvider: React.FC<MainPopupModalProviderProps> = ({
  children,
}) => {
  const [modal, setModal] = React.useState<IModal>({
    opened: false,
    body: '',
  });

  return (
    <MainPopupModalContext.Provider value={{ modal, setModal }}>
      {children}
    </MainPopupModalContext.Provider>
  );
};

export const MainPopupModal: React.FC = () => {
  const { classes } = useStyles();
  const { modal, setModal } = useMainPopupModalContext();

  const closeModal = () => {
    setModal({ ...modal, opened: false });
  };

  return (
    <Modal
      {...modal}
      size='xl'
      centered
      radius={'lg'}
      // shadow='lg'
      lockScroll={false}
      overlayProps={{
        blur: '7px',
      }}
      transitionProps={{
        transition: 'fade',
        duration: 500,
        timingFunction: 'ease',
      }}
      classNames={{
        header: classes.header,
        body: classes.body,
        content: classes.content,
      }}
      onClose={closeModal}>
      {modal.body}
    </Modal>
  );
};
