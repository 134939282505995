import React from 'react';
import { createStyles, Title, Container, Box } from '@mantine/core';
import NoozText from '../../Nooz/NoozText/NoozText';
import { ILabel } from '@/@types/generated/contentful';

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundImage:
      'radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,0,70,1) 0.3%, rgba(28,181,224,1) 90.2% )',
    position: 'relative',
    paddingTop: 80,
    paddingBottom: 80,
    margin: theme.spacing.md,
    borderRadius: theme.radius.xl,

    [theme.fn.smallerThan('sm')]: {
      paddingTop: 80,
      paddingBottom: 80,
    },
  },

  inner: {
    position: 'relative',
    zIndex: 1,
  },

  title: {
    textAlign: 'center',
    fontSize: 40,
    letterSpacing: -1,
    color: theme.white,
    marginBottom: theme.spacing.xs,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 28,
    },
  },
  subTitle: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 300,
    color: theme.white,
    marginBottom: theme.spacing.xs,
  },
}));

interface HeroTextBannerProps {
  title?: string | null;
  subTitle?: string | null;
  children?: JSX.Element;
}

export function HeroTextBanner({
  title,
  subTitle,
  children,
}: HeroTextBannerProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      <div className={classes.inner}>
        <Container>
          <Title
            order={1}
            className={classes.title}>
            {title}
          </Title>
          <NoozText
            component={'h2'}
            weight={500}
            className={classes.subTitle}>
            {subTitle}
          </NoozText>
          {children}
        </Container>
      </div>
    </Box>
  );
}

export function HeroTextBannerShopStory({
  title,
  subTitle,
}: { title: ILabel, subTitle: ILabel }) {
  return (
    <HeroTextBanner title={title.fields.text} subTitle={subTitle.fields.text} />
  );
}
