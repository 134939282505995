import { IBlock, ILabel } from '@/@types/generated/contentful';
import { Container, SimpleGrid } from '@mantine/core';
import ProductCard from '@/components/Collection/ProductCard/ProductCard';
import { useContainerStyles } from '@/utils/style/useContainer';
import SkeletonProductCard from '@/components/Collection/ProductCard/SkeletonProductCard';
import React, { memo } from 'react';
import { ShopItem } from '@/@types/shopitem';

interface GridLineShopItemProps {
  type: 'default' | 'simple' | 'new';
  facets: IBlock;
}

const GridLineShopItem = ({ type, facets }: GridLineShopItemProps) => {
  const shopItems: ShopItem[] = ((facets as any).shopitems?.items || []).slice(
    0,
    4,
  );
  const containerStyles = useContainerStyles();

  return (
    <Container
      mb={{ base: 20, md: 40 }}
      sx={{
        width: '100%',
      }}
      className={containerStyles.classes.container}>
      <SimpleGrid
        cols={4}
        spacing='lg'
        breakpoints={[{ maxWidth: 'sm', cols: 2, spacing: 'sm' }]}>
        {shopItems.map((si, key) => (
          <ProductCard
            key={si.defaultVariantId}
            item={si}
            position={key}
            type={type}
          />
        ))}
        {shopItems.length === 0
          ? [
              ...(facets.fields.items || []),
              { id: 1 },
              { id: 2 },
              { id: 3 },
              { id: 4 },
            ]
              .splice(0, 4)
              .map((el) => (
                <SkeletonProductCard
                  key={(el as any).id}
                  label={(el as ILabel)?.fields.text}
                />
              ))
          : null}
      </SimpleGrid>
    </Container>
  );
};

export default memo(GridLineShopItem);
