import React, { useRef } from 'react';
import {
  BackgroundImage,
  Box,
  Center,
  Container,
  Grid,
  createStyles,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useContainerStyles } from '@/utils/style/useContainer';
import {
  IBlock,
  IBoolean,
  IButton,
  IImage,
  ILabel,
} from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import TextCard from '@/components/Cards/TextCard';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: rem(680),
    position: 'relative',
    // [theme.fn.smallerThan('lg')]: {
    //   minHeight: rem(780),
    // },
    [theme.fn.smallerThan('md')]: {
      alignItems: 'flex-start',
      padding: '20px',
      minHeight: rem(680),
    },
  },
  backgroundImage: {
    width: 'calc(100% + 80px)',
    height: '100%',
    position: 'absolute',
    top: '50%',
    borderRadius: theme.radius.lg,
    overflow: 'hidden',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.fn.smallerThan('md')]: {
      width: 'calc(100% + 0px)',
    },
  },
}));

export function HeroBannerHighlightCard({
  image,
  desktopImage,
  isCardRight,
  label,
  subLabel,
  button,
}: {
  image: IImage;
  desktopImage: IImage;
  label: ILabel;
  subLabel: ILabel;
  button: IButton;
  isCardRight?: IBoolean;
}) {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);

  const desktopBackgroundImage =
    desktopImage?.fields.media?.fields.file.url ||
    (image?.fields.media?.fields.file.url as string);
  const mobileBackgroundImage = image?.fields.media?.fields.file.url as string;

  const GridCols = [
    <Grid.Col
      h='100%'
      key={0}
      span={12}
      md={4}>
      <TextCard
        label={label}
        subLabel={subLabel}
        button={button}
      />
    </Grid.Col>,
    <Grid.Col
      key={1}
      xs={0}
      md={4}
    />,
    <Grid.Col
      key={2}
      xs={0}
      md={4}
    />,
  ];

  return (
    <Container
      sx={{ position: 'relative', overflow: 'hidden' }}
      className={containerStyles.classes.container}>
      <Center
        w={'100%'}
        className={classes.wrapper}>
        <BackgroundImage
          className={classes.backgroundImage}
          src={
            isDesktop
              ? desktopBackgroundImage
              : (mobileBackgroundImage as string)
          }
        />
        <Grid
          w='100%'
          h='100%'
          gutter={0}
          gutterMd={30}>
          {isCardRight?.fields.value ? GridCols.reverse() : GridCols}
        </Grid>
      </Center>
    </Container>
  );
}

export function HeroBannerHighlightCardSlider({
  list,
  isCardRight,
}: {
  list: IBlock;
  isCardRight: IBoolean;
}) {
  const autoplay = useRef(Autoplay({ delay: 4000 }));

  return (
    <Carousel
      {...CAROUSEL_PROPS}
      loop={true}
      slideSize='100%'
      classNames={useCarouselIndicatorsStyles({}).classes}
      withIndicators={true}
      withControls={false}
      height={'100%'}
      plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}>
      {list.fields.items?.map((item, k) => {
        const {
          image,
          desktopImage,
          label,
          subLabel,
          button,
        }: {
          image: IImage;
          label: ILabel;
          subLabel: ILabel;
          button: IButton;
          desktopImage: IImage;
        } = formatCfItems(item);
        return (
          <Carousel.Slide
            key={k}
            h={'100%'}>
            <HeroBannerHighlightCard
              image={image}
              desktopImage={desktopImage}
              isCardRight={isCardRight}
              label={label}
              subLabel={subLabel}
              button={button}
            />
          </Carousel.Slide>
        );
      })}
    </Carousel>
  );
}
