import { ActionIcon, Indicator } from '@mantine/core';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import React, { useEffect } from 'react';
import { useStyles } from '@/components/Header/HeaderAction/HeaderAction';
import useCartItems from '@/utils/hook/useCartItems';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import { IPath } from '@/@types/generated/contentful';
import Link from '@/components/Link/Link';

const B2BCartAction = () => {
  const classes = useStyles().classes;
  const cartLineItems = useCartItems();
  const nbItems = cartLineItems.reduce((qty, item) => qty + item.quantity, 0);

  const { layout, shopstory } = usePageContext();
  const { cart }: { cart?: IPath } = formatCfItems(layout?.fields.header);
  const [lineItemCount, setLineItemCount] = React.useState(0);

  useEffect(() => {
    setLineItemCount(cartLineItems?.length || 0);
  }, [cartLineItems]);

  return cart ? (
    <Link href={cart.fields.url as string}>
      <ActionIcon
        radius='xl'
        size='lg'
        variant='subtle'
        color='black'
        className={classes.button}>
        <Indicator
          label={nbItems}
          size={20}
          disabled={lineItemCount === 0}
          styles={{
            root: {
              display: 'flex',
            },
          }}>
          <NoozIcon
            iconKey={'ShoppingBag'}
            strokeWidth={1.3}
            size={24}
          />
        </Indicator>
      </ActionIcon>
    </Link>
  ) : null;
};
export default B2BCartAction;
