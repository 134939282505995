import { NextApiRequest, NextApiResponse } from 'next';
import NextCors from 'nextjs-cors';

const API_KEY_NAME = 'x-api-key';

export const API_KEY_VALUES = {
  NEXT_APP: 'd40dbce5-aec8-427c-8e94-47ba5c80be31',
  ALGOLIA_CONTENTFUL_APP: 'ba6a3751-3204-4fd3-8bc5-01292ae53086',
  SHOPIFY_APP: '4b47067b-14d0-49c5-9a31-9b3c45d00ae3',
  MAGIC_MIRROR: '5a4882e2-9025-4c3f-8224-fd8828aa73e9',
  DESKTOP_APP: '5a4882e2-9025-4c3f-8224-ad8828aa73e9',
  POSTMAN: 'a8df990e-b55b-4d22-9ae0-8fc138f8f355',
};

export const MiddleWareFunctions = {
  method:
    (authorizedMethod: string[]) =>
    async (req: NextApiRequest, res: NextApiResponse): Promise<-1 | 1> => {
      if (!req.method || !authorizedMethod.includes(req.method)) {
        res.status(400).json({ message: 'Unknown method' });
        return -1;
      }
      return 1;
    },
  cors: async (req: NextApiRequest, res: NextApiResponse): Promise<-1 | 1> => {
    return new Promise((resolve) => {
      NextCors(req, res, {
        // Options
        methods: ['GET', 'HEAD', 'PUT', 'PATCH', 'POST', 'DELETE'],
        origin: '*',
        optionsSuccessStatus: 200, // some legacy browsers (IE11, various SmartTVs) choke on 204
      }).finally(() => {
        resolve(1);
      });

      // cors(req, res, (result) => {
      //   if (result instanceof Error) {
      //     return resolve(-1);
      //   }
      //   return resolve(1);
      // });
    });
  },
  apiKey:
    (apiKeys = Object.values(API_KEY_VALUES)) =>
    async (req: NextApiRequest, res: NextApiResponse): Promise<-1 | 1> => {
      const xApiKey = req.headers[API_KEY_NAME];

      if (
        !xApiKey ||
        typeof xApiKey !== 'string' ||
        !apiKeys.includes(xApiKey)
      ) {
        res.status(401).json({
          message: 'Missing or invalid token.',
        });
        return -1;
      }
      return 1;
    },
};

const apiMiddleware =
  (handler: any, middlewareFns: any[]) =>
  async (req: NextApiRequest, res: NextApiResponse) => {
    for (let fn of middlewareFns) {
      const status = await fn(req, res);
      if (status === -1) return;
    }

    await handler(req, res);
  };

export const allowCors =
  (fn: any) => async (req: NextApiRequest, res: NextApiResponse) => {
    res.setHeader('Access-Control-Allow-Credentials', 'true');
    res.setHeader('Access-Control-Allow-Origin', '*');
    // another common pattern
    // res.setHeader('Access-Control-Allow-Origin', req.headers.origin);
    res.setHeader(
      'Access-Control-Allow-Methods',
      'GET,OPTIONS,PATCH,DELETE,POST,PUT',
    );
    res.setHeader(
      'Access-Control-Allow-Headers',
      'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Key',
    );
    if (req.method === 'OPTIONS') {
      res.status(200).end();
      return;
    }
    return await fn(req, res);
  };
export default apiMiddleware;
