import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { IParagraphFields } from '@/@types/generated/contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import NoozText from '@/components/Nooz/NoozText/NoozText';

const options = (
  replaceStrings?: Record<string, string>,
  styles?: Record<BLOCKS, any>,
) => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      return (
        <NoozText {...(styles?.[BLOCKS.PARAGRAPH] || {})}>{children}</NoozText>
      );
    },
  },
  renderText: (text: string) => {
    let str = text;
    if (replaceStrings) {
      for (let [key, value] of Object.entries(replaceStrings)) {
        str = str.replaceAll(key, value);
      }
    }
    return str.split('\n').reduce((children: any, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
});

const NoozRichBody = ({
  richBody,
  replaceStrings,
  styles,
}: IParagraphFields & {
  replaceStrings?: Record<string, string>;
  styles?: Record<BLOCKS, any>;
}) => {
  return richBody ? (
    <>{documentToReactComponents(richBody, options(replaceStrings, styles))}</>
  ) : null;
};

export default NoozRichBody;
