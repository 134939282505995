import {
  Box,
  Container,
  Flex,
  LoadingOverlay,
  Transition,
} from '@mantine/core';
import CategoryFilters from '../CategoryFilters/CategoryFilters';
import { useCategoryFilterSection } from '@/templates/ProductCategory';
import { useFilteredShopItems } from '@/templates/ProductCategory.context';
import CategoryFilterList from '../CategoryFilterList/CategoryFilterList';
import { motion } from 'framer-motion';
import { TIMING_FUNCTION } from '@/utils/style/animation';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import { useContainerStyles } from '@/utils/style/useContainer';
import { CategoryListSchemaOrgJSON } from '@/helpers/SchemaOrgJsonLd';
import useCollectionPageType from '@/utils/hook/useCollectionPageType';

const expandText = (headerHeight: number) => ({
  in: { maxWidth: '25%', opacity: 1 },
  out: { maxWidth: 0, opacity: 0 },
  common: {
    overflow: 'hidden',
    position: 'sticky',
    height: `calc(100vh - ${headerHeight}px)`,
    top: headerHeight,
  },
  transitionProperty: 'max-width, opacity',
});

const ProductCategoryCardList = () => {
  const items = useFilteredShopItems();
  const containerStyles = useContainerStyles();
  const headerHeight = useHeaderHeight();
  const { opened } = useCategoryFilterSection();

  return (
    <>
      <CategoryListSchemaOrgJSON />
      <Container
        mb={80}
        className={containerStyles.classes.categoryContainer}>
        <Flex>
          <Transition
            mounted={opened}
            keepMounted={true}
            //@ts-ignore
            transition={expandText(
              headerHeight.wrapper + headerHeight.marginTop,
            )}
            duration={500}
            timingFunction={TIMING_FUNCTION}>
            {(styles) => (
              <Box
                sx={{ flex: 1 }}
                style={{ ...styles, overflow: 'scroll' }}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ ease: 'easeOut', duration: 0.6, delay: 0.4 }}>
                  <CategoryFilters />
                </motion.div>
              </Box>
            )}
          </Transition>
          <Box sx={{ flex: 3, position: 'relative' }}>
            {items.quantity > 0 ? (
              <CategoryFilterList premiumEnabled={true} />
            ) : null}
          </Box>
        </Flex>
      </Container>
    </>
  );
};

export default ProductCategoryCardList;
