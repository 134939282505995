import React, { useRef } from 'react';
import { Box, createStyles } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { IBlock } from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import Autoplay from 'embla-carousel-autoplay';
import HeroBanner from './Banner/HeroBanner';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';
import HeroVideoBanner from '../HeroVideo/HeroVideo';

const useStyles = () =>
  createStyles((theme) => ({
    wrapper: {
      width: '100vw',
      aspectRatio: '1600/650',
      '& .mantine-Button-root': {
        height: '2.5rem',
      },
      '& .mantine-Container-root': {
        aspectRatio: '1600/650',
        height: 'auto',
      },
      [theme.fn.largerThan(theme.breakpoints.sm)]: {
        '& .mantine-Container-root': {
          padding: '0 114px',
        },
        '& #labels': {
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
          '& > *': {
            lineHeight: 1,
          },
        },
      },

      [theme.fn.largerThan(theme.breakpoints.sm)]: {
        [theme.fn.smallerThan(theme.breakpoints.sm)]: {
          '& .mantine-Container-root': {
            alignContent: 'center',
            '& #text': {
              padding: 0,
              height: 'fit-content',
            },
          },
        },
      },

      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        aspectRatio: '3/4',
        '& .mantine-Carousel-slide .mantine-Container-root': {
          aspectRatio: '3/4',
          height: 'unset',
          '& > div': {
            paddingBottom: '2.5rem',
          },
        },
      },
    },
  }))().classes;

export function HeroSlider({ list }: { list: IBlock }) {
  const classes = useStyles();
  const autoplay = useRef(Autoplay({ delay: 5000 }));

  return (
    <Box className={classes.wrapper}>
      <Carousel
        {...CAROUSEL_PROPS}
        loop={true}
        slideGap={0}
        classNames={useCarouselIndicatorsStyles({ color: 'white' }).classes}
        slideSize='100%'
        withIndicators={true}
        withControls={false}
        height={'100%'}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}>
        {list.fields.items?.map((item, k) => {
          const props = formatCfItems(item);

          if (props.video) {
            return (
              <Carousel.Slide key={k}>
                <HeroVideoBanner {...formatCfItems(item)} />
              </Carousel.Slide>
            );
          }

          return (
            <Carousel.Slide key={k}>
              <HeroBanner {...formatCfItems(item)} />
            </Carousel.Slide>
          );
        })}
      </Carousel>
    </Box>
  );
}
