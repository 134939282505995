import axios from 'axios';

const PLACE_ID = 'ChIJI8syTqBx5kcRdkfXpEsTdHw';

const GoogleRest = {
  getRating: async () => {
    const { data } = await axios.get<any>(
      `https://maps.googleapis.com/maps/api/place/details/json?place_id=${PLACE_ID}&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`,
    );

    return {
      rating: data.result?.rating.toFixed(1),
      userRatingsTotal: data.result?.user_ratings_total,
    };
  },
  reviewLink: `https://search.google.com/local/writereview?placeid=${PLACE_ID}`,
};

export default GoogleRest;
