import React, { useMemo } from 'react';
import { Box, createStyles } from '@mantine/core';
import { IBlock } from '@/@types/generated/contentful';
import SecondaryHighlightCard from './Secondary/SecondaryHighlightCard';

const useStyles = createStyles((theme) => {
  return {
    motion: {
      height: '100%',
    },
    wrapper: {
      background: theme.colors.gray[1],
    },
  };
});


export default function HeaderMobileCollectionSecondaryNavigation({
  details,
}: {
  details: IBlock;
}) {

  const { classes } = useStyles();

  return (
    <Box
      className={classes.wrapper}
      h='100%'>
      <SecondaryHighlightCard elements={details} />
    </Box>
  );
}
