import NextLink from 'next/link';
import React from 'react';
import { clsx, createStyles } from '@mantine/core';
import useLocalizedSlug from '@/utils/hook/useLocalizedSlug';
import { IFacet } from '@/@types/generated/contentful';

interface LinkProps {
  href: string;
  query?: any;
  children: React.ReactNode;
  className?: string;
  underline?: boolean;
  rel?: string;
  id?: string;
  facet?: IFacet[] | undefined;
  onClick?: (e?: any) => void;
}

const useStyles = createStyles(() => ({
  noUnderline: {
    textDecoration: 'none !important',
    color: 'unset',
  },
}));
const Link = ({
  children,
  href,
  className,
  query,
  underline = false,
  id,
  onClick,
  facet,
  ariaLabel,
  title,
  target,
  rel,
}: LinkProps & {
  ariaLabel?: string;
  title?: string;
  target?: React.HTMLAttributeAnchorTarget;
  rel?: string;
}) => {
  const { classes } = useStyles();
  const ref = href?.split('?');

  // This code block splits the href URL, parses its query parameters.
  // merges them with an existing query object
  // and then generates a localized slug using the merged query.
  const queryParams: any = new URLSearchParams(ref?.[1]);
  const queryParamsObj: any = {};

  for (const [key, value] of queryParams) {
    queryParamsObj[key] = value;
  }

  const mergedQuery = { ...query, ...queryParamsObj };

  const localisedSlug = useLocalizedSlug(ref?.[0], mergedQuery, facet);

  return (
    <NextLink
      id={id}
      aria-label={ariaLabel}
      title={title}
      // prefetch={false} // to avoid prefetching result in bundle size
      onClick={onClick}
      className={clsx(className, !underline && classes.noUnderline)}
      href={localisedSlug}
      target={target}
      rel={rel}>
      {children}
    </NextLink>
  );
};

export default Link;
