import { Box, Skeleton } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';

const SkeletonProductCard = ({ label }: { label?: string }) => (
  <Box>
    <Skeleton sx={{ aspectRatio: '1' }} />
    {label ? (
      <NoozText>{label}</NoozText>
    ) : (
      <Skeleton
        height={18}
        mt={12}
      />
    )}
    <Skeleton
      height={18}
      mt={12}
    />
  </Box>
);

export default SkeletonProductCard;
