import LocaleHelper from '@/helpers/Locale';

export const capitalizeFirstLetter = (string: string) =>
  string?.charAt(0).toUpperCase() + string?.slice(1);

export const BRAND = 'Nooz Optics';

export const APP_INFO = (() => {
  const appName = process.env.NEXT_PUBLIC_APP_NAME?.toUpperCase();
  switch (appName) {
    case 'B2B':
      return {
        appId: '6bPYihIW3JXcnJn12XFvVI',
        type: 'B2B',
        algoliaPrefix: `${
          process.env.NEXT_PUBLIC_ALGOLIA_PREFIX_INDEX || ''
        }b2b_`,
      };
    case 'B2C':
    default:
      return {
        appId: '6f8d5Hp6dQsrIEhyqoadjV',
        type: 'B2C',
        algoliaPrefix: process.env.NEXT_PUBLIC_ALGOLIA_PREFIX_INDEX || '',
      };
  }
})();

export const pageIsInvalid = (newProps: any, locale: string): boolean =>
  // S'il ne s'agit pas d'une page de type ProductCollection/Product
  // && la page n'est pas disponible dans le marché
  'notFound' in newProps ||
  (newProps.props.page.fields.pageType.type === 'Product' &&
    (!(newProps.props.product?.fields.variants?.length > 0) ||
      !newProps.props.product)) ||
  (!['ProductCollection', 'Product'].includes(
    newProps.props?.page.fields.pageType.type,
  ) &&
    ((newProps.props?.page.fields.market &&
      !newProps.props.page.fields.market.includes(
        LocaleHelper.getLocaleMarket(locale),
      )) ||
      !newProps.props?.page.fields.market)) ||
  // S'il ne s'agit d'une page de type ProductCollection
  // && la collection n'est pas disponible dans le marché
  (newProps.props?.page.fields.pageType.type === 'ProductCollection' &&
    // @ts-ignore
    ((newProps.props?.productCategory.fields.market &&
      // @ts-ignore
      !newProps.props?.productCategory.fields.market.includes(
        LocaleHelper.getLocaleMarket(locale),
      )) ||
      // @ts-ignore
      !newProps.props?.productCategory.fields.market)) ||
  // S'il ne s'agit d'une page de type Product
  // && la collection n'est pas disponible dans le marché
  (newProps.props?.page.fields.pageType.type === 'Product' &&
    !newProps.props?.page.fields.market &&
    // @ts-ignore
    newProps.props?.product?.fields?.collection?.fields &&
    // @ts-ignore
    ((newProps.props?.product?.fields?.collection.fields?.market &&
      // @ts-ignore
      !newProps.props?.product?.fields.collection.fields.market.includes(
        LocaleHelper.getLocaleMarket(locale),
      )) ||
      // @ts-ignore
      !newProps.props?.product?.fields?.collection.fields?.market)) ||
  // S'il ne s'agit d'une page de type Product
  // && une exception de marché a été configuré dans la page produit
  (newProps.props?.page.fields.pageType.type === 'Product' &&
    newProps.props?.page.fields.market &&
    !newProps.props.page.fields.market.includes(
      LocaleHelper.getLocaleMarket(locale),
    ));

export const ACCESS_TOKEN_COOKIE_NAME = '_jwt_at';
