import React from 'react';
import { Box, clsx, createStyles, Flex } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { useMemo } from 'react';
import {
  IBadge,
  IBlock,
  IColor,
  IImage,
  ILabel,
  IPath,
} from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import HighlightCard from '@/components/Cards/HighlightCard';
import { Carousel } from '@mantine/carousel';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { useCarouselListIndicatorStyles } from '@/components/Retail/RetailProductExplo';
import useScrollbarClasses from '@/utils/useScrollbarClasses';
import Image from 'next/image';
import Link from '@/components/Link/Link';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';
import { motion } from 'framer-motion';

const MOBILE_BREAKPOINT = 'sm';

const useClasses = () =>
  createStyles((theme) => ({
    container: {
      flexDirection: 'column',
      gap: 20,
    },
    titles: {
      flexDirection: 'row',
      gap: 24,
      padding: '40px 64px 0',
      [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
        padding: '16px 16px 0',
      },
    },
    label: {
      fontSize: 60,
      fontWeight: 700,
      lineHeight: 1,
      flex: 1,
      [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
        fontSize: 34,
        fontWeight: 500,
      },
    },
    subLabel: {
      fontSize: 22,
      fontWeight: 500,
      lineHeight: 1,
      flex: 1,
      [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
        display: 'none',
      },
    },
    carousel: {
      flex: 3,
      marginBottom: 60,
      [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
        marginBottom: 30,
      },
      '& > div': {
        width: '100%',
      },
    },
  }))().classes;

interface CategoryItem {
  image?: IImage;
  label?: ILabel;
  subLabel?: ILabel;
  slug?: IPath;
  badge?: IBadge;
}

const CollectionCards = ({
  label,
  subLabel,
  collections: {
    fields: { items },
  },
  color,
}: {
  label: ILabel;
  subLabel: ILabel;
  collections: IBlock;
  color: IColor;
}) => {
  const classes = useClasses();
  const scrollbarClasses = useScrollbarClasses();
  const collections = useMemo(() => {
    const arr: CategoryItem[] = [];

    if (items) {
      for (let el of items) {
        const { label, image, subLabel, slug, badge } = formatCfItems(el);
        arr.push({
          image: image as IImage,
          label: label as ILabel,
          subLabel: subLabel as ILabel,
          slug: slug as IPath,
          badge: badge as IBadge,
        });
      }
    }

    return arr.map((e) => ({
      ...e,
      textSize: {
        label: {
          weight: 500,
          size: {
            base: 28,
            xs: 24,
          },
        },
        subLabel: {
          weight: 300,
          size: {
            base: 18,
            xs: 14,
          },
        },
      },
    }));
  }, [items]);
  const classnames = {
    ...useCarouselIndicatorsStyles({}).classes,
    ...useCarouselListIndicatorStyles().classes,
  };

  const carouselProps = {
    classNames: classnames,
    dragFree: false,
    loop: true,
    withControls: true,
    withIndicators: false,
    nextControlIcon: (
      <NoozIcon
        size={28}
        color='black'
        iconKey='ChevronRight'
      />
    ),
    previousControlIcon: (
      <NoozIcon
        size={28}
        color='black'
        iconKey='ChevronLeft'
      />
    ),
    height: '100%',
  };

  return (
    <>
      <Flex
        className={clsx(classes.container)}
        sx={{ background: color.fields.hex }}>
        <Flex gap={60} mb={{ base: 20, md: 40 }} mt={20} className={clsx(classes.titles, scrollbarClasses.noScrollbar)}>
          <NoozText
            className={classes.label}
            color={label.fields.color?.fields.classname || 'title'}>
            {label.fields.text}
          </NoozText>
          <NoozText
            className={classes.subLabel}
            color={label.fields.color?.fields.classname || 'title'}>
            {subLabel.fields.text}
          </NoozText>
        </Flex>
        <Flex pb={24} className={classes.carousel}>
          <Carousel
            {...carouselProps}
            align={'center'}
            withIndicators
            slideGap={20}
            loop
            sx={(theme) => ({
              [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
                display: 'none',
              },
            })}
            styles={{
              viewport: {
                padding: '0 64px',
              },
              indicators: {
                marginBottom: '-2.5rem',
              },
              indicator: {
                background: label.fields.color?.fields.hex
                  ? `${label.fields.color?.fields.hex} !important`
                  : undefined,
              },
            }}
            slideSize={'30%'}>
            {collections.map((cat, idx) => {
              return (
                <Carousel.Slide
                  sx={{
                    marginTop: idx % 2 ? 30 : undefined,
                    marginBottom: idx % 2 ? undefined : 30,
                    padding: 10,
                  }}
                  key={idx}>
                  <Box sx={{ aspectRatio: '3/4' }}>
                    <HighlightCard
                      {...(cat as any)}
                      radius={'lg'}
                      textSize={{ label: { size: { base: "28px", xs: "34px" }, weight: 500 }, subLabel: { size: { base: "18px" } } }}
                    />
                  </Box>
                </Carousel.Slide>
              );
            })}
          </Carousel>
          <Carousel
            {...carouselProps}
            align={'center'}
            loop
            withControls={false}
            withIndicators={true}
            sx={(theme) => ({
              display: 'none',
              [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
                display: 'block',
              },
            })}
            styles={{
              root: {
                marginBottom: '1rem',
              },
              indicators: {
                bottom: '-1rem',
              },
              indicator: {
                background: label.fields.color?.fields.hex
                  ? `${label.fields.color?.fields.hex} !important`
                  : undefined,
              },
            }}
            slideSize={'90%'}>
            {collections.map((cat, idx) => {
              return (
                <Carousel.Slide
                  sx={{
                    margin: idx % 2 ? '10px 0 0' : '0 0 10px',
                    padding: 5,
                  }}
                  key={idx}>
                  <Box sx={{ aspectRatio: '3/4' }}>
                    <HighlightCard
                      {...(cat as any)}
                      radius={'lg'}
                      textSize={{ label: { size: { base: "28px" }, weight: 500 }, subLabel: { size: { base: "18px" } } }}
                    />
                  </Box>
                  {/* <Box sx={{ aspectRatio: '35/50' }}>
                    <Link href={cat.slug?.fields.url as string}>
                      <Flex
                        sx={{
                          borderRadius: 10,
                          overflow: 'hidden',
                          flexDirection: 'column',
                          cursor: 'pointer',
                        }}
                        align={'center'}
                        justify={'flex-end'}
                        h={'100%'}
                        w={'100%'}
                        pos={'relative'}
                        p={'0 20px 30px'}>
                        <Image
                          src={
                            cat.image?.fields.media?.fields.file.url as string
                          }
                          alt={cat.label?.fields.text || ''}
                          fill
                          style={{ zIndex: -1, objectFit: 'cover' }}
                        />
                        {cat.badge ? (
                          <NoozBadge // badgeProps={badgeProps}
                            badgeProps={{
                              color: 'dark',
                              size: 'lg',
                              variant: 'filled',
                              sx: {
                                position: 'absolute',
                                top: '20px',
                                left: '20px',
                                backgroundColor: 'rgba(0, 0, 0, .3)',
                              },
                            }}
                            iconPosition={'right'}
                            textProps={{
                              size: 10,
                              weight: 500,
                              color: 'white',
                            }}
                            icon={
                              cat.badge?.fields.icon ? (
                                <NoozIcon
                                  strokeWidth={1.5}
                                  size={
                                    cat.badge?.fields.icon.fields.size || 18
                                  }
                                  iconKey={
                                    cat.badge?.fields.icon.fields.iconKey
                                  }
                                  color={
                                    cat.badge?.fields.icon.fields.color?.fields
                                      ?.hex || 'white'
                                  }
                                />
                              ) : undefined
                            }
                            text={cat.badge?.fields.label.fields.text as string}
                          />
                        ) : undefined}
                        <NoozText
                          color={'white'}
                          size={34}
                          weight={700}>
                          {cat.label?.fields.text}
                        </NoozText>
                        <NoozText
                          color={'white'}
                          size={10}
                          weight={700}
                          sx={{
                            textDecoration: 'underline',
                            textTransform: 'uppercase',
                          }}>
                          {cat.subLabel?.fields.text}
                        </NoozText>
                      </Flex>
                    </Link>
                  </Box> */}
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </Flex>
      </Flex>
    </>
  );
};

export default CollectionCards;
