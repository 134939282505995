import { Divider, Flex, Group } from '@mantine/core';
import i18n from '@/helpers/i18n';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import React from 'react';
import useStaticContent from '@/utils/hook/useStaticContent';

const RetailBookingStepResult = ({ form }: { form: any }) => {
  const staticContent = useStaticContent();

  return form.slot && form.name ? (
    <Flex
      direction={'column'}
      h={'100%'}>
      <Divider
        my={24}
        labelPosition='left'
        label={i18n(staticContent, 'yourAppointment')}
        color={'title'}
      />
      <Group my={16}>
        <NoozIcon
          iconKey={'CalendarEvent'}
          color={'grey'}
          size={30}
          strokeWidth={1}
        />
        <NoozText
          color={'title'}
          size={18}
          weight={500}>
          {`${form.date.longWeekDay} ${form.date.day} ${form.date.longMonth}`}
        </NoozText>
      </Group>
      <Divider />
      <Group my={16}>
        <NoozIcon
          iconKey={'ClockHour7'}
          color={'grey'}
          size={30}
          strokeWidth={1}
        />
        <NoozText
          color={'title'}
          size={18}
          weight={500}>
          {form.slot.slot}
        </NoozText>
      </Group>
      <Divider />
      <Group my={16}>
        <NoozIcon
          iconKey={'Sunglasses'}
          color={'grey'}
          size={30}
          strokeWidth={1}
        />
        <NoozText
          color={'title'}
          size={18}
          weight={500}>
          {form.type.label}
        </NoozText>
      </Group>
      <Divider />
      <Group my={16}>
        <NoozIcon
          iconKey={'UserCircle'}
          color={'grey'}
          size={30}
          strokeWidth={1}
        />
        <NoozText
          color={'title'}
          size={18}
          weight={500}>
          {form.name}
        </NoozText>
      </Group>
      <Divider />
      <Group my={16}>
        <NoozIcon
          iconKey={'Mail'}
          color={'grey'}
          size={30}
          strokeWidth={1}
        />
        <NoozText
          color={'title'}
          size={18}
          weight={500}>
          {form.email}
        </NoozText>
      </Group>
      <Divider />
      {form.phone ? (
        <>
          <Group my={16}>
            <NoozIcon
              iconKey={'Phone'}
              color={'grey'}
              size={30}
              strokeWidth={1}
            />
            <NoozText
              color={'title'}
              size={18}
              weight={500}>
              {form.phone}
            </NoozText>
          </Group>
          <Divider />
        </>
      ) : null}
    </Flex>
  ) : null;
};

export default RetailBookingStepResult;
