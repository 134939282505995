import {
  Box,
  clsx,
  createStyles,
  Flex,
  Group,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import {
  IBlock,
  ILabel,
  IProduct,
  IProductFields,
} from '@/@types/generated/contentful';
import {
  useBreakpointClasses,
  useContainerStyles,
} from '@/utils/style/useContainer';
import useRetailStyles from '@/components/Retail/useRetailStyles';
import NoozText from '../Nooz/NoozText/NoozText';
import { Carousel } from '@mantine/carousel';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';
import PrettyCard from '@/components/Collection/ProductCard/PrettyCard';

export const useCarouselListIndicatorStyles = createStyles((theme) => ({
  controls: {
    height: '100%',
    display: 'flex',
    top: '0px !important',
    alignItems: 'center',
    padding: '0px 1rem',
  },
  control: {
    padding: 6,
    borderRadius: '100%',
    background: theme.colors.white[0],
    borderColor: theme.black,
  },
}));
const MobileCarousel = ({ products }: { products: IProduct[] }) => {
  const classnames = {
    ...useCarouselIndicatorsStyles({}).classes,
    ...useCarouselListIndicatorStyles().classes,
  };

  const props = {
    classNames: classnames,
    dragFree: false,
    loop: true,
    withControls: true,
    withIndicators: false,
    initialSlide: products.findIndex((p) => p.fields.title === 'Stella-Optic'),
    nextControlIcon: (
      <NoozIcon
        size={28}
        color='black'
        iconKey='ChevronRight'
      />
    ),
    previousControlIcon: (
      <NoozIcon
        size={28}
        color='black'
        iconKey='ChevronLeft'
      />
    ),

    height: '100%',
  };

  return (
    <Carousel
      {...CAROUSEL_PROPS}
      {...props}
      slideGap={10}
      slideSize={'80%'}>
      {products?.map((hit, index: number) => (
        <Carousel.Slide key={`${Math.random()}-${index}`}>
          <PrettyCard product={hit} />
        </Carousel.Slide>
      ))}
    </Carousel>
  );
};
const RetailProductExplo = ({
  label,
  subLabel,
  productList,
}: {
  productList: IBlock;
  label: ILabel;
  subLabel: ILabel;
}) => {
  const theme = useMantineTheme();
  const [bufferQty, setBufferQty] = useState(10);
  const list = useMemo(() => {
    const full = productList.fields.items as unknown as IProduct[];
    return {
      full,
      preNameOnly: full
        .slice(full.length - bufferQty, full.length)
        .map((p) => p.fields.label?.fields.text),
      nameOnly: full.map((p) => p.fields.label?.fields.text),
      postNameOnly: full
        .slice(0, bufferQty)
        .map((p) => p.fields.label?.fields.text),
    };
  }, [productList, bufferQty]);

  const classes = useRetailStyles();
  const [variantIdx, setVariantIdx] = useState(0);
  const [hoverVariantIdx, setHoverVariantIdx] = useState<number | undefined>(
    undefined,
  );
  const [productIdx, setProductIdx] = useState(0);
  const containerStyles = useContainerStyles();
  const product = useMemo(
    () => (productList.fields.items || [])[productIdx] as IProduct,
    [productList, productIdx],
  );
  const scrollWrapper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const onScrollEnd = () => {
      if (scrollWrapper.current) {
        const current = scrollWrapper.current;
        const currRect = current.getBoundingClientRect();
        const isMobile = window.matchMedia(
          theme.fn.smallerThan(theme.breakpoints.sm).replace('@media ', ''),
        ).matches;
        const closest = Array.from(scrollWrapper.current.children).reduce(
          (element: { delta: number; e: Element | null }, c, idx) => {
            const cRect = c.getBoundingClientRect();

            const delta = isMobile
              ? Math.abs(
                  currRect.x + currRect.width / 2 - (cRect.x + cRect.width / 2),
                )
              : Math.abs(
                  Math.abs(
                    cRect.y - currRect.y < 0
                      ? 1000
                      : Math.abs(cRect.y - currRect.y),
                  ) -
                    (currRect.height - cRect.height) / 2,
                );
            if (delta < element.delta) {
              return {
                delta,
                e: c,
              };
            }
            return element;
          },
          { delta: 2000, e: null },
        );
        const targetedIndex = parseInt(
          (closest.e as HTMLElement).dataset.index as string,
        );
        setProductIdx(targetedIndex);
        setVariantIdx(0);
      }
    };

    if (typeof window !== 'undefined') {
      const first = document.getElementById('first');
      if (scrollWrapper.current && first) {
        setBufferQty(
          Math.round(
            scrollWrapper.current.clientHeight / 2 / first.clientHeight,
          ),
        );

        window.requestAnimationFrame(() => {
          if (scrollWrapper.current) {
            setTimeout(() => {
              scrollWrapper.current?.addEventListener('scrollend', onScrollEnd);
            }, 250);
          }
        });
      }
    }

    return () => {
      scrollWrapper.current?.removeEventListener('scrollend', onScrollEnd);
    };
  }, []);
  const bp = useBreakpointClasses();

  return (
    <>
      <Flex
        w='100%'
        mb={40}
        direction='column'
        justify='center'
        className={containerStyles.classes.container}>
        <NoozText
          className={classes.label}
          text={label.fields.text}
        />
        <NoozText
          className={classes.subLabel}
          text={subLabel.fields.text}
        />
      </Flex>
      <Box
        className={clsx(
          classes.wrapper,
          containerStyles.classes.container,
          bp.desktopOnly,
        )}>
        <Box
          ref={scrollWrapper}
          id={'product-explorer'}
          className={clsx(classes.element, classes.listWrapper)}>
          {list.preNameOnly.map((n, i) => (
            <NoozText
              data-index={list.nameOnly.length - bufferQty + i}
              onClick={() => {
                setVariantIdx(0);
                setProductIdx(list.nameOnly.length - bufferQty + i);
              }}
              className={clsx(classes.text, {
                active:
                  productIdx >= list.nameOnly.length - bufferQty &&
                  list.nameOnly.length - bufferQty + i === productIdx,
              })}
              key={'pre-' + i}>
              {n}
            </NoozText>
          ))}
          {list.nameOnly.map((n, i) => (
            <NoozText
              data-index={i}
              onClick={() => {
                setVariantIdx(0);
                setProductIdx(i);
              }}
              className={clsx(classes.text, { active: productIdx === i })}
              id={i === 0 ? 'first' : undefined}
              key={i}>
              {n}
            </NoozText>
          ))}
          {list.postNameOnly.map((n, i) => (
            <NoozText
              data-index={i}
              onClick={() => {
                setVariantIdx(0);
                setProductIdx(i);
              }}
              className={clsx(classes.text, { active: productIdx === i })}
              key={'post-' + i}>
              {n}
            </NoozText>
          ))}
        </Box>
        <Box className={clsx(classes.element, classes.productWrapper)}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className={clsx(classes.img)}
            src={
              product.fields.variants?.[variantIdx]?.fields.posterImages?.[0]
                .fields.media?.fields.file.url + '?fm=webp'
            }
            alt={(product.fields as IProductFields).variants?.[0]?.fields.title}
            key={(product as any).id}
          />
          <p className={classes.productName}>
            {product.fields.label?.fields.text}
          </p>
          <p className={classes.productColor}>
            {
              product.fields.variants?.[
                typeof hoverVariantIdx === 'number'
                  ? hoverVariantIdx
                  : variantIdx
              ]?.fields.color?.fields.text
            }
          </p>

          <Group
            className={classes.colorPicker}
            position={'center'}>
            {product.fields.variants?.map((v, key) => (
              <Box
                className={classes.color}
                key={v.fields.title}
                sx={(theme) => ({
                  background: v.fields.color?.fields.hex,
                  border:
                    key === variantIdx
                      ? `2px solid ${theme.colors.dark[3]}`
                      : `1px solid ${theme.colors.gray[4]}`,
                })}
                onMouseEnter={() => {
                  setHoverVariantIdx(key);
                }}
                onMouseLeave={() => {
                  setHoverVariantIdx(undefined);
                }}
                onClick={() => {
                  setVariantIdx(key);
                }}>
                {key === variantIdx ? (
                  <motion.div
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{
                      scale: 1,
                      opacity: 1,
                      transition: { ease: 'easeOut', duration: 0.2 },
                    }}
                    exit={{
                      opacity: 0,
                      scale: 0,
                      transition: { ease: 'easeOut', duration: 0.5 },
                    }}>
                    <div className={classes.active} />
                  </motion.div>
                ) : null}
              </Box>
            ))}
          </Group>
        </Box>
      </Box>
      <Box className={bp.mobileOnly}>
        <MobileCarousel products={list.full} />
      </Box>
    </>
  );
};

export default RetailProductExplo;
