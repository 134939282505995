export const getSlugsFromUrlQuery = (variables: Record<string, any>) => ({
  query: `query getSlugsFromUrl($path: [String], $locale: String!) {
  pathCollection(
    where: { url_in: $path, facetCollection_exists: false }
    locale: $locale
  ) {
    total
    items {
      sys {
        id
      }
      title
      url
    }
  }
}
`,
  variables,
});

export const getPrebuildPagesQuery = (variables: Record<string, any>) => ({
  query: `
query getPrebuildPages($id: String!, $locale: String) {
  app(id: $id, locale: $locale) {
    pages: pagesCollection(limit: 100, where: { preBuild_exists: true }) {
      items {
        sys {
          id
        }
        slug {
          url
        }
        preBuild
      }
    }
    categories: categoriesCollection(limit: 1, where: {pages: {preBuild_exists: true}}) {
      items {
        pages: pagesCollection(limit: 10) {
          items {
            sys {
              id
            }
            slug {
              url
            }
            preBuild
          }
        }
      }
    }
    collections: collectionsCollection(limit: 100, where: {pageReference: {preBuild_exists: true}}) {
      items {
        pageReference {
          sys {
            id
          }
          slug {
            url
          }
          preBuild
        }
      }
    }
  }
}
`,
  variables,
});

export const getAssetByIdQuery = (variables: Record<string, any>) => ({
  query: `
query getAssetById($id: String!, $locale: String) {
  asset(id: $id, locale: $locale) {
    title
    url
    description
  }
}
`,
  variables,
});

export const getAppLocaleRoutesQuery = (variables: Record<string, any>) => ({
  query: `
query getAppLocaleRoutes( $locale: String) {
  pageCollection(limit: 200, locale: $locale) {
    items {
      slug {
        url
      }
      label {
        text
      }
      pageType {
        title
      }
      robots
    }
  }
}
`,
  variables,
});
