import * as process from 'process';
import { APP_INFO } from '@/utils';

class CustomerAccount {
  protected static async generateCodeVerifier() {
    const rando = this.generateRandomCode();
    return this.base64UrlEncode(rando);
  }
  protected static async generateState() {
    const timestamp = Date.now().toString();
    const randomString = Math.random().toString(36).substring(2);
    return timestamp + randomString;
  }
  protected static async generateNonce(length?: number) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let nonce = '';

    if (length)
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        nonce += characters.charAt(randomIndex);
      }

    return nonce;
  }
  protected static async generateCodeChallenge(codeVerifier: string) {
    const digestOp = await crypto.subtle.digest(
      { name: 'SHA-256' },
      new TextEncoder().encode(codeVerifier),
    );
    const hash = this.convertBufferToString(digestOp);
    return this.base64UrlEncode(hash);
  }
  protected static generateRandomCode() {
    const array = new Uint8Array(32);
    crypto.getRandomValues(array);
    return String.fromCharCode.apply(null, Array.from(array));
  }
  protected static base64UrlEncode(str: string) {
    const base64 = btoa(str);
    // This is to ensure that the encoding does not have +, /, or = characters in it.
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }
  protected static convertBufferToString(hash: ArrayBuffer) {
    const uintArray = new Uint8Array(hash);
    const numberArray = Array.from(uintArray);
    return String.fromCharCode(...numberArray);
  }

  static async getClientUrl(locale?: string) {
    const redirectUri = `${process.env.NEXT_PUBLIC_DOMAIN}/auth`;
    const authorizationRequestUrl = new URL(
      `https://shopify.com/${process.env.NEXT_PUBLIC_SHOPIFY_SHOP_ID}/auth/oauth/authorize`,
    );

    authorizationRequestUrl.searchParams.append(
      'scope',
      'openid email https://api.customers.com/auth/customer.graphql',
    );
    authorizationRequestUrl.searchParams.append(
      'client_id',
      process.env.NEXT_PUBLIC_SHOPIFY_CLIENT_ID as string,
    );
    authorizationRequestUrl.searchParams.append('response_type', 'code');
    authorizationRequestUrl.searchParams.append('redirect_uri', redirectUri);
    authorizationRequestUrl.searchParams.append(
      'state',
      await this.generateState(),
    );

    authorizationRequestUrl.searchParams.append(
      'nonce',
      await this.generateNonce(),
    );
    authorizationRequestUrl.searchParams.append(
      'ui_locales',
      this.getShopifyLocale(locale),
    );

    // Public client
    const verifier = await this.generateCodeVerifier();
    const challenge = await this.generateCodeChallenge(verifier);
    localStorage.setItem('code-verifier', verifier);

    authorizationRequestUrl.searchParams.append('code_challenge', challenge);
    authorizationRequestUrl.searchParams.append(
      'code_challenge_method',
      'S256',
    );
    return authorizationRequestUrl.toString();
  }

  static getShopifyLocale(locale?: string) {
    const UI_LOCALES = [
      'en',
      'fr',
      'cs',
      'da',
      'de',
      'es',
      'fi',
      'it',
      'ja',
      'ko',
      'nb',
      'nl',
      'pl',
      'pt-BR',
      'pt-PT',
      'sv',
      'th',
      'tr',
      'vi',
      'zh-CN',
      'zh-TW',
    ];
    return (
      UI_LOCALES.find(
        (l) =>
          l ===
          locale?.split('-')?.reduce((str: string, el: string, idx) => {
            if (idx === 0) {
              str = el;
            } else {
              str += `-${el.toUpperCase()}`;
            }
            return str;
          }, ''),
      ) ||
      UI_LOCALES.find((l) => l === locale?.split('-')?.[0]) ||
      'en'
    );
  }
  protected static decodeJwt(token: string) {
    const [header, payload, signature] = token.split('.');

    const decodedHeader = JSON.parse(atob(header));
    const decodedPayload = JSON.parse(atob(payload));

    return {
      header: decodedHeader,
      payload: decodedPayload,
      signature,
    };
  }
  static async getNonce(token: string) {
    return this.decodeJwt(token).payload.nonce;
  }
}

export default CustomerAccount;
