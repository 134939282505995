import { Box, Center, clsx, createStyles } from '@mantine/core';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import React from 'react';
import {
  IBadge,
  IBlock,
  IButton,
  IButtonFields,
  IVideo,
} from '@/@types/generated/contentful';
import Link from '@/components/Link/Link';
import useLocalizedSlug from '@/utils/hook/useLocalizedSlug';
import { useRouter } from 'next/router';

interface HeroVideoProps {
  buttons: IBlock;
  badge: IBadge;
  video: IVideo;
  mobileVideo: IVideo;
  button: IButton;
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100vw',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 80,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      bottom: 40,
    },
  },

  desktopVideo: {
    aspectRatio: '1600/650',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      display: 'none',
    },
  },
  mobileVideo: {
    aspectRatio: '3/4',
    display: 'none',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      display: 'block',
    },
  },
}));

const HeroVideoBanner = ({
  buttons,
  video,
  mobileVideo,
  button,
}: HeroVideoProps) => {
  const styles = useStyles();

  const { type, ...btnProps } = button?.fields || {};

  const localisedSlug = useLocalizedSlug(
    (buttons?.fields.items?.[0]?.fields as IButtonFields)?.slug?.fields
      ?.url as string,
  );
  const router = useRouter();
  return (
    <Box
      sx={{ position: 'relative', cursor: 'pointer' }}
      className={clsx(styles.classes.container)}
      onClick={() => {
        if (
          (buttons?.fields.items?.[0]?.fields as IButtonFields)?.slug?.fields
            ?.url
        )
          router.push(localisedSlug);
      }}>
      <video
        className={styles.classes.desktopVideo}
        width='100%'
        height='auto'
        poster={video?.fields.poster?.fields.media?.fields.file.url}
        autoPlay
        playsInline
        muted
        loop>
        <source
          src={video?.fields.url?.fields.file.url}
          type='video/mp4'></source>
      </video>
      <video
        className={styles.classes.mobileVideo}
        width='100%'
        height='auto'
        poster={mobileVideo?.fields.poster?.fields.media?.fields.file.url}
        autoPlay
        playsInline
        muted
        loop>
        <source
          src={mobileVideo.fields.url?.fields.file.url}
          type='video/mp4'></source>
      </video>
      <Center
        w={'100%'}
        className={styles.classes.buttonContainer}>
        {buttons
          ? buttons.fields?.items?.map(
              // @ts-ignore
              (button: IButton, index: number) => {
                if (!button.fields.slug?.fields.url) return null;
                // @ts-ignore
                return (
                  <Link
                    key={index}
                    href={button.fields.slug?.fields.url as string}>
                    {/* @ts-ignore */}
                    <NoozButton
                      key={index}
                      {...button.fields}
                      variant={button.fields.type || 'filled'}
                      color={button?.fields?.color?.fields?.classname || 'dark'}
                      className={'cta-button'}
                      sx={
                        button?.fields?.label?.fields.color?.fields.classname
                          ? // @ts-ignore
                            (theme) => ({
                              color:
                                theme.colors[
                                  // @ts-ignore
                                  button.fields.label.fields.color.fields
                                    .classname
                                ],
                            })
                          : null
                      }
                      size={button.fields.size || 'lg'}>
                      {button?.fields?.label?.fields.text}
                    </NoozButton>
                  </Link>
                );
              },
            )
          : null}
      </Center>
    </Box>
  );
};
export default HeroVideoBanner;
