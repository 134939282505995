// Docs: https://www.contentful.com/developers/docs/references/images-api/
export default function contentfulLoader({ src, quality, width }) {
  try {
    if (src && /^(\/\/)|(http)/g.test(src)) {
      const url = new URL(/^\/\//g.test(src) ? `https:${src}` : src);
      url.searchParams.set('fm', 'webp');
      url.searchParams.set('w', width.toString());
      url.searchParams.set('q', quality?.toString() || '75');
      return url.href;
    } else if (src) {
      return src;
    }
  } catch (e) {
    console.error('[ERROR] ', src);
  }
  return '';
}
