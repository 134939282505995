import * as React from 'react';
import { IModal, IModalContext, ModalProviderProps } from '@/interface.custom';

export const ModalContext = React.createContext<IModalContext | null>(null);

export const useModalContext = (): IModalContext => {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error('useDrawerContext must be used within a DialogProvider');
  }
  return context;
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modal, setModal] = React.useState<IModal>({
    opened: false,
    body: undefined,
    title: '',
  });

  return (
    <ModalContext.Provider value={{ modal, setModal }}>
      {children}
    </ModalContext.Provider>
  );
};
