import {
  ActionIcon,
  Box,
  Container,
  Group,
  Image,
  createStyles,
} from '@mantine/core';
import React, { useRef } from 'react';
import { IVideo } from '@/@types/generated/contentful';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { useContainerStyles } from '@/utils/style/useContainer';
interface VideoBannerProps {
  video: IVideo;
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    borderRadius: theme.radius.xl,
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    [theme.fn.smallerThan('md')]: {
      borderRadius: theme.radius.lg,
    },
  },
  button: {
    width: 60,
    height: 60,
    borderRadius: 60,
    [theme.fn.smallerThan('md')]: {
      width: 50,
      height: 50,
      borderRadius: 50,
    },
  },
}));

const VideoBanner = ({ video }: VideoBannerProps) => {
  const { classes } = useStyles();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isMuted, setIsMuted] = React.useState(false);

  const handleVideoPlay = () => {
    const video: any = videoRef?.current;
    if (video?.paused) {
      setIsPlaying(false);
      video.play();
    } else {
      setIsPlaying(true);
      video?.pause();
    }
  };

  const handleSoundPlay = () => {
    const video: any = videoRef?.current;
    if (!video?.muted) {
      setIsMuted(true);
      video.muted = true;
    } else {
      setIsMuted(false);
      video.muted = false;
    }
  };

  return (
    <Box className={classes.wrapper}>
      {video.fields.url ? (
        <video
          autoPlay
          loop
          muted
          playsInline
          ref={videoRef}
          style={{ width: '100%', height: '100%' }}>
          <source
            src={video.fields.url?.fields.file.url}
            type='video/mp4'
          />
        </video>
      ) : (
        <Image
          src={video.fields.poster?.fields.media?.fields.file.url + '?fm=webp'}
          alt={video.fields.poster?.fields.title}
          style={{ width: '100%', height: '100%' }}
        />
      )}
      <Container
        className={useContainerStyles().classes.container}
        sx={{ position: 'absolute', zIndex: 1, bottom: 50, right: 50 }}>
        <Group
          position='center'
          spacing='xs'>
          <ActionIcon
            className={classes.button}
            onClick={() => handleVideoPlay()}
            variant='outline'
            color='white'>
            {isPlaying ? (
              <NoozIcon
                size={40}
                strokeWidth={1.5}
                color='white'
                iconKey={'PlayerPlay'}
              />
            ) : (
              <NoozIcon
                size={40}
                strokeWidth={1.5}
                color='white'
                iconKey={'PlayerPause'}
              />
            )}
          </ActionIcon>
          <ActionIcon
            className={classes.button}
            onClick={() => handleSoundPlay()}
            variant='outline'
            color='white'>
            {isMuted ? (
              <NoozIcon
                size={40}
                strokeWidth={1.5}
                color='white'
                iconKey={'VolumeOff'}
              />
            ) : (
              <NoozIcon
                size={40}
                strokeWidth={1.5}
                color='white'
                iconKey={'Volume'}
              />
            )}
          </ActionIcon>
        </Group>
      </Container>
    </Box>
  );
};

export default VideoBanner;
