import React, { useEffect } from 'react';
import { usePrevious, useWindowScroll } from '@mantine/hooks';

function useScroll() {
  const [scroll] = useWindowScroll();
  const [isScrollingDown, setIsScrollingDown] = React.useState(false);

  const prevScrollY = usePrevious(scroll.y);

  useEffect(() => {
    if (prevScrollY === undefined) return;

    if (prevScrollY < scroll.y && scroll.y > 200) {
      setIsScrollingDown(true);
    } else {
      setIsScrollingDown(false);
    }
  }, [scroll.y]);

  return { scroll, isScrollingDown };
}

export default useScroll;
