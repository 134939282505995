import { createStyles, rem } from '@mantine/core';

export const useCarouselIndicatorsStyles = createStyles(
  (theme: any, { color }: { color?: string }) => ({
    indicator: {
      width: rem(12),
      height: rem(10),
      transition: 'width 250ms ease',
      background: color || theme.colors.gray[6],

      '&[data-active]': {
        width: rem(40),
      },
    },
  }),
);
