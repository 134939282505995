import React from 'react';
import { Box, createStyles } from '@mantine/core';
import NextNProgress from 'nextjs-progressbar';

const useStyles = createStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
  },
}));

const LoadingProgress = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.root}>
      <NextNProgress
        transformCSS={(css) => {
          const newCss =
            css +
            `
          #nprogress .spinner {
            display: block;
            position: fixed;
            z-index: 1031;
            top: unset;
            bottom: 15px;
            right: 15px;
          }
        `;
          return <style>{newCss}</style>;
        }}
        height={2}
        color={'#1C38CC'}
      />
    </Box>
  );
};

export default LoadingProgress;
