import React from 'react';
import { Group } from '@mantine/core';
import HeaderDesktopNavigationsMasterNavigation from './HeaderDesktopNavigationsMasterNavigation';
import HeaderDesktopCollectionSecondaryNavigation from './HeaderDesktopNavigationsSecondaryNavigation';
import { IBlock, IColor } from '@/@types/generated/contentful';

type HeaderDesktopNavigationsDrawerProps = {
  masterDetails: IBlock;
  secondaryDetails: IBlock;
  color: IColor;
};

export default function HeaderDesktopNavigationsDrawer({
  details,
}: {
  details: HeaderDesktopNavigationsDrawerProps;
}) {
  const { masterDetails, secondaryDetails, color } = details;

  return (
    <Group
      grow
      h={'100%'}>
      {masterDetails && (
        <HeaderDesktopNavigationsMasterNavigation
          details={masterDetails}
          color={color}
        />
      )}
      {secondaryDetails && (
        <HeaderDesktopCollectionSecondaryNavigation
          details={secondaryDetails}
        />
      )}
    </Group>
  );
}
