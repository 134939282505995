import React from 'react';
import { ShopstoryProvider } from '@shopstory/react';
import ProductCard from '@/components/Collection/ProductCard/ProductCard';
import ActionCard from '@/components/Cards/ActionCard';
import ActionCardBackdrop from '@/components/Cards/ActionCardBackdrop';
import { Title } from '@mantine/core';
import HighlightCard from '@/components/Cards/HighlightCard';
import {
  ShopstoryNoozText,
  ShopstoryNoozTitle,
} from '@/components/Nooz/NoozText/NoozText';
import { NoozShopstoryButton } from '@/components/Nooz/NoozButton/NoozButton';
import ActionHorizontalCard from '@/components/Cards/ActionHorizontalCard';
import NextImage, { ImageProps } from 'next/image';
import ProductCardListsSelector from '@/components/ProductCategory/List/ProductCardListSelector';
import {
  FeaturedCardNavigation,
  FeaturedBlogArticlesNavigation,
  FeaturedCardCarousel,
} from '@/components/FeaturedCardNavigation/FeaturedCardNavigation';
import {
  HeroBannerHighlightCard,
  HeroBannerHighlightCardSlider,
} from '@/components/Hero/Banner/HeroBannerHighlightCard';
import { HeroSlider } from '@/components/Hero/HeroSlider';
import ReassuranceBanner from '@/components/Hero/Banner/ReassuranceBanner';
import HeroBanner from '@/components/Hero/Banner/HeroBanner';
import TrustpilotReviews from '@/components/Trustpilot/TrustpilotReviews/TrustpilotReviews';
import VideoBanner from '@/components/Hero/Banner/VideoBanner';
import ImageBanner from '@/components/Hero/Banner/ImageBanner';
import Quotation from '@/components/Quotation/Quotation';
import ProductCardList from '@/components/ProductCategory/List/ProductCardList';
import ProductCardListCarousel from '@/components/ProductCategory/List/ProductCardListCarousel';
import { IntroCardNavigation } from '@/components/IntroCardNavigation/IntroCardNavigation';
import HeroVideoBanner from '@/components/HeroVideo/HeroVideo';
import CodeDiscount from '@/components/CodeDiscount/CodeDiscount';
import ShareSection from '@/components/ShareSection/ShareSection';
import RetailProductExplo from '@/components/Retail/RetailProductExplo';
import RetailPricing from '@/components/Retail/RetailPricing';
import HeroDuoBanner from '@/components/Hero/Banner/HeroDuoBanner';
import GridCards from '@/components/GridCards/GridCards';
import HeroVideoSvg from '@/components/HeroVideoSvg/HeroVideoSvg';
import RetailInfo from '@/components/Retail/RetailInfo';
import SeoRichBody from '@/components/SeoRichBody/SeoRichBody';
import HeroSmallBordered from '@/components/Hero/HeroSmallBordered';
import HeroDuoCompare from '@/components/Hero/HeroDuoCompare';
import CardsNavigation from '@/components/FeaturedCardNavigation/CardsNavigation';
import ButtonsBanner from '@/components/Hero/Banner/ButtonsBanner';
import GridLineShopItem from '@/components/LineShopItem/GridLineShopItem';
import LookBookLineShopItem from '@/components/LineShopItem/LookBookLineShopItem';
import HeroDuoBannerV2 from '@/components/Hero/Banner/HeroDuoBannerV2';
import { ShopstoryNoozImage } from '@/components/Nooz/NoozImage/NoozImage';
import RetailBookingCancel from '@/components/Retail/Booking/RetailBookingCancel';
import RetailBooking from '@/components/Retail/Booking/RetailBooking';
import BannerDuo from '@/components/Hero/Banner/BannerDuo';
import BannerCTA from '@/components/Hero/Banner/BannerCTA';
import CategoriesGrid from '@/components/CategoriesGrid/CategoriesGrid';
import { HeroTextBannerShopStory } from '@/components/Hero/Banner/HeroTextBanner';
import CollectionCards from '@/components/CollectionCards/CollectionCards';
import CustomerServiceGrid from '@/components/CustomerService/CustomerServiceGrid';

interface NoozShopstoryProviderProps {
  children: React.ReactNode;
}

const Image = (props: ImageProps) => {
  return (
    <NextImage
      src={props.src}
      alt={props.alt}
      fill
      priority
    />
  );
};

export const NoozShopstoryProvider = ({
  children,
}: NoozShopstoryProviderProps) => {
  return (
    <ShopstoryProvider
      Image={Image}
      buttons={{
        // @ts-ignore
        Button: NoozShopstoryButton,
      }}
      components={{
        BannerCTA,
        BannerDuo,
        ProductCard,
        UspCard: HighlightCard,
        ActionCardBackdrop: ActionCardBackdrop,
        ActionHorizontalCard,
        ActionCard,
        Label: ShopstoryNoozTitle,
        LabelItem: ShopstoryNoozText,
        ImageItem: ShopstoryNoozImage,
        CodeDiscount,
        Title,
        CategoryListsSelector: ProductCardListsSelector,
        ProductCardList,
        FeaturedCardCarousel,
        ProductCardListCarousel,
        Reassurance: ReassuranceBanner,
        FeaturedCardNavigation,
        IntroCardNavigation: IntroCardNavigation,
        FeaturedBlogArticlesNavigation: FeaturedBlogArticlesNavigation,
        HeroBannerHighlightCard,
        HeroBannerHighlightCardSlider,
        HeroSlider,
        HeroBanner,
        HeroDuoBanner,
        HeroDuoBannerV2,
        HeroTextBanner: HeroTextBannerShopStory,
        HeroVideo: HeroVideoBanner,
        HeroVideoSvg,
        VideoBanner,
        ImageBanner,
        TrustpilotReviews,
        CardsNavigation,
        Quotation,
        ShareSection,
        ProductExplorer: RetailProductExplo,
        RetailPricing,
        RetailInfo,
        GridCards,
        SeoRichBody,
        HeroSmallBordered,
        HeroDuoCompare,
        FaqAccordion: SeoRichBody,
        ButtonsBanner,
        LineItemLookBook: LookBookLineShopItem,
        LineItemGrid: GridLineShopItem,
        RetailBooking,
        RetailBookingCancel,
        CategoriesGrid,
        CollectionCards,
        CustomerServiceGrid,
      }}>
      {children}
    </ShopstoryProvider>
  );
};
