import {
  IBlock,
  IButton,
  IColorFields,
  IIconFields,
  ILabel,
} from '@/@types/generated/contentful';
import {
  Box,
  Button,
  Card,
  Center,
  clsx,
  createStyles,
  Flex,
  Group,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import React, { useMemo, useState } from 'react';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import { formatCfItems } from '@/helpers/formatCfItems';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import useMarketingStyles from '../Cards/useMarketingStyles';
import useRetailStyles from './useRetailStyles';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';
import { Carousel } from '@mantine/carousel';

const useStyles = createStyles((theme) => ({
  banner: {
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      display: 'none',
    },
  },
  bannerImg: {
    width: '100%',
    // width: 'auto',
    height: 'auto',
    maxHeight: 320,
    borderRadius: 20,
    objectFit: 'cover',
  },
  toggle: {
    alignSelf: 'center',
    width: 'fit-content',
  },
  pricesWrapper: {
    width: '100%',
    alignSelf: 'center',
    '& > *': { flex: 1 },
    '& .divider': { display: 'none' },
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '0 30px',
      '& > *': { flex: 'unset' },
      '& .divider': { display: 'block', margin: '0 40px' },
      '& .filler': {
        display: 'none',
      },
    },
  },
  pricingLabel: {
    color: theme.colors.dark,
    fontFamily: 'Mont',
    fontSize: 42,
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: 1,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      fontSize: 32,
      textAlign: 'center',
    },
  },
  pricingValue: {
    color: theme.colors.dark,
    fontFamily: 'Mont',
    fontSize: 42,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 1,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      fontSize: 26,
      textAlign: 'center',
    },
  },
  featureWrapper: {
    flexWrap: 'nowrap',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
  },
  dot: {
    height: 14,
    width: 14,
    borderRadius: 14,
    background: theme.colors.dark,
  },
  featureLabel: {
    color: theme.colors.dark,
    fontFamily: 'Mont',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 1,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      fontSize: 14,
      color: theme.colors.black[5],
    },
  },
  imagePricing: {
    objectFit: 'contain',
    [theme.fn.largerThan(theme.breakpoints.sm)]: {
      height: 75,
    },

    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      width: 58,
    },
  },
  labelWrapperPricing: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
}));

const RetailPricing = ({
  label,
  subLabel,
  pricing,
  button,
}: {
  label: ILabel;
  subLabel: ILabel;
  pricing: IBlock;
  button: IButton;
}) => {
  const { colors } = useMantineTheme();
  const { classes } = useStyles();
  const retailStyles = useRetailStyles();
  //@ts-ignore
  const marketingStyles = useMarketingStyles({ animationVariant: 'scale' });

  const typologies = useMemo(() => {
    return (pricing.fields.items as []).map((t: IBlock) => ({
      id: (t as any).id,
      ...formatCfItems(t),
      price: undefined,
      prices: (t.fields.items || []).filter((f) => f?.fields.key === 'price'),
    }));
  }, [pricing.fields.items]);
  const [active, setActive] = useState(typologies[0]);

  const containerStyles = useContainerStyles();

  return (
    <Flex
      direction={'column'}
      gap={40}>
      <Flex
        direction='column'
        justify='center'>
        <NoozText className={retailStyles.label}>{label.fields.text}</NoozText>
        <NoozText className={retailStyles.subLabel}>
          {subLabel.fields.text}
        </NoozText>
      </Flex>
      <Group position={'center'}>
        <Button.Group>
          {typologies.map((t) => (
            <NoozButton
              key={t.id}
              size='lg'
              variant={active.id === t.id ? 'filled' : 'outline'}
              color={'terracota'}
              onClick={() => setActive(t)}
              className={classes.toggle}>
              {t.label?.fields.text}
            </NoozButton>
          ))}
        </Button.Group>
      </Group>
      <Flex
        justify='center'
        gap='xl'
        className={clsx(
          containerStyles.classes.container,
          classes.pricesWrapper,
        )}
        sx={(theme) => ({
          [theme.fn.smallerThan(theme.breakpoints.sm)]: {
            display: 'none',
          },
        })}>
        {active.prices.map((col: IBlock, key: number) => {
          const { label, price, badge, image } = formatCfItems(col);

          return (
            <React.Fragment key={(col as any).id}>
              <Card
                className={marketingStyles.classes.cardWrapper}
                withBorder
                radius='lg'
                p={0}
                pt={40}
                display={'flex'}
                style={{ flexDirection: 'column' }}
                h={'unset'}>
                <Stack
                  px={40}
                  pb={40}>
                  <Flex className={classes.labelWrapperPricing}>
                    <Stack>
                      <NoozText className={classes.pricingLabel}>
                        {label.fields.text}
                      </NoozText>
                      <NoozText className={classes.pricingValue}>
                        {price.fields.text}
                      </NoozText>
                    </Stack>
                    {badge ? (
                      <NoozBadge
                        text={badge.fields.label.fields.text}
                        type={badge.fields.type || 'filled'}
                        badgeProps={{
                          variant: badge.fields.type || 'filled',
                          color:
                            (badge.fields.color.fields as IColorFields)
                              .classname || 'terracota',
                          radius: 'lg',
                          size: 'lg',
                        }}
                        icon={
                          badge.fields.icon ? (
                            <NoozIcon
                              iconKey={
                                (badge.fields.icon.fields as IIconFields)
                                  .iconKey || 'Star'
                              }
                              color={
                                (badge.fields.icon.fields as IIconFields).color
                                  ?.fields.hex || colors.white[0]
                              }
                            />
                          ) : undefined
                        }
                        iconPosition='left'
                      />
                    ) : null}
                    {image ? (
                      //eslint-disable-next-line @next/next/no-img-element
                      <img
                        className={classes.imagePricing}
                        src={image.fields.media.fields.file.url + '?fm=webp'}
                        alt={''}
                      />
                    ) : null}
                  </Flex>
                  {(col.fields.items || [])
                    .filter((f) => f && f.fields.key === 'feature')
                    .map((f) => {
                      const { label } = formatCfItems(f);
                      const dots = ((f.fields.items || []) as any[]).filter(
                        (f) => f && f.fields.key === 'dot',
                      );

                      return (
                        <Group
                          key={(f as any).id}
                          className={classes.featureWrapper}>
                          <Group style={{ gap: 8, flexWrap: 'nowrap' }}>
                            {dots.map((d, k) => (
                              <Box
                                key={`${(d as any).id}_${k}`}
                                className={classes.dot}
                                bg={d.fields.color?.fields.hex}
                              />
                            ))}
                          </Group>
                          <NoozText>{label.fields.text}</NoozText>
                        </Group>
                      );
                    })}
                </Stack>
                {key < active.prices.length - 1 && (
                  <Box
                    className='filler'
                    style={{
                      flex: 1,
                      background: col.fields.color?.fields.hex,
                    }}
                  />
                )}
              </Card>
            </React.Fragment>
          );
        })}
      </Flex>

      <Carousel
        slideGap={10}
        slideSize={'90%'}
        styles={{
          control: {
            '&[data-inactive]': {
              opacity: 0,
              cursor: 'default',
            },
          },
        }}
        sx={(theme) => ({
          [theme.fn.largerThan(theme.breakpoints.sm)]: {
            display: 'none',
          },
        })}>
        {active.prices.map((col: IBlock, key: number) => {
          const { label, price, badge, image } = formatCfItems(col);

          return (
            <Carousel.Slide
              key={(col as any).id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Card
                className={marketingStyles.classes.cardWrapper}
                withBorder
                radius='lg'
                p={0}
                pt={40}
                display={'flex'}
                style={{ flexDirection: 'column' }}>
                <Stack
                  px={{ base: 20, sm: 40 }}
                  pb={{ base: 20, sm: 40 }}>
                  <Flex className={classes.labelWrapperPricing}>
                    <Stack>
                      <NoozText className={classes.pricingLabel}>
                        {label.fields.text}
                      </NoozText>
                      <NoozText className={classes.pricingValue}>
                        {price.fields.text}
                      </NoozText>
                    </Stack>
                    {badge ? (
                      <NoozBadge
                        text={badge.fields.label.fields.text}
                        type={badge.fields.type || 'filled'}
                        badgeProps={{
                          variant: badge.fields.type || 'filled',
                          color:
                            (badge.fields.color.fields as IColorFields)
                              .classname || 'terracota',
                          radius: 'lg',
                          size: 'lg',
                        }}
                        icon={
                          badge.fields.icon ? (
                            <NoozIcon
                              iconKey={
                                (badge.fields.icon.fields as IIconFields)
                                  .iconKey || 'Star'
                              }
                              color={
                                (badge.fields.icon.fields as IIconFields).color
                                  ?.fields.hex || colors.white[0]
                              }
                            />
                          ) : undefined
                        }
                        iconPosition='left'
                      />
                    ) : null}
                    {image ? (
                      // eslint-disable-next-line @next/next/no-img-element
                      <img
                        className={classes.imagePricing}
                        src={image.fields.media.fields.file.url + '?fm=webp'}
                        alt={''}
                      />
                    ) : null}
                  </Flex>
                  {(col.fields.items || [])
                    .filter((f) => f && f.fields.key === 'feature')
                    .map((f) => {
                      const { label } = formatCfItems(f);
                      const dots = ((f.fields.items || []) as any[]).filter(
                        (f) => f && f.fields.key === 'dot',
                      );

                      return (
                        <Group
                          key={(f as any).id}
                          className={classes.featureWrapper}>
                          <Group style={{ gap: 8, flexWrap: 'nowrap' }}>
                            {dots.map((d, k) => (
                              <Box
                                key={`${(d as any).id}_${k}`}
                                className={classes.dot}
                                bg={d.fields.color?.fields.hex}
                              />
                            ))}
                          </Group>
                          <NoozText>{label.fields.text}</NoozText>
                        </Group>
                      );
                    })}
                </Stack>
                {key < active.prices.length - 1 && (
                  <Box
                    className='filler'
                    style={{
                      flex: 1,
                      background: col.fields.color?.fields.hex,
                    }}
                  />
                )}
              </Card>
            </Carousel.Slide>
          );
        })}
      </Carousel>
      <Center>
        <NoozButton
          urlLink={button?.fields.urlLink}
          function={button?.fields.function}
          size='lg'
          color={button?.fields.color?.fields.classname || 'terracota'}>
          {button?.fields.label?.fields.text}
        </NoozButton>
      </Center>
    </Flex>
  );
};

export default RetailPricing;
