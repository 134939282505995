import { ActionIcon, Indicator } from '@mantine/core';
import { useToggleCartDrawer } from '@/utils/context/drawer';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import React, { useEffect } from 'react';
import { useStyles } from '@/components/Header/HeaderAction/HeaderAction';
import useCartItems from '@/utils/hook/useCartItems';
import useGamification from '@/utils/hook/gamification';

const CartAction = () => {
  useGamification();
  const toggleCartDrawer = useToggleCartDrawer();
  const classes = useStyles().classes;
  const cartLineItems = useCartItems();
  const nbItems = cartLineItems.reduce((qty, item) => qty + item.quantity, 0);

  const [lineItemCount, setLineItemCount] = React.useState(0);

  useEffect(() => {
    setLineItemCount(cartLineItems?.length || 0);
  }, [cartLineItems]);

  return (
    <ActionIcon
      radius='xl'
      size='lg'
      variant='subtle'
      color='black'
      className={classes.button}
      onClick={toggleCartDrawer}>
      <Indicator
        label={nbItems}
        size={20}
        disabled={lineItemCount === 0}
        styles={{
          root: {
            display: 'flex',
          },
        }}>
        <NoozIcon
          iconKey={'ShoppingBag'}
          strokeWidth={1.3}
          size={24}
        />
      </Indicator>
    </ActionIcon>
  );
};
export default CartAction;
