import {
  Box,
  Container,
  Flex,
  Group,
  clsx,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import React, { useMemo } from 'react';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { Property } from 'csstype';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import Link from '@/components/Link/Link';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozButton from '../../Nooz/NoozButton/NoozButton';
import {
  IBadge,
  IBlock,
  IButton,
  IButtonFields,
  IImage,
  IJson,
  ILabel,
} from '@/@types/generated/contentful';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';
import useLocalizedSlug from '@/utils/hook/useLocalizedSlug';
import { useRouter } from 'next/router';

const useStyles = createStyles((theme) => {
  return {
    container: {
      position: 'relative',
      width: '100vw',
      padding: '0 !important',
      aspectRatio: '1600/650',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        aspectRatio: '3/4',
      },
    },
  };
});

interface HeroBannerProps {
  height?: string;
  labels?: IBlock;
  label?: ILabel;
  buttons?: IBlock;
  badges?: IBlock;
  image?: IImage;
  desktopImage?: IImage;
  vertical?: Property.AlignItems;
  horizontal?: Property.JustifyContent;
  config?: IJson;
}

const HeroBanner = ({
  labels,
  badges,
  image,
  desktopImage,
  buttons,
  height,
  config,
}: HeroBannerProps) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const computedConfig = useMemo(() => {
    const defaultConfig = { position: 'left' };

    return { ...defaultConfig, ...(config?.fields.value || {}) };
  }, [config]);
  const containerStyles = useContainerStyles();

  const desktopBackgroundImage = useMemo(
    () =>
      desktopImage?.fields.media?.fields.file.url ||
      image?.fields.media?.fields.file.url,
    [desktopImage, image],
  );
  const mobileBackgroundImage = useMemo(
    () => image?.fields.media?.fields.file.url,
    [image],
  );

  const localisedSlug = useLocalizedSlug(
    (buttons?.fields.items?.[0]?.fields as IButtonFields)?.slug?.fields
      ?.url as string,
  );
  const router = useRouter();

  return (
    <>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          if (
            (buttons?.fields.items?.[0]?.fields as IButtonFields)?.slug?.fields
              ?.url
          )
            router.push(localisedSlug);
        }}>
        <Container
          h={height || '100%'}
          sx={(theme) => ({
            display: 'flex',
            aspectRatio:
              (badges?.fields?.items && badges.fields.items.length > 0) ||
              (labels?.fields?.items && labels.fields.items.length > 0) ||
              (buttons?.fields?.items && buttons.fields.items.length > 0)
                ? undefined
                : '4/1 !important',
            alignItems: 'center',
            background: `no-repeat center/cover url("${desktopBackgroundImage}") ${theme.colors.dark[7]}`,
            [theme.fn.smallerThan('sm')]: {
              background: `no-repeat center/cover url("${mobileBackgroundImage}") ${theme.colors.dark[7]}`,
            },
          })}
          className={clsx(
            containerStyles.classes.container,
            classes.container,
            // @ts-ignore
            classes[computedConfig.position],
          )}>
          <Flex
            direction={'column'}
            p={{
              base: '1.5rem',
              xs: '2rem',
              md: '4rem',
            }}
            pr={{ base: '1.5rem', xs: '2rem', sm: 0 }}
            h={'100%'}
            w={{ base: '100%', sm: '50%' }}
            gap={28}
            justify={{ base: 'space-between', sm: 'flex-end' }}>
            <Flex
              direction={'column'}
              gap={{ base: 12, sm: 28 }}>
              <Group>
                {/* @ts-ignore */}
                {(badges?.fields?.items as IBadge[])?.map(
                  (badge: IBadge, index: number) => {
                    return (
                      <NoozBadge
                        key={index}
                        badgeProps={{
                          variant: badge.fields.color?.fields.gradientObject
                            ? 'gradient'
                            : badge.fields.type || 'filled',
                          size: badge.fields.size || 'lg',
                          gradient: badge.fields.color?.fields
                            .gradientObject as any,
                          color: badge.fields.color?.fields.gradientObject
                            ? undefined
                            : badge.fields.color?.fields.classname || 'dark',
                        }}
                        text={badge.fields.label.fields.text as string}
                        textProps={{
                          sx: {
                            display: 'flex',
                            margin: '0 8px',
                            gap: 12,
                            alignItems: 'center',
                          },
                          weight: 500,
                          size: 14,
                          lh: '12px',
                          transform: 'uppercase',
                          color:
                            badge.fields.label.fields.color?.fields.classname ||
                            'white',
                        }}
                        icon={
                          badge.fields.icon ? (
                            <NoozIcon
                              iconKey={badge.fields.icon.fields.iconKey}
                              size={16}
                              strokeWidth={1.5}
                              color={
                                theme.colors[
                                  badge.fields.icon.fields.color?.fields
                                    .classname as string
                                ]?.[7] || 'white'
                              }
                            />
                          ) : undefined
                        }
                      />
                    );
                  },
                )}
              </Group>
              <Flex
                id={'labels'}
                direction={'column'}
                gap={{ base: 6 }}>
                {(labels?.fields.items as ILabel[])?.map(
                  (label: ILabel, index: number) => {
                    // @ts-ignore
                    return (
                      <NoozText
                        m={0}
                        lh={1.2}
                        key={index}
                        sx={{
                          fontSize: (() => {
                            try {
                              return label?.fields.size
                                ? `${
                                    label.fields.size === 'base'
                                      ? 22
                                      : label.fields.size
                                  }px`
                                : '22px';
                            } catch (e) {
                              return '22px';
                            }
                          })(),
                          [theme.fn.smallerThan('xs')]: {
                            fontSize: (() => {
                              try {
                                return label?.fields.mobileSize ||
                                  label?.fields.size
                                  ? `${
                                      label.fields.mobileSize ||
                                      label?.fields.size
                                    }px`
                                  : '22px';
                              } catch (e) {
                                return '22px';
                              }
                            })(),
                          },
                        }}
                        italic={label?.fields.italic}
                        weight={label?.fields.weight || 400}
                        color={label.fields.color?.fields.classname || 'white'}
                        // @ts-ignore
                        family={label?.fields.family}
                        // @ts-ignore
                        dangerouslySetInnerHTML={{
                          __html: label?.fields.text,
                        }}></NoozText>
                    );
                  },
                )}
              </Flex>
            </Flex>

            {!buttons?.fields?.items ||
            buttons?.fields?.items.length === 0 ? null : (
              <Group
                align={'center'}
                sx={(theme) => ({
                  [theme.fn.smallerThan(theme.breakpoints.xs)]: {
                    justifyContent: 'center',
                  },
                })}>
                {buttons?.fields?.items?.slice(0, 1)?.map(
                  // @ts-ignore
                  (button: IButton, index: number) => {
                    if (typeof button.fields.slug?.fields?.url !== 'string')
                      return null;
                    // @ts-ignore
                    return (
                      <Link
                        key={index}
                        href={button.fields.slug?.fields.url as string}>
                        {/* @ts-ignore */}
                        <NoozButton
                          key={index}
                          {...button.fields}
                          variant={button.fields.type || 'filled'}
                          color={
                            button?.fields?.color?.fields?.classname || 'dark'
                          }
                          gradient={
                            button.fields.color?.fields.gradientObject as any
                          }
                          className={'cta-button'}
                          sx={
                            button?.fields?.label?.fields.color?.fields
                              .classname
                              ? // @ts-ignore
                                (theme) => ({
                                  color:
                                    theme.colors[
                                      // @ts-ignore
                                      button.fields.label.fields.color.fields
                                        .classname
                                    ],
                                })
                              : null
                          }>
                          {button?.fields?.label?.fields.text}
                        </NoozButton>
                      </Link>
                    );
                  },
                )}
              </Group>
            )}
          </Flex>
        </Container>
      </Box>
    </>
  );
};

export default HeroBanner;
