import { TIMING_FUNCTION } from '@/utils/style/animation';
import React, { CSSProperties, useState } from 'react';
import {
  Badge as UiBadge,
  BadgeProps,
  createStyles,
  clsx,
  TextProps,
  Transition,
  useMantineTheme,
  Center,
  Flex,
} from '@mantine/core';
import NoozText from '../NoozText/NoozText';

const useStyles = createStyles(() => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    textTransform: 'uppercase',
    '&.icon-hover': {
      margin: '0 13.333px',
    },
    '&:first-of-type:not(:last-of-type):not(.icon-hover)': {
      paddingRight: 8,
    },
    '&:last-of-type:not(:first-of-type):not(.icon-hover)': {
      paddingLeft: 8,
    },
  },
  textContained: {
    color: '#FFF !important',
  },
  blurBadge: {
    backgroundColor: 'rgb(255 255 255 / 0.3)',
    backdropFilter: 'blur(10px)',
    zIndex: 2,
  },
}));

export interface IBadgeProps {
  shouldAnimateText?: boolean;
  textProps?: TextProps;
  badgeProps?: BadgeProps;
  text: string | JSX.Element;
  icon?: JSX.Element;
  iconPosition?: 'left' | 'right';
  type?: string;
  color?: string;
}

const expandText = {
  in: { maxWidth: 250, opacity: 1 },
  out: { maxWidth: 0, opacity: 0 },
  common: { overflow: 'hidden' },
  transitionProperty: 'max-width, opacity',
};

const NoozBadge = ({
  shouldAnimateText = false,
  text,
  textProps,
  badgeProps = {},
  icon,
  iconPosition = 'left',
  type,
}: IBadgeProps) => {
  const [hovered, setHovered] = useState(false);
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const badgeSizeProps = shouldAnimateText ? { pl: 6, pr: 6 } : {};

  const badgePropsWithSize = {
    radius: 'xl',
    color: theme.colors.text[0],
    ...badgeProps,
    ...badgeSizeProps,
    className:
      badgeProps.variant === 'blurred'
        ? classes.blurBadge
        : badgeProps.className,
  };

  let badgeContent = text && (
    <NoozText
      key={'text'}
      size={textProps?.size || 10}
      weight={textProps?.weight || 500}
      className={clsx(
        classes.text,
        { [classes.textContained]: type === 'contained' },
        textProps?.className,
      )}
      {...textProps}>
      {text}
    </NoozText>
  );

  if (shouldAnimateText) {
    const transitionStyles = {
      ...expandText,
      ...(hovered ? expandText.in : expandText.out),
    };

    badgeContent = text && (
      <Transition
        key={'text'}
        mounted={hovered}
        transition={transitionStyles}
        duration={500}
        timingFunction={TIMING_FUNCTION}>
        {(styles) => (
          <NoozText
            {...textProps}
            style={{ ...styles, ...(textProps?.style || {}) }}
            size={textProps?.size || 10}
            weight={textProps?.weight || 500}
            className={clsx(
              classes.text,
              icon && shouldAnimateText && 'icon-hover',
              type === 'contained' && classes.textContained,
              textProps?.className,
            )}>
            {text}
          </NoozText>
        )}
      </Transition>
    );
  }

  return (
    <UiBadge
      onMouseEnter={shouldAnimateText ? () => setHovered(true) : undefined}
      onMouseLeave={shouldAnimateText ? () => setHovered(false) : undefined}
      {...badgePropsWithSize}>
      <div className={classes.content}>
        {icon && iconPosition === 'left' ? <Center>{icon}</Center> : null}
        {badgeContent && badgeContent}
        {icon && iconPosition === 'right' ? <Center>{icon}</Center> : null}
      </div>
    </UiBadge>
  );
};
export const BasicNoozBadge = ({
  color,
  text,
  children,
  height,
  size = 12,
  cssProps,
}: {
  color: string;
  text?: string;
  children?: any;
  size?: number | string;
  height?: number | string;
  cssProps?: CSSProperties;
}) => (
  <Flex
    align={'center'}
    sx={(theme) => ({
      borderWidth: 1,
      padding: '6px 12px',
      borderRadius: 100,
      borderStyle: 'solid',
      borderColor: theme.colors[color]?.[0] || color,
      color: theme.colors[color]?.[0] || color,
      fontSize: size,
      backgroundColor: 'white',
      height,
      width: 'fit-content',
    })}
    style={cssProps}>
    {text || children}
  </Flex>
);

export default NoozBadge;
