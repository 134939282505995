import { DrawerContext, IDrawerContext } from '@/utils/context/drawer';
import {
  createStyles,
  Drawer as DrawerM,
  useMantineTheme,
} from '@mantine/core';
import { useContext } from 'react';
import { motion } from 'framer-motion';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';

export const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: 'easeOut', duration: 0.6, delay: 0.2 },
};

export const useDrawerOverlayColor = () => {
  const theme = useMantineTheme();
  return {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[9]
        : theme.colors.gray[2],
  };
};

//@ts-ignore
export const useStyles = createStyles((theme, { headerHeight }) => {
  return {
    inner: {
      height: `calc(100vh - ${headerHeight}px)`,
      top: headerHeight,
      '* > .mantine-Drawer-body': {
        // height: '100%',
        overflow: 'auto',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
});

const Drawer = () => {
  const headerHeight = useHeaderHeight();
  const overlayProps = useDrawerOverlayColor();

  //@ts-ignore
  const { classes } = useStyles({ headerHeight: headerHeight.wrapper });
  const { drawer, resetDrawer } = useContext(DrawerContext) as IDrawerContext;

  return (
    <DrawerM
      {...drawer}
      overlayProps={drawer.overlayProps || overlayProps}
      onClose={drawer.onClose || resetDrawer}
      classNames={drawer.classNames || classes}>
      <motion.div
        {...motionProps}
        style={{
          height: '100%',
        }}>
        {drawer.body}
      </motion.div>
    </DrawerM>
  );
};

export default Drawer;
