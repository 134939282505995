import React from 'react';
import { Box, clsx, createStyles, Flex, Group } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { useMemo } from 'react';
import {
  IBadge,
  IBlock,
  IImage,
  ILabel,
  IPath,
} from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import { Carousel } from '@mantine/carousel';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { useCarouselListIndicatorStyles } from '@/components/Retail/RetailProductExplo';
import useScrollbarClasses from '@/utils/useScrollbarClasses';
import Link from '@/components/Link/Link';
import { Star } from 'tabler-icons-react';

const MOBILE_BREAKPOINT = 'sm';

const useClasses = () =>
  createStyles((theme) => ({
    container: {
      flexDirection: 'row',
      gap: 30,
      [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
        flexDirection: 'column',
        gap: 20,
      },
    },
    titles: {
      marginLeft: '64px',
      padding: '24px 0',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      gap: 12,
      flex: 1,

      [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
        display: 'none',
      },
    },
    label: {
      fontSize: 42,
      fontWeight: 700,
      cursor: 'pointer',
      color: theme.colors.titleDimmed[0],
      '&.active': {
        color: theme.colors.title[0],
        fontWeight: 900,
      },
    },
    carousel: {
      flex: 2,
      '& > div': {
        width: '100%',
      },
    },
  }))().classes;

interface Card {
  label?: ILabel;
  subLabel?: ILabel;
  nbStart?: ILabel;
  link?: IPath;
}

const TrustpilotReviews = ({
  readReview,
  element,
  reviews,
}: {
  readReview: ILabel;
  element: IBlock;
  reviews: IBlock;
}) => {
  const trustPilotCard = useMemo(() => {
    return formatCfItems(element) as Card;
  }, [element]);
  const reviewCards = useMemo(() => {
    return (reviews.fields.items || []).map((item) => formatCfItems(item));
  }, [reviews]);
  const classes = useClasses();
  const scrollbarClasses = useScrollbarClasses();
  const classnames = {
    ...useCarouselIndicatorsStyles({}).classes,
    ...useCarouselListIndicatorStyles().classes,
  };

  const carouselProps = {
    classNames: classnames,
    dragFree: false,
    loop: true,
    withControls: true,
    withIndicators: false,
    nextControlIcon: (
      <NoozIcon
        size={28}
        color='black'
        iconKey='ChevronRight'
      />
    ),
    previousControlIcon: (
      <NoozIcon
        size={28}
        color='black'
        iconKey='ChevronLeft'
      />
    ),
    height: '100%',
  };

  return (
    <>
      <Flex className={clsx(classes.container)}>
        <Flex className={clsx(classes.titles, scrollbarClasses.noScrollbar)}>
          <Group spacing={8}>
            {[1, 2, 3, 4, 5].map((key) => (
              <Star
                key={key}
                color={'#51B37F'}
                fill={'#51B37F'}
                size={16}
                strokeWidth={2}
              />
            ))}
          </Group>
          {trustPilotCard.label ? (
            <NoozText
              size={42}
              weight={900}
              color={'title'}>
              {trustPilotCard.label?.fields.text}
            </NoozText>
          ) : null}
          {trustPilotCard.subLabel ? (
            <NoozText
              size={22}
              weight={500}
              color={'text'}>
              {trustPilotCard.subLabel?.fields.text}
            </NoozText>
          ) : null}
        </Flex>
        <Flex className={classes.carousel}>
          <Carousel
            {...carouselProps}
            align={'start'}
            loop={true}
            slideGap={20}
            sx={(theme) => ({
              [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
                display: 'none',
              },
            })}
            styles={{
              controls: {
                padding: '0 !important',
                left: -12,
                right: 12,
              },
            }}
            slideSize={'40%'}>
            {reviewCards.map((card, key) => (
              <Carousel.Slide
                key={key}
                w={'100%'}
                h={'100%'}
                p={10}
                sx={{
                  cursor: 'pointer',
                  transition: 'all .2s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                }}>
                <Link href={(card.link as IPath)?.fields.url as string}>
                  <Box
                    w={'100%'}
                    h={'100%'}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '40px 30px 30px',
                      borderRadius: 20,
                      border: '1px solid #DCE2F2',
                    }}>
                    <Group
                      spacing={2}
                      mb={12}>
                      {[1, 2, 3, 4, 5].map((key) => (
                        <Flex
                          key={key}
                          align={'center'}
                          justify={'center'}
                          sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: '#51B37F',
                          }}>
                          <Star
                            color={'#FFFFFF'}
                            fill={'#FFFFFF'}
                            size={16}
                            strokeWidth={2}
                          />
                        </Flex>
                      ))}
                    </Group>
                    {card.label ? (
                      <NoozText
                        size={22}
                        weight={700}
                        color={'title'}>
                        {card.label?.fields.text}
                      </NoozText>
                    ) : null}
                    {card.subLabel ? (
                      <NoozText
                        size={10}
                        weight={900}
                        color={'black.4'}
                        mt={12}
                        transform={'uppercase'}>
                        {card.subLabel?.fields.text}
                      </NoozText>
                    ) : null}
                    <NoozText
                      size={10}
                      weight={900}
                      color={'greenReview'}
                      mt={12}
                      underline
                      transform={'uppercase'}>
                      {readReview?.fields.text}
                    </NoozText>
                  </Box>
                </Link>
              </Carousel.Slide>
            ))}
          </Carousel>
          <Carousel
            {...carouselProps}
            align={'center'}
            withControls
            withIndicators={true}
            slideGap={20}
            sx={(theme) => ({
              display: 'none',
              [theme.fn.smallerThan(theme.breakpoints[MOBILE_BREAKPOINT])]: {
                display: 'block',
              },
            })}
            styles={{
              root: {
                marginBottom: '1.5rem',
              },
              indicators: {
                bottom: '-1.5rem',
              },
            }}
            slideSize={'70%'}>
            <Carousel.Slide w={'100%'}>
              <Box
                w={'100%'}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '40px 30px 20px',
                  borderRadius: 20,
                  border: '1px solid #DCE2F2',
                  backgroundColor: '#F5F5F5',
                }}>
                <Group>
                  {[1, 2, 3, 4, 5].map((key) => (
                    <NoozIcon
                      key={key}
                      iconKey={'Star'}
                      color={'#51B37F'}
                      size={12}
                      strokeWidth={2}
                    />
                  ))}
                </Group>
                {trustPilotCard.label ? (
                  <NoozText
                    size={34}
                    weight={900}
                    color={'title'}>
                    {trustPilotCard.label?.fields.text}
                  </NoozText>
                ) : null}
                {trustPilotCard.subLabel ? (
                  <NoozText
                    size={14}
                    weight={500}
                    mt={12}
                    color={'text'}>
                    {trustPilotCard.subLabel?.fields.text}
                  </NoozText>
                ) : null}
              </Box>
            </Carousel.Slide>
            {reviewCards.map((card, key) => (
              <Carousel.Slide
                key={key}
                w={'100%'}
                h={'100%'}>
                <Box
                  w={'100%'}
                  h={'100%'}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '40px 30px 20px',
                    borderRadius: 20,
                    border: '1px solid #DCE2F2',
                    backgroundColor: '#F5F5F5',
                  }}>
                  <Group
                    spacing={2}
                    mb={12}>
                    {[1, 2, 3, 4, 5].map((key) => (
                      <Flex
                        key={key}
                        align={'center'}
                        justify={'center'}
                        sx={{
                          width: 20,
                          height: 20,
                          backgroundColor: '#51B37F',
                        }}>
                        <Star
                          color={'#FFFFFF'}
                          fill={'#FFFFFF'}
                          size={16}
                          strokeWidth={2}
                        />
                      </Flex>
                    ))}
                  </Group>
                  {card.label ? (
                    <NoozText
                      size={14}
                      weight={700}
                      color={'title'}>
                      {card.label?.fields.text}
                    </NoozText>
                  ) : null}
                  {card.subLabel ? (
                    <NoozText
                      size={10}
                      weight={900}
                      color={'black.4'}
                      mt={12}
                      transform={'uppercase'}>
                      {card.subLabel?.fields.text}
                    </NoozText>
                  ) : null}
                  <NoozText
                    size={10}
                    weight={900}
                    color={'greenReview'}
                    mt={12}
                    underline
                    transform={'uppercase'}>
                    {readReview?.fields.text}
                  </NoozText>
                </Box>
              </Carousel.Slide>
            ))}
          </Carousel>
        </Flex>
      </Flex>
    </>
  );
};

export default TrustpilotReviews;
