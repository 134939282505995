import usePageContext from '@/utils/context/page';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import NoozNextApi from '@/utils/services/NoozNextApi';
import { ShopItem } from '@/@types/shopitem';

export enum SHOP_ITEMS_ACTION {
  // eslint-disable-next-line no-unused-vars
  RESET,
  // eslint-disable-next-line no-unused-vars
  LOAD_FULL_RESOURCE,
  // eslint-disable-next-line no-unused-vars
  SET_FILTER,
  // eslint-disable-next-line no-unused-vars
  SHOW_MORE,
}
interface ShopItemsObj {
  quantity: number;
  items: ShopItem[];
  quantityToShow: number;
}
interface IShopItemsContext {
  isFeatured: boolean;
  base: ShopItemsObj;
  filtered: ShopItemsObj;
  activeFilters: Record<string, any>;
  nbFilters: number;
}
export const SHOP_ITEM_QTY_PER_PAGE = 20;
export const ShopItemsContext = createContext<IShopItemsContext>({
  isFeatured: true,
  base: {
    quantity: 0,
    items: [],
    quantityToShow: SHOP_ITEM_QTY_PER_PAGE,
  },
  filtered: {
    quantity: 0,
    items: [],
    quantityToShow: SHOP_ITEM_QTY_PER_PAGE,
  },
  activeFilters: {},
  nbFilters: 0,
});

export const ShopItemsDispatchContext = createContext<
  React.Dispatch<REDUCER_ACTION>
>(null as unknown as React.Dispatch<REDUCER_ACTION>);

export const useFilteredShopItems = () => {
  const ctx = useContext(ShopItemsContext);
  return ctx.filtered;
};
export const useShopItems = () => useContext(ShopItemsContext);
export const useShopItemsDispatch = () => useContext(ShopItemsDispatchContext);
type RESET_ACTION = {
  type: SHOP_ITEMS_ACTION.RESET;
};
type LOAD_FULL_RESOURCE_ACTION = {
  type: SHOP_ITEMS_ACTION.LOAD_FULL_RESOURCE;
  payload: any;
};
type SET_FILTER_ACTION = {
  type: SHOP_ITEMS_ACTION.SET_FILTER;
  payload: {
    attribute: string;
    value: any;
  };
};
type SHOW_MORE_ACTION = {
  type: SHOP_ITEMS_ACTION.SHOW_MORE;
};
type REDUCER_ACTION =
  | RESET_ACTION
  | LOAD_FULL_RESOURCE_ACTION
  | SET_FILTER_ACTION
  | SHOW_MORE_ACTION;

const filterBaseList = (
  baseList: ShopItemsObj,
  filters: Record<string, any>,
  nbFilters: number,
  isFeatured: boolean,
) => {
  const activeFilters =
    isFeatured && nbFilters === 0 ? { isFeatured: [true] } : filters;

  return structuredClone(baseList.items).filter((item, keyFilter) => {
    if (item.pack) return nbFilters === 0;
    if (nbFilters === 0 && !isFeatured) return true;

    for (let [key, values] of Object.entries(activeFilters)) {
      if (values.length > 0) {
        let pointer: any = item;
        for (let el of key.split('.')) {
          // @ts-ignore
          pointer = pointer?.[el];
        }

        if (
          [undefined, null].includes(pointer) ||
          // vérifie que l'élément existe dans une liste
          (['string', 'boolean'].includes(typeof pointer) &&
            !Array.isArray(pointer) &&
            !values.includes(pointer)) ||
          // vérifie qu'au moins un élément, d'une liste (ex: sexe peut-être ['Male', 'Female']), existe dans la liste du filtre
          (Array.isArray(pointer) &&
            !pointer.reduce?.((condition, value) => {
              if (values.includes(value)) return true;
              return condition;
            }, false))
        ) {
          return false;
        }
      }
    }
    return true;
  });
};

export const reducer = (
  state: IShopItemsContext,
  action: REDUCER_ACTION,
): IShopItemsContext => {
  switch (action.type) {
    case SHOP_ITEMS_ACTION.SHOW_MORE: {
      const clone = structuredClone(state);
      const listKey = clone.filtered ? 'filtered' : 'base';
      (clone[listKey] as ShopItemsObj).quantityToShow += SHOP_ITEM_QTY_PER_PAGE;
      return clone;
    }
    case SHOP_ITEMS_ACTION.RESET:
      const resetList = filterBaseList(state.base, {}, 0, state.isFeatured);

      if (typeof window !== 'undefined') {
        let initialSp = new URLSearchParams(
          window.location.href.split('?')[1] || '',
        );
        let url = new URL(window.location.href.split('?')[0]);
        initialSp.forEach((value, key) => {
          if (
            !/^hue$/.test(key) &&
            !/^material$/.test(key) &&
            !/^sexe$/.test(key) &&
            !/^shape$/.test(key) &&
            !/^style$/.test(key) &&
            !/^product\./.test(key)
          ) {
            url.searchParams.append(key, value);
          }
        });
        history.replaceState(history.state, '', url.href);
      }

      return {
        ...state,
        base: { ...state.base, quantityToShow: SHOP_ITEM_QTY_PER_PAGE },
        nbFilters: 0,
        activeFilters: {},
        filtered: {
          quantity: resetList.length,
          items: resetList,
          quantityToShow: SHOP_ITEM_QTY_PER_PAGE,
        },
      };
    case SHOP_ITEMS_ACTION.SET_FILTER:
      const activeFilters: Record<string, any> = structuredClone(
        state.activeFilters,
      );

      let url = new URL(window.location.href);

      if (!activeFilters[action.payload.attribute]) {
        activeFilters[action.payload.attribute] = [action.payload.value];
      } else if (
        !activeFilters[action.payload.attribute]?.includes(action.payload.value)
      ) {
        activeFilters[action.payload.attribute].push(action.payload.value);
      } else {
        activeFilters[action.payload.attribute] = activeFilters[
          action.payload.attribute
        ].filter((f: any) => f !== action.payload.value);
      }

      const nbFilters = Object.values(activeFilters).reduce(
        (count, values) => count + values.length,
        0,
      );

      url.searchParams.delete(action.payload.attribute);
      if (activeFilters[action.payload.attribute].length > 0)
        url.searchParams.append(
          action.payload.attribute,
          activeFilters[action.payload.attribute].join(','),
        );
      history.replaceState(history.state, '', url.href);
      const list = filterBaseList(
        state.base,
        activeFilters,
        nbFilters,
        state.isFeatured,
      );
      return {
        ...state,
        nbFilters,
        activeFilters,
        filtered: {
          quantity: list.length,
          items: list,
          quantityToShow: 20,
        },
      };
    case SHOP_ITEMS_ACTION.LOAD_FULL_RESOURCE: {
      const clone = structuredClone(state);
      clone.base = {
        ...action.payload,
        quantityToShow: clone.base.quantityToShow,
      };
      const list = filterBaseList(
        clone.base,
        clone.activeFilters,
        clone.nbFilters,
        clone.isFeatured,
      );
      clone.filtered = {
        quantity: list.length,
        items: list,
        quantityToShow: clone.filtered.quantityToShow,
      };
      return clone;
    }
    default:
      return state;
  }
};

const ShopItemProvider = ({ children }: PropsWithChildren) => {
  const { shopitems, page, productCategory, locale } = usePageContext();
  const [items, dispatch] = useReducer(
    reducer,
    {
      base: { ...shopitems, quantityToShow: SHOP_ITEM_QTY_PER_PAGE },
      activeFilters: {},
      nbFilters: 0,
    },
    () => {
      let nbFilters = 0,
        activeFilters: Record<string, string[]> = {},
        filtered = { ...shopitems, quantityToShow: SHOP_ITEM_QTY_PER_PAGE };
      if (typeof window !== 'undefined') {
        if (window.location.search) {
          const sp = new URLSearchParams(window.location.search);
          sp.forEach((value: string, key: string) => {
            if (
              /^hue$/.test(key) ||
              /^material$/.test(key) ||
              /^sexe$/.test(key) ||
              /^shape$/.test(key) ||
              /^style$/.test(key) ||
              /^product\./.test(key)
            ) {
              activeFilters[
                key.replace(
                  /product\.fields\.category\.fields\.title/g,
                  'product.categoryTitle',
                )
              ] = decodeURIComponent(
                value
                  .replace(/^\?/, '')
                  .replace(/\+/g, ' ')
                  .replace(/%2B/g, ' ')
                  .replace(/%2C/g, ','),
              ).split(',');
            }
          });
          nbFilters = Object.values(activeFilters).reduce((count, list) => {
            return count + list.length;
          }, 0);
          if (nbFilters > 0) {
            const list = filterBaseList(
              shopitems as unknown as ShopItemsObj,
              activeFilters,
              nbFilters,
              false,
            );
            filtered.items = list;
            filtered.quantity = list.length;
          }
        }
      }
      return {
        isFeatured: (page?.fields as any)?.pageType?.type !== 'ProductFacet',
        base: { ...shopitems, quantityToShow: SHOP_ITEM_QTY_PER_PAGE },
        filtered,
        activeFilters,
        nbFilters,
      };
    },
  );

  useEffect(() => {
    const path = `/json/shopitems/${
      (page?.fields as any).pageType.type === 'ProductFacet'
        ? (page as any).id
        : (productCategory as any).id
    }-${locale}.json`;

    NoozNextApi.instance.get(path).then(({ data }) => {
      dispatch({ type: SHOP_ITEMS_ACTION.LOAD_FULL_RESOURCE, payload: data });
    });
  }, [productCategory, page]);

  return (
    <ShopItemsContext.Provider value={items}>
      <ShopItemsDispatchContext.Provider value={dispatch}>
        {children}
      </ShopItemsDispatchContext.Provider>
    </ShopItemsContext.Provider>
  );
};

export default ShopItemProvider;
