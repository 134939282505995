import React, { useEffect } from 'react';
import { shopstoryConfig } from '@/config/shopstory/config';
import { IShopstoryBlock } from '@/@types/generated/contentful';
import { ShopstoryClient } from '@shopstory/core';
import usePageContext from '../context/page';
import LocaleHelper from '@/helpers/Locale';

function useShopstoryClient(shopstoryBlock: IShopstoryBlock) {
  const { locale } = usePageContext();
  const [renderableContent, setRenderableContent] = React.useState<any>(null);
  const [meta, setMeta] = React.useState<any>(null);

  useEffect(() => {
    async function fetchData() {
      const shopstoryClient = new ShopstoryClient(shopstoryConfig, {
        locale: LocaleHelper.getValidContentfulLocale(
          locale as string,
        ) as string,
        contentful: { preview: false },
      });

      setRenderableContent(shopstoryClient.add(shopstoryBlock?.fields.content));
      setMeta(await shopstoryClient.build());

      return { renderableContent, meta };
    }
    fetchData();
  }, [shopstoryBlock]);

  return { renderableContent, meta };
}

export default useShopstoryClient;
