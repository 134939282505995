import React from 'react';
import {
  Box,
  Center,
  Container,
  Grid,
  Group,
  MediaQuery,
  clsx,
  createStyles,
} from '@mantine/core';
import HighlightCard from '../Cards/HighlightCard';
import { formatCfItems } from '@/helpers/formatCfItems';
import { useContainerStyles } from '@/utils/style/useContainer';
import {
  IBlock,
  IBlogArticle,
  IColor,
  ILabel,
  ILink,
} from '@/@types/generated/contentful';
import NoozText from '../Nooz/NoozText/NoozText';
import GridSlider from '../GridSlider/GridSlider';
import { useGridSliderHighlightCardTryptiqueProps } from '@/utils/hook/useGridSliderHighlightCardProps';
import { Carousel } from '@mantine/carousel';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';
import NoozButton from '../Nooz/NoozButton/NoozButton';
import Link from '../Link/Link';
import TextLink from '../Link/TextLink';
import usePageContext from '@/utils/context/page';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: 42,
    fontWeight: 500,
    [theme.fn.smallerThan('md')]: {
      fontSize: 28,
      fontWeight: 600,
    },
  },
}));

const useSliderStyles = createStyles((theme) => ({
  indicators: {
    bottom: -30,
  },
}));

export function FeaturedCardNavigation({
  list,
  link,
  title,
  label,
  backgroundColor,
  color,
  padding,
  originalBadge,
}: {
  list: IBlock;
  link: ILink;
  title: ILabel;
  label?: ILabel;
  backgroundColor?: string;
  color: string;
  padding?: string;
  originalBadge?: boolean;
}) {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();

  const returnGridColumns = () => {
    switch (list.fields.items?.length) {
      case 3:
        return list.fields.items?.map((item: any, index: number) => {
          return (
            <Grid.Col
              key={index}
              span={12}
              md={4}>
              <Box h={600}>
                <HighlightCard
                  {...formatCfItems(item)}
                  color={(formatCfItems(item)?.color as IColor)?.fields?.hex}
                />
              </Box>
            </Grid.Col>
          );
        });
      case 4:
        return (
          <>
            <Grid.Col
              span={12}
              md={4}>
              <Box h={600}>
                <HighlightCard {...formatCfItems(list.fields.items?.[0])} />
              </Box>
            </Grid.Col>
            <Grid.Col
              span={12}
              md={4}>
              <Box h={600}>
                <HighlightCard {...formatCfItems(list.fields.items?.[1])} />
              </Box>
            </Grid.Col>
            <Grid.Col
              span={12}
              md={4}>
              <Box
                h={285}
                mb={30}>
                <HighlightCard {...formatCfItems(list.fields.items?.[2])} />
              </Box>
              <Box h={285}>
                <HighlightCard {...formatCfItems(list.fields.items?.[3])} />
              </Box>
            </Grid.Col>
          </>
        );
      case 5:
        return (
          <>
            <Grid.Col
              span={12}
              md={4}>
              <Box
                h={285}
                mb={30}>
                <HighlightCard
                  {...formatCfItems(list.fields.items?.[0])}
                  animationVariant='zoom'
                />
              </Box>
              <Box h={285}>
                <HighlightCard {...formatCfItems(list.fields.items?.[1])} />
              </Box>
            </Grid.Col>
            <Grid.Col
              span={12}
              md={4}>
              <Box h={600}>
                <HighlightCard {...formatCfItems(list.fields.items?.[2])} />
              </Box>
            </Grid.Col>
            <Grid.Col
              span={12}
              md={4}>
              <Box
                h={285}
                mb={30}>
                <HighlightCard {...formatCfItems(list.fields.items?.[3])} />
              </Box>
              <Box h={285}>
                <HighlightCard {...formatCfItems(list.fields.items?.[4])} />
              </Box>
            </Grid.Col>
          </>
        );
      default:
        return '';
    }
  };

  return (
    <Box sx={{ background: backgroundColor, color: color, padding: padding }}>
      <Container className={clsx(containerStyles.classes.container)}>
        <Group
          align='center'
          position='apart'
          mb={{ base: 20, md: 40 }}>
          {title || label ? (
            <NoozText
              component={'h3'}
              m={0}
              className={classes.label}>
              {(label || title)?.fields.text}
            </NoozText>
          ) : null}

          <MediaQuery
            smallerThan={'md'}
            styles={{ display: 'none' }}>
            <span>
              {(title || label) && link?.fields.slug?.fields.url && (
                <TextLink
                  type='animated-underline'
                  size={18}
                  text={link?.fields.label?.fields.text}
                  url={link?.fields.slug?.fields.url as string}
                />
              )}
            </span>
          </MediaQuery>
        </Group>
      </Container>
      <MediaQuery
        largerThan={'md'}
        styles={{ display: 'none' }}>
        <span>
          <Carousel
            {...CAROUSEL_PROPS}
            loop={true}
            align={'center'}
            slideSize='90%'
            classNames={{
              ...useSliderStyles().classes,
              ...useCarouselIndicatorsStyles({}).classes,
            }}
            withControls={false}
            withIndicators={true}
            nextControlIcon={<></>}
            previousControlIcon={<></>}
            height={'100%'}>
            {list.fields.items?.map((item: any, index: number) => {
              return (
                <Carousel.Slide key={index}>
                  <Box h={520}>
                    <HighlightCard
                      {...formatCfItems(item)}
                      originalBadge={originalBadge}
                      radius='md'
                    />
                  </Box>
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </span>
      </MediaQuery>
      <MediaQuery
        smallerThan={'md'}
        styles={{ display: 'none' }}>
        <span>
          <Container className={clsx(containerStyles.classes.container)}>
            <Grid gutter={30}>{returnGridColumns()}</Grid>
          </Container>
        </span>
      </MediaQuery>
      <MediaQuery
        largerThan={'md'}
        styles={{ display: 'none' }}>
        <span>
          {title && link?.fields.slug?.fields.url && (
            <Center mt={60}>
              <Link href={link?.fields.slug?.fields.url as string}>
                <NoozButton
                  size='md'
                  color='dark'>
                  {link?.fields.label?.fields.text}
                </NoozButton>
              </Link>
            </Center>
          )}
        </span>
      </MediaQuery>
    </Box>
  );
}

export function FeaturedCardCarousel({ list }: { list: IBlock }) {
  return (
    <Carousel
      {...CAROUSEL_PROPS}
      loop={true}
      align={'center'}
      slideSize='90%'
      classNames={{
        ...useSliderStyles().classes,
        ...useCarouselIndicatorsStyles({}).classes,
      }}
      withControls={false}
      withIndicators={true}
      nextControlIcon={<></>}
      previousControlIcon={<></>}
      height={'100%'}>
      {list.fields.items?.map((item: any, index: number) => {
        return (
          <Carousel.Slide key={index}>
            <Box h={520}>
              <HighlightCard
                {...formatCfItems(item)}
                radius='md'
              />
            </Box>
          </Carousel.Slide>
        );
      })}
    </Carousel>
  );
}

export function FeaturedBlogArticlesNavigation({
  list,
  label,
}: {
  list: IBlock;
  label: ILabel;
}) {
  const { locale } = usePageContext();
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();
  const sliderProps = useGridSliderHighlightCardTryptiqueProps();

  // @ts-ignore
  const props = list?.fields.items?.map((article: IBlogArticle) => {
    return {
      ...article.fields,
      subLabel: null,
      image: article.fields.posterImage,
      textSize: {
        label: {
          weight: 500,
          size: {
            base: 28,
            xs: 24,
          },
        },
        subLabel: {
          weight: 300,
          size: {
            base: 18,
            xs: 14,
          },
        },
      },
    };
  });

  return (
    <Box
      sx={(theme) => ({ background: theme.colors.dark[9], color: 'white' })}
      py={80}>
      <Container className={containerStyles.classes.container}>
        {label && (
          <NoozText
            mb={40}
            className={classes.label}
            sx={{
              color: 'inherit',
            }}>
            {label?.fields.text}
          </NoozText>
        )}
      </Container>
      <GridSlider
        {...sliderProps}
        breakpoint='md'
        // @ts-ignore
        items={props}
      />
    </Box>
  );
}
