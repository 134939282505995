import { useMemo } from 'react';
import useCommerceContext from '@/utils/context/commerce';
import { ICartLine } from '@/interface.custom';

const useCartItems = () => {
  const { cart } = useCommerceContext();
  // @ts-ignore
  const cartLineItems = useMemo(
    () => (cart ? cart.lineItems : []),
    [cart?.lineItems],
  );

  return useMemo(() => {
    return cartLineItems.map((item) => {
      // @ts-ignore
      const formatted: ICartLine = {
        ...item,
      };
      item.customAttributes.forEach(({ key, value }) => {
        try {
          if (value) {
            // @ts-ignore
            formatted[key] = ['__itemId', '__itemType'].includes(key)
              ? value
              : (() => {
                  try {
                    return JSON.parse(value);
                  } catch (e) {
                    return value;
                  }
                })();
          }
        } catch (e) {
          console.error(e);
        }
      });
      formatted.lineItems = [formatted as ICartLine];

      return formatted;
    });
  }, [cartLineItems]);
};

export default useCartItems;
