import React, { useMemo } from 'react';
import { Box, Container } from '@mantine/core';
import GridSlider from '@/components/GridSlider/GridSlider';
import usePageContext from '@/utils/context/page';
import { useContainerStyles } from '@/utils/style/useContainer';
import { NoozSectionTitle } from '@/components/Nooz/NoozTitle/NoozTitle';
import { useGridSliderHighlightCardTryptiqueProps } from '@/utils/hook/useGridSliderHighlightCardProps';
import useStaticContent from '@/utils/hook/useStaticContent';

const CategoryNavigation = () => {
  const staticContent = useStaticContent();
  const { topProductCategories, productCategory } = usePageContext();
  const containerStyles = useContainerStyles();

  const sliderProps = useGridSliderHighlightCardTryptiqueProps();

  const categories = useMemo(() => {
    if (!topProductCategories) return null;
    if (productCategory) {
      return (
        topProductCategories
          // @ts-ignore
          .filter((pc) => pc.id !== productCategory.id)
          .slice(0, 3)
      );
    }
    return topProductCategories.sort(() => Math.random() - 0.5).slice(0, 3);
  }, [topProductCategories]);

  if (!topProductCategories?.length) {
    return null;
  }

  return categories ? (
    <Box py={80}>
      <Container className={containerStyles.classes.container}>
        <NoozSectionTitle>
          {staticContent.exploreMoreCollections}
        </NoozSectionTitle>
      </Container>

      <GridSlider
        {...sliderProps}
        items={categories}
      />
    </Box>
  ) : null;
};

export default CategoryNavigation;
