import React, { createContext, useContext, useState } from 'react';

type ICookieBannerContext = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CookieBannerContext = createContext<ICookieBannerContext>({
  opened: false,
  setOpened: () => {},
});

const useCookieBannerContext = (): ICookieBannerContext => {
  return useContext(CookieBannerContext);
};

export const CookieBannerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <CookieBannerContext.Provider value={{ opened, setOpened }}>
      {children}
    </CookieBannerContext.Provider>
  );
};
export default useCookieBannerContext;
