import React, { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Center, LoadingOverlay } from '@mantine/core';

interface ErrorBoundaryProps {
  children: ReactNode;
  locale?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class AppBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  locale?: string = undefined;
  constructor(props: ErrorBoundaryProps) {
    super(props);

    // Define a state variable to track whether there is an error or not
    this.state = { hasError: false };
    this.locale = props.locale;
  }

  componentDidUpdate(
    prevProps: Readonly<ErrorBoundaryProps>,
    prevState: Readonly<ErrorBoundaryState>,
    snapshot?: any,
  ) {
    if (prevProps.locale) this.locale = prevProps.locale;
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can use your own error logging service here
    if (typeof window !== 'undefined') {
      // Sentry.captureMessage(
      //   'Something went wrong on ' + document.location.href,
      // );
      // Sentry.captureException(error);
      // window.location.href = `${window.location.origin}${
      //   this.locale ? `/${this.locale}` : ''
      // }/404${
      //   window.location.pathname
      //     ? `?from=${encodeURIComponent(window.location.pathname)}`
      //     : ''
      // }`;
    }
  }

  render(): ReactNode {
    // Check if an error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <LoadingOverlay
          visible={true}
          overlayBlur={2}
        />
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default AppBoundary;
