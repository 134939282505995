import React, { ReactNode } from 'react';
import { AppProps } from 'next/app';
import { Box, Flex } from '@mantine/core';
import { Header } from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
import Drawer from '@/components/Drawer/Drawer';
import { Dialog } from '@/components/Dialog/Dialog';
import { CookiesBanner } from '@/components/CookieBanner/CookieBanner';
import AppHead from '@/components/AppHead/AppHead';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import LoadingProgress from '@/components/Progress/LoadingProgress';
import AppProvider from '@/components/AppProvider/AppProvider';
import Popup from '@/components/Popup/Popup';
import { HomeSchemaOrgJSON } from '@/helpers/SchemaOrgJsonLd';
import useScrollRestoration from '@/utils/hook/useScrollPosition';
import AppBoundary from '@/components/ErrorBoundary/AppBoundary';
import { Modal } from '@/components/Modal/Modal';
import { LoadingOverlayProvider } from '@/utils/context/loadingOverlay';
import Cookie from '@/helpers/Cookie';
import { APPLY_DISCOUNT_PRODUCT_FACET } from '@/templates/ProductCategory';
import useHasDefaultHeader from '@/utils/hook/cart/useHasDefaultHeader';

const ComponentWrapper = ({
  children,
}: {
  children: ReactNode;
  isShopstory: boolean;
}) => {
  const hasDefaultHeader = useHasDefaultHeader();
  const { marginTop, wrapper } = useHeaderHeight();

  return (
    <Box
      id={'content'}
      // component='span'
      sx={{
        marginTop: hasDefaultHeader ? marginTop + wrapper : 0,
        flex: 1,
      }}>
      {children}
    </Box>
  );
};

const NoozApp = ({ Component, pageProps, router }: AppProps) => {
  useScrollRestoration(router);
  if (
    router.asPath.includes('/shopstory-canvas') ||
    router.asPath.includes('/redirect-multipass') ||
    router.asPath.includes('/auth')
  ) {
    return (
      <AppProvider pageProps={pageProps}>
        <Component {...pageProps} />
      </AppProvider>
    );
  }
  Cookie.delete(APPLY_DISCOUNT_PRODUCT_FACET);
  return (
    <LoadingOverlayProvider>
      <Flex
        direction={'column'}
        id={'layout'}
        className={router.asPath === 'retail' ? 'landing-page' : undefined}
        sx={{ minHeight: '100vh' }}>
        <AppProvider pageProps={pageProps}>
          <AppBoundary locale={pageProps.locale}>
            <AppHead />
            <HomeSchemaOrgJSON />
            <Header />
            <ComponentWrapper isShopstory={pageProps.shopstory}>
              <Component
                {...pageProps}
                key={router.asPath}
              />
            </ComponentWrapper>
            {pageProps.page?.fields.pageType.hideFooter ? null : <Footer />}
            <Popup />
            <Modal />
            <Drawer />
            <Dialog />
            <CookiesBanner />
            <LoadingProgress />
          </AppBoundary>
        </AppProvider>
      </Flex>
    </LoadingOverlayProvider>
  );
};

export default NoozApp;
