import NoozText from '@/components/Nooz/NoozText/NoozText';
import {
  createStyles,
  Divider,
  Group,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import i18n from '@/helpers/i18n';
import React from 'react';
import useStaticContent from '@/utils/hook/useStaticContent';

const useClasses = () =>
  createStyles((theme) => ({
    iconConfirmBooking: {
      alignSelf: 'center',
    },
    input: {
      borderColor: theme.colors.title[0],
    },
  }))().classes;

const RetailBookingStepInfo = ({
  form,
  onSubmit,
  setForm,
  loading,
}: {
  form: any;
  onSubmit: () => any;
  setForm: any;
  loading?: boolean;
}) => {
  const theme = useMantineTheme();
  const classes = useClasses();
  const staticContent = useStaticContent();
  return (
    <>
      <form
        onSubmit={onSubmit}
        style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
        <NoozText
          weight={900}
          align={'center'}
          size={24}>
          {staticContent.infoTitle}
        </NoozText>

        <TextInput
          required
          onChange={(e) => {
            setForm({ name: e.target.value });
          }}
          label={staticContent.nameLabel}
          placeholder={staticContent.nameLabel}
          width={'100%'}
          size='lg'
          radius='md'
          classNames={{ input: classes.input }}
          icon={
            <NoozIcon
              iconKey={'UserCircle'}
              color={theme.colors.indigo[0]}
              strokeWidth={1}
            />
          }
        />
        <TextInput
          required
          type={'email'}
          onChange={(e) => {
            setForm({ email: e.target.value });
          }}
          label={staticContent.emailLabel}
          placeholder={staticContent.emailLabel}
          width={'100%'}
          size='lg'
          radius='md'
          classNames={{ input: classes.input }}
          icon={
            <NoozIcon
              iconKey={'Mail'}
              color={theme.colors.indigo[0]}
              strokeWidth={1}
            />
          }
        />
        <TextInput
          onChange={(e) => {
            setForm({ phone: e.target.value });
          }}
          label={staticContent.phoneLabel}
          placeholder={staticContent.phoneLabel}
          width={'100%'}
          required
          size='lg'
          radius='md'
          classNames={{ input: classes.input }}
          icon={
            <NoozIcon
              iconKey={'Phone'}
              color={theme.colors.indigo[0]}
              strokeWidth={1}
            />
          }
        />
        <NoozButton
          type={'submit'}
          size={'md'}
          loading={loading}>
          {staticContent.confirmButton}
        </NoozButton>
      </form>
      <Divider
        label={i18n(staticContent, 'yourAppointment')}
        mt={30}
        color={'text'}
      />
      {form.slot && form.type ? (
        <>
          <Group my={16}>
            <NoozIcon
              iconKey={'CalendarEvent'}
              color={'grey'}
              size={30}
              strokeWidth={1}
            />
            <NoozText
              color={'title'}
              size={24}
              weight={500}>
              {`${form.date.longWeekDay} ${form.date.day} ${form.date.longMonth}`}
            </NoozText>
          </Group>

          <Divider />
          <Group my={16}>
            <NoozIcon
              iconKey={'ClockHour7'}
              color={'grey'}
              size={30}
              strokeWidth={1}
            />
            <NoozText
              color={'title'}
              size={24}
              weight={500}>
              {form.slot.slot}
            </NoozText>
          </Group>

          <Divider />
          <Group my={16}>
            <NoozIcon
              iconKey={'Sunglasses'}
              color={'grey'}
              size={30}
              strokeWidth={1}
            />
            <NoozText
              color={'title'}
              size={24}
              weight={500}>
              {form.type.label}
            </NoozText>
          </Group>
          <Divider />
        </>
      ) : null}
    </>
  );
};

export default RetailBookingStepInfo;
