import HighlightCard from '@/components/Cards/HighlightCard';
import { Box, createStyles, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useCarouselIndicatorsStyles } from '../style/useCarouselIndicatorStyle';

const useSliderStyles = createStyles(() => ({
  indicators: {
    bottom: -30,
  },
}));

export const useGridSliderHighlightCardTryptiqueProps = () => {
  const theme = useMantineTheme();
  const { classes } = useSliderStyles();
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);

  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  const height = isDesktop ? 600 : isTablet ? 550 : 530;

  return {
    gridProps: {
      gutter: isDesktop ? 30 : isTablet ? 20 : 10,
      h: height,
      ml: 0,
      mr: 0,
    },
    sliderProps: {
      slideSize: '90%',
      classNames: { ...classes, ...useCarouselIndicatorsStyles({}).classes },
      loop: true,
    },
    colProps: {
      span: 4,
    },
    Component: (props: any) => {
      return (
        <Box h={height}>
          <HighlightCard
            {...props}
            radius={isDesktop ? 'xl' : 'lg'}
            color={props.color || theme.colors.main[0]}
          />
        </Box>
      );
    },
  };
};

export const useGridSliderHighlightCardQuadriProps = () => {
  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);

  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  const gridSliderProps = {
    gridProps: {
      gutter: isDesktop ? 30 : isTablet ? 20 : 10,
      h: isDesktop ? 450 : isTablet ? 450 : 400,
      ml: 0,
      mr: 0,
    },
    sliderProps: {
      childHeight: 400,
      width: 300,
    },
    colProps: {
      span: 3,
    },
    Component: (props: any) => {
      return (
        <HighlightCard
          {...props}
          radius={isDesktop ? 'xl' : 'lg'}
          color={theme.colors.main[0]}
        />
      );
    },
  };

  return gridSliderProps;
};
