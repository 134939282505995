import React, { memo, useCallback, useContext, useRef, useState } from 'react';
import {
  ActionIcon,
  Badge,
  Box,
  Center,
  CloseButton,
  Divider,
  Grid,
  Group,
  Loader,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import ProductCard from '../Collection/ProductCard/ProductCard';
import { SearchDrawerContext } from './SearchDrawer/SearchDrawer';
import TextLink from '../Link/TextLink';
import { useMediaQuery } from '@mantine/hooks';
import NoozButton from '../Nooz/NoozButton/NoozButton';
import { SearchView } from './SearchView/SearchView';
import usePageContext from '@/utils/context/page';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import { formatCfItems } from '@/helpers/formatCfItems';
import { IBlock, ILabel } from '@/@types/generated/contentful';
import NoozText from '../Nooz/NoozText/NoozText';
import NoozIcon from '../Nooz/NoozIcon/NoozIcon';
import Link from '../Link/Link';
import { useRouter } from 'next/router';
import useStaticContent from '@/utils/hook/useStaticContent';
import { ShopItem } from '@/@types/shopitem';
import useTopShopItems from '@/utils/hook/useTopShopItems';

export const ProductHitsWrapper = memo(
  ({
    defaultReco,
    reco,
    query,
  }: {
    defaultReco: ShopItem[];
    query: string;
    reco: { items: ShopItem[]; total: number };
  }) => {
    const { layout } = usePageContext();
    const { search }: { search: IBlock } = formatCfItems(layout?.fields.header);
    const { productsLabel, productMoreLink } = formatCfItems(search);

    return reco.total === 0 && query ? null : (
      <Grid.Col
        mb={20}
        span={12}>
        <Box sx={{ zIndex: -1 }}>
          <Group
            mb={20}
            align='center'>
            <NoozText
              size={24}
              weight='bold'>
              {productsLabel.fields.text}
            </NoozText>
            {reco.total ? (
              <Badge
                size={'lg'}
                variant='filled'
                w='fit-content'>
                {reco.total}
              </Badge>
            ) : null}
          </Group>
          <Grid>
            {(reco.total > 0 ? reco.items : defaultReco)
              .slice(0, 4)
              .map((hit: ShopItem, key: number) => {
                return (
                  <Grid.Col
                    key={hit.defaultVariantId}
                    span={6}
                    sm={3}>
                    <ProductCard
                      item={hit}
                      position={key}
                    />
                  </Grid.Col>
                );
              })}
          </Grid>
          {query ? (
            <Center my={50}>
              <Link
                query={{ query }}
                href={`${productMoreLink.fields.slug.fields.url}`}>
                <NoozButton>
                  <NoozText>
                    {productMoreLink.fields.label.fields.text}
                  </NoozText>
                </NoozButton>
              </Link>
            </Center>
          ) : null}
        </Box>
      </Grid.Col>
    );
  },
);

export interface SearchInputProps {
  onChange: (v?: string) => void;
  query: string;
  clear: () => void;
  loading?: boolean;
}

export const useInput = (): SearchInputProps => {
  const [query, setQuery] = useState('');

  const onChange = useCallback((v?: string) => setQuery(v || ''), []);
  const clear = useCallback(() => setQuery(''), []);
  return { query, onChange, clear };
};

// controlled input
export const SearchInput = ({
  query,
  clear,
  onChange,
  loading,
}: SearchInputProps) => {
  const { layout } = usePageContext();
  const theme = useMantineTheme();
  const { search }: { search: IBlock } = formatCfItems(layout?.fields.header);
  const { input, closeLabel } = formatCfItems(search);
  const staticContent = useStaticContent();
  const router = useRouter();

  const handleOnSearchChange = (e: any) => {
    onChange(e.currentTarget.value);
  };

  const handleReset = () => {
    clear();
  };

  return (
    <Box pos={'relative'}>
      <TextInput
        autoFocus={true}
        variant='default'
        placeholder={staticContent.faqSearchPlaceholder}
        size='lg'
        radius='xl'
        value={query}
        icon={
          <NoozIcon
            iconKey={'Search'}
            color={theme.colors[theme.primaryColor][4]}
          />
        }
        rightSection={loading ? <Loader size='xs' /> : undefined}
        onChange={handleOnSearchChange}
      />
      <Box
        pos='absolute'
        display='flex'
        right={10}
        top={0}
        h='100%'>
        <Group>
          {query && query.length > 0 && (
            <NoozButton
              size='xs'
              color={'black'}
              rightIcon={
                <NoozIcon
                  color='white'
                  iconKey='Trash'
                />
              }
              onClick={() => handleReset()}>
              {closeLabel.fields.text}
            </NoozButton>
          )}
        </Group>
      </Box>
    </Box>
  );
};

const SearchWrapper = () => {
  const searchDrawerState = useContext(SearchDrawerContext);
  const isSearchActive = searchDrawerState[0].opened;
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);
  const headerHeight = useHeaderHeight();
  const defaultReco = useTopShopItems();

  const handleOnSearchClick = () => {
    searchDrawerState[1]((prev) => {
      return {
        ...prev,
        size: `calc(100vh - ${
          headerHeight.wrapper + headerHeight.marginTop
        }px)`,
        zIndex: 1,
        position: 'top',
        opened: !prev.opened,
        body: <SearchView defaultReco={defaultReco} />,
      };
    });
  };

  return !isSearchActive || isDesktop ? (
    <ActionIcon
      radius='xl'
      aria-label={'Search'}
      variant={isSearchActive ? 'filled' : 'subtle'}
      size='lg'
      color='black'
      sx={{
        color: isSearchActive ? 'white' : 'inherit',
        transition: 'color 0.1s ease-in-out',
      }}
      onClick={() => handleOnSearchClick()}>
      <NoozIcon
        iconKey={'Search'}
        color={isSearchActive ? 'white' : undefined}
        strokeWidth={1.3}
        size={24}
      />
    </ActionIcon>
  ) : (
    <CloseButton
      aria-label={'Close'}
      radius='xl'
      size='lg'
      variant='light'
      color='black'
      onClick={() => handleOnSearchClick()}
    />
  );
};

export { SearchWrapper as default };
