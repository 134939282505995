import { useContainerStyles } from '@/utils/style/useContainer';
import {
  Container,
  createStyles,
  Divider,
  Flex,
  Group,
  Loader,
  Stack,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import NoozNextApi from '@/utils/services/NoozNextApi';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import format from 'date-fns/format';
import { capitalizeFirstLetter } from '@/utils';
import usePageContext from '@/utils/context/page';
import LocaleHelper from '@/helpers/Locale';
import useStaticContent from '@/utils/hook/useStaticContent';
import i18n from '@/helpers/i18n';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';

const useClasses = () =>
  createStyles(() => ({
    icon: {
      marginLeft: 4,
    },
  }))().classes;
const RetailBookingCancel = () => {
  const classes = useClasses();
  const staticContent = useStaticContent();
  const { locale } = usePageContext();
  const { language } = LocaleHelper.splitInfo(locale || 'en-gb');
  const containerStyles = useContainerStyles();
  const [data, setData] = useState<Record<string, any> | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const [cancelled, setCancelled] = useState(false);

  const onDelete = () => {
    if (typeof window !== undefined) {
      const id = new URLSearchParams(window.location.search).get('id');

      if (!id) return;
      const api = NoozNextApi;

      api.instance
        .delete(`/api/odoo/appointments/${id}`)
        .then(() => {
          setCancelled(true);
        })
        .catch((err) => console.error(err));
    }
  };
  useEffect(() => {
    if (typeof window !== undefined) {
      const id = new URLSearchParams(window.location.search).get('id');
      if (!id) {
        setLoading(false);
        setError('no id');
        return;
      }

      const api = NoozNextApi;
      api.instance
        .get(`/api/odoo/appointments/${id}`)
        .then((res) => {
          const date = new Date(`${res.data.start.replace(' ', 'T')}Z`);
          setData({
            ...res.data,
            time: format(date, 'HH:mm'),
            date: format(
              date,
              `'${capitalizeFirstLetter(
                new Intl.DateTimeFormat(language, {
                  weekday: 'long',
                }).formatToParts(date)[0].value,
              )}' dd '${capitalizeFirstLetter(
                new Intl.DateTimeFormat(language, {
                  month: 'long',
                }).formatToParts(date)[0].value,
              )}' yyyy`,
            ),
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <Container
      mb={{ base: 20, md: 40 }}
      className={containerStyles.classes.container}>
      {loading ? (
        <Flex
          align={'center'}
          justify={'center'}
          mih={'50vh'}
          h={'100%'}>
          <Loader />
        </Flex>
      ) : cancelled ? (
        <Stack>
          {Array.isArray(staticContent.congratulations)
            ? staticContent.congratulations.map((str, id) => (
                <NoozText
                  key={id}
                  align={'center'}
                  color={'text'}
                  size={14}>
                  {str}
                </NoozText>
              ))
            : null}
          {typeof staticContent.congratulations === 'string' ? (
            <NoozText
              color={'text'}
              align={'center'}
              size={14}>
              {staticContent.congratulations}
            </NoozText>
          ) : null}
          <NoozButton
            color={'title'}
            variant={'outline'}
            href={staticContent.bookButton.slug}
            w={'fit-content'}>
            {staticContent.bookButton.label}
          </NoozButton>
        </Stack>
      ) : error || !data ? (
        <>{staticContent.errorCancel || ''}</>
      ) : (
        <Flex
          direction={'column'}
          h={'100%'}>
          <NoozText
            align={'center'}
            size={24}
            weight={900}>
            {i18n(staticContent, 'yourAppointment')}
          </NoozText>
          <Divider
            my={24}
            label={i18n(staticContent, 'yourAppointment')}
            color={'title'}
          />
          <Group my={16}>
            <NoozIcon
              iconKey={'CalendarEvent'}
              color={'#2D2D2D'}
              size={30}
              strokeWidth={2}
            />
            <NoozText
              color={'title'}
              size={24}
              weight={900}>
              {data.date}
            </NoozText>
          </Group>
          <Divider />
          <Group my={16}>
            <NoozIcon
              iconKey={'ClockHour7'}
              color={'#2D2D2D'}
              size={30}
              strokeWidth={2}
            />
            <NoozText
              color={'title'}
              size={24}
              weight={900}>
              {data.time}
            </NoozText>
          </Group>
          <Divider />
          <Group my={16}>
            <NoozIcon
              iconKey={'Sunglasses'}
              color={'#2D2D2D'}
              size={30}
              strokeWidth={2}
            />
            <NoozText
              color={'title'}
              size={24}
              weight={900}>
              {data.display_name.split(' - ')[1]}
            </NoozText>
          </Group>
          <Divider />
          <Group my={16}>
            <NoozIcon
              iconKey={'UserCircle'}
              color={'#2D2D2D'}
              size={30}
              strokeWidth={2}
            />
            <NoozText
              color={'title'}
              size={24}
              weight={900}>
              {data.user.name}
            </NoozText>
          </Group>
          <Divider />
          <Group my={16}>
            <NoozIcon
              iconKey={'Mail'}
              color={'#2D2D2D'}
              size={30}
              strokeWidth={2}
            />
            <NoozText
              color={'title'}
              size={24}
              weight={900}>
              {data.user.email}
            </NoozText>
          </Group>
          <Divider />
          <Group my={16}>
            <NoozIcon
              iconKey={'Phone'}
              color={'#2D2D2D'}
              size={30}
              strokeWidth={2}
            />
            <NoozText
              color={'title'}
              size={24}
              weight={900}>
              {data.user.phone}
            </NoozText>
          </Group>
          <Divider mb={16} />
          <NoozButton
            type={'submit'}
            size={'md'}
            color={'original'}
            loading={loading}
            onClick={onDelete}>
            {i18n(staticContent, 'cancel')}
            <NoozIcon
              iconKey={'ChevronRight'}
              color={'white'}
              strokeWidth={2}
              className={classes.icon}
            />
          </NoozButton>
        </Flex>
      )}
    </Container>
  );
};

export default RetailBookingCancel;
