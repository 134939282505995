import React, { useRef } from 'react';
import usePageContext from '@/utils/context/page';
import { Accordion, Container, clsx, createStyles } from '@mantine/core';
import { formatCfItems } from '@/helpers/formatCfItems';
import { IBlock, ILabel } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { NoozSectionTitle } from '@/components/Nooz/NoozTitle/NoozTitle';
import { useContainerStyles } from '@/utils/style/useContainer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '@/components/Body/body';
import generateUUID from '@/helpers/uuid';

const useStyles = createStyles((theme) => ({
  accordion: {
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      padding: 0,
    },
  },
}));

const SeoRichBody = ({ list, title }: { list?: IBlock; title: ILabel }) => {
  const containerStyles = useContainerStyles();
  const { classes } = useStyles();
  const refId = useRef(generateUUID());

  return (
    <>
      <Container
        mt={80}
        className={containerStyles.classes.container}>
        <NoozSectionTitle
          className={containerStyles.classes.containerFullWidth}>
          {title.fields?.text}
        </NoozSectionTitle>
      </Container>
      <Container
        mb={80}
        className={clsx(containerStyles.classes.container, classes.accordion)}>
        <Accordion
          mb={20}
          multiple
          defaultValue={['0']}
          styles={{
            chevron: {
              '&[data-rotate]': {
                transform: 'rotate(45deg)',
              },
            },
          }}>
          {list?.fields?.items?.map((item: any, key: number) => {
            const { label, body } = formatCfItems(item);
            return (
              <Accordion.Item
                className={`seo-rich-body_${key}`}
                key={key}
                value={`${key}`}>
                <Accordion.Control
                  chevron={
                    <NoozIcon
                      iconKey='Plus'
                      size={30}
                      strokeWidth={1.5}
                    />
                  }>
                  <NoozText
                    color={'title'}
                    size={24}
                    fw={500}>
                    {label?.fields?.text}
                  </NoozText>
                </Accordion.Control>
                <Accordion.Panel>
                  {body?.fields.richBody
                    ? documentToReactComponents(body.fields.richBody, options())
                    : null}
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Container>
    </>
  );
};
export const PageSeoRichBody = () => {
  const { components } = usePageContext();
  const { seo } = formatCfItems(components);

  if (!seo) return null;

  const { title, list } = formatCfItems(seo);
  return (
    <SeoRichBody
      list={list}
      title={title}
    />
  );
};

export default SeoRichBody;
