import { DialogProps } from '@mantine/core';
import * as React from 'react';

export interface IDialog extends DialogProps {
  opened: boolean;
  body: string | React.ReactNode;
}

export type DialogContextType = {
  dialog: IDialog;
  setDialog: React.Dispatch<React.SetStateAction<IDialog>>;
};

export const DialogContext = React.createContext<DialogContextType | null>(
  null,
);

export interface DialogProviderProps {
  children: React.ReactNode;
}

export const useDialogContext = (): DialogContextType => {
  const context = React.useContext(DialogContext);
  if (!context) {
    throw new Error('useDrawerContext must be used within a DialogProvider');
  }
  return context;
};

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialog, setDialog] = React.useState<IDialog>({
    opened: false,
    body: '',
  });

  return (
    <DialogContext.Provider value={{ dialog, setDialog }}>
      {children}
    </DialogContext.Provider>
  );
};
