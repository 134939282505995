import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';

const useHasDefaultHeader = () => {
  const { components } = usePageContext();
  const config: any = formatCfItems(components)?.config?.fields?.metaData || {};

  // @ts-ignore
  return !(config.header === 'simple');
};

export default useHasDefaultHeader;
