import { ActionIcon, Container, Flex, Group, Stepper } from '@mantine/core';
import React from 'react';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import useStaticContent from '@/utils/hook/useStaticContent';
import connectBooking, {
  RetailBookingProps,
} from '@/components/Retail/Booking/RetailOnBookingHoc';
import RetailBookingStepResult from '@/components/Retail/Booking/RetailBookingStepResult';
import RetailBookingStepInfo from '@/components/Retail/Booking/RetailBookingStepInfo';
import RetailBookingStepType from '@/components/Retail/Booking/RetailBookingStepType';
import RetailBookingStepDate from '@/components/Retail/Booking/RetailBookingStepDate';

const stepperStyles: any = {
  root: {
    width: '100%',
    maxWidth: 750,
    margin: '0 auto',
    height: '100%',
  },
  steps: {
    alignItems: 'center !important',
    // @ts-ignore
    flexDirection: 'row !important',
  },
  separator: {
    width: 'unset !important',
    minHeight: 'unset !important',
    margin: '0 1rem !important',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
};

const RetailBooking = ({
  onSubmit,
  onAddToCalendar,
  setForm,
  setActive,
  data,
  loading,
  form,
  isCurrentDay,
  active,
  resetForm,
  onClose,
}: RetailBookingProps) => {
  const staticContent = useStaticContent();

  return (
    <Container>
      <Group
        position={'right'}
        mb={8}>
        <ActionIcon
          variant={'outline'}
          radius={'xl'}
          onClick={onClose}>
          <NoozIcon iconKey={'X'} />
        </ActionIcon>
      </Group>
      <Stepper
        allowNextStepsSelect={false}
        completedIcon={
          <NoozIcon
            iconKey='Check'
            color='white'
            size={25}
          />
        }
        styles={stepperStyles}
        active={active}
        onStepClick={setActive}
        breakpoint='sm'>
        <Stepper.Step>
          <RetailBookingStepType
            setForm={setForm}
            setActive={setActive}
          />
        </Stepper.Step>
        <Stepper.Step>
          <RetailBookingStepDate
            form={form}
            data={data}
            isCurrentDay={isCurrentDay}
            setForm={setForm}
            setActive={setActive}
          />
        </Stepper.Step>
        <Stepper.Step>
          <RetailBookingStepInfo
            form={form}
            onSubmit={onSubmit}
            setForm={setForm}
            loading={loading}
          />
        </Stepper.Step>
        <Stepper.Completed>
          <RetailBookingStepResult form={form} />
        </Stepper.Completed>
      </Stepper>
      {active > 0 && active < 3 ? (
        <Flex
          mt={24}
          justify={'center'}>
          <NoozButton
            size='md'
            color={'title'}
            sx={{ alignSelf: 'center' }}
            disabled={loading}
            variant='outline'
            onClick={() => {
              setActive(-1, true);
            }}>
            {staticContent.backLabel}
          </NoozButton>
        </Flex>
      ) : null}
    </Container>
  );
};

export default connectBooking(RetailBooking);
