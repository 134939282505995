import { Box, LoadingOverlay } from '@mantine/core';
import * as React from 'react';
import { PropsWithChildren } from 'react';

export interface ILoadingOverlay {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
export const LoadingOverlayContext = React.createContext<ILoadingOverlay>(
  {} as ILoadingOverlay,
);

const useLoadingOverlayContext = (): ILoadingOverlay => {
  return React.useContext(LoadingOverlayContext);
};

export const LoadingOverlayProvider = ({ children }: PropsWithChildren) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <LoadingOverlayContext.Provider value={{ setVisible }}>
      <Box pos='relative'>
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          sx={() => ({ position: 'fixed', zIndex: 1000 })}
        />
      </Box>
      {children}
    </LoadingOverlayContext.Provider>
  );
};

export default useLoadingOverlayContext;
