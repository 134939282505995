import React from 'react';
import {
  BackgroundImage,
  createStyles,
  Stack,
  Group,
  ActionIcon,
  Box,
  Flex,
  useMantineTheme,
} from '@mantine/core';
import NoozText from '../Nooz/NoozText/NoozText';
import { IBadge, IColor, ILabel, IPath } from '@/@types/generated/contentful';
import Link from '../Link/Link';
import NoozIcon from '../Nooz/NoozIcon/NoozIcon';
import { TIMING_FUNCTION } from '@/utils/style/animation';
import useMarketingStyles from './useMarketingStyles';
import NoozBadge from '../Nooz/NoozBadge/NoozBadge';

const useStyles = createStyles((theme) => {
  return {
    card: {
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
    },
    image: {
      transition: `transform 600ms ease`,
      [theme.fn.largerThan(theme.breakpoints.sm)]: {
        '&:hover': {
          transform: 'scale(1.02)',
        },
      },
    },
    label: {
      fontSize: 24,
      fontWeight: 500,
      [theme.fn.smallerThan('md')]: {
        fontSize: 16,
        fontWeight: 500,
      },
    },
    subLabel: {
      fontSize: 20,
      fontWeight: 500,
      [theme.fn.smallerThan('md')]: {
        fontSize: 16,
        fontWeight: 500,
      },
    },
  };
});

interface NavigationCardProps {
  image: any;
  badge: IBadge;
  label: ILabel;
  subLabel?: ILabel;
  slug: IPath;
  color?: IColor;
  height?: string | number;
  backgroundColor?: string;
}

export function NavigationCard({
  badge,
  image,
  label,
  slug,
  subLabel,
  height,
}: NavigationCardProps) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [, setHovered] = React.useState(false);
  const marketingStyles = useMarketingStyles();

  return (
    <Link
      href={slug?.fields.url as string}
      facet={slug?.fields.facet || []}>
      <Box
        className={classes.card}
        h={'100%'}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
        <BackgroundImage
          h={height}
          src={image?.fields.file?.url}
          className={classes.image}>
          <Box className={marketingStyles.classes.overlay}>
            <Flex
              w='100%'
              h='100%'
              justify='end'
              align='end'>
              <Group
                w='100%'
                noWrap
                p={{ base: 'md', md: 'lg' }}
                py={{ base: 'xs', md: 12 }}
                position='apart'>
                <Stack spacing={1}>
                  {badge && (
                    <Box>
                      <NoozBadge
                        badgeProps={{
                          variant: badge.fields.type || 'filled',
                          size: badge.fields.size || 'lg',
                          color: badge.fields.color?.fields.classname || 'dark',
                        }}
                        text={badge.fields.label.fields.text as string}
                        textProps={{
                          sx: {
                            display: 'flex',
                            margin: '0 8px',
                            gap: 12,
                            alignItems: 'center',
                          },
                          weight: 500,
                          size: 14,
                          lh: '12px',
                          transform: 'uppercase',
                          color:
                            badge.fields.label.fields.color?.fields.classname ||
                            'white',
                        }}
                      />
                    </Box>
                  )}
                  <NoozText
                    className={classes.label}
                    color={theme.colors.white[0]}>
                    {label?.fields.text}
                  </NoozText>
                  <NoozText
                    className={classes.subLabel}
                    weight={500}
                    color={theme.colors.white[0]}>
                    {subLabel?.fields.text}
                  </NoozText>
                </Stack>
                <ActionIcon
                  size='xl'
                  radius='xl'
                  color='white'
                  variant='outline'
                  sx={{
                    transition: `border 0.5s ${TIMING_FUNCTION}`,
                  }}>
                  <NoozIcon
                    iconKey={'ChevronRight'}
                    color='white'
                    strokeWidth={1}
                    size={22}
                  />
                </ActionIcon>
              </Group>
            </Flex>
          </Box>
        </BackgroundImage>
      </Box>
    </Link>
  );
}
