import React from 'react';
import { IBlock } from '@/@types/generated/contentful';
import { Flex } from '@mantine/core';
import { formatCfItems } from '@/helpers/formatCfItems';
import { NavigationCard } from '@/components/Cards/NavigationCard';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';

const SecondaryHighlightCard = ({ elements }: { elements: IBlock }) => {
  const headerHeight = useHeaderHeight();

  const itemCount = elements.fields.items?.length || 1; // Fallback to 1 to avoid division by zero
  let dynamicHeight: string;
  switch (itemCount) {
    case 1:
      dynamicHeight = `calc(100vh - ${headerHeight.wrapper}px)`; // Full height for a single item
      break;
    case 2:
      dynamicHeight = `calc(50vh - ${headerHeight.wrapper / 2}px)`; // Half height for two items
      break;
    case 3:
      dynamicHeight = `calc(33.33vh - ${headerHeight.wrapper / 3}px)`; // One third height for three items
      break;
    default:
      dynamicHeight = `calc(33.33vh - ${headerHeight.wrapper / 3}px)`; // Default height, you may want to adjust this
  }

  return (
    <Flex
      direction={'column'}
      sx={{ overflow: "scroll" }}>
      {elements.fields.items?.map((item, idx) => {
        if (!item.fields) return null;

        const { label, subLabel, badge } = formatCfItems(item);

        // @ts-ignore
        let img = item.fields.items?.find(i => i.fields?.media)?.fields?.media;
        // @ts-ignore
        const url = item.fields.items?.find(i => i.fields?.url);

        return (
          <React.Fragment key={(item as unknown as { id: string }).id}>
            <NavigationCard
              height={dynamicHeight} // Apply the dynamic height here
              image={img}
              badge={badge}
              slug={url}
              label={label}
              subLabel={subLabel}
              backgroundColor={'white !important'}
            />
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

export default SecondaryHighlightCard;
