import React, { useEffect, useState } from 'react';

const clientSideOnly = (Component: React.ComponentType) => (props: any) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return mounted ? <Component {...props} /> : null;
};

export default clientSideOnly;
