import {
  Box,
  Container,
  Flex,
  Group,
  clsx,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import React, { useMemo } from 'react';
import NoozText, {
  ShopStoryTextProps,
} from '@/components/Nooz/NoozText/NoozText';
import Link from '@/components/Link/Link';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozButton from '../../Nooz/NoozButton/NoozButton';
import { IBadge, IBlock, IButton, IImage } from '@/@types/generated/contentful';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';

const useStyles = createStyles((theme) => {
  return {
    container: {
      display: 'flex',
      padding: '0 !important',
      overflow: "hidden",
      aspectRatio: '2/1',
      [theme.fn.smallerThan('sm')]: {
        flexDirection: 'column',
        aspectRatio: '1/2',
      },
    },
    imgWrapper: {
      position: 'relative',
      width: '50%',
      overflow: "hidden",
      transition: `transform 600ms ease`,
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        height: '50%',
        width: '100%',
      },
      [theme.fn.largerThan(theme.breakpoints.sm)]: {
        '&:hover': {
          transform: 'scale(1.02)',
        },
      },
    },
    contentWrapper: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      left: 50,
      right: 50,
      bottom: 30,
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        left: 30,
        right: 30,
        bottom: 30,
      },
    },
    icon: {
      marginLeft: 4,
    },
    backdrop: {
      position: 'absolute',
      top: '50%',
      right: 0,
      bottom: 0,
      left: 0,
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .50) 90%)',
    },
  };
});

interface BannerDuoProps {
  contentLeft: IBlock;
  contentRight: IBlock;
  leftImage?: IImage;
  rightImage?: IImage;
}

const Badges = ({ entries }: { entries?: any[] }) => {
  const theme = useMantineTheme();
  const badges = useMemo(() => {
    return entries?.filter((e) => e.fields.key === 'badge') || [];
  }, [entries]);

  return badges ? (
    <Group>
      {badges.map((badge: IBadge, index: number) => {
        return (
          <NoozBadge
            key={index}
            badgeProps={{
              variant: badge.fields.type || 'filled',
              size: badge.fields.size || 'lg',
              color: badge.fields.color?.fields.classname || 'dark',
            }}
            text={badge.fields.label.fields.text as string}
            textProps={{
              sx: {
                display: 'flex',
                margin: '0 8px',
                gap: 12,
                alignItems: 'center',
              },
              weight: 500,
              size: 14,
              lh: '12px',
              transform: 'uppercase',
              color:
                badge.fields.label.fields.color?.fields.classname || 'white',
            }}
            icon={
              badge.fields.icon ? (
                <NoozIcon
                  iconKey={badge.fields.icon.fields.iconKey}
                  size={16}
                  strokeWidth={2.5}
                  color={
                    theme.colors[
                    badge.fields.icon.fields.color?.fields.classname as string
                    ]?.[7] || 'white'
                  }
                />
              ) : undefined
            }
          />
        );
      })}
    </Group>
  ) : null;
};
const Labels = ({ entries }: { entries?: any[] }) => {
  const labels = useMemo(() => {
    return entries?.filter((e) => e.fields.key === 'label') || [];
  }, [entries]);
  return labels ? (
    <Box>
      {labels.map((l) =>
        Array.isArray(l.fields.textList) ? (
          <React.Fragment key={l.id}>
            {l.fields.textList.map((text: string, id: number) => (
              <NoozText
                key={id}
                {...ShopStoryTextProps(l.fields)}>
                {text}
              </NoozText>
            ))}
          </React.Fragment>
        ) : (
          <NoozText
            key={l.id}
            {...ShopStoryTextProps(l.fields)}>
            {l.fields.text}
          </NoozText>
        ),
      )}
    </Box>
  ) : null;
};
const Buttons = ({ entries }: { entries?: any[] }) => {
  const theme = useMantineTheme();
  const classes = useStyles().classes;
  const buttons = useMemo(() => {
    return entries?.filter((e) => e.fields.key === 'button') || [];
  }, [entries]);

  return buttons ? (
    <Group>
      {buttons.map(
        // @ts-ignore
        (button: IButton, index: number) => {
          if (typeof button.fields.slug?.fields?.url !== 'string') return null;
          // @ts-ignore
          return (
            <Link
              key={index}
              href={button.fields.slug?.fields.url as string}>
              {/* @ts-ignore */}
              <NoozButton
                key={index}
                {...button.fields}
                variant={button.fields.type || 'filled'}
                color={button?.fields?.color?.fields?.classname || 'dark'}
                gradient={button.fields.color?.fields.gradientObject as any}
                className={'cta-button'}
                sx={
                  button?.fields?.label?.fields.color?.fields.classname
                    ? // @ts-ignore
                    (theme) => ({
                      color:
                        theme.colors[
                        // @ts-ignore
                        button.fields.label.fields.color.fields.classname
                        ],
                    })
                    : null
                }>
                <NoozText
                  color={'inherit'}
                  size={16}>
                  {button?.fields?.label?.fields.text}
                </NoozText>
                {button?.fields.icon ? (
                  <NoozIcon
                    iconKey={button.fields.icon.fields.iconKey}
                    strokeWidth={3}
                    className={classes.icon}
                    color={
                      theme.colors[
                      // @ts-ignore
                      button?.fields.type === 'white'
                        ? 'title'
                        : // @ts-ignore
                        button?.fields?.icon?.fields.color?.fields
                          .classname ||
                        button?.fields?.label?.fields.color?.fields
                          .classname
                      ][0]
                    }
                  />
                ) : null}
              </NoozButton>
            </Link>
          );
        },
      )}
    </Group>
  ) : null;
};

const BannerDuo = ({
  leftImage,
  rightImage,
  contentLeft,
  contentRight,
}: BannerDuoProps) => {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();

  return (
    <>
      <Box>
        <Container
          h={'100%'}
          className={clsx(
            containerStyles.classes.container,
            classes.container,
          )}>
          <Box
            className={classes.imgWrapper}
            sx={(theme) => ({
              background: `no-repeat center/cover url("${leftImage?.fields.media?.fields.file.url}")`,
              [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                background: !leftImage?.fields.mobileMedia
                  ? undefined
                  : `no-repeat center/cover url("${leftImage?.fields.mobileMedia?.fields.file.url}")`,
              },
            })}>
            <Box className={classes.backdrop} />
            <Flex className={classes.contentWrapper}>
              <Badges entries={contentLeft.fields.items} />
              <Labels entries={contentLeft.fields.items} />
              <Buttons entries={contentLeft.fields.items} />
            </Flex>
          </Box>
          <Box
            className={classes.imgWrapper}
            sx={(theme) => ({
              background: `no-repeat center/cover url("${rightImage?.fields.media?.fields.file.url}")`,
              [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                background: !rightImage?.fields.mobileMedia
                  ? undefined
                  : `no-repeat center/cover url("${rightImage?.fields.mobileMedia?.fields.file.url}")`,
              },
            })}>
            <Box className={classes.backdrop} />
            <Flex className={classes.contentWrapper}>
              <Badges entries={contentRight.fields.items} />
              <Labels entries={contentRight.fields.items} />
              <Buttons entries={contentRight.fields.items} />
            </Flex>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BannerDuo;
