import { createStyles, Flex, Stack } from '@mantine/core';
import React, { useMemo } from 'react';
import usePageContext from '@/utils/context/page';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import ProductCard from '@/components/Collection/ProductCard/ProductCard';
import { formatCfItems } from '@/helpers/formatCfItems';
import useCommerceContext from '@/utils/context/commerce';

const useClasses = () =>
  createStyles(() => ({
    ellipsisText: {
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
      overflow: 'auto',
    },
    breakText: {
      wordBreak: 'break-all',
    },
    scrollbar: {
      '&::-webkit-scrollbar': {
        width: 8,
        borderRadius: 4,
      },
      '::-webkit-scrollbar-thumb': {
        background: '#1949E7',
        borderRadius: 4,
      },
      '::-webkit-scrollbar-track': {
        background: '#D9D9D9',
        borderRadius: 4,
      },
    },
  }))().classes;

const CartDrawerCrossSell = ({}: { closeDrawer: () => any }) => {
  const { recommendations: reco } = useCommerceContext();
  const classes = useClasses();
  const { layout } = usePageContext();
  const { crossSellLabel } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );

  return (
    <Flex
      w={'100%'}
      sx={{ overflow: 'hidden' }}
      direction={'column'}>
      <NoozText
        p={'8px 16px'}
        size={40}
        weight={700}
        color={'title'}>
        {crossSellLabel?.fields.text}
      </NoozText>
      <Stack
        mr={20}
        p={18}
        className={classes.scrollbar}
        sx={{ overflow: 'auto' }}>
        {reco.map((el: any, key: any) => (
          <ProductCard
            item={el}
            position={key}
            key={el.id}
            forceMini
          />
        ))}
      </Stack>
    </Flex>
  );
};

export default CartDrawerCrossSell;
