import usePageContext from '@/utils/context/page';
import { useMemo } from 'react';
import LocaleHelper, { COUNTRY_COOKIE_NAME } from '@/helpers/Locale';
import Cookies from 'js-cookie';

const useShippingRates = () => {
  const { locale, shippingZone } = usePageContext(),
    all = shippingZone?.all || [];

  return useMemo(() => {
    const country = LocaleHelper.splitInfoWithInt(locale).country;
    const countryCookie = Cookies.get(COUNTRY_COOKIE_NAME);
    let rates;

    if (country === 'fr' && countryCookie?.toLowerCase() === 'mc') {
      rates = all.fr;
    }
    if (country === 'int') {
      if (!countryCookie || !all[(countryCookie as string).toLowerCase()])
        return null;

      rates = all[(countryCookie as string).toLowerCase()];
    } else {
      rates = all[country];
    }
    if (rates?.free) {
      rates.free.min_order_subtotal = parseFloat(rates.free.min_order_subtotal);
    }
    return rates;
  }, [all, locale]);
};

export default useShippingRates;
