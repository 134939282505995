import { useEffect, useState } from 'react';
import { ShopItem } from '@/@types/shopitem';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';
import usePageContext from '@/utils/context/page';

const useTopShopItems = () => {
  const { locale } = usePageContext();
  const [shopItems, set] = useState<ShopItem[]>([]);

  useEffect(() => {
    NoozNextApi.instance({
      ...NOOZ_NEXT_REGISTRY.PRODUCTS.TOP(locale as string),
    }).then((res) => set(res.data));
  }, []);

  return shopItems;
};

export default useTopShopItems;
