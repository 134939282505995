import { isWindowDefined } from 'swr/_internal';

export const expiresCountryOrigin = () => {
  const date = new Date();
  date.setDate(date.getDate() + 7);
  return date;
};
const Cookie = {
  set: (key: string, value: string, expiryDate?: string | number) => {
    if (!isWindowDefined) return;
    let cookieStr = key + '=' + value + '; path=/';
    if (expiryDate) {
      let date = new Date(expiryDate);
      const expires = 'expires=' + date.toUTCString();
      cookieStr += `; ${expires}`;
    }
    document.cookie = cookieStr;
  },
  get: (key: string) => {
    if (!isWindowDefined) return null;
    const name = key + '=';
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  },
  delete: (key: string) => {
    if (!isWindowDefined) return;
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  },
};

export default Cookie;
