import React, { useCallback, useState } from 'react';
import { Card, Box, Group, createStyles } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { ProductCardUiProps } from '@/interface.custom';
import ProductCardNewBadges from '@/components/Collection/ProductCard/ProductCardNewBadges';
import ProductCardPrice from '@/components/Collection/ProductCard/ProductCardPrice';
import ProductCardNewImages from '@/components/Collection/ProductCard/ProductCardImages/ProductCardNewImages';
import ProductCardVariantPicker from '@/components/Collection/ProductCard/ProductCardVariantPicker/ProductCardVariantPicker';
import { ShopItemVariant } from '@/@types/shopitem';

const useStyles = createStyles((theme, { type }: { type: string }) => ({
  card: {
    padding: 0,
    height: '100%',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    cursor: 'pointer',
  },
  imageSection: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.gray[1],
    width: '100%',
    margin: 0,
    [theme.fn.smallerThan('sm')]: {
      padding: 0,
      aspectRation: '1',
    },
  },
  imageColorPicker: {
    padding: '12px 0px 6px 0px',
  },
  productLabelWrapper: {
    marginTop: 10,
    minHeight: '56px',
    alignItems: 'start',
  },
  productTitle: {
    margin: 0,
    fontSize: type === 'new' ? 18 : 20,
    color: '#2D2D2D',
  },
}));

const ProductCardNew = React.memo(
  ({ item, label, setVariant, variant, elementKey }: ProductCardUiProps) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const { classes } = useStyles({ type: 'new' });

    const handleMouseEnter = useCallback(() => {
      setHovered(true);
    }, []);

    const handleTouchStart = useCallback(() => {
      setHovered(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
      setTimeout(() => setHovered(false), 250);
    }, []);

    const handleTouchEnd = useCallback(() => {
      setTimeout(() => setHovered(false), 250);
    }, []);
    return (
      <Card
        p={0}
        className={classes.card}>
        <Box
          onMouseEnter={handleMouseEnter}
          onTouchStart={handleTouchStart}
          onMouseLeave={handleMouseLeave}
          onTouchEnd={handleTouchEnd}
          className={classes.imageSection}>
          <ProductCardNewBadges
            {...item}
            variant={variant}
          />
          <ProductCardNewImages
            {...variant}
            variantId={variant.id}
            hovered={hovered}
            productLifestyleImages={item.product?.lifestyleImages as string[]}
          />
        </Box>

        <ProductCardVariantPicker
          hasColorPicker={true}
          variants={item.variants as ShopItemVariant[]}
          variant={variant}
          setVariant={setVariant}
        />
        <Group
          position='apart'
          noWrap
          spacing={40}
          className={classes.productLabelWrapper}>
          <NoozText
            weight={500}
            component='h3'
            className={classes.productTitle}>
            {label}
          </NoozText>
          <ProductCardPrice
            item={variant}
            isNew
          />
        </Group>
      </Card>
    );
  },
);

export default ProductCardNew;
