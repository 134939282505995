import React, { useMemo } from 'react';
import {
  Accordion,
  createStyles,
  Divider,
  Group,
  MediaQuery,
  NativeSelect,
} from '@mantine/core';
import { IBlock, IColor, ILabel, ILink } from '@/@types/generated/contentful';
import { Language, Minus } from 'tabler-icons-react';
import TextLink from '@/components/Link/TextLink';
import usePageContext from '@/utils/context/page';
import { useRouter } from 'next/router';
import NoozText from '../Nooz/NoozText/NoozText';
import { ICountryDictionary } from '@/helpers/interface';
import Image from 'next/image';
import LocaleHelper, {
  COUNTRIES,
  COUNTRY_COOKIE_NAME,
  getCountryCodeLocal,
  getCountryLocal,
} from '@/helpers/Locale';
import Cookies from 'js-cookie';

const useStyles = createStyles((theme) => ({
  dividerTitle: {
    color: theme.white,
  },
  localizationSelect: {
    '& .mantine-Input-input': {
      backgroundColor: theme.black,
      borderColor: theme.white,
      color: theme.white,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.xs,
  },
  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginLeft: parseInt(theme.spacing.xs) / 2,
    color: theme.white,
    marginBottom: 0,
  },
  subText: {
    fontSize: theme.fontSizes.md,
    marginBottom: theme.spacing.xs,
    color: '#9E9999',
  },
  link: {
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      padding: '12px 0',
    },
  },
}));

interface FooterGridElementProps {
  title: ILabel;
  subText?: ILabel;
  links: IBlock;
  color?: IColor;
  linkColor?: string;
  accordionMobile: boolean;
  children?: React.ReactNode;
}
const FooterGridElement = ({
  title,
  subText,
  links,
  color,
  linkColor,
  accordionMobile = false,
  children,
}: FooterGridElementProps) => {
  const { classes } = useStyles();

  const Links = useMemo(
    () =>
      links?.fields?.items?.map((link, nb) => (
        <TextLink
          icon={(link as ILink)?.fields?.icon?.fields}
          url={
            ((link as ILink).fields.slug?.fields.url ||
              (link as ILink).fields.urlLink) as string
          }
          size={16}
          text={(link as ILink).fields.label?.fields.text as string}
          color={linkColor || 'white'}
          rel={(link as ILink).fields.rel}
          key={`${(link as unknown as { id: string }).id}_${nb}`}
          isFadeIn
        />
      )),
    [links],
  );

  return (
    <div
      className={classes.wrapper}
      key={(title as unknown as { id: string }).id}>
      <MediaQuery
        smallerThan='sm'
        styles={{ display: 'none' }}>
        <div className={classes.wrapper}>
          <div className={classes.titleWrapper}>
            {color ? (
              <Minus
                size={16}
                strokeWidth={3}
                color={color?.fields?.hex}
              />
            ) : null}
            <NoozText
              size={18}
              component='h4'
              className={classes.title}>
              {title.fields.text}
            </NoozText>
          </div>
          {subText ? (
            <NoozText className={classes.subText}>
              {subText.fields.text}
            </NoozText>
          ) : null}
          {Links}
          {children}
        </div>
      </MediaQuery>
      <MediaQuery
        largerThan='sm'
        styles={{ display: 'none' }}>
        <div>
          {accordionMobile ? (
            <>
              <Divider />

              <Accordion.Item value={(title as unknown as { id: string }).id}>
                <Accordion.Control>{title.fields.text}</Accordion.Control>
                <Accordion.Panel>
                  {subText ? (
                    <NoozText className={classes.subText}>
                      {subText.fields.text}
                    </NoozText>
                  ) : null}
                  {Links}
                  {children}
                </Accordion.Panel>
              </Accordion.Item>
            </>
          ) : (
            <>
              <Divider
                label={title.fields.text}
                labelProps={{
                  color: 'white',
                  size: '14px',
                  weight: '500',
                  mb: '8px',
                }}
              />
              {subText ? (
                <NoozText className={classes.subText}>
                  {subText.fields.text}
                </NoozText>
              ) : null}
              {Links}
              {children}
            </>
          )}
        </div>
      </MediaQuery>
    </div>
  );
};

export const FooterLocalisation = (props: FooterGridElementProps) => {
  const router = useRouter();
  const { classes } = useStyles();
  const { locale, localizedSlugs } = usePageContext();

  const { language, country } = useMemo(() => {
    const { language } = LocaleHelper.splitInfoWithInt(
      locale as string,
      false,
    ) as any;
    return {
      country: getCountryCodeLocal(locale),
      language,
    };
  }, [locale]);

  const onChangeCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e?.currentTarget?.value) {
      // @ts-ignore
      const newLocale = LocaleHelper.getLocale(
        // @ts-ignore
        `${COUNTRIES[e.currentTarget.value].languages[0].key}-${
          // @ts-ignore
          e.currentTarget.value
        }`,
      );

      if (typeof window !== 'undefined')
        window.location.href = `/${newLocale}/${
          localizedSlugs?.[
            LocaleHelper.getValidContentfulLocale(newLocale as string)
          ] ||
          localizedSlugs?.en ||
          ''
        }`;
    }
  };
  const onChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e?.currentTarget?.value) {
      const newLocale = `${e?.currentTarget?.value}-${country}`;

      router.push(
        `/${newLocale}/${
          localizedSlugs?.[
            LocaleHelper.getValidContentfulLocale(newLocale as string)
          ] ||
          localizedSlugs?.en ||
          ''
        }`,
      );
    }
  };

  return (
    <FooterGridElement {...props}>
      <Group mt={20}>
        <NativeSelect
          className={classes.localizationSelect}
          radius={'xl'}
          data={
            Object.keys(COUNTRIES)?.map((c) => ({
              label:
                // @ts-ignore
                COUNTRIES[c as keyof typeof COUNTRIES].labelTranslations[
                  (locale?.split('-')[0] || 'en') as keyof ICountryDictionary
                ],
              value: c,
            })) || []
          }
          icon={
            // @ts-ignore
            COUNTRIES[country]?.flag ? (
              <Image
                alt={`${country} country flag`}
                // @ts-ignore
                src={COUNTRIES[country].flag}
                width={15}
                height={12}
                unoptimized
              />
            ) : undefined
          }
          value={country}
          onChange={onChangeCountry}
        />

        <NativeSelect
          className={classes.localizationSelect}
          radius={'xl'}
          data={
            // @ts-ignore
            COUNTRIES[country]?.languages.map((l: any) => ({
              value: l.key,
              label: l.labelTranslations[language],
            })) || []
          }
          // @ts-ignore
          disabled={COUNTRIES[country]?.languages.length === 1}
          icon={<Language size={14} />}
          value={language}
          onChange={onChangeLanguage}
        />
      </Group>
    </FooterGridElement>
  );
};

export default FooterGridElement;
