import { createStyles } from '@mantine/core';

export const DESKTOP_CONTAINER_PADDING = 64;
export const MOBILE_CONTAINER_PADDING = 16;

export const useContainerStyles = createStyles((theme) => ({
  container: {
    maxWidth: 'unset',
    padding: `0 ${DESKTOP_CONTAINER_PADDING}px`,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      padding: `0 ${MOBILE_CONTAINER_PADDING}px`,
    },
  },
  categoryContainer: {
    maxWidth: 'unset',
    padding: `0 ${DESKTOP_CONTAINER_PADDING}px`,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      padding: `0 ${MOBILE_CONTAINER_PADDING / 2}px`,
    },
  },
  containerLeftFullWidth: {
    maxWidth: 'unset',
    paddingLeft: DESKTOP_CONTAINER_PADDING,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      padding: `0 ${MOBILE_CONTAINER_PADDING}px`,
    },
  },
  containerFullWidth: {
    marginLeft: `-${DESKTOP_CONTAINER_PADDING}px`,
    marginRight: `-${DESKTOP_CONTAINER_PADDING}px`,
    [theme.fn.smallerThan(theme.breakpoints.lg)]: {
      marginLeft: `-${MOBILE_CONTAINER_PADDING}px`,
      marginRight: `-${MOBILE_CONTAINER_PADDING}px`,
    },
  },
  sliderFullContainer: {
    '& > .wrapper > div': {
      paddingLeft: `${DESKTOP_CONTAINER_PADDING}px`,
      paddingRight: `${DESKTOP_CONTAINER_PADDING}px`,
      [theme.fn.smallerThan(theme.breakpoints.md)]: {
        paddingLeft: MOBILE_CONTAINER_PADDING,
        paddingRight: MOBILE_CONTAINER_PADDING,
      },
    },
  },
}));

export const useBreakpointClasses = () =>
  createStyles((theme) => ({
    desktopOnly: {
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        display: 'none',
      },
    },
    mobileOnly: {
      [theme.fn.largerThan(theme.breakpoints.sm)]: {
        display: 'none',
      },
    },
  }))().classes;
