//@ts-nocheck
import React, { createContext, useEffect, useRef, useState } from 'react';
import { IBlock } from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import {
  createStyles,
  Header as HeaderMantine,
  Group,
  Box,
  Flex,
  DrawerProps,
  MediaQuery,
  Divider,
  useMantineTheme,
} from '@mantine/core';
import usePageContext from '@/utils/context/page';
import useScroll from '@/utils/hook/useScroll';
import HeaderDesktopNavigations from './HeaderNavigations/HeaderDesktopNavigations';
import HeaderDrawer from './HeaderDrawer/HeaderDrawer';
import HeaderAction from './HeaderAction/HeaderAction';
import HeaderBanner from './HeaderBanner/headerBanner';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import HeaderMobileNavigations from './HeaderNavigations/HeaderMobileNavigations';
import SearchDrawer, {
  SearchDrawerContext,
  searchDrawerInitState,
} from '../Search/SearchDrawer/SearchDrawer';
import { TIMING_FUNCTION } from '@/utils/style/animation';
import { subscribe, unsubscribe } from '@/helpers/events';
import zIndexes from '@/utils/style/zIndex';
import HeaderLogo from './HeaderLogo/HeaderLogo';
import StickySubHeader from '@/components/Header/StickySubHeader';
import { useMediaQuery } from '@mantine/hooks';
import useHasDefaultHeader from '@/utils/hook/cart/useHasDefaultHeader';
import Link from '@/components/Link/Link';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';

interface IStyleProps {
  headerHeight: number;
  isScrollingDown: boolean;
  isFixed: boolean;
}

export const useHeaderStyles = createStyles(
  (theme, { isScrollingDown, headerHeight }: IStyleProps) => {
    const { colors } = theme;

    return {
      wrapper: {
        position: 'fixed',
        width: '100%',
        zIndex: zIndexes.appbar,
        transform: `translate(0px, -${isScrollingDown ? headerHeight : 0}px)`,
        transition:
          'transform 0.5s, background 0.5s, borderBottom 0.5s ease-in-out',
        transitionTimingFunction: TIMING_FUNCTION,
      },

      header: {
        transform: `translate(0px, -${isScrollingDown ? headerHeight : 0}px)`,
        transition:
          'transform 0.5s, background 0.5s, borderBottom 0.5s ease-in-out',
        transitionTimingFunction: TIMING_FUNCTION,
        borderBottom: `${colors.gray[3]} 1px solid`,
        background: 'white',
        '> *': {
          transition: 'color 0.2s ease-in-out',
          transitionTimingFunction: TIMING_FUNCTION,
          color: colors.dark,
        },
      },

      inner: {
        padding: '0 30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.fn.smallerThan('md')]: {
          padding: '0 15px',
        },
      },

      links: {
        [theme.fn.smallerThan('md')]: {
          display: 'none',
        },
      },
    };
  },
);

export const headerDrawerInitState: DrawerProps = {
  opened: false,
  position: 'left',
  onClose: () => {},
  zIndex: zIndexes.drawer,
  padding: 0,
};

export const HeaderDrawerContext = createContext({
  headerDrawerState: [] as [
    DrawerProps,
    React.Dispatch<React.SetStateAction<DrawerProps>>,
  ],
  headerDrawerBodyState: [],
});

export const HeaderActiveTabContext = createContext({
  headerActiveTabState: [] as [any, React.Dispatch<React.SetStateAction<any>>],
});

const HeaderWrapper = () => {
  const elementOutOfWindow = useRef<undefined | boolean>();
  const { isScrollingDown } = useScroll();
  const { layout } = usePageContext();
  const headerHeight = useHeaderHeight();

  const headerActiveTabState = useState<any>();
  const headerDrawerState = useState(headerDrawerInitState);
  const headerDrawerBodyState = useState();
  const searchDrawerState = useState(searchDrawerInitState);

  const {
    navigations,
    actions,
    banner,
    sticky,
  }: { sticky: IBlock; navigations: IBlock; actions: IBlock; banner: IBlock } =
    formatCfItems(layout?.fields.header);

  const {
    collectionsList,
    servicesList,
    stores,
  }: {
    collectionsList: IBlock;
    servicesList: IBlock;
    featuredCollection: IBlock;
    stores: IBlock;
  } = formatCfItems(navigations);

  const theme = useMantineTheme();
  const mediaQuery = theme.fn.smallerThan('md');
  const isMobile = useMediaQuery(mediaQuery.split('@media ')[1]);

  const { classes } = useHeaderStyles({
    isScrollingDown: isMobile
      ? false
      : (typeof elementOutOfWindow.current === 'boolean'
          ? elementOutOfWindow.current
          : true) && isScrollingDown,
    isFixed: true,
    headerHeight: headerHeight.wrapper,
    headerActiveTabState: headerDrawerState[0].opened,
  });

  useEffect(() => {
    const fct = (evt) => {
      if (typeof evt.detail.outOfWindow === 'boolean') {
        elementOutOfWindow.current = evt.detail.outOfWindow;
      } else {
        elementOutOfWindow.current = undefined;
      }
    };

    subscribe('header', fct);

    return () => {
      unsubscribe('header', fct);
    };
  }, []);

  return (
    <>
      <SearchDrawerContext.Provider value={searchDrawerState}>
        <HeaderDrawerContext.Provider
          value={{ headerDrawerState, headerDrawerBodyState }}>
          <HeaderActiveTabContext.Provider value={{ headerActiveTabState }}>
            <Box className={classes.wrapper}>
              <HeaderDrawer />
              <SearchDrawer />
              <HeaderMantine
                withBorder={false}
                height={headerHeight.wrapper}
                className={classes.header}>
                <HeaderBanner banner={banner} />
                <Flex
                  h={
                    headerHeight.wrapper -
                    headerHeight.banner -
                    headerHeight.stickyHeight
                  }
                  className={classes.inner}>
                  <MediaQuery
                    smallerThan='md'
                    styles={{ display: 'none' }}>
                    <Group spacing={20}>
                      <HeaderDesktopNavigations list={collectionsList} />
                    </Group>
                  </MediaQuery>
                  <MediaQuery
                    largerThan='md'
                    styles={{ display: 'none' }}>
                    <span>
                      <HeaderMobileNavigations />
                    </span>
                  </MediaQuery>
                  <HeaderLogo />

                  <MediaQuery
                    smallerThan='md'
                    styles={{ display: 'none' }}>
                    <Group
                      spacing={10}
                      className={classes.links}>
                      <Group spacing={20}>
                        <HeaderDesktopNavigations
                          list={stores}
                          drawerPosition='right'
                        />
                        <Divider orientation='vertical' />
                        <HeaderDesktopNavigations
                          drawerPosition='right'
                          list={servicesList}
                        />
                      </Group>
                      <HeaderAction identifier={'desktop_view'} />
                    </Group>
                  </MediaQuery>
                  <MediaQuery
                    largerThan='md'
                    styles={{ display: 'none' }}>
                    <Group spacing={10}>
                      <HeaderAction identifier={'mobile-view'} />
                    </Group>
                  </MediaQuery>
                </Flex>
                <StickySubHeader sticky={sticky} />
              </HeaderMantine>
            </Box>
          </HeaderActiveTabContext.Provider>
        </HeaderDrawerContext.Provider>
      </SearchDrawerContext.Provider>
    </>
  );
};

const Header = () => {
  const hasDefaultHeader = useHasDefaultHeader();
  const headerHeight = useHeaderHeight();
  return hasDefaultHeader ? (
    <HeaderWrapper />
  ) : (
    <Flex
      align={'center'}
      justify={'center'}
      h={
        headerHeight.wrapper - headerHeight.banner - headerHeight.stickyHeight
      }>
      <Link href='/'>
        <AnimatePresence initial={false}>
          <motion.div
            {...{
              initial: { opacity: 0 },
              animate: { opacity: 1 },
              exit: { opacity: 0 },
              transition: { ease: 'easeOut', duration: 0.5, delay: 0.3 },
            }}>
            <Image
              src={'/assets/logo-black.svg'}
              alt='Black logo'
              width={90}
              height={25}
              unoptimized
              priority
            />
          </motion.div>
        </AnimatePresence>
      </Link>
    </Flex>
  );
};

export { Header };
