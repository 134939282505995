// @ts-nocheck
import React, { useMemo, useState, useCallback } from 'react';
import { Box, createStyles, useMantineTheme } from '@mantine/core';
import DataLayerHelper from '@/helpers/Datalayer';
import { useMediaQuery } from '@mantine/hooks';
import ProductCardDefault from './ProductCardDefault';
import ProductCardMini from './ProductCardMini';
import ProductCardSimple from './ProductCardSimple';
import Link from '@/components/Link/Link';
import ProductCardNew from '@/components/Collection/ProductCard/ProductCardNew';
import { ShopItem } from '@/utils/context/page';

const useStyles = createStyles((theme) => ({
  link: {
    textDecoration: 'unset',
    height: '100%',
  },
  labelDesktop: {
    fontWeight: 500,
    fontSize: 18,
    display: 'inline-block',
  },
}));

interface ProductCardProps {
  item: ShopItem;
  position: number;
  type?: 'default' | 'simple' | 'new';
  hasPicker?: boolean;
  searchParams?: Record<string, string>;
  customElements?: any;
  newIsVariantCustom?: boolean;
  forceMini?: boolean;
}

const ProductCard = ({
  item,
  position,
  type,
  searchParams,
  customElements,
  newIsVariantCustom,
  forceMini,
}: ProductCardProps) => {
  const theme = useMantineTheme();
  const mediaQuery = theme.fn.smallerThan('xs');
  const matches = useMediaQuery(mediaQuery.split('@media ')[1]);

  const Component = useMemo(() => {
    switch (type) {
      case 'new':
        return ProductCardNew;
      case 'simple':
        return ProductCardSimple;
      default:
        return matches || forceMini ? ProductCardMini : ProductCardDefault;
    }
  }, [type, matches, forceMini]);

  const { product, defaultVariantId, variants } = item;
  const { classes } = useStyles();

  const [variant, setVariant] = useState(
    variants?.find((v) => v.id === defaultVariantId),
  );

  const productPath = product?.url || '#';

  const productQuery = useMemo(() => {
    let query = { color: variant?.colorShopifyKey };

    if (searchParams) query = { ...query, ...searchParams };

    return query;
  }, [variant, searchParams]);

  const label = useMemo(() => {
    return product ? (
      <>
        <span>
          <span
            className={classes.labelDesktop}
            style={{
              color: product.categoryTitle?.includes('Bluelight')
                ? theme.colors.blue[8]
                : '',
            }}>
            {type === 'new' ? (
              <b>{`${[product.categoryShortLabel, product.label]
                .filter((f) => !!f)
                .join(' ')}`}</b>
            ) : (
              <>{`${[product.categoryLabel, product.label]
                .filter((f) => !!f)
                .join(' ')}`}</>
            )}
          </span>
        </span>
        <span
          style={{
            display: 'block',
            color: theme.colors.gray[6],
            fontSize: 18,
            fontWeight: 400,
            fontStyle: 'italic',
          }}>
          {`${variant?.colorText || ''}`}
        </span>
      </>
    ) : null;
  }, [type, variant]);

  const onClick = useCallback(() => {
    DataLayerHelper.pushSelectItemEvent(variant, product);
  }, [variant]);

  return variant ? (
    <Box
      component='article'
      onClick={() => {}}>
      <Link
        rel='bookmark'
        id={`ProductCard_${item.defaultVariantId}`}
        className={classes.link}
        href={productPath}
        query={productQuery}
        onClick={onClick}>
        <Component
          item={item}
          premiumEnabled={product.isPremium}
          label={label}
          variant={variant}
          setVariant={setVariant}
          position={position}
          newIsVariantCustom={newIsVariantCustom}
          forceMini={forceMini}
        />
        {customElements
          ? customElements({
              defaultProductVariant,
              product,
              item,
            })
          : null}
      </Link>
    </Box>
  ) : null;
};

export default ProductCard;
