import React, {
  StrictMode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, clsx, createStyles, Group } from '@mantine/core';
import TextLink from '@/components/Link/TextLink';
import { IBlock, ILink } from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import { motion, AnimatePresence } from 'framer-motion';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import useCommerceContext from '@/utils/context/commerce';
import usePageContext from '@/utils/context/page';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Currency from '@/helpers/Currency';
import useDrawerContext, {
  drawerContextInitState,
} from '@/utils/context/drawer';
import LocalisationSideView from '@/components/LocalisationSideView/LocalisationSideView';
import LocaleHelper, { getCountryLocal } from '@/helpers/Locale';
import {
  HeaderDrawerContext,
  headerDrawerInitState,
} from '@/components/Header/Header';
import useShippingRates from '@/utils/hook/useShippingRates';

export const FREE_SHIPPING_ENABLED =
  (process.env.NEXT_PUBLIC_FREE_SHIPPING_ENABLED || '') === 'true';

const useStyles = createStyles((theme) => {
  return {
    container: {
      width: '100%',
      background: 'black',
      position: 'sticky',
    },
    bodies: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      [theme.fn.smallerThan('md')]: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        left: 'unset',
        transform: 'unset',
      },
    },
    bodyItem: {
      width: '100%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      fontSize: theme.fontSizes.sm,
      [theme.fn.smallerThan('md')]: {
        fontSize: theme.fontSizes.xs,
      },
    },
    link: {
      cursor: 'pointer',
      '&:not(.main-link)': {
        [theme.fn.smallerThan('md')]: {
          display: 'none !important',
        },
      },
    },
    country: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
  };
});

const HeaderBannerBodies = ({ bodies }: { bodies: IBlock }) => {
  const { classes } = useStyles();
  const { shippingZone } = usePageContext();
  const shippingRates = useShippingRates();
  const { freeShipping, cart } = useCommerceContext();

  const [bodyIndex, setBodyIndex] = useState(0);
  let timer: any;

  const bannerBodies = useMemo(() => {
    const list = [
      ...(bodies?.fields?.items?.map((i, key) => {
        if (i.fields.key === 'linkFreeShipping' && !FREE_SHIPPING_ENABLED)
          return null;

        switch (i.fields.key) {
          case 'label':
            return (
              <React.Fragment key={key}>
                {i.fields.text as string}
              </React.Fragment>
            );
          case 'linkFreeShipping':
          case 'link':
            return (
              <HeaderBannerLink
                classNames={'main-link'}
                key={key}
                link={i as ILink}
              />
            );
          default:
            return null;
        }
      }) || []),
    ].filter((f) => !!f);

    if (
      !FREE_SHIPPING_ENABLED &&
      freeShipping &&
      freeShipping?.isActive &&
      shippingRates?.free
    ) {
      const freeShippingCaption = shippingZone?.label?.fields.text?.replace(
        ' FreeShippingCaption - Component  ',
        Currency.moneyToPrice({
          amount: shippingRates.free.min_order_subtotal.toString(),
          currencyCode: shippingRates.free?.price?.currencyCode as string,
        }) || '',
      );
      list.push(
        <React.Fragment key={'freeShippingCaption'}>
          {freeShippingCaption}
        </React.Fragment>,
      );
    }

    return list;
  }, [bodies, shippingRates]);

  const updateCount = () => {
    timer = setInterval(() => {
      setBodyIndex((count) =>
        count === bannerBodies.length - 1 ? 0 : count + 1,
      );
    }, 3000);
  };

  useEffect(() => {
    updateCount();
    return () => timer && clearInterval(timer);
  }, []);

  return (
    <div className={classes.bodies}>
      {bannerBodies?.map((item, index) => {
        return (
          bodyIndex === index && (
            <AnimatePresence key={index}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={classes.bodyItem}
                transition={{ ease: 'easeOut', duration: 0.6, delay: 0.3 }}>
                <NoozText
                  key={index}
                  color='white'
                  truncate>
                  {item as any}
                </NoozText>
              </motion.div>
            </AnimatePresence>
          )
        );
      })}
    </div>
  );
};

const HeaderBannerLink = ({
  link,
  classNames,
}: {
  link: ILink;
  classNames?: string;
}) => {
  const { classes } = useStyles();
  return link ? (
    <TextLink
      className={clsx(classes.link, classNames)}
      url={link.fields.slug?.fields.url as string}
      text={link.fields.label?.fields.text as string}
      size={14}
      color='white'
      type={'animated-underline'}
    />
  ) : (
    <Box
      sx={(theme) => ({
        [theme.fn.smallerThan(theme.breakpoints.md)]: {
          display: 'none',
        },
      })}
    />
  );
};

const HeaderBanner = ({ banner }: { banner: IBlock }) => {
  const { locale } = usePageContext();
  const country = useRef<any>(getCountryLocal(locale));

  const { classes } = useStyles();
  const headerHeight = useHeaderHeight();
  const { primaryLink, bodies, localization } = formatCfItems(banner);

  const { headerDrawerState, headerDrawerBodyState } =
    useContext(HeaderDrawerContext);
  const { label } = formatCfItems(localization);
  const { drawer, setDrawer } = useDrawerContext();
  const handleZoneSwitchClick = () => {
    if (drawer.opened && drawer.title === label?.fields.text) {
      setDrawer({
        ...drawerContextInitState.drawer,
        opened: false,
      });
    } else if (drawer.opened) {
      setDrawer({
        ...drawerContextInitState.drawer,
        opened: true,
        title: label?.fields.text,
        withCloseButton: true,
        body: <LocalisationSideView />,
      });
      // @ts-ignore
      headerDrawerState[1]({
        ...headerDrawerInitState,
        opened: false,
        size: 0,
      });
    } else {
      setDrawer({
        ...drawerContextInitState.drawer,
        opened: true,
        title: label?.fields.text,
        withCloseButton: true,
        body: <LocalisationSideView />,
      });
      // @ts-ignore
      headerDrawerState[1]({
        ...headerDrawerInitState,
        opened: false,
        size: 0,
      });
    }
  };

  return (
    <StrictMode>
      <Box
        px={headerHeight.banner}
        className={classes.container}>
        <Group
          h={headerHeight.banner}
          align='center'
          position='apart'>
          <HeaderBannerLink link={primaryLink} />
          <HeaderBannerBodies bodies={bodies} />
          <NoozText
            className={clsx(classes.link, classes.country)}
            size={14}
            color='white'
            onClick={handleZoneSwitchClick}>
            {`${
              // @ts-ignore
              country.current?.labelTranslations[
                LocaleHelper.getValidContentfulLang(locale)
              ] || ''
            }`}
            {/*// @ts-ignore*/}
            {/* eslint-disable-next-line @next/next/no-img-element */}
            {country.current?.flag ? (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                alt={country.current.label}
                height={16}
                src={country.current.flag}
              />
            ) : null}
          </NoozText>
        </Group>
      </Box>
    </StrictMode>
  );
};

export default HeaderBanner;
