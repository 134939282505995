import usePageContext from '@/utils/context/page';
import { useMemo } from 'react';
import { IPage } from '@/@types/generated/contentful';

const useStaticContent = () => {
  const { page, staticContent: sc } = usePageContext();

  return useMemo(
    // @ts-ignore
    () => ({ ...((page as IPage)?.fields.pageType.staticItems || {}), ...sc }),
    [page, sc],
  ) as Record<string, any>;
};

export default useStaticContent;
