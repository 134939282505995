import React, { ReactNode, useContext } from 'react';
import { Drawer, createStyles } from '@mantine/core';
import { IDrawer, drawerContextInitState } from '@/utils/context/drawer';
import zIndexes from '@/utils/style/zIndex';

export type ITeaserPopupDrawerContext = {
  drawer: IDrawer;
  setDrawer: (drawer: IDrawer) => void;
};

export const TeaserPopupDrawerContext =
  React.createContext<ITeaserPopupDrawerContext>(drawerContextInitState);

const useStyles = createStyles({
  header: {
    position: 'absolute',
    right: 0,
    background: 'none',
  },
  body: {
    padding: 0,
  },
  close: {
    background: 'white',
    color: 'black',
  },
});

export const useTeaserPopupDrawerContext = (): ITeaserPopupDrawerContext => {
  const context = React.useContext(TeaserPopupDrawerContext);
  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
};

export const TeaserPopupDrawerProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [drawer, setDrawer] = React.useState<IDrawer>({
    opened: false,
    body: '',
    footer: '',
    onClose: () => {},
  });

  return (
    <TeaserPopupDrawerContext.Provider value={{ drawer, setDrawer }}>
      {children}
    </TeaserPopupDrawerContext.Provider>
  );
};

export const TeaserPopupDrawer = () => {
  const { classes } = useStyles();
  const { drawer } = useContext(
    TeaserPopupDrawerContext,
  ) as ITeaserPopupDrawerContext;
  return (
    <Drawer
      {...drawer}
      size={'auto'}
      classNames={{
        header: classes.header,
        body: classes.body,
        close: classes.close,
      }}
      zIndex={zIndexes.modal}>
      {drawer.body}
    </Drawer>
  );
};
