import React, { useEffect, useState } from 'react';
import ProductCard from '@/components/Collection/ProductCard/ProductCard';
import {
  Box,
  Center,
  Container,
  MediaQuery,
  clsx,
  createStyles,
  useMantineTheme,
  Group,
} from '@mantine/core';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { formatCfItems } from '@/helpers/formatCfItems';
import {
  IBlock,
  IButton,
  IColor,
  ILabel,
  IPath,
} from '@/@types/generated/contentful';
import useLocalizedSlug from '@/utils/hook/useLocalizedSlug';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mantine/hooks';
import useScrollbarClasses from '@/utils/useScrollbarClasses';
import { useCarouselIndicatorsStyles } from '@/utils/style/useCarouselIndicatorStyle';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { Carousel } from '@mantine/carousel';
import { CAROUSEL_PROPS as DEFAULT_CAROUSEL_PROPS } from '@/utils/style/carousel';
import { ShopItem } from '@/@types/shopitem';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: 42,

    [theme.fn.smallerThan('md')]: {
      fontSize: 28,
      fontWeight: 600,
    },
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      marginLeft: 16,
    },
  },
  buttonsGroup: {
    maxWidth: 'unset',
    padding: '0 64px',
    [theme.fn.smallerThan('sm')]: {
      padding: 0,
    },
  },
  viewport: {
    margin: 0,
    padding: '0 64px',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      padding: '0 16px',
    },
  },
}));

const useCarouselListIndicatorStyles = createStyles((theme) => ({
  controls: {
    height: '100%',
    display: 'flex',
    top: '0px !important',
    alignItems: 'center',
    padding: '0px 43px',
  },
  control: {
    padding: 6,
    borderRadius: '100%',
    background: theme.colors.gray[9],
  },
  viewport: {
    margin: '0',
  },
  [theme.fn.smallerThan('md')]: {
    viewport: {
      margin: '0px',
    },
  },
}));

const List = ({ list, prefixKey }: { prefixKey: number; list: ShopItem[] }) => {
  const theme = useMantineTheme();
  const classes = useStyles().classes;

  const classnames = {
    ...useCarouselIndicatorsStyles({}).classes,
    ...useCarouselListIndicatorStyles().classes,
    viewport: clsx(classes.viewport),
  };

  const carouselProps = {
    classNames: classnames,
    dragFree: true,
    loop: false,
    withControls: true,
    withIndicators: false,
    nextControlIcon: (
      <NoozIcon
        size={28}
        color='white'
        iconKey='ChevronRight'
      />
    ),
    previousControlIcon: (
      <NoozIcon
        size={28}
        color='white'
        iconKey='ChevronLeft'
      />
    ),
    height: '100%',
  };

  return (
    <>
      <MediaQuery
        smallerThan={theme.breakpoints.lg}
        styles={{ display: 'none' }}>
        <span>
          <Carousel
            {...DEFAULT_CAROUSEL_PROPS}
            {...carouselProps}
            align={'start'}
            slideGap={20}
            slideSize={'25%'}>
            {list?.map((hit: ShopItem, index: number) => (
              <Carousel.Slide
                key={`${prefixKey}-${index}-${hit.defaultVariantId}`}>
                <Box>
                  <ProductCard
                    position={index}
                    item={hit}
                  />
                </Box>
              </Carousel.Slide>
            ))}
          </Carousel>
        </span>
      </MediaQuery>
      <MediaQuery
        largerThan={theme.breakpoints.lg}
        styles={{ display: 'none' }}>
        <span>
          <MediaQuery
            smallerThan={theme.breakpoints.sm}
            styles={{ display: 'none' }}>
            <span>
              <Carousel
                {...DEFAULT_CAROUSEL_PROPS}
                {...carouselProps}
                align={'start'}
                slideGap={20}
                slideSize={'45%'}>
                {list?.map((hit: ShopItem, index: number) => (
                  <Carousel.Slide
                    key={`md-${prefixKey}-${index}-${hit.defaultVariantId}`}>
                    <Box>
                      <ProductCard
                        position={index}
                        item={hit}
                      />
                    </Box>
                  </Carousel.Slide>
                ))}
              </Carousel>
            </span>
          </MediaQuery>
        </span>
      </MediaQuery>
      <MediaQuery
        largerThan={theme.breakpoints.sm}
        styles={{ display: 'none' }}>
        <span>
          <Carousel
            {...DEFAULT_CAROUSEL_PROPS}
            {...carouselProps}
            align={'center'}
            withControls={false}
            loop={true}
            dragFree={false}
            slideGap={10}
            slideSize={'80%'}>
            {list?.map((hit: ShopItem, index: number) => (
              <Carousel.Slide
                key={`sm-${prefixKey}-${index}-${hit.defaultVariantId}`}>
                <Box>
                  <ProductCard
                    position={index}
                    item={hit}
                  />
                </Box>
              </Carousel.Slide>
            ))}
          </Carousel>
        </span>
      </MediaQuery>
    </>
  );
};

const Selector = ({
  item,
  index,
  onClick,
  activeList,
}: {
  item: IBlock;
  index: number;
  onClick: () => void;
  activeList: number;
}) => {
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  const { label, color }: { label: ILabel; color: IColor } =
    formatCfItems(item);

  const isSelected = activeList === index;

  return (
    <NoozButton
      onClick={onClick}
      variant={'filled'}
      color={isSelected ? color?.fields.classname || 'dark' : 'lightGray'}
      size={'md'}
      radius='xl'>
      <NoozText
        size={16}
        color={isSelected ? 'white' : 'text'}
        weight={400}>
        {label.fields.text}
      </NoozText>
    </NoozButton>
  );
};

const Button = ({
  item,
  index,
  activeList,
}: {
  item: IBlock;
  index: number;
  activeList: number;
}) => {
  const {
    button,
    color,
    slug,
  }: { button: IButton; color: IColor; slug: IPath } = formatCfItems(item);

  const router = useRouter();

  const localisedSLug = useLocalizedSlug(slug?.fields.url as string);

  if (!button) return null;

  const isSelected = activeList === index;

  return isSelected && slug?.fields.url ? (
    <NoozButton
      onClick={() => {
        router.push(localisedSLug);
      }}
      variant={'filled'}
      color={color?.fields.classname || 'dark'}
      size='lg'
      radius='xl'>
      <NoozText
        size={18}
        weight={400}>
        {button.fields.label?.fields.text}
      </NoozText>
    </NoozButton>
  ) : null;
};

const ProductCardListsSelector = ({
  title,
  facets,
}: {
  title: ILabel;
  facets: IBlock;
}) => {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();
  const [lists, setLists] = useState<ShopItem[][]>(() =>
    (facets.fields.items || [])?.map((i: any) => i.shopitems?.items || [[]]),
  );
  const [active, setActive] = useState(0);
  const scrollbarClasses = useScrollbarClasses();

  useEffect(() => {
    // Promise.all(
    //   (facets.fields.items || []).map((l: any) =>
    //     axios.get(
    //       `${process.env.NEXT_PUBLIC_DOMAIN || ''}/json/${
    //         (l as any).id
    //       }.${locale}.json`,
    //     ),
    //   ),
    // )
    //   .then((res) => {
    //     setLists(
    //       res.map((r, idx) => {
    //         return (facets.fields.items?.[idx].fields.items as any[])
    //           ?.filter(
    //             (i: any) =>
    //               i.fields.attribute === 'defaultProductVariant.fields.title',
    //           )
    //           .reduce((arr: any[], el: any) => {
    //             if (
    //               r.data.hits.find(
    //                 (i: any) =>
    //                   i.defaultProductVariant.fields.title === el.fields.value,
    //               )
    //             )
    //               arr.push(
    //                 r.data.hits.find(
    //                   (i: any) =>
    //                     i.defaultProductVariant.fields.title ===
    //                     el.fields.value,
    //                 ),
    //               );
    //             return arr;
    //           }, []);
    //       }),
    //     );
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  }, []);

  return (
    <>
      <Container
        mb={40}
        className={clsx(classes.buttonsGroup)}>
        <Group position='apart'>
          <NoozText className={classes.label}>{title?.fields.text}</NoozText>
          <Group
            noWrap
            className={scrollbarClasses.noScrollbar}
            sx={(theme) => ({
              overflowX: 'auto',
              overflowY: 'visible',
              [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                paddingRight: 16,
                paddingLeft: 16,
              },
            })}>
            {facets?.fields?.items?.map((selector: any, index: number) => {
              return (
                <Selector
                  key={index}
                  index={index}
                  item={selector}
                  activeList={active}
                  onClick={() => {
                    const list = lists[index];
                    const sFacets = selector.fields.items
                      .filter(
                        (i: any) =>
                          !!i.fields.attribute &&
                          /^defaultProductVariant/.test(i.fields.attribute),
                      )
                      .map((i: any) => ({
                        keys: i.fields.attribute.split('.'),
                        value: i.fields.value.trim(),
                      }));

                    setActive(index);
                  }}
                />
              );
            })}
          </Group>
        </Group>
      </Container>

      <List
        key={active}
        prefixKey={active}
        list={lists[active]}
      />
      <Center
        className={clsx(containerStyles.classes.container)}
        mt={40}
        w='100%'>
        {facets?.fields?.items?.map((selector: any, index: number) => {
          return (
            <Button
              key={index}
              item={selector}
              activeList={active}
              index={index}
            />
          );
        })}
      </Center>
    </>
  );
};

export default ProductCardListsSelector;
