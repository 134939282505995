import { clsx, Flex, ScrollArea } from '@mantine/core';
import useClasses, {
  CROSS_SELL_WIDTH,
} from '@/components/Cart/CartDrawer/useClasses';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import React, { useEffect, useMemo } from 'react';
import useCommerceContext from '@/utils/context/commerce';
import ShopifyBuy from 'shopify-buy';
import useMount from '@/utils/hook/useMount';
import CartDrawerUpsellCarousel from '@/components/Cart/CartDrawer/CartDrawerUpsellCarousel';
import CartDrawerProgressBar from '@/components/Cart/CartDrawer/CartDrawerProgressBar';
import CartDrawerLineItems from '@/components/Cart/CartDrawer/CartDrawerLineItems';
import CartDrawerHeader from '@/components/Cart/CartDrawer/CartDrawerHeader';
import useCartItems from '@/utils/hook/useCartItems';
import CartDrawerReassurance from '@/components/Cart/CartDrawer/CartDrawerReassurance';
import CartDrawerCheckoutTotal from '@/components/Cart/CartDrawer/CartDrawerCheckoutTotal';
import { useRouter } from 'next/router';
import CartDrawerCrossSell from '@/components/Cart/CartDrawer/CartDrawerCrossSell';

export const cssGapDrawer = 26;

const CartDrawer = ({ onClose }: { onClose: () => void }) => {
  const { recommendations: reco } = useCommerceContext();
  const lineItems = useCartItems();
  const classes = useClasses(0);
  const { layout } = usePageContext();
  const { myCartLabel, addUpsellLabel, soldOut } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', onClose);
    return () => {
      router.events.off('routeChangeStart', onClose);
    };
  }, []);

  const { cart } = useCommerceContext() as unknown as {
    client: ShopifyBuy;
    cart: ShopifyBuy.Checkout;
    setCart: React.Dispatch<React.SetStateAction<ShopifyBuy.Checkout | null>>;
  };

  const hasMount = useMount();
  return (
    <>
      <Flex
        direction={'row-reverse'}
        pos={'relative'}
        h={'100%'}>
        <Flex
          w={{ base: 0, sm: CROSS_SELL_WIDTH }}
          direction={'column'}
          className={clsx(
            classes.crossSell,
            hasMount && reco.length > 0 && classes.crossSellAnim,
          )}>
          <CartDrawerCrossSell closeDrawer={onClose} />
        </Flex>
        <Flex
          w={{ base: '100%', sm: `calc(100% - ${CROSS_SELL_WIDTH}px)` }}
          bg={'white'}
          direction={'column'}
          className={clsx(
            hasMount && reco.length === 0 ? 'no-reco' : undefined,
          )}
          sx={{
            zIndex: 1,
            transition: 'width 500ms',
            '&.no-reco': {
              width: '100%',
            },
          }}
          h={'100%'}>
          <Flex className={clsx(classes.header)}>
            <CartDrawerHeader
              count={lineItems.length}
              closeDrawer={onClose}
              title={myCartLabel}
            />
          </Flex>
          <Flex className={clsx(classes.body)}>
            <ScrollArea className={'content'}>
              <CartDrawerLineItems lineItems={lineItems} />
            </ScrollArea>
          </Flex>
          <Flex
            className={clsx(classes.footer)}
            gap={{ base: 14, sm: cssGapDrawer }}
            p={{ base: 14, sm: cssGapDrawer }}>
            <CartDrawerProgressBar currency={cart.subtotalPrice.currencyCode} />
            <CartDrawerUpsellCarousel
              label={addUpsellLabel?.fields.text || '+'}
              soldOut={soldOut?.fields.text}
            />
            <CartDrawerCheckoutTotal />
            <CartDrawerReassurance />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
export default CartDrawer;
