import { Box, clsx, createStyles } from '@mantine/core';
import React from 'react';
import { useContainerStyles } from '@/utils/style/useContainer';
import { Asset } from 'contentful';
import { IImage } from '@/@types/generated/contentful';

const useStyles = createStyles((theme) => ({
  banner: {},
  bannerImg: {
    width: '100%',
    // width: 'auto',
    height: 'auto',
    borderRadius: 20,
    objectFit: 'cover',
  },
  mobile: {
    aspectRatio: '1',
    [theme.fn.largerThan(theme.breakpoints.sm)]: {
      display: 'none',
    },
  },
  desktop: {
    aspectRatio: '1239/320',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      display: 'none',
    },
  },
}));

const HeroSmallBordered = ({
  mobileImg,
  img,
}: {
  mobileImg: IImage;
  img: IImage;
}) => {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();
  return (
    <Box className={clsx(classes.banner, containerStyles.classes.container)}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        className={clsx(classes.bannerImg, classes.mobile)}
        alt={mobileImg.fields.title}
        src={mobileImg.fields.media?.fields.file.url + '?fm=webp'}
      />
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        className={clsx(classes.bannerImg, classes.desktop)}
        alt={img.fields.title}
        src={img.fields.media?.fields.file.url + '?fm=webp'}
      />
    </Box>
  );
};

export default HeroSmallBordered;
