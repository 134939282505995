import { createStyles } from '@mantine/core';

const useScrollbarClasses = () =>
  createStyles(() => ({
    noScrollbar: {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }))().classes;

export default useScrollbarClasses;
