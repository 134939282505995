//@ts-nocheck
import React, { createContext, useContext, useEffect } from 'react';
import { createStyles, Drawer, DrawerProps } from '@mantine/core';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import { TIMING_FUNCTION } from '@/utils/style/animation';
import { SearchView } from '../SearchView/SearchView';
import { useDrawerOverlayColor } from '@/components/Drawer/Drawer';
import { useRouter } from 'next/router';
import zIndexes from '@/utils/style/zIndex';
import useTopShopItems from '@/utils/hook/useTopShopItems';

const useStyles = createStyles((theme, { headerHeight }) => ({
  content: {
    overflowX: 'hidden',
  },
  inner: {
    paddingTop: headerHeight,
    '& .mantine-Drawer-header': {
      display: 'none',
    },
    '& .mantine-Drawer-body': {
      height: '100%',
    },
  },
  body: {
    height: '100%',
  },
}));

export const searchDrawerInitState: DrawerProps = {
  opened: false,
  position: 'top',
  onClose: () => {},
  size: '60vh',
  padding: 0,
};

export const SearchDrawerContext = createContext([
  searchDrawerInitState,
  () => null,
] as [DrawerProps, React.Dispatch<React.SetStateAction<DrawerProps>>]);

export default function SearchDrawer() {
  const router = useRouter();
  const defaultReco = useTopShopItems();

  const headerHeight = useHeaderHeight();
  const { classes } = useStyles({
    headerHeight: headerHeight.wrapper + headerHeight.marginTop,
  });

  const searchDrawerState = useContext(SearchDrawerContext);
  const overlayProps = useDrawerOverlayColor();

  useEffect(() => {
    searchDrawerState[1](searchDrawerInitState);
  }, [router.asPath]);

  return (
    <Drawer
      {...searchDrawerState[0]}
      zIndex={zIndexes.drawer}
      overlayProps={overlayProps}
      transitionProps={{ duration: 300, timingFunction: TIMING_FUNCTION }}
      classNames={classes}>
      <SearchView defaultReco={defaultReco} />
    </Drawer>
  );
}
