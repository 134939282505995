import axios, { AxiosInstance } from 'axios';
import { Checkout } from 'shopify-buy';
import { checkoutCreateQuery, fetchCartQuery } from '@/queries/shopifyQueries';

class ShopifyStorefrontService {
  instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: `https://${process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN}/api/${process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_VERSION}/graphql`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Shopify-Storefront-Access-Token': process.env
          .NEXT_PUBLIC_SHOPIFY_SF_PUBLIC_TOKEN as string,
      },
    });
  }
}

const DEFAULT_LOCALE = 'en-gb';

export const createShopifyCart = async (
  geoMarket: Record<string, any>,
  locale: string,
  previousCart?: Checkout,
): Promise<Checkout | null> => {
  const { Shopify } = getShopifyStorefront({});
  try {
    let { data } = await Shopify({
      method: 'post',
      data: checkoutCreateQuery({
        country: geoMarket.name,
        input: {
          customAttributes: [
            {
              key: 'locale',
              value: locale,
            },
          ],
          presentmentCurrencyCode:
            geoMarket?.currencySettings?.baseCurrency?.currencyCode,
          lineItems: previousCart
            ? previousCart.lineItems.map(
                ({ customAttributes, quantity, variant }) => ({
                  customAttributes: customAttributes.map(({ key, value }) => ({
                    key,
                    value,
                  })),
                  quantity,
                  variantId: variant?.id as string,
                }),
              )
            : undefined,
        },
      }),
    });

    if (data?.data?.checkoutCreate?.checkout) {
      return {
        ...data.data.checkoutCreate?.checkout,
        discountApplications:
          data.data.checkoutCreate?.checkout?.discountApplications?.edges.map(
            // @ts-ignore
            ({ node }) => node,
          ) || [],
        lineItems: data.data.checkoutCreate?.checkout.lineItems.edges.map(
          // @ts-ignore
          ({ node }) => node,
        ),
      };
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};
export const fetchShopifyCart = async (
  geoMarket: Record<string, any>,
  id: string,
): Promise<Checkout | null> => {
  const { Shopify } = getShopifyStorefront({});
  try {
    let { data } = await Shopify({
      method: 'post',
      data: fetchCartQuery({
        country: geoMarket.name,
        id,
      }),
    });

    if (data?.data?.node)
      return {
        ...data.data.node,
        discountApplications:
          data.data.node?.discountApplications.edges.map(
            // @ts-ignore
            ({ node }) => node,
          ) || [], // @ts-ignore
        lineItems: data.data.node.lineItems.edges.map(({ node }) => node),
      };
    return data?.data?.node;
  } catch (e) {
    console.error(e);
  }
  return null;
};
export const getShopifyStorefront = ({
  locale,
}: Record<string, undefined | string | string[]> = {}): {
  Shopify: AxiosInstance;
  locale: string;
} => {
  const { instance } = new ShopifyStorefrontService();
  const definedLocale = typeof locale === 'string' ? locale : DEFAULT_LOCALE;

  return {
    // @ts-ignore
    Shopify: instance,
    locale: definedLocale,
  };
};
export default ShopifyStorefrontService;
