import useCommerceContext from '@/utils/context/commerce';
import ShopifyBuy from 'shopify-buy';
import React, { useEffect, useMemo } from 'react';
import Cookie from '@/helpers/Cookie';
import { APPLY_DISCOUNT_PRODUCT_FACET } from '@/templates/ProductCategory';
import { fetchShopifyCart } from '@/utils/services/ShopstoryStorefrontService';
import usePageContext from '@/utils/context/page';
import { isWindowDefined } from 'swr/_internal';
import { Box } from '@mantine/core';
import LocaleHelper from '@/helpers/Locale';

const CodeDiscount = ({
  code,
  discountPercent,
  expirationTs,
}: {
  code: string;
  expirationTs?: string;
  discountPercent?: string;
}) => {
  const { geoMarket, locale } = usePageContext();
  const { client, cart, setCart } = useCommerceContext() as unknown as {
    client: ShopifyBuy;
    cart: ShopifyBuy.Checkout;
    setCart: React.Dispatch<React.SetStateAction<ShopifyBuy.Checkout | null>>;
  };
  const isDev = useMemo(() => {
    if (isWindowDefined)
      return window.location.pathname.includes('shopstory-canvas');
    return false;
  }, []);

  useEffect(() => {
    if (isDev || !cart) return;

    // @ts-ignore
    if (discountPercent) {
      Cookie.set(APPLY_DISCOUNT_PRODUCT_FACET, discountPercent, expirationTs);
    }

    const applyCode = async function () {
      if (
        !cart.discountApplications.find(
          // @ts-ignore
          (da) => da?.code === code,
        )
      ) {
        for (let retry = 0; retry < 5; retry++) {
          try {
            const checkout = await client.checkout.addDiscount(cart.id, code);
            const tmpCart = await fetchShopifyCart(
              {
                ...geoMarket,
                name: LocaleHelper.getValidShopifyCountry(locale as string),
              },
              checkout.id,
            );
            if (tmpCart) setCart(tmpCart);
            retry = 5;
          } catch (e) {
            console.error(e);
          }
        }
      }
    };
    applyCode();
  }, [cart]);

  return isDev ? (
    <Box
      w={'100%'}
      h={50}>{`Applicateur de code promo ${code ? ` (${code})` : ''}${
      discountPercent ? ` (${discountPercent}%)` : ''
    }${expirationTs ? ` (${new Date(expirationTs).toUTCString()})` : ''}`}</Box>
  ) : null;
};

export default CodeDiscount;
