import { createStyles } from '@mantine/core';

export const DUO_COMPARE_SLIDER_SIZE = 14;
export const DUO_COMPARE_BTN_HEIGHT = 30;
export const DUO_COMPARE_BTN_WIDTH = 14;

const useHeroDuoCompareClasses = () =>
  createStyles((theme) => ({
    container: {
      position: 'relative',
      aspectRatio: '1327/580',
      // border: '2px solid white',
      borderRadius: 20,
      overflow: 'hidden',
      cursor: 'col-resize',

      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        aspectRatio: '1',
      },
    },
    label: {
      fontSize: 42,
      fontWeight: 500,
      [theme.fn.smallerThan('md')]: {
        fontSize: 28,
        fontWeight: 600,
      },
    },
    badge: {
      background: 'white',
      padding: '5px 15px',
      borderRadius: '100px',

      textTransform: 'uppercase',
      color: theme.colors.black[9],
      textAlign: 'center',
      fontSize: '12px',
      fontWeight: 900,
      lineHeight: 'normal',
      letterSpacing: '1.2px',
      display: 'flex',
      gap: 6,
    },
    before: {
      margin: 0,
      position: 'relative',
      aspectRatio: '1327/580',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        aspectRatio: '1',
      },
      '& .text-badge': {
        position: 'absolute',
        top: '50%',
        left: '25%',
        transform: 'translateY(-50%) translateX(-50%)',
        [theme.fn.smallerThan(theme.breakpoints.sm)]: {
          top: '25%',
        },
      },
    },
    after: {
      margin: 0,
      position: 'relative',
      aspectRatio: '1327/580',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        aspectRatio: '1',
      },
      '& .text-badge': {
        position: 'absolute',
        top: '50%',
        right: '25%',
        transform: 'translateY(-50%) translateX(50%)',
        [theme.fn.smallerThan(theme.breakpoints.sm)]: {
          top: '25%',
        },
      },
    },
    img: {
      width: '100%',
      height: '100%',
      '&.desktop': {
        [theme.fn.smallerThan(theme.breakpoints.sm)]: {
          display: 'none',
        },
      },
      '&.mobile': {
        [theme.fn.largerThan(theme.breakpoints.sm)]: {
          display: 'none',
        },
      },
    },
    sliderWrapper: {
      width: '100%',
      height: '100%',
      '--divider-shadow': `0px 0px 0px 1px ${theme.colors.black[9]}`,
      '& .handler': {
        width: DUO_COMPARE_BTN_WIDTH,
        height: DUO_COMPARE_BTN_HEIGHT,
        borderRadius: 7,
        backgroundColor: 'white',

        border: `1px solid ${theme.colors.black[9]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:after': {
          content: '" "',
          height: 8,
          width: 1,
          background: theme.colors.black[0],
        },
      },
    },
  }))().classes;

export default useHeroDuoCompareClasses;
