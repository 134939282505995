import { Group, Box, createStyles, Overlay } from '@mantine/core';
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import NoozButton from '../Nooz/NoozButton/NoozButton';
import NoozText from '../Nooz/NoozText/NoozText';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import { ITemplateFields } from '@/@types/generated/contentful';
import zIndexes from '@/utils/style/zIndex';
import useCookieBannerContext from '@/utils/context/cookieBanner';
import TextLink from '../Link/TextLink';

const useStyles = createStyles((theme) => ({
  container: {
    position: 'fixed',
    borderRadius: theme.radius.md,
    left: '50%',
    right: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    bottom: -40,
    width: '75%',
    zIndex: zIndexes.modal,
    padding: theme.spacing.md,

    [theme.fn.smallerThan('md')]: {
      width: '100%',
      bottom: 0,
      left: 0,
      right: 0,
      transform: 'unset',
      borderRadius: 0,
    },
  },
}));

export function CookiesBanner() {
  const { opened, setOpened } = useCookieBannerContext();
  const { classes } = useStyles();
  const { layout } = usePageContext();
  const { cookieBanner } = layout?.fields as ITemplateFields;
  const { label, link, button } = formatCfItems(cookieBanner);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setOpened(!(window.localStorage['cookie-accept'] === 'accepted'));
    }
  }, []);

  const handleCookieAccept = () => {
    if (typeof window !== 'undefined') {
      setOpened(false);
      window.localStorage.setItem('cookie-accept', 'accepted');
    }
  };

  return (
    <AnimatePresence>
      {opened ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { ease: 'easeOut', duration: 1, delay: 1 },
          }}
          exit={{
            opacity: 0,
            transition: { ease: 'easeOut', duration: 0.5, delay: 0 },
          }}>
          <Box className={classes.container}>
            <Overlay
              opacity={0.6}
              color='#000'
              blur={20}
              zIndex={-1}
            />
            <NoozText
              color='white'
              size='md'>
              {label.fields.text}
            </NoozText>
            <Group mt={20}>
              <TextLink
                type='underline'
                color='white'
                text={link.fields.label.fields.text}
                url={link.fields.slug.fields.url}
              />
              <NoozButton
                onClick={handleCookieAccept}
                size='sm'>
                {button.fields.label.fields.text}
              </NoozButton>
            </Group>
          </Box>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
