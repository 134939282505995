import { Group, Stack } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import Currency from '@/helpers/Currency';
import React, { useMemo } from 'react';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import useCommerceContext from '@/utils/context/commerce';
import ShopifyBuy, { MoneyV2 } from 'shopify-buy';
import { useCartStock } from '@/utils/hook/cart/useCartStock';
import NoozRichBody from '@/components/Nooz/NoozRichBody/NoozRichBody';
import useShippingRates from '@/utils/hook/useShippingRates';
import { FREE_SHIPPING_ENABLED } from '@/components/Header/HeaderBanner/headerBanner';

const CartDrawerCheckoutTotal = () => {
  const { layout } = usePageContext();

  const shippingRates = useShippingRates();
  const {
    checkoutLabel,
    shippingPriceLabel,
    shippingEstimatedBody,
    shippingFreeBody,
  } = useMemo(
    () => formatCfItems(formatCfItems(layout?.fields.cart).drawer),
    [layout],
  );
  const { cart } = useCommerceContext() as unknown as {
    client: ShopifyBuy;
    cart: ShopifyBuy.Checkout;
    setCart: React.Dispatch<React.SetStateAction<ShopifyBuy.Checkout | null>>;
  };
  const { isLoading } = useCartStock();
  const prices = useMemo(() => {
    const discountsScript = cart.discountApplications.filter(
      (f) => (f as any).__typename === 'ScriptDiscountApplication',
    );
    const discounts = cart.discountApplications.filter(
      (f) => (f as any).__typename !== 'ScriptDiscountApplication',
    );
    localStorage.getItem('sca_token');

    return {
      total: cart.subtotalPrice,
      withoutDiscount:
        discounts.length > 0
          ? {
              amount: (() => {
                let price = parseFloat(
                  cart.lineItemsSubtotalPrice.amount as any,
                );

                for (let d of discountsScript) {
                  price -= parseFloat((d.value as MoneyV2).amount as any);
                }

                return (Math.round(price * 100) / 100).toString();
              })(),
              currencyCode: cart.lineItemsSubtotalPrice.currencyCode,
            }
          : undefined,
    };
  }, [cart]);

  return (
    <Stack spacing={8}>
      {shippingRates ? (
        <Group position={'apart'}>
          <NoozText weight={700}>{shippingPriceLabel?.fields.text}</NoozText>
          <NoozText weight={500}>
            {parseFloat(cart.subtotalPrice.amount as any) >=
              shippingRates?.free?.min_order_subtotal ||
            FREE_SHIPPING_ENABLED ? (
              <NoozRichBody {...shippingFreeBody.fields} />
            ) : (
              <NoozRichBody
                {...shippingEstimatedBody.fields}
                replaceStrings={{
                  //  on a le pris des livraisons en euros uniquement
                  '{{amount}}': `${Currency.moneyToPrice(
                    shippingRates.min.price,
                  )}`,
                }}
              />
            )}
          </NoozText>
        </Group>
      ) : null}
      {cart ? (
        <a
          onClick={(e) => {
            if (isLoading) e.preventDefault();
          }}
          href={cart.webUrl}
          style={{ textDecoration: 'none' }}>
          <NoozButton
            fullWidth
            size={'lg'}
            disabled={isLoading}
            loading={isLoading}>
            <NoozText
              color={'white'}
              size={16}
              weight={500}
              lh={'19.2px'}>
              {`${checkoutLabel?.fields.text} - `}
              <span style={{ fontWeight: 900 }}>
                {Currency.moneyToPrice(prices.total)}
              </span>
              {prices.withoutDiscount ? (
                <span
                  style={{
                    textDecoration: 'line-through',
                    marginLeft: 8,
                    opacity: 0.8,
                  }}>
                  {Currency.moneyToPrice(
                    prices.withoutDiscount as unknown as MoneyV2,
                  )}
                </span>
              ) : null}
            </NoozText>
          </NoozButton>
        </a>
      ) : null}
    </Stack>
  );
};

export default CartDrawerCheckoutTotal;
