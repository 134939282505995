import * as React from 'react';
import { Shopstory, ShopstoryMetadataProvider } from '@shopstory/react';
import { NoozShopstoryProvider } from '@/utils/context/shopstory';
import { IShopstoryBlock } from '@/@types/generated/contentful';
import useShopstoryClient from '@/utils/hook/useShopstoryClient';
import { ShopstoryBlockProps } from '@/interface.custom';

export const ShopstoryBlock = ({
  meta,
  renderableContent,
}: ShopstoryBlockProps) => {
  return (
    <NoozShopstoryProvider>
      <ShopstoryMetadataProvider meta={meta}>
        <Shopstory content={renderableContent} />
      </ShopstoryMetadataProvider>
    </NoozShopstoryProvider>
  );
};

export const ShopStoryEl = ({
  shopstoryBlock,
}: {
  shopstoryBlock: IShopstoryBlock;
}) => {
  const { renderableContent, meta } = useShopstoryClient(shopstoryBlock);

  return meta &&
    renderableContent &&
    renderableContent.renderableContent !== null ? (
    <ShopstoryBlock
      renderableContent={renderableContent}
      meta={meta}
    />
  ) : null;
};
