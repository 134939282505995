import {
  Box,
  Container,
  Flex,
  Group,
  clsx,
  createStyles,
  useMantineTheme,
  rem,
} from '@mantine/core';
import React, { useMemo } from 'react';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { Property } from 'csstype';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import Link from '@/components/Link/Link';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozButton from '../../Nooz/NoozButton/NoozButton';
import {
  IBadge,
  IBlock,
  IButton,
  IImage,
  ILabel,
} from '@/@types/generated/contentful';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import useWindowHeight from '@/utils/hook/useWindowInnerHeight';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';

const useStyles = createStyles((theme) => {
  const headerHeight = useHeaderHeight();
  const windowHeight = useWindowHeight();
  return {
    container: {
      minHeight: rem(680),
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.fn.smallerThan('md')]: {
        height: `calc(${windowHeight} - ${headerHeight.wrapper}px - 80px)`,
      },
      [theme.fn.smallerThan('sm')]: {
        height: `unset`,
        minHeight: `unset`,
        aspectRatio: '3/4',
      },
    },
    leftImg: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '50%',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        height: '50%',
        width: '100%',
      },
    },
    rightImg: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      width: '50%',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        height: '50%',
        width: '100%',
        left: 0,
        top: 'unset',
        bottom: 0,
      },
    },
    label: {
      fontSize: 60,
      [theme.fn.smallerThan('md')]: {
        fontSize: 36,
      },
    },
    buttons: {
      [theme.fn.smallerThan('md')]: {
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
      },
    },
  };
});

interface HeroBannerProps {
  height?: string;
  labels?: IBlock;
  label?: ILabel;
  subLabel?: ILabel;
  buttons?: IBlock;
  badges?: IBlock;
  leftImage?: IImage;
  rightImage?: IImage;
  leftImageMobile?: IImage;
  rightImageMobile?: IImage;
  vertical?: Property.AlignItems;
  horizontal?: Property.JustifyContent;
}

const HeroDuoBanner = ({
  labels,
  subLabel,
  badges,
  leftImage,
  rightImage,
  leftImageMobile,
  rightImageMobile,
  buttons,
  height,
}: HeroBannerProps) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();

  return (
    <>
      <Box>
        <Container
          h={height || '100%'}
          className={clsx(
            containerStyles.classes.container,
            classes.container,
          )}>
          <Box
            className={classes.leftImg}
            sx={(theme) => ({
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), no-repeat center/cover url("${leftImage?.fields.media?.fields.file.url}") ${theme.colors.dark[7]}`,
              [theme.fn.smallerThan(theme.breakpoints.xs)]: {
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), no-repeat center/cover url("${leftImageMobile?.fields.media?.fields.file.url}") ${theme.colors.dark[7]}`,
              },
            })}
          />
          <Box
            className={classes.rightImg}
            sx={(theme) => ({
              background: ` no-repeat center/cover url("${rightImage?.fields.media?.fields.file.url}") ${theme.colors.dark[7]}`,
              [theme.fn.smallerThan(theme.breakpoints.xs)]: {
                background: ` no-repeat center/cover url("${rightImageMobile?.fields.media?.fields.file.url}") ${theme.colors.dark[7]}`,
              },
            })}
          />
          <Flex
            py={{
              base: '1.5rem',
              xs: '5.5rem',
              lg: 100,
              xl: 150,
            }}
            h={'100%'}
            w={{ base: '100%', sm: '90%' }}
            align={'center'}>
            <Flex
              sx={{ gap: 24 }}
              direction={'column'}
              h={'auto'}
              w={'100%'}
              justify={{ base: 'space-between', sm: 'normal' }}>
              <Flex
                direction={'column'}
                gap={24}>
                <Group position={'center'}>
                  {/* @ts-ignore */}
                  {(badges?.fields?.items as IBadge[])?.map(
                    (badge: IBadge, index: number) => {
                      return (
                        <NoozBadge
                          key={index}
                          badgeProps={{
                            variant: badge.fields.type || 'filled',
                            size: badge.fields.size || 'lg',
                            color:
                              badge.fields.color?.fields.classname || 'dark',
                          }}
                          text={badge.fields.label.fields.text as string}
                          textProps={{
                            sx: {
                              display: 'flex',
                              margin: '0 8px',
                              gap: 12,
                              alignItems: 'center',
                            },
                            weight: 500,
                            size: 14,
                            lh: '12px',
                            transform: 'uppercase',
                            color: 'white',
                          }}
                          icon={
                            badge.fields.icon ? (
                              <NoozIcon
                                iconKey={badge.fields.icon.fields.iconKey}
                                size={16}
                                strokeWidth={2.5}
                                color={
                                  theme.colors[
                                  badge.fields.icon.fields.color?.fields
                                    .classname as string
                                  ]?.[7] || 'white'
                                }
                              />
                            ) : (
                              <></>
                            )
                          }
                        />
                      );
                    },
                  )}
                </Group>
                <Box style={{ zIndex: 1 }}>
                  {labels
                    ? (labels.fields.items as ILabel[])?.map(
                      (label: ILabel, index: number) => {
                        // @ts-ignore
                        return (
                          <NoozText
                            m={0}
                            lh={1.2}
                            key={index}
                            sx={
                              label?.fields.size &&
                                !['base', ''].includes(label.fields.size)
                                ? {
                                  fontSize: (() => {
                                    try {
                                      return parseInt(label?.fields.size);
                                    } catch (e) {
                                      return undefined;
                                    }
                                  })(),

                                  [theme.fn.smallerThan('sm')]: {
                                    fontSize: (() => {
                                      try {
                                        return parseInt(
                                          label?.fields.size,
                                        ) === 50
                                          ? 28
                                          : undefined;
                                      } catch (e) {
                                        return undefined;
                                      }
                                    })(),
                                  },
                                }
                                : undefined
                            }
                            align={'center'}
                            italic={label?.fields.italic}
                            weight={label?.fields.weight || 500}
                            size={
                              label?.fields.size !== 'base'
                                ? label?.fields.size
                                : undefined
                            }
                            color={
                              label.fields.color?.fields.classname || 'white'
                            }
                            // @ts-ignore
                            family={label?.fields.family}
                            className={classes.label}
                            // @ts-ignore
                            dangerouslySetInnerHTML={{
                              __html: label?.fields.text,
                            }}></NoozText>
                        );
                      },
                    )
                    : null}
                </Box>
                {subLabel ? (
                  <NoozText
                    weight={500}
                    color={'white'}
                    size={subLabel?.fields.size || 14}>
                    {subLabel?.fields.text}
                  </NoozText>
                ) : null}
              </Flex>

              <Group
                className={classes.buttons}
                position={'center'}>
                {buttons
                  ? buttons.fields?.items?.map(
                    // @ts-ignore
                    (button: IButton, index: number) => {
                      if (button.fields.urlLink) {
                        return (
                          // @ts-ignore
                          <NoozButton
                            key={index}
                            miw={260}
                            {...button.fields}
                            variant={button.fields.type || 'filled'}
                            color={
                              button?.fields?.color?.fields?.classname ||
                              'dark'
                            }
                            sx={
                              button?.fields?.label?.fields.color?.fields
                                .classname
                                ? // @ts-ignore
                                (theme) => ({
                                  color:
                                    theme.colors[
                                    button?.fields.label?.fields.color
                                      ?.fields.classname as string
                                    ],
                                })
                                : null
                            }
                            size={button.fields.size || 'lg'}
                            urlLink={button.fields.urlLink}>
                            {button?.fields?.label?.fields.text}
                          </NoozButton>
                        );
                      }
                      if (button.fields.slug?.fields.url)
                        // @ts-ignore
                        return (
                          <Link
                            key={index}
                            href={button.fields.slug?.fields.url as string}>
                            {/* @ts-ignore */}
                            <NoozButton
                              miw={260}
                              key={index}
                              {...button.fields}
                              variant={button.fields.type || 'filled'}
                              color={
                                button?.fields?.color?.fields?.classname ||
                                'dark'
                              }
                              sx={
                                button?.fields?.label?.fields.color?.fields
                                  .classname
                                  ? // @ts-ignore
                                  (theme) => ({
                                    color:
                                      theme.colors[
                                      button?.fields.label?.fields.color
                                        ?.fields.classname as string
                                      ],
                                  })
                                  : null
                              }
                              size={button.fields.size || 'lg'}>
                              {button?.fields?.label?.fields.text}
                            </NoozButton>
                          </Link>
                        );
                      return null;
                    },
                  )
                  : null}
              </Group>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </>
  );
};

export default HeroDuoBanner;
