import { Box, createStyles, Flex, Group } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import React from 'react';
import theme from '@/helpers/Theme';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { IBadge, IImage, ILabel } from '@/@types/generated/contentful';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: 40,
    gap: '.75rem',
    position: 'absolute',
    height: 200,
    width: '100%',
    top: 0,
    left: 0,
  },
  card: {
    height: '100%',
    width: '100%',
    padding: 0,
    backgroundColor: theme.colors.white[0],
    border: '1px solid #D7D7D7',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'default',
    borderRadius: 20,
    transition: 'transform .5s ease',
    '& > *:not(:first-of-type)': {
      marginTop: 16,
    },
    '& > .description': {
      flex: 1,
      whiteSpace: 'pre-wrap',
    },
    '& > .footer': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  plusAction: {
    position: 'absolute',
    right: 40,
    bottom: 40,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      right: 20,
      bottom: 20,
    },
  },
  label: {
    fontSize: 32,
    fontWeight: 500,
    lineHeight: 'normal',
  },
}));

interface ClearCardProps {
  badge?: IBadge;
  label?: ILabel;
  image?: IImage;
}

const ClearCard = ({ badge, label, image }: ClearCardProps) => {
  const { classes } = useStyles();

  return (
    <Box
      sx={() => ({
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        height: '100%',
        borderRadius: 20,
      })}>
      <Flex
        className={classes.card}
        justify={'space-between'}
        sx={{
          position: 'relative',
        }}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={image?.fields.media?.fields.file.url + '?fm=webp'}
          alt={''}
          style={{
            position: 'absolute',
            height: 300,
            width: '100%',
            bottom: 0,
            left: 0,
            objectFit: 'cover',
          }}
        />

        <Flex
          direction={'column'}
          className={classes.wrapper}>
          {badge ? (
            <Group>
              <NoozBadge
                badgeProps={{
                  variant: badge.fields.type || 'filled',
                  size: badge.fields.size || 'lg',
                  color: badge.fields.color?.fields.classname || 'dark',
                }}
                text={(badge.fields.label?.fields.text || '')?.toUpperCase()}
                textProps={
                  {
                    // sx: {
                    //   display: 'flex',
                    //   margin: '0 8px',
                    //   gap: 12,
                    //   alignItems: 'center',
                    // },
                    // weight: 500,
                    // size: 14,
                    // lh: '12px',
                    // transform: 'uppercase',
                    // color: 'white',
                  }
                }
                icon={
                  badge.fields.icon ? (
                    <NoozIcon
                      iconKey={badge.fields.icon.fields.iconKey}
                      size={16}
                      strokeWidth={2.5}
                      color={
                        theme.colors[
                          badge.fields.icon.fields.color?.fields
                            .classname as string
                        ]?.[7] || 'white'
                      }
                    />
                  ) : undefined
                }
              />
            </Group>
          ) : null}
          <Box sx={{ flex: 2 }}>
            <NoozText className={classes.label}>{label?.fields.text}</NoozText>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ClearCard;
