import React, { useState, useCallback } from 'react';
import { Box, Card, createStyles } from '@mantine/core';
import ProductCardBadges from '@/components/Collection/ProductCard/ProductCardBadges';
import ProductCardImages from '@/components/Collection/ProductCard/ProductCardImages/ProductCardImages';
import ProductCardPrice from './ProductCardPrice';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { ProductCardUiProps } from '@/interface.custom';
import ProductCardVariantPicker from '@/components/Collection/ProductCard/ProductCardVariantPicker/ProductCardVariantPicker';
import { ShopItemVariant } from '@/@types/shopitem';

const useStyles = createStyles((theme) => ({
  card: {
    padding: 0,
    height: '100%',
    cursor: 'pointer',
  },
  imageSection: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    width: '100%',
    margin: 0,
  },
  imageColorPickerMini: {
    marginTop: 9,
  },
  forceMini: {
    position: 'relative',
    bottom: 'unset',
  },
  productLabelWrapper: {
    minHeight: '56px',
    alignItems: 'start',
  },
  productTitle: {
    margin: 0,
    fontSize: 16,
    fontWeight: 500,
    color: '#2D2D2D',
  },
  whiteBadge: {
    backgroundColor: 'white',
  },
}));

const ProductCardMini = ({
  item,
  label,
  setVariant,
  variant,
  premiumEnabled,
  forceMini,
}: ProductCardUiProps) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const { classes } = useStyles();

  const handleTouchStart = useCallback(() => {
    setHovered(true);
  }, []);

  const handleTouchEnd = useCallback(() => {
    setTimeout(() => setHovered(false), 250);
  }, []);

  return (
    <Card
      p={0}
      className={classes.card}>
      <Box className={classes.imageSection}>
        {forceMini ? null : (
          <ProductCardBadges
            {...item}
            showAction={false}
            variant={variant}
            premiumEnabled={premiumEnabled}
            forceMini={forceMini}
          />
        )}
        <Box
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          sx={{ aspectRatio: '1', width: '100%' }}>
          <ProductCardImages
            {...variant}
            variantId={variant.id}
            hovered={hovered}
            productLifestyleImages={item.product.lifestyleImages as string[]}
          />
        </Box>
      </Box>

      <ProductCardVariantPicker
        mini
        hasColorPicker={true}
        variants={item.variants as ShopItemVariant[]}
        variant={variant}
        setVariant={setVariant}
      />
      <Box
        my={9}
        className={classes.productLabelWrapper}>
        <NoozText
          component='h3'
          className={classes.productTitle}>
          {label}
        </NoozText>
        <ProductCardPrice item={variant} />
      </Box>
    </Card>
  );
};

export default ProductCardMini;
