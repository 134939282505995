import React from 'react';
import { BackgroundImage, Box, Flex } from '@mantine/core';
import usePageContext from '@/utils/context/page';
import { IPopupFields } from '@/@types/generated/contentful';
import { formatCfItems } from '@/helpers/formatCfItems';
import { useDialogContext } from '@/utils/context/dialog';
import MainPopup from './MainPopup';
import { useMainPopupModalContext } from './MainPopupModal';
import { useTeaserPopupDrawerContext } from './TeaserPopupDrawer';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { useRouter } from 'next/router';

const TeaserPopup = () => {
  const { layout, locale } = usePageContext();
  const { modal, setModal } = useMainPopupModalContext();
  const { dialog, setDialog } = useDialogContext();
  const { drawer, setDrawer } = useTeaserPopupDrawerContext();
  const router = useRouter();

  const { teaserComponent } = layout?.fields.popup?.fields as IPopupFields;

  const { image, label, body, link } = formatCfItems(teaserComponent);

  return (
    <Flex
      direction={'column'}
      justify={'flex-end'}
      sx={{ cursor: 'pointer', height: '100%' }}
      onClick={() => {
        if (link?.fields.slug?.fields) {
          setDialog({ ...modal, opened: false });
          router.push(`/${locale}/${link.fields.slug.fields.url}`);
        } else {
          setDialog({ ...modal, opened: false });
          setModal({
            ...modal,
            opened: true,
            body: <MainPopup />,
          });
        }
      }}>
      {image ? (
        <BackgroundImage
          src={image?.fields.media.fields.file.url}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
          }}
        />
      ) : null}
      <Box
        sx={{
          color: 'white',
          position: 'relative',
          padding: '25px',
        }}>
        {label ? (
          <NoozText
            size={18}
            weight={500}>
            {label.fields.text}
          </NoozText>
        ) : null}
        {body ? (
          <NoozText
            size={14}
            weight={400}>
            {body.fields.text}
          </NoozText>
        ) : null}
      </Box>
    </Flex>
  );
};

export default TeaserPopup;
