import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  IBlogArticle,
  ICategory,
  IFacet,
  IPage,
  IProduct,
  ISettingsFields,
  ITemplate,
} from '@/@types/generated/contentful';
import { Entry } from 'contentful';
import {
  IBreadcrumb,
  ShippingZoneData,
  ShopstoryBlockProps,
} from '@/interface.custom';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';
import { ShopItem } from '@/@types/shopitem';

export interface IPageContext {
  article: any;
  shopitems: {
    quantity: number;
    items: ShopItem[];
    filters: Record<string, any>;
  };

  allPages: Record<string, string>[];
  breadcrumbs: IBreadcrumb[];
  facets?: IFacet[];
  category?: ICategory;
  components: Record<string, Entry<any>>;
  geoMarket?: Record<string, string>;
  layout?: ITemplate;
  locale?: string;
  localizedSlugs?: Record<string, string>;
  page?: IPage | IBlogArticle;
  product?: IProduct;
  productCategory?: ICategory;
  shippingZone?: ShippingZoneData;
  shopstory?: ShopstoryBlockProps;
  staticContent: Record<string, string>;
  gifts: Record<string, any>;
  upsell: Record<string, any>;
  stores?: {
    features: {}[];
    opts: {
      categories: Record<
        number,
        {
          child_id: number[];
          display_name: string;
          id: number;
          name: string;
          parent_id: string | null;
        }
      >;
    };
  };
  stocks: Record<string, number>;
  settings?: { id: string; fields: ISettingsFields };
  topProductCategories?: ICategory[];
}

export const PageContext = createContext<IPageContext>({
  staticContent: {},
  shopitems: {
    quantity: 0,
    items: [],
    filters: {},
  },
  allPages: [],
  breadcrumbs: [],
  components: {},
  gifts: {},
  upsell: {},
  stocks: {},
} as unknown as IPageContext);

const usePageContext = (): IPageContext => {
  const context = useContext(PageContext);
  if (!context) {
    // throw new Error('usePageContext must be used within a PageContextProvider');
    return {} as IPageContext;
  }
  return context;
};

export const PageContextProvider = ({
  children,
  value,
}: PropsWithChildren<Record<string, any>>) => {
  const [override, setOverride] = useState<any>({});

  const timeOut = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (value?.gifts && value?.upsell) {
      const fetchShopifyProductConfig = {
        ...NOOZ_NEXT_REGISTRY.SHOPIFY.VARIANTS.GET_STOCK,
        data: {
          ids: Array.from(
            new Set([
              ...Object.values(value.gifts).map((e: any) => e.shopifyId),
              ...Object.values(value.upsell).map((e: any) => e.shopifyId),
            ]),
          ).filter((f) => f),
          locale: value.locale,
        },
      };

      const fetchStock = () => {
        // Make the API call to fetch Shopify data
        NoozNextApi.instance(fetchShopifyProductConfig)
          .then(({ data }) => {
            setOverride({ stocks: data });
          })
          .catch(console.error)
          .finally(() => {
            timeOut.current = setTimeout(fetchStock, 1000 * 60 * 5);
          });
      };
      fetchStock();
      return () => {
        if (timeOut.current) clearTimeout(timeOut.current);
      };
    }
  }, []);

  return (
    <PageContext.Provider value={{ ...value, ...override }}>
      {children}
    </PageContext.Provider>
  );
};

export default usePageContext;
