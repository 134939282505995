import {
  FetchFunctionOptions,
  FetchResourcesInput,
  FetchResourcesOutput,
} from '@shopstory/core';
import * as contentful from 'contentful';
import NoozContentful, { NoozCfGraphQl } from '@/helpers/NoozContentful';
import { getAssetByIdQuery } from '@/queries/cfQueries';

const LIMIT = 800;

export const fetchContentfulAsset = async (
  resources: Array<FetchResourcesInput>,
  { locale }: FetchFunctionOptions,
): Promise<FetchResourcesOutput[]> => {
  const output: FetchResourcesOutput[] = [];

  for (let i = 0; i < resources.length; ++i) {
    const id = resources[i].id;

    try {
      const { data } = await NoozCfGraphQl.post(
        '',
        getAssetByIdQuery({
          id,
          locale: 'fr-FR',
        }),
      );

      output[i] = {
        ...resources[i],
        value: data.asset,
      };
    } catch (err) {
      console.error(err);
    }
  }

  return output;
};

// getContentfulItems
export const fetchAssetGetItems = async (query: string) => {
  let items: contentful.Asset[] = [];

  try {
    const data = await NoozContentful.getAssets({
      include: 1,
      limit: LIMIT,
    });

    items = items.concat(data.items);

    for (let skip = LIMIT; skip < data.total; skip += LIMIT) {
      const data = await NoozContentful.getAssets({
        include: 1,
        limit: LIMIT,
      });
      items = items.concat(data.items);
    }

    if (query) {
      const filteredItems = items?.filter((i) =>
        i.fields.title?.toLowerCase().includes(query.toLowerCase()),
      );
      items = filteredItems;
    }
  } catch (err) {
    console.error(err);
  }

  return items.map((s) => ({
    id: s.sys.id,
    title: s.fields.title as string,
  }));
};

export const fetchAssetById = async (id: string) => {
  const asset: contentful.Asset = await NoozContentful.getAsset(id);

  return {
    id: asset.sys.id,
    title: asset.fields.title as string,
  };
};
