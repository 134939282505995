import { Box, clsx, createStyles, Group, Stack } from '@mantine/core';
import React, { useState } from 'react';
import { IProduct, IProductFields } from '@/@types/generated/contentful';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { motion } from 'framer-motion';

interface PrettyCardProps {
  defaultVariantIdx?: number;
  product: IProduct;
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundColor: '#F4F4F4',
    position: 'relative',
  },
  img: {
    aspectRatio: '1',
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
  info: {},
  color: {
    height: 28,
    width: 28,
    borderRadius: 28,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  active: {
    background: theme.white,
    height: 14,
    width: 14,
    borderRadius: 28,
  },

  productName: {
    position: 'absolute',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexShrink: 0,
    color: '#2D2D2D',

    fontFamily: 'Mont',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    height: 35,
    top: 20,
    left: 35,
  },
  productColor: {
    position: 'absolute',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    color: '#2D2D2D',

    textAlign: 'right',
    fontFamily: 'Mont',
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 600,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    height: 35,
    top: 20,
    right: 35,
  },
  colorPicker: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '2rem',
  },
  text: {
    cursor: 'pointer',
    color: '#2D2D2D',
    fontFamily: 'Mont',
    fontSize: 64,
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '66px',
    textAlign: 'center',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      fontSize: 44,
      lineHeight: '46px',
    },
  },
}));

const PrettyCard = ({ defaultVariantIdx = 0, product }: PrettyCardProps) => {
  const { classes } = useStyles();
  const [variantIdx, setVariantIdx] = useState(defaultVariantIdx);
  return (
    <Stack>
      <Box className={classes.wrapper}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          className={clsx(classes.img)}
          src={
            product.fields.variants?.[variantIdx]?.fields.posterImages?.[0]
              .fields.media?.fields.file.url + '?fm=webp'
          }
          alt={(product.fields as IProductFields).variants?.[0]?.fields.title}
          key={(product as any).id}
        />
        <NoozText className={classes.productColor}>
          {
            product.fields.variants?.[
              typeof variantIdx === 'number' ? variantIdx : variantIdx
            ]?.fields.color?.fields.text
          }
        </NoozText>

        <Group
          position={'center'}
          className={classes.colorPicker}>
          {product.fields.variants?.map((v, key) => (
            <Box
              className={classes.color}
              key={v.fields.title}
              sx={(theme) => ({
                background: v.fields.color?.fields.hex,
                border:
                  key === variantIdx
                    ? `2px solid ${theme.colors.dark[3]}`
                    : `1px solid ${theme.colors.gray[4]}`,
              })}
              onClick={() => {
                setVariantIdx(key);
              }}>
              {key === variantIdx ? (
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: { ease: 'easeOut', duration: 0.2 },
                  }}
                  exit={{
                    opacity: 0,
                    scale: 0,
                    transition: { ease: 'easeOut', duration: 0.5 },
                  }}>
                  <div className={classes.active} />
                </motion.div>
              ) : null}
            </Box>
          ))}
        </Group>
        <NoozText className={classes.productName}>
          {product.fields.label?.fields.text}
        </NoozText>
      </Box>
      <NoozText className={classes.text}>
        {product.fields.label?.fields.text}
      </NoozText>
    </Stack>
  );
};

export default PrettyCard;
