import { Container, Flex, SimpleGrid, useMantineTheme } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { useContainerStyles } from '@/utils/style/useContainer';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import TextLink from '@/components/Link/TextLink';
import React from 'react';
import { IBlock, IIcon, ILabel, ILink } from '@/@types/generated/contentful';

const CustomerServiceGrid = ({
  label,
  blocks,
}: {
  label?: ILabel;
  blocks?: IBlock;
}) => {
  const theme = useMantineTheme();
  const containerStyles = useContainerStyles();
  const elements = ['Email', 'Phone'];

  return label || blocks ? (
    <Container className={containerStyles.classes.container}>
      {label ? (
        <NoozText
          align={'center'}
          size={label.fields.size}
          color={label.fields.color?.fields.classname || 'text'}
          mb={16}>
          {label.fields.text}
        </NoozText>
      ) : null}
      <SimpleGrid
        verticalSpacing={88}
        spacing={48}
        mb={16}
        cols={elements.length}
        breakpoints={[
          { maxWidth: 'md', cols: 2, spacing: 'sm', verticalSpacing: 44 },
          { maxWidth: 'xs', cols: 1, spacing: 'sm', verticalSpacing: 20 },
        ]}>
        {blocks?.fields.items?.map((el) => {
          const icon = (el.fields.items as any[])?.find(
            (i) => i.fields.key === 'icon',
          ) as IIcon | undefined;
          const texts = ((el.fields.items as any[]) || [])?.find(
            (i) => i.fields.key === 'texts',
          );
          return (
            <Flex
              key={(el as any).id}
              gap={12}
              justify={'center'}
              align={'center'}>
              {icon ? (
                <NoozIcon
                  iconKey={icon.fields.iconKey}
                  strokeWidth={2}
                  size={42}
                  color={
                    theme.colors[
                      icon.fields.color?.fields.classname || 'main'
                    ][0]
                  }
                />
              ) : null}
              <Flex direction={'column'}>
                {texts?.fields.items?.map((text: ILabel | ILink) => {
                  if (text.fields.key === 'label') {
                    const el = text as ILabel;
                    return (
                      <NoozText
                        key={(text as any).id}
                        align={'center'}>
                        {el.fields.text}
                      </NoozText>
                    );
                  }
                  if (text.fields.key === 'link') {
                    const el = text as ILink;
                    return (
                      <TextLink
                        key={(text as any).id}
                        size={16}
                        align={'center'}
                        type='animated-underline'
                        url={el.fields.urlLink}
                        text={el.fields.label?.fields.text}
                      />
                    );
                  }

                  return;
                })}
              </Flex>
            </Flex>
          );
        })}
      </SimpleGrid>
    </Container>
  ) : null;
};

export default CustomerServiceGrid;
