import React, { createContext, useContext, useEffect, useState } from 'react';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';

type ICountryContext = { [key: string]: string }[];

export const CountryContext = createContext<ICountryContext>([]);

const useCountries = (): ICountryContext => {
  return useContext(CountryContext);
};

export const CountryProvider = ({
  children,
}: {
  locale: string;
  children: React.ReactNode;
}) => {
  const [value, set] = useState([]);

  useEffect(() => {
    NoozNextApi.instance({
      ...NOOZ_NEXT_REGISTRY.SHOPIFY.COUNTRIES,
    })
      .then((res) => {
        set(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <CountryContext.Provider value={value}>{children}</CountryContext.Provider>
  );
};
export default useCountries;
