import { createStyles, Text, TitleProps } from '@mantine/core';
import React, { useMemo } from 'react';
import Size from '@/helpers/Size';
import { NoozTextProps } from '@/interface.custom';
import { ILabelFields } from '@/@types/generated/contentful';

export const weightFromCf = (labelFields: ILabelFields) => {
  switch (labelFields.weight) {
    case 'light':
      return 'lighter';
    case 'black':
      return 'bolder';
    default:
      return labelFields.weight;
  }
};

const NoozText = (props: NoozTextProps) => {
  let defaultedProps = useMemo<TitleProps>(
    () => ({
      ...props,
      size: Size.convertFromCf(props.size || 14),
    }),
    [props],
  );

  if (props.family) {
    defaultedProps = {
      ...defaultedProps,
      sx: { fontFamily: props.family, ...props.sx },
    };
  }

  if (props.text) {
    return <Text {...defaultedProps}>{props.text}</Text>;
  }

  return <Text {...defaultedProps} />;
};

const useClasses = (props: { size?: string; sizeMobile?: string }) =>
  createStyles(
    (theme, { size, sizeMobile }: { size?: string; sizeMobile?: string }) => {
      return {
        label: {
          fontSize: size ? `${size}px` : undefined,
          [theme.fn.smallerThan('md')]: {
            fontSize: sizeMobile ? `${sizeMobile}px` : undefined,
          },
        },
      };
    },
  )(props).classes;

export const ShopstoryNoozText = (props: any) => {
  const classes = useClasses({
    size: props.label?.fields.size,
    sizeMobile: props.label?.fields.sizeMobile,
  });
  return (
    <NoozText
      {...props.label?.fields}
      className={classes.label}
    />
  );
};

export const ShopstoryNoozTitle = (props: any) => {
  const classes = useClasses({
    size: '42',
    sizeMobile: '28',
  });
  return (
    <NoozText
      {...props.label?.fields}
      className={classes.label}
    />
  );
};

export const ShopStoryTextProps = (
  fields: ILabelFields,
  props: NoozTextProps = {},
): NoozTextProps => ({
  weight: weightFromCf(fields),
  italic: fields.italic,
  family: fields.family,
  color: fields.color?.fields.classname,
  sx: (theme) => ({
    fontSize: `${(() => {
      try {
        // @ts-ignore
        return parseInt(fields.size);
      } catch (e) {
        return 52;
      }
    })()}px`,
    [theme.fn.smallerThan('sm')]: {
      fontSize: `${(() => {
        try {
          // @ts-ignore
          return parseInt(fields.mobileSize);
        } catch (e) {
          return 24;
        }
      })()}px`,
    },
  }),
  ...props,
});

export default NoozText;
