import React from 'react';
import { Card, createStyles, Image, Flex, Box } from '@mantine/core';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { ProductCardUiProps } from '@/interface.custom';

const useStyles = createStyles((theme) => ({
  card: {
    padding: 0,
    height: '100%',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    cursor: 'pointer',
  },
  productTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#2D2D2D',
  },
}));

const ProductCardSimple = React.memo(
  ({ label, variant }: ProductCardUiProps) => {
    const { classes } = useStyles();
    return (
      <Card
        radius='md'
        className={classes.card}>
        <Flex>
          <Image
            width={150}
            src={variant.posterImages[0] + '?fm=webp'}
            alt={variant.title}
          />
          <Box ml={20}>
            <NoozText className={classes.productTitle}>{label}</NoozText>
          </Box>
        </Flex>
      </Card>
    );
  },
);

export default ProductCardSimple;
