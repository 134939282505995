import {
  ColProps,
  Container,
  Grid,
  GridProps,
  MantineSize,
  MediaQuery,
} from '@mantine/core';
import React from 'react';
import { Carousel, CarouselProps } from '@mantine/carousel';
import { useContainerStyles } from '@/utils/style/useContainer';
import { CAROUSEL_PROPS } from '@/utils/style/carousel';

interface GridSliderProps {
  breakpoint?: MantineSize | number;
  Component: (props: any) => JSX.Element;
  items: any[];
  gridProps?: Omit<GridProps, 'children'>;
  sliderProps?: CarouselProps;
  colProps?: ColProps;
  gap?: number;
}

const GridSlider = ({
  breakpoint = 'md',
  items,
  Component,
  gridProps,
  sliderProps,
  colProps,
  gap = 30,
}: GridSliderProps) => {
  const containerStyles = useContainerStyles();

  return (
    <>
      <MediaQuery
        largerThan={breakpoint}
        styles={{ display: 'none' }}>
        <Carousel
          {...CAROUSEL_PROPS}
          slideSize='90%'
          withControls={false}
          withIndicators={true}
          nextControlIcon={<></>}
          previousControlIcon={<></>}
          height={'100%'}
          {...sliderProps}>
          {items?.map((itemProps, k) => (
            <Carousel.Slide key={k}>
              <Component {...itemProps} />
            </Carousel.Slide>
          ))}
        </Carousel>
      </MediaQuery>
      <MediaQuery
        smallerThan={breakpoint}
        styles={{ display: 'none' }}>
        <Container className={containerStyles.classes.container}>
          <Grid
            gutter={gap}
            ml={gap}
            mr={gap}
            {...gridProps}>
            {items?.map((itemProps, k) => (
              <Grid.Col
                key={k}
                {...colProps}>
                <Component {...itemProps} />
              </Grid.Col>
            ))}
          </Grid>
        </Container>
      </MediaQuery>
    </>
  );
};

export default GridSlider;
