import { useState, useCallback } from 'react';

type StorageType = 'local' | 'session';

export const useStorage = (
  storageType: StorageType,
  key: string,
  initialValue: any,
  toFormat?: any,
) => {
  const [value, set] = useState(() => {
    if (typeof window !== 'undefined') {
      let storedValue: string | null = null;
      try {
        const storage =
          storageType === 'local'
            ? window?.localStorage
            : window?.sessionStorage;
        storedValue = storage?.getItem(key);
      } catch (e) {
        console.error(e);
      }
      if (storedValue) storedValue = JSON.parse(storedValue);
      if (storedValue && toFormat) storedValue = toFormat(storedValue);
      return storedValue || initialValue;
    }
    return initialValue;
  });

  const setValue = useCallback((v: any) => {
    let fValue = v;
    if (toFormat) fValue = toFormat(fValue);
    set(fValue);
    if (typeof window !== 'undefined') {
      try {
        const storage =
          storageType === 'local'
            ? window?.localStorage
            : window?.sessionStorage;
        storage?.setItem(key, JSON.stringify(v));
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  return [value, setValue];
};
