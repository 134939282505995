import Head from 'next/head';
import React, { useMemo } from 'react';
import siteMetadata from '@/siteMetadata';
import SeoHelper from '@/helpers/SeoHelper';
import AppScripts from '../AppScripts/AppScripts';
import usePageContext from '@/utils/context/page';
import { useRouter } from 'next/router';

const AppHead = () => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    image: defaultImage,
    twitterUsername,
  } = siteMetadata;

  const router = useRouter();
  const { page, product, article, locale, localizedSlugs } = usePageContext();

  const seoMetadata = page?.fields.seoMetadata;

  const baseCanonicalUrl = (
    siteMetadata.siteUrl + (router.asPath === '/' ? '' : router.asPath)
  )?.split('?')[0];

  const returnSeoImage = () => {
    if (product?.fields.variants?.[0].fields?.images) {
      return product.fields.variants[0].fields.images[0].fields.media?.fields
        .file.url;
    }
    if (seoMetadata?.fields?.image?.fields?.file.url) {
      return seoMetadata?.fields?.image?.fields?.file.url;
    }
    return `${siteUrl}/${defaultImage}`;
  };

  const seo = useMemo<Record<string, any> | null>(() => {
    if (seoMetadata) {
      return {
        title: seoMetadata?.fields?.title || defaultTitle,
        description: seoMetadata?.fields?.description || defaultDescription,
        image: returnSeoImage(),
        url: `${siteUrl}`,
        twitterUsername,
      };
    }
    return null;
  }, [
    seoMetadata,
    defaultDescription,
    defaultTitle,
    defaultImage,
    siteUrl,
    twitterUsername,
  ]);

  const robots = useMemo(() => {
    if (page?.fields.robots) {
      return page?.fields.robots;
    }

    return 'all';
  }, [page]);

  return (
    <>
      <Head>
        {(seo?.title || article?.fields.label?.fields?.text) && (
          <title>
            {article?.fields.label?.fields?.text ||
              `${
                (page?.fields as unknown as Record<string, any>).pageType
                  ?.type === 'Product'
                  ? `${product?.fields.label?.fields.text} | `
                  : ''
              }${seo?.title}`}
          </title>
        )}
        {/* canonical link *!/*/}
        {SeoHelper.canonicalLink(
          // @ts-ignore
          page?.fields.canonical as Record<string, string>,
          baseCanonicalUrl,
          locale,
        )}
        {/* hrefLang links */}
        {SeoHelper.hrefLangLinks(localizedSlugs as Record<string, string>)}

        <meta
          name='viewport'
          content='width=device-width, initial-scale=1'
        />
        <link
          rel='icon'
          sizes={'32x32'}
          href='/favicon-32x32.ico'
        />
        <meta
          name='robots'
          content={robots}
        />
        {seo?.description && (
          <meta
            name='description'
            content={seo?.description}
          />
        )}

        {/* Open graph  */}
        {seo?.url && (
          <meta
            name='og:url'
            content={seo?.url}
          />
        )}
        {seo?.title && (
          <meta
            name='og:title'
            content={`${
              (page?.fields as unknown as Record<string, any>).pageType
                ?.type === 'Product'
                ? `${product?.fields.label?.fields.text} | Nooz Optics | `
                : ''
            }${seo?.title}`}
          />
        )}
        {seo?.description && (
          <meta
            name='og:description'
            content={seo?.description}
          />
        )}
        {seo?.title && (
          <meta
            name='og:image'
            content={seo?.image}
          />
        )}
        {seo?.title && (
          <meta
            name='og:type'
            content='website'
          />
        )}

        {/* Twitter card */}
        <meta
          name='twitter:card'
          content={seo?.image}
        />
        <meta
          name='twitter:image'
          content={seo?.image}
        />
        <meta
          name='twitter:image:src'
          content={seo?.image}
        />
        <meta
          name='twitter:title'
          content={seo?.title}
        />
        <meta
          name='twitter:creator'
          content={seo?.twitterUsername}
        />
        <meta
          name='twitter:description'
          content={seo?.description}
        />

        {/* prevent screen orientation */}
        <meta
          httpEquiv='ScreenOrientation'
          content='autoRotate:disabled'
        />
      </Head>
      <AppScripts />
    </>
  );
};

export default AppHead;
