import React, { memo } from 'react';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import { Badge, Box, clsx, Group, useMantineTheme } from '@mantine/core';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import {
  linearHotGradientText,
  useHighlightStyles,
} from '@/utils/style/useHighlightStyles';
import { useMediaQuery } from '@mantine/hooks';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import { ILabel } from '@/@types/generated/contentful';

export const SearchSuggestion = memo(
  ({ onSearch }: { onSearch?: (_str: string) => void }) => {
    const { layout } = usePageContext();
    const { search } = formatCfItems(layout?.fields.header);
    const { suggestedSearchesLabel, keywordList } = formatCfItems(search);
    const highLightColors = useHighlightStyles();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

    return !keywordList.fields.items ||
      keywordList.fields.items.length === 0 ? null : (
      <Box mb={40}>
        <Group
          align='center'
          mb={12}>
          <NoozText
            size={24}
            weight='bold'>
            {suggestedSearchesLabel.fields.text}
          </NoozText>
          <Badge
            size={'lg'}
            variant='filled'
            w='fit-content'>
            {keywordList.fields.items.length}
          </Badge>
        </Group>
        <Group>
          {keywordList.fields.items.map((kw: ILabel, idx: number) =>
            kw.fields.text ? (
              <NoozButton
                key={(kw as unknown as Record<string, string>).id}
                variant={'outline'}
                className={clsx(
                  idx === 0 ? highLightColors.classes.outlineButton : null,
                )}
                onClick={() => {
                  if (kw.fields.text) onSearch?.(kw.fields.text);
                }}
                color='black'
                size={isMobile ? 'sm' : 'md'}>
                <NoozText
                  fw={500}
                  style={idx === 0 ? linearHotGradientText : undefined}>
                  {kw.fields.text}
                </NoozText>
              </NoozButton>
            ) : null,
          )}
        </Group>
      </Box>
    );
  },
);
